import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import formStyle from "../../styles/Technician/TechnicianPromote.module.css";
import {
  promoteTechnician,
  technicianTenentLevels,
} from "../../services/technicianService";
import { useDispatch, useSelector } from "react-redux";
import SpinnerLoading from "../../components/SpinnerLoading";
import { isArray } from "util";
import Popup from "../../components/Popup";

interface FormData {
  tenant: string;
  level: string;
}

interface Props {
  onCancel: () => void;
  onSaveSuccess: () => void;
}

interface PromoteProps {
  singleTechnicianData: any;
  closeModal: () => void;
  reloadData?: () => void;
}

interface requestObj {
  technicianId: string;
  tanentId: string;
  technicianLevelId: string;
}

const TechnicianPromote: React.FC<PromoteProps> = ({
  singleTechnicianData,
  closeModal,
}) => {
  const dispatch = useDispatch<any>();
  var previousPromotionsData =
  singleTechnicianData && singleTechnicianData.technicianTanentLevel &&
    singleTechnicianData.technicianTanentLevel;

  const [previousPromotions, setPreviousPromotions] = useState<any>([]);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  useEffect(() => {
    if (Array.isArray(previousPromotionsData)) {
      const updatedPromotions = previousPromotionsData.map((promotion: any) => {
        const tName = (promotion.tanent && promotion.tanent.name) || "";
        const tLevel =
          (promotion.technicianLevel && promotion.technicianLevel.name) || "";
        const newObj = {
          tenant: tName,
          level: tLevel,
        };
        return newObj;
      });

      const uniquePromotions = new Set(
        previousPromotions.map(
          (promotion: any) => `${promotion.tenant}-${promotion.level}`
        )
      );
      const filteredUpdatedPromotions = updatedPromotions.filter(
        (promotion: any) => {
          return !uniquePromotions.has(
            `${promotion.tenant}-${promotion.level}`
          );
        }
      );
      setPreviousPromotions((prevPromotions: any) => [
        ...prevPromotions,
        ...filteredUpdatedPromotions,
      ]);
    }
  }, [previousPromotionsData]);

  const technicianTanants = useSelector(
    (state: any) => state.selectedTechnican.technicianTanants
  );
  const [formData, setFormData] = useState<FormData>({
    // Initialize form fields with default values
    tenant: "",
    level: "",
  });
  const [showSccessMsg, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const errorPromate = useSelector(
    (state: any) => state.selectedTechnican.error
  );
  const successPromote = useSelector(
    (state: any) => state.selectedTechnican.success
  );
  const loading = useSelector((state: any) => state.selectedTechnican.loading);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    changeMessages();
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const [errors, setErrors] = useState<Partial<FormData>>({});
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isValid = validateForm(); // Get validation result
    if (isValid) {
      const requestObj: requestObj = {
        technicianId: singleTechnicianData.id,
        tanentId: formData.tenant,
        technicianLevelId: formData.level,
      };
      dispatch(promoteTechnician(requestObj));
      setShowSuccessMessage(true);
      setShowErrorMessage(true);
      setShowPopup(true);
    } else {
      // Form has errors, display error messages
      console.log("Form submission failed due to errors:", errors);
    }
  };

  const changeMessages = () => {
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
  };

  const validateForm = () => {
    const newErrors: any = {};

    // Tenant
    if (!formData.tenant) {
      newErrors.tenant = "Please select a tenant.";
    }

    // Level
    if (!formData.level) {
      newErrors.level = "Please select a level.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  if (loading) {
    return <SpinnerLoading />;
  }

  return (
    <div className="promote_container" style={{ padding: "1rem" }}>
      {showSccessMsg && successPromote && (
        <Popup
          show={showPopup}
          onHide={() => {
            setShowPopup(false);
            closeModal();
          }}
          item={"success"}
          actionButton={false}
          modaltitle={"Success!"}
          content={"Record Successfully Updated!"}
        />
      )}
      {errorPromate && showErrorMessage && (
        <div
          style={{ width: "auto", display: "flex", justifyContent: "flex-end" }}
        >
          <div
            className="error-message-submit"
            style={{ height: "50px", padding: "2px" }}
          >
            {errorPromate}
          </div>
        </div>
      )}
      <Form onSubmit={handleSubmit}>
        <div className="promote-header" style={{ marginBottom: "2rem" }}>
          <Form.Label className={formStyle.formlabelStyles}>
            Select Levels
          </Form.Label>
          <div style={{ display: "flex" }}>
            <Form.Group controlId="formTanent" style={{ width: "100%" }}>
              <Form.Control
                as="select"
                name="tenant"
                value={formData.tenant}
                onChange={handleInputChange}
              >
                <option value="">Tenant</option>
                {technicianTanants &&
                  technicianTanants.length > 0 &&
                  technicianTanants
                    .slice() // Make a copy of the array to avoid mutating the original array
                    .sort((a: any, b: any) => a.name.localeCompare(b.name)) // Sort the array alphabetically by the 'name' property
                    .map((item: any) => (
                      <option key={item.tanent_id} value={item.tanent_id}>
                        {item.name}
                      </option>
                    ))}
              </Form.Control>
              {errors.tenant && (
                <span className={formStyle.inputerrorMessage}>
                  {errors.tenant}
                </span>
              )}
            </Form.Group>
            <Form.Group controlId="formLevel" style={{ width: "100%" }}>
              <Form.Control
                as="select"
                name="level"
                value={formData.level}
                onChange={handleInputChange}
              >
                <option value="">Level</option>
                {formData.tenant &&
                  technicianTanants &&
                  technicianTanants.length > 0 &&
                  technicianTanants
                    .find((item: any) => item.tanent_id === formData.tenant)
                    ?.levelCriterias.slice() // Make a copy of the array to avoid mutating the original array
                    .sort((a: any, b: any) => a.level.rank - b.level.rank)
                    .map((item: any) => (
                      <option key={item.level.id} value={item.level.id}>
                        {item.level.name}
                      </option>
                    ))}
              </Form.Control>
              {errors.level && (
                <span className={formStyle.inputerrorMessage}>
                  {errors.level}
                </span>
              )}
            </Form.Group>
          </div>
        </div>
        {/* Display previous promotions */}
        {previousPromotions &&
          previousPromotions.length > 0 &&
          previousPromotions.map((promotion: any, index: number) => (
            <div className="promote-body">
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderRadius: "0.5rem",
                  border: "1px solid #ccc",
                  padding: "0.5rem",
                  marginTop: "10px",
                }}
              >
                <div>
                  <b>{promotion.tenant}</b>
                </div>
                <div>
                  <b>{promotion.level}</b>
                </div>
              </div>
            </div>
          ))}
        <div className={formStyle.promote_footer}>
          <button className={formStyle.pp_promote_button} type="submit">
            Promote | Demote
          </button>
        </div>
      </Form>
    </div>
  );
};

export default TechnicianPromote;
