import { BASE_URL } from '../constants/appConstants';
import { fetchWithToken } from '../utils/utils';

export const fetchProductTypes = async () => {
  try {
    const end_url = BASE_URL + "/product-types?page=0&size=0";
    const response = await fetchWithToken(end_url);
    if (!response.ok) {
      throw new Error("Failed to fetch product types");
    }

    const data = await response.json();
    return data.results;
  } catch (error) {
    throw new Error("Failed to fetch product types");
  }
};

export const fetchProducts = async (productType:string) => {
  try {
    const end_url = BASE_URL + "/products/product-types/"+productType+"?page=0&size=0";
    const response = await fetchWithToken(end_url);
    
    if (!response.ok) {
      throw new Error("Failed to fetch product types");
    }

    const data = await response.json();
    return data.results;
  } catch (error) {
    throw new Error("Failed to fetch product types");
  }
};

export const fetchProductsByJobTypes = async (jobID:string) => {
  try {
    const end_url = BASE_URL + "/products/job-types/" +jobID ;
    const response = await fetchWithToken(end_url);
    
    if (!response.ok) {
      throw new Error("Failed to fetch product types");
    }

    const data = await response.json();
    return data.results;
  } catch (error) {
    throw new Error("Failed to fetch product types");
  }
};
