// workRequestSlice.js

import { createSlice } from "@reduxjs/toolkit";

const center = {
  lat: 7.2905715,
  lng: 80.6337262,
};

export const workRequestSlice = createSlice({
  name: "workRequestCreate",
  initialState: {
    formData: {
      nic: "",
      date: "",
      budget: 0,
      workDescription: "",
      address: "",
      district: "",
      city: "",
      jobType: [],
      selectedJobTypes: [],
      latitude: center.lat,
      longitude: center.lng,
      workRequestImageUrls: [],
      selectedImages: [],
      workImages: [],
    },
  },
  reducers: {
    updateFormData: (state, action) => {
      console.log("updateFormData work request data", action.payload)
      state.formData = action.payload;
    },
    clearFormData: (state, action) => {
      state.formData = {
        nic: "",
        date: "",
        budget: 0,
        workDescription: "",
        address: "",
        district: "",
        city: "",
        jobType: [],
        selectedJobTypes: [],
        latitude: 0,
        longitude: 0,
        workRequestImageUrls: [],
        selectedImages: [],
        workImages: [],
      };
    },
  },
});

export const { updateFormData, clearFormData } = workRequestSlice.actions;

export default workRequestSlice.reducer;
