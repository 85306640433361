import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";

interface PieChartProps {
  dashboardPieChartData: any;
}
const CustomPieChart: React.FC<PieChartProps> = ({ dashboardPieChartData }) => {
  const COLORS = ["#5055C9", "#00C49F", "#FFBB28", "#FF8042", "#E45E56"];

  const data = [
    {
      name: "Completed",
      value: dashboardPieChartData && parseInt(dashboardPieChartData.completed),
    },
    {
      name: "InProgress",
      value: dashboardPieChartData && parseInt(dashboardPieChartData.inProgress),
    },
    {
      name: "InReview",
      value: dashboardPieChartData && parseInt(dashboardPieChartData.inReview),
    },
    {
      name: "OnHold",
      value: dashboardPieChartData && parseInt(dashboardPieChartData.onHold),
    },
    {
      name: "Rejected",
      value: dashboardPieChartData && parseInt(dashboardPieChartData.rejected),
    },
  ];

  return (
    <PieChart
      width={window.innerWidth > 1536 ? 500 : window.innerWidth < 1500 ? 280: 350}
      height={window.innerWidth > 1536 ? 350 : window.innerWidth < 1500 ? 210: 220}
    >
      <Pie
        data={data}
        cx={window.innerWidth > 1536 ? 300 : window.innerWidth < 1500 ? 180: 250}
        cy={window.innerWidth > 1536 ? 130 : 80}
        innerRadius={0}
        outerRadius={window.innerWidth > 1536 ? 105: 80}
        fill="#8884d8"
        paddingAngle={0}
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
      <Legend
        width={window.innerWidth > 1536 ? 600 : window.innerWidth < 1500 ? 350: 460}
        // height={window.innerWidth > 1536 ? 0 : 180}
      />
    </PieChart>
  );
};

export default CustomPieChart;
