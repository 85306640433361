import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "../../components/Button";
import "../../styles/EditJobTypeFrom.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { getProductTypes } from "../../services/productService";
import { editJobType } from "../../services/jobTypeService";
import { setJobTypesLoading } from "../../features/JobTypeSlice";
import { extractIds } from "../../utils/utils";
import SpinnerLoading from "../../components/SpinnerLoading";
import Popup from "../../components/Popup";

interface JobTypeData {
  id: string;
  type: string;
  description: string;
  productTypes: any;
  status: string;
}

interface Props {
  jobTypeData: JobTypeData;
  onCancel: () => void;
}

interface Product {
  id: number;
  name: string;
}

const EditJobTypeForm: React.FC<Props> = ({ jobTypeData, onCancel }) => {
  const dispatch = useDispatch<any>();
  const [jobType, setJobType] = useState(jobTypeData.type);
  const [description, setDescription] = useState(jobTypeData.description);
  const [selectedProducts, setSelectedProducts] = useState(
    jobTypeData.productTypes
  );
  const [submitProducts, setSubmitProducts] = useState<string[]>([]);
  const [jobStatus, setJobStatus] = useState(jobTypeData.status);
  const [showSccessMsg, setShowSuccessMessage] = useState(false);
  const [showMinus, setShowMinus] = useState(true);
  const [wordCount, setWordCount] = useState(0);
  const [jobTypeError, setJobTypeError] = useState<string | null>(null);
  const [descriptionError, setDescriptionError] = useState<string | null>(null);
  const [jobStatusError, setJobStatusError] = useState<string | null>(null);
  const [productTypeError, setProductTypeError] = useState<string | null>(null);
  const [isFormModified, setIsFormModified] = useState(false);
  const [numProductTypeDropdowns, setNumProductTypeDropdowns] = useState(1);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const productTypesBe = useSelector(
    (state: any) => state.productTypes.productTypes
  );
  const errorJobTypes = useSelector((state: any) => state.jobTypes.error);
  const successJobTypes = useSelector((state: any) => state.jobTypes.success);
  const loading = useSelector((state: any) => state.jobTypes.loading);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getProductTypes());
  }, [dispatch]);

  useEffect(() => {
    if (
      jobType !== jobTypeData.type ||
      description !== jobTypeData.description ||
      JSON.stringify(selectedProducts) !==
        JSON.stringify(jobTypeData.productTypes) ||
      jobStatus !== jobTypeData.status
    ) {
      setIsFormModified(true);
    } else {
      setIsFormModified(false);
    }
  }, [jobType, description, jobStatus, selectedProducts]);

  useEffect(() => {
    if (selectedProducts.length > 1) {
      selectedProducts.forEach((element: any) => {
        setNumProductTypeDropdowns(selectedProducts.length);
      });
    }
    const idsArray = extractIds(selectedProducts);
    setSubmitProducts(idsArray);
  }, []);

  const clearInputs = () => {
    if (errorJobTypes || successJobTypes) {
      dispatch(setJobTypesLoading(true));
      changeMessages();
    }
  };

  useEffect(() => {
    clearInputs();
  }, []);

  const changeMessages = () => {
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
    setJobTypeError(null);
    setDescriptionError(null);
    setJobStatusError(null);
    setProductTypeError(null);
  };

  const addProductTypeDropdown = () => {
    setShowMinus(true);
    setNumProductTypeDropdowns(numProductTypeDropdowns + 1);
    setSubmitProducts((prevSelected) => [...prevSelected, ""]);
  };

  const deleteProductTypeDropdown = (indexToDelete: number) => {
    if (numProductTypeDropdowns !== 1) {
      setShowMinus(numProductTypeDropdowns > 1);
      setNumProductTypeDropdowns(numProductTypeDropdowns - 1);
      setIsFormModified(true);

      setSelectedProducts((prevSelected: any) =>
        prevSelected.filter((_: any, index: number) => index !== indexToDelete)
      );

      const updatedSubmitProducts = submitProducts.filter(
        (_, index) => index !== indexToDelete
      );
      setSubmitProducts(updatedSubmitProducts);
    }
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const text = event.target.value;
    setDescription(text);
    const count = text.length;
    setWordCount(count);
    setDescriptionError(null);
    changeMessages();
    if (count > 300) {
      setDescriptionError("Description not exceed 300 words.");
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Perform validation
    let isValid = true;

    if (!jobType) {
      setJobTypeError("Job type is required");
      isValid = false;
    }

    if (!description) {
      setDescriptionError("Description is required");
      isValid = false;
    }

    if (descriptionError) {
      setDescriptionError("Description not exceed 300 words.");
      isValid = false;
    }

    if (selectedProducts.length === 0) {
      setProductTypeError("Product type is required");
      isValid = false;
    }
    if (jobStatus === null) {
      setJobStatusError("Job status is required");
      isValid = false;
    }

    if (isValid) {
      const nonEmptySubmitProducts = submitProducts.filter(
        (item) => item !== ""
      );
      console.log("Form submitted with data:", {
        jobType,
        description,
        jobStatus,
        nonEmptySubmitProducts,
      });
      const requestObj: any = {
        type: jobType,
        description: description,
        productTypeIds: nonEmptySubmitProducts,
        status: parseInt(jobStatus),
      };
      dispatch(editJobType(jobTypeData.id, requestObj));
      setShowSuccessMessage(true);
      setShowErrorMessage(true);
      setShowPopup(true);
    }
  };

  if (loading) {
    return <SpinnerLoading />;
  }

  return (
    <>
      {showSccessMsg && successJobTypes && (
        <Popup
          show={showPopup}
          onHide={() => {
            setShowPopup(false);
            onCancel();
          }}
          actionButton={false}
          item={"success"}
          modaltitle={"Success!"}
          content={"Record Successfully Updated!"}
        />
      )}
      {errorJobTypes && showErrorMessage && (
        <Popup
          show={showPopup}
          onHide={() => {
            setShowPopup(false);
            changeMessages();
          }}
          actionButton={false}
          modaltitle={"Error!"}
          content={errorJobTypes}
        />
      )}
      <Form onSubmit={handleSubmit}>
        <div className="edit-job-type-title">Edit Technician Job Type</div>
        <Form.Group controlId="jobType" className="mb-3 left-fields">
          <Form.Label>Job Type</Form.Label>
          <Form.Control
            type="text"
            value={jobType}
            onChange={(e) => {
              setJobType(e.target.value);
              setJobTypeError(null);
              changeMessages();
            }}
          />
          {jobTypeError && <p className="error-message">{jobTypeError}</p>}
        </Form.Group>
        <Form.Group controlId="productType" className="mb-3 right-fields">
          <Form.Label className="right-type-label">Product Type </Form.Label>
          <div className="circular-icon" onClick={addProductTypeDropdown}>
            <FontAwesomeIcon
              icon={faPlus}
              style={{ fontSize: "1rem", color: "white", cursor: "pointer" }}
            />
          </div>
          {[...Array(numProductTypeDropdowns)].map((_, index) => (
            <div key={index} className="dropdown-container">
              <div className="dropdown-wrapper">
                <Form.Select
                  as="select"
                  value={
                    selectedProducts[index] && selectedProducts[index].id
                      ? selectedProducts[index].id
                      : ""
                  }
                  onChange={(e: any) => {
                    const productId = e.target.value;
                    changeMessages();

                    // Update the list of selected product IDs
                    const updatedSubmitProducts = [...submitProducts];
                    if (!updatedSubmitProducts.includes(productId)) {
                      updatedSubmitProducts.push(productId);
                      setSubmitProducts(updatedSubmitProducts);
                    }
                    if (
                      selectedProducts[index] &&
                      selectedProducts[index].id &&
                      selectedProducts[index].id !== productId
                    ) {
                      const newArray = updatedSubmitProducts.filter(
                        (item) => item !== selectedProducts[index].id
                      );
                      setSubmitProducts(newArray);
                    }

                    // Update the list of selected products
                    const updatedSelectedProducts = [...selectedProducts];
                    const product = productTypesBe.find(
                      (product: any) => product.id === productId
                    );
                    if (product) {
                      updatedSelectedProducts[index] = product;
                      setSelectedProducts(updatedSelectedProducts);
                      setProductTypeError(null);
                    }
                  }}
                >
                  <option value="">
                    {selectedProducts[index] && selectedProducts[index].name
                      ? selectedProducts[index].name
                      : "Select product type"}
                  </option>
                  {productTypesBe.map(
                    (product: any) =>
                      // Exclude already selected products from the options
                      !selectedProducts.some(
                        (selectedProduct: any) =>
                          selectedProduct && selectedProduct.id === product.id
                      ) && (
                        <option key={product.id} value={product.id}>
                          {product.name}
                        </option>
                      )
                  )}
                </Form.Select>
                {numProductTypeDropdowns > 1 && ( // Conditionally render minus circle icon
                  <div
                    className="circular-icon minus"
                    onClick={() => deleteProductTypeDropdown(index)}
                  >
                    <FontAwesomeIcon
                      icon={faMinus}
                      style={{
                        fontSize: "1rem",
                        color: "white",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                )}
                {productTypeError && (
                  <p className="error-message">{productTypeError}</p>
                )}
              </div>
            </div>
          ))}
        </Form.Group>
        <Form.Group controlId="description" className="mb-3 left-fields">
          <Form.Label>Job Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            value={description}
            onChange={handleDescriptionChange}
          />
          <p>{wordCount}/300</p>
          {descriptionError && (
            <p className="error-message">{descriptionError}</p>
          )}
        </Form.Group>
        <Form.Group controlId="jobStatus" className="mb-3 right-fields">
          <Form.Label className="right-type-label">Job Status</Form.Label>
          <Form.Select
            as="select"
            value={jobStatus}
            onChange={(e: any) => {
              setJobStatus(e.target.value);
              setJobStatusError(null);
              changeMessages();
            }}
          >
            <option value={""}>Select job status</option>
            <option value={"1"}>Active</option>
            <option value={"0"}>Inactive</option>
          </Form.Select>
          {jobStatusError && <p className="error-message">{jobStatusError}</p>}
        </Form.Group>
        <div className="button-container">
          <Button
            style={{
              width: "25vh",
              backgroundColor: "black",
              marginRight: "2vh",
            }}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            style={{ width: "25vh", backgroundColor: "#FFC600" }}
            type="submit"
            disabledButton={!isFormModified}
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};

export default EditJobTypeForm;
