import React from 'react'
import WorkRequestComponent from './WorkRequestComponent'


interface Props {
  onCancel: () => void;
  openProductList: (items:any) => void;
}


const WorkRequest: React.FC<Props> = ({ onCancel , openProductList}) => {
  return (
    <WorkRequestComponent onCancel={onCancel} openProductList={openProductList}></WorkRequestComponent>
  )
}

export default WorkRequest