import { BASE_URL } from '../constants/appConstants';
import { fetchWithToken } from '../utils/utils';

export const fetchTechLevels = async () => {
  try {
    const end_url = BASE_URL + "/technician-levels?page=0&size=0";
    const response = await fetchWithToken(end_url);
    if (!response.ok) {
      throw new Error('Failed to fetch job types');
    }
    const techLevels = await response.json();
    return techLevels.results;
  } catch (error) {
    throw new Error('Failed to fetch job types');
  }
};

export const createTechLevel = async (technicalLevelData: any) => {
  try {
    const end_url = BASE_URL + "/technician-levels";
    const response = await fetchWithToken(end_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(technicalLevelData),
    });
    const newTechLevel = await response.json();
    return newTechLevel;
  } catch (error) {
    throw new Error('Failed to create job type');
  }
};

export const updateTechLevel = async (technicalLevelId: string, updatedData: any) => {
  try {
    const end_url = `${BASE_URL}/technician-levels/${technicalLevelId}`;
    const response = await fetchWithToken(end_url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedData),
    });
    const updatedTechLevel = await response.json();
    return updatedTechLevel;
  } catch (error) {
    throw new Error('Failed to update job type');
  }
};

