import { BASE_URL } from "../constants/appConstants";
import { LogoutUser } from "../services/authService";

export const getAccessTokenFromStorage = () => {
  return localStorage.getItem("solidClubAccessToken") || "";
};

export const fetchWithToken = async (url: string, options?: RequestInit) => {
  try {
    const response = await fetch(url, {
      ...options,
      headers: {
        ...(options?.headers || {}),
        "x-auth-token": getAccessTokenFromStorage(),
       
      },
    });
    if (!response.ok) {
     console.log("token access token expired or wrong or api issue",getAccessTokenFromStorage(),response);
    }
    return response;
  } catch (error) {
    throw new Error(`Failed to fetch data from ${url}`);
  }
};

export const validateEmail = (input: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(input);
};

export const extractIds = (selectedProducts: { id: string }[]): string[] => {
  return selectedProducts.map((product) => product.id);
};

export const convertToImageFile = async (
  profileImageUrl: any
): Promise<File> => {
  const response = await fetch(profileImageUrl.url);
  const blob = await response.blob();
  const file = new File([blob], `${profileImageUrl.id}.jpg`, {
    type: "image/jpeg",
  });
  return file;
};

export const uploadImage = async (imageFile: File) => {
  try {
    const formData = new FormData();
    formData.append("file", imageFile);
    const response = await fetchWithToken(`${BASE_URL}/files/images`, {
      method: "POST",
      body: formData,
    });

    const data = await response.json();
    return data; // Return the URL of the uploaded image
  } catch (error) {
    throw new Error("Failed to upload image");
  }
};

export const uploadImageMuliple = async (imageFileArray: any) => {
  try {
    
    if (imageFileArray && imageFileArray[0]) {
      const formData = new FormData();
      for (let i = 0; i < imageFileArray[0].length; i++) {
        const singleFile = imageFileArray[0][i];
        formData.append("file", singleFile);
      }

      const response = await fetchWithToken(`${BASE_URL}/files/images-multi`, {
        method: "POST",
        body: formData,
      });
      const data = await response;
      return data;
    }
  } catch (error) {
    throw new Error("Failed to upload image");
  }
};

export const datMapping: any = {
  accepted: {
    title: "Verify Acceptance",
    actionButton: "Accept",
    subtitle: "Do you want to accept this work request? ",
    content: "you cannot decline the work request after you accepted it.",
    successMsg: "You have accepted the work request. Good Luck!",
  },
  rejected: {
    title: "Reason for Rejecting",
    actionButton: "Submit",
    subtitle: "Tell us why are you rejecting the work request?",
    content: "",
    successMsg: "You have rejected the work request.",
  },
  "before-rejected" : {
    title: "Verify Rejection",
    actionButton: "Reject",
    subtitle: "Do you want to reject this work request?",
    content: "you cannot accept the work request after you rejected it.",
    successMsg: "You have rejected the work request.",
  },
  "on-hold": {
    title: "Work request On-Hold",
    actionButton: "Submit",
    subtitle: "You have marked the work request as On-Hold, Please enter your reason.",
    content: "",
    successMsg: "You have hold the work request.",
  },
  "on-hold-before": {
    title: "Verify On-Hold",
    actionButton: "Hold Request",
    subtitle: "Do you want to hold this work request?",
    content: "Your work request’s progress will be On-Hold.",
    successMsg: "You have hold the work request.",
  },
  completed: {
    title: "Verify Completion",
    actionButton: "Complete",
    subtitle: "Do you want to mark this as completed?",
    content: "Once you have mark this work request as completed you will not be able to change it again.",
    successMsg: "Congratulations! on finishing the work request now it’s time to give a feedback for your consumer.",
  },
  resume: {
    title: "Continue Work Request",
    actionButton: "Continue",
    subtitle: "Do you want to continue this work request?",
    content: "After continue the work request you have to complete the work.",
    successMsg: "You are now continuing your On-Hold work request.",
  },
};
