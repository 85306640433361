import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Tenant } from "../type"; // Assuming you have a Tenant type defined

interface TenantsState {
  tenants: Tenant[];
  loading: boolean;
  error: string | null;
}

const initialState: TenantsState = {
  tenants: [],
  loading: false,
  error: null,
};

const tenantsSlice = createSlice({
  name: "tenants",
  initialState,
  reducers: {
    setTenants(state, action: PayloadAction<Tenant[]>) {
      state.tenants = action.payload;
      state.loading = false;
      state.error = null;
    },
    setTenantsLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
      state.error = null;
    },
    setTenantsError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setTenants, setTenantsLoading, setTenantsError } = tenantsSlice.actions;

export default tenantsSlice.reducer;
