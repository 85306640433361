import React, { useState } from "react";
import { Form } from "react-bootstrap";
import formStyle from "../../styles/Technician/TechnicianPromote.module.css";
import { useDispatch, useSelector } from "react-redux";
import SpinnerLoading from "../../components/SpinnerLoading";
import Popup from "../../components/Popup";
import { downgradeTechnicianService } from "../../services/technicianService";

interface DowngradeProps {
  closeModal: () => void;
  singleTechnicianData: any;
}
interface requestObj {
  technicianId: string;
  downgradeTechnician: any;
}

interface FormData {
  downgradeValue:any;
  description: string;
}

const TechnicianDowngrade: React.FC<DowngradeProps> = ({
  closeModal,
  singleTechnicianData,
}) => {
  const dispatch = useDispatch<any>();
  const [downgradeValue, setDowngradeValue] = useState<number>(0);
  const [showSccessMsg, setShowSuccessMessage] = useState(false);
  const [description, setDescription] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [wordCount, setWordCount] = useState(0);
  const [errors, setErrors] = useState<Partial<FormData>>({});
  const [descriptionError, setDescriptionError] = useState<string | null>(null);
  const errorDowngrade = useSelector(
    (state: any) => state.selectedTechnican.error
  );

  const successDowngrade = useSelector(
    (state: any) => state.selectedTechnican.success
  );
  const loading = useSelector((state: any) => state.selectedTechnican.loading);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDowngradeValue(parseFloat(e.target.value));
    changeMessages();
  };

  const validateForm = () => {
    const newErrors: Partial<FormData> = {};
    if (!downgradeValue) {
      newErrors.downgradeValue = "Please enter a rating count.";
    }

    if (!description) {
      newErrors.description = "Please enter the reason.";
    }

    if(descriptionError) {
      newErrors.description = "Reason not exceed 200 words.";
    } 
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isValid = validateForm(); // Get validation result
    if (isValid) {
      const requestObj: requestObj = {
        technicianId: singleTechnicianData.id,
        downgradeTechnician: {
          downgradeValue: downgradeValue,
          downgradeReason: description,
        },
      };
      dispatch(downgradeTechnicianService(requestObj));
      setShowSuccessMessage(true);
      setShowErrorMessage(true);
      setShowPopup(true);
    } else {
      // Form has errors, display error messages
      console.log("Form submission failed due to errors:", errors);
    }
  };

  const changeMessages = () => {
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const text = event.target.value;
    setDescription(text);
    const count = text.length;
    setWordCount(count);
    setDescriptionError(null);
    changeMessages();
    if (count > 200) {
      setDescriptionError("Reason not exceed 200 words.");
    }
  };

  if (loading) {
    return <SpinnerLoading />;
  }

  return (
    <div
      className="downgrade_container"
      style={{
        paddingTop: "0",
        paddingBottom: "1rem",
        paddingLeft: "2rem",
        paddingRight: "2rem",
      }}
    >
      {showSccessMsg && successDowngrade && (
        <Popup
          show={showPopup}
          onHide={() => {
            setShowPopup(false);
            closeModal();
          }}
          item={"success"}
          actionButton={false}
          modaltitle={"Success!"}
          content={"Record Successfully Updated!"}
        />
      )}
      {errorDowngrade && showErrorMessage && (
        <div
          style={{ width: "auto", display: "flex", justifyContent: "flex-end" }}
        >
          <div
            className="error-message-submit"
            style={{ height: "50px", padding: "10px" , borderRadius : "10px", alignItems: "center"}}
          >
            {errorDowngrade}
          </div>
        </div>
      )}
      <Form onSubmit={handleSubmit}>
        <div className="downgrade-header">
          <Form.Label className={formStyle.formlabelStyles}>
            Select rating count
          </Form.Label>
          <div style={{ display: "flex" }}>
            <Form.Control
              type="number"
              value={downgradeValue}
              onChange={handleInputChange}
              step="0.1"
              min="0"
            />
          </div>
          {errors.downgradeValue && (
            <span className={formStyle.inputerrorMessage}>
              {errors.downgradeValue}
            </span>
          )}
          <Form.Label className={formStyle.formlabelStyles}>Reason</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Type your reason here"
            value={description}
            onChange={handleDescriptionChange}
          />
          <p>{wordCount}/200</p>

        </div>
        {errors.description  && (
          <span className={formStyle.inputerrorMessage}>
            {errors.description}
          </span>
        )}
        
        <div className={formStyle.downgrade_footer}>
          <button className={formStyle.pp_downgrade_button} type="submit">
            Save
          </button>
        </div>
      </Form>
    </div>
  );
};

export default TechnicianDowngrade;
