import React, { useEffect, useState } from "react";
import Button from "../components/Button";
import Modal from "react-bootstrap/Modal";
import "../styles/ActionPopup.scss";
import { Form } from "react-bootstrap";

interface ActionPopupProps {
  show: boolean;
  onHide?: () => void;
  modaltitle: string | null;
  modalSubTitle?: string | null;
  content?: string | null;
  actionButton?: string | null;
  actionMethod?: (reason?: string) => void;
  imgIcon?: any;
  item?: string;
  inputItem?: boolean;
  displayDiv?:any;
}

const ActionPopup: React.FC<ActionPopupProps> = (props) => {
  const [reason, setReason] = useState("");
  const [modalHeight, setModalHeight] = useState("auto");

  useEffect(() => {
    function handleResize() {
      // Adjust height based on screen width
      if (window.innerWidth >= 1700) {
        setModalHeight("40rem");
      } else {
        setModalHeight("40rem"); 
      }
    }

    window.addEventListener("resize", handleResize);

    // Call handleResize to set initial height
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Modal
      {...props}
      size="sm"
      centered
      backdrop="static"
      className="success-modal"
       style={{ height: modalHeight }}
    >
      <Modal.Header
        style={{
          border: "none",
          fontSize: "14px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {props.item === "success" ? (
          <Modal.Title className="modal-title-success">
            {props.modaltitle}
          </Modal.Title>
        ) : (
          <div className="modal-title-normal">{props.modaltitle}</div>
        )}
      </Modal.Header>
      <Modal.Body className="modal-body" style={{ border: "none" , marginTop : "-10px"}}>
        <div className="modal-body-sub">
          {props.modalSubTitle !== "" && props.modalSubTitle}
        </div>
        <p className="modal-body-content">{props.content !== "" && props.content}</p>
        {props.imgIcon}
        {props.displayDiv  && (
          props.displayDiv
        )}
        {props.inputItem && (
          <Form.Control
            as="textarea"
            rows={4}
            value={reason}
            placeholder={props.item === "rate" ? "Tell us about the employer": "Type your reason"}
            onChange={(e) => setReason(e.target.value)}
          />
        )}
        
      </Modal.Body>
      <Modal.Footer style={{ border: "none" }}>
        {props.actionButton ? (
          <Button
            style={{
              justifyContent: "flex-start",
              alignSelf: "flex-start",
              width: "100%",
              height: "50px",
              marginBottom: "10px",
            }}
            onClick={() => props.actionMethod && props.actionMethod(reason)}
            disabledButton={props.inputItem && reason.trim() === ""}
          >
            {props.actionButton}
          </Button>
        ) : null}
        {props.item === "success" ? (
          <Button
            style={{
              backgroundColor: "green",
              color: "#FFFFFF",
              justifyContent: "flex-start",
              alignSelf: "flex-start",
              width: "100%",
              height: "50px",
              marginBottom: "10px",
            }}
            onClick={props.onHide}
          >
            Close
          </Button>
        ) : (
          props.onHide && (
            <Button
              style={{
                backgroundColor: "black",
                color: "#FFFFFF",
                justifyContent: "flex-start",
                alignSelf: "flex-start",
                width: "100%",
                height: "50px",
                marginBottom: "10px",
              }}
              onClick={props.onHide}
            >
              Cancel
            </Button>
          )
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ActionPopup;
