// import React from 'react';
// import { useSelector } from 'react-redux';
// import { Navigate, Outlet } from 'react-router-dom';

// const AuthRoute:  React.FC<
// { setLoggedIn: (status: boolean) => void }
// > = ({ setLoggedIn }) => {
//   const isAuthenticated = useSelector((state:any) => state.auth.solidUser);
//   if(!isAuthenticated){
//     setLoggedIn(false);
//   } 
//   return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
// }
// export default AuthRoute;


import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { LogoutUser } from '../services/authService';
import AuthVerify from '../utils/AuthVerify';

const AuthRoute: React.FC<{ setLoggedIn: (status: boolean) => void }> = ({ setLoggedIn }) => {
  const isAuthenticated = useSelector((state: any) => state.auth.solidUser);
  const accessToken = useSelector((state: any) => state.auth.accessToken);
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  
  useEffect(() => {
    const tokenValid = AuthVerify(); // Call AuthVerify and pass setLoggedIn function
    if (!tokenValid || !isAuthenticated) { // Corrected condition
      dispatch(LogoutUser());
      setLoggedIn(false);
    }
  }, [isAuthenticated, navigate, dispatch, setLoggedIn]);

  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
}

export default AuthRoute;
