
import { Dispatch } from "redux";
import { ConsumerCreate } from "../type";
import {
  setConsumers,
  setConsumersLoading,
  setConsumersError,
  addConsumersState,
  setSelectedConsumer,
  setConsumersFilters,
  editConsumerState,
  setConusmersCount
} from "../features/consumerSlice";
import {
  setWorkRequestsError,
  setWorkRequestsForConsumer
} from "../features/workRequestSlice";
import { fetchConsumerList, createConsumer, getConsumerById, searchConsumersByNIC, fetchWorkRequestsForConsumer, editConsumer, searchConsumers, filterConsumerAPI } from "../api/consumerAPI";

export const getConsumers = (page: number, items: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setConsumersLoading(true));
      const consumers = await fetchConsumerList(page, items);
      dispatch(setConsumersLoading(false));
      dispatch(setConsumers(consumers.results));
      dispatch(setConusmersCount(consumers.count));
    } catch (error) {
      console.error("Error fetching job types:", error);
      dispatch(
        setConsumersError("Failed to fetch job types. Please try again!")
      );
    }
  };
};

const convertToImageFile = async (profileImageUrl: any): Promise<File> => {
  // Fetch the image data
  const response = await fetch(profileImageUrl.url);
  // Get the blob from the response
  const blob = await response.blob();
  // Construct a File object using the blob
  const file = new File([blob], `${profileImageUrl.id}.jpg`, {
    type: "image/jpeg",
  });
  return file;
};

export const addConsumer = (consumerData: ConsumerCreate) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setConsumersLoading(true));
      const newConsumer = await createConsumer(consumerData);
      dispatch(setConsumersLoading(false)); // Reset loading state
      if (newConsumer.errorCode || !newConsumer.ok) {
        const newConsumerError = await newConsumer.json();
        const errormsg = newConsumerError.errorDescription ? newConsumerError.errorDescription : "Something went wrong, please try again!";
        dispatch(setConsumersError(errormsg));
      } else {
        dispatch(addConsumersState(newConsumer));
      }
    } catch (error) {
      console.error("Error creating consumere:", error);
      dispatch(
        setConsumersError("Failed to create consumer. Please try again!")
      );
    }
  };
};


// Action to fetch consumer by ID
export const getConsumerByIdAction = (consumerId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setConsumersLoading(true));
      const consumer = await getConsumerById(consumerId);
      dispatch(setConsumersLoading(false));
      dispatch(setSelectedConsumer(consumer)); // Set the selected consumer in the Redux store
    } catch (error) {
      console.error("Error fetching consumer details:", error);
      dispatch(
        setConsumersError("Failed to fetch consumer details. Please try again!")
      );
    }
  };
};

//search consumer by nic.
// export const searchConsumers = async (nic: string): Promise<any[]> => {
//   try {
//     const searchResults = await searchConsumersByNIC(nic);
//     return searchResults;
//   } catch (error) {
//     console.error("Error searching consumers by NIC:", error);
//     throw error;
//   }
// };

export const searchConsumersByNic = (nic: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setConsumersLoading(true));
      const searchResults = await searchConsumersByNIC(nic);
      dispatch(setConsumersFilters(searchResults));
      dispatch(setConsumersLoading(false));
      return searchResults;
    } catch (error) {
      console.error("Error searching consumers by NIC:", error);
      dispatch(
        setConsumersError("Failed to search consumers by NIC. Please try again!")
      );
      dispatch(setConsumersLoading(false));
    }
  };

};

//work request for consumer
export const getWorkRequestsForConsumer = (consumerId: any) => {
  return async (dispatch: Dispatch) => {
    try {

      const workRequests = await fetchWorkRequestsForConsumer(consumerId);

      dispatch(setWorkRequestsForConsumer(workRequests));
    } catch (error) {
      console.error("Error fetching work requests for the consumer:", error);
      dispatch(
        setWorkRequestsError("Failed to fetch work requests for the consumer. Please try again!")
      );
    }
  };
};


// Edit consumer details
export const updateConsumer = (consumerId: string, updatedConsumerDetails: any, profilePicChanged: boolean) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setConsumersLoading(true));
      // Add profileImageUrl property if it's available
      const editedConsumer = await editConsumer(consumerId, updatedConsumerDetails, profilePicChanged);
      dispatch(setConsumersLoading(false));
      if (editedConsumer.errorCode) {
        const errorMessage = editedConsumer.errorDescription ? editedConsumer.errorDescription : "Failed to edit consumer. Please try again!";
        dispatch(setConsumersError(errorMessage));
      } else {
        // Dispatch action to update consumer state
        dispatch(editConsumerState({ consumerId: consumerId, updatedConsumer: editedConsumer }));
      }
    } catch (error) {
      dispatch(setConsumersError("Failed to edit consumer. Please try again!!"));
    }
  };
};


//search by nic or name
export const searchConsumersAction = (searchBy: string, searchValue: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setConsumersLoading(true));
      let searchResults;
      if (searchBy === 'nic') {
        searchResults = await searchConsumers('nic', searchValue);
      } else if (searchBy === 'name') {
        searchResults = await searchConsumers('name', searchValue);
      }
      dispatch(setConsumersLoading(false));
      dispatch(setConsumers(searchResults));
    } catch (error) {
      console.error("Error searching consumers:", error);
      dispatch(
        setConsumersError("Failed to search consumers. Please try again!")
      );
    }
  };
};

// Service method to filter consumers based on filter values
export const filterConsumers = async (filterValues: any) => {
  try {
    const { area, tenantId, leveledUp, source } = filterValues;

    // Filter out empty values and convert "true" and "false" strings to boolean
    const filteredValues: any = {};
    if (area) filteredValues.area = area;
    if (tenantId !== null) filteredValues.tenantId = tenantId;
    if (leveledUp !== null) {
      filteredValues.leveledUp = leveledUp === "true";
    }
    if (source) filteredValues.source = source;

    const filteredResponse = await filterConsumerAPI(filteredValues);
    return filteredResponse.results;
  } catch (error) {
    console.error("Error filtering consumers:", error);
    throw error; // Rethrow the error if needed
  }
};
