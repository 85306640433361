import { BASE_URL } from '../constants/appConstants';
import { fetchWithToken } from '../utils/utils';

export const fetchTenants = async () => {
  try {
    const response = await fetchWithToken(`${BASE_URL}/tanents?page=0&size=0`);
    if (!response.ok) {
      throw new Error('Failed to fetch tenants');
    }
    const tenants = await response.json();
    return tenants.results
  } catch (error) {
    throw new Error('Failed to fetch tenants');
  }
};
