import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import workStyles from "../../styles/Consumer/Consumer.workInfor.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import ConsumerWorkView from "./ConsumerWorkView";
import { Button, Modal } from "react-bootstrap";
import arrowIcon from "../../assets/down_arrow.png";
import { useDispatch, useSelector } from "react-redux";
import { getWorkRequestsForConsumer } from "../../services/consumerService";
import { BASE_URL_FOR_IMAGE } from "../../constants/appConstants";
import { getWorkRequestById } from "../../services/workRequestService";
import WorkRequestStatus from "../../components/WorkRequestStatus";

interface Consumer {
  id: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  contactNumberPrimary: string;
  email: string;
  profileImage: {
    id: string;
    url: string;
  };
  rating: 4;
}

interface ProductImage {
  id: string;
  url: string;
}

interface Product {
  id: string;
  name: string;
  notes: string;
  colorId: number;
  productCode: string;
  productType: string;
  unitPrice: number;
  productImages: ProductImage[];
}

interface ProductType {
  id: string;
  name: string;
  description: string;
  jobTypes: any[];
  products: Product[];
}

interface JobType {
  id: string;
  type: string;
  description: string;
  productTypes: ProductType[];
  status: number;
}

interface AssignedTechnician {
  id: string;
  firstName: string;
  lastName: string;
  address: string;
  district: string;
  city: string;
  profileImage: {
    id: string;
    url: string;
  };
}

interface WorkRequest {
  id: string;
  startingDate: string;
  budjet: number;
  workDescription: string;
  address: string;
  district: string;
  city: string;
  workRequestImages: {
    id: string;
    url: string;
  };
  jobTypes: JobType[];
  status: string;
  longitude: number;
  latitude: number;
  consumer: Consumer;
  assignedTechnician: AssignedTechnician;
}

const ConsumerWorkInfor = ({ consumerData }: { consumerData: any }) => {
  const dispatch = useDispatch<any>();
  const workRequests: WorkRequest[] = useSelector(
    (state: any) => state.workRequests.workRequestsForConsumer
  );

  useEffect(() => {
    dispatch(getWorkRequestsForConsumer(consumerData.id));
  }, [dispatch, consumerData]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage] = useState<number>(10);
  const [show, setShow] = useState(false);
  const [filter, setFilter] = useState("all");
  const [selectedWorkRequest, setSelectedWorkRequest] =
    useState<WorkRequest | null>(null);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    workRequests && workRequests.length > 0
      ? workRequests.slice(indexOfFirstItem, indexOfLastItem)
      : [];

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const handleClose = () => setShow(false);

  const handleShow = (workRequest: WorkRequest) => {
    if (workRequest) {
      dispatch(getWorkRequestById(workRequest.id));
    }
    setSelectedWorkRequest(workRequest);
    setShow(true);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value.toLowerCase();
    setFilter(selectedValue);
    setCurrentPage(1); // Reset pagination when filter changes
  };

  const filteredItems = currentItems.filter((workRequest: WorkRequest) => {
    if (filter === "all") return true;
    return workRequest.status.toLowerCase() === filter;
  });

  return (
    <div className={workStyles.workinfotab}>
      <p style={{ marginBottom: "-1rem" }}>Current Work</p>
      <div className={workStyles.filterOption}>
        <select value={filter} onChange={handleFilterChange}>
          <option value="all">All</option>
          <option value="in-review">New</option>
          <option value="assigned">Assigned</option>
          <option value="in-progress">In Progress</option>
          <option value="on-hold">On Hold</option>
          <option value="completed">Completed</option>
          <option value="accepted">Accepted</option>
          <option value="rejected">Rejected</option>
        </select>
        <img
          src={arrowIcon}
          alt="Arrow Icon"
          className={workStyles.arrowIcon}
        />
      </div>

      {filteredItems.length > 0 &&
        filteredItems.map((workRequest: WorkRequest) => (
          <div className={workStyles.workcard} key={workRequest.id}>
            <Card>
              <Card.Body>
                <div className={workStyles.workinfo}>
                  <div className={workStyles.profile} style={{ flex: 2 }}>
                    <div className={workStyles.profileimg}>
                      <img
                        src={
                          consumerData.profileImage
                            ? BASE_URL_FOR_IMAGE + consumerData.profileImage.url
                            : "https://media.istockphoto.com/id/519977141/photo/daydreaming-about-her-future.jpg?s=2048x2048&w=is&k=20&c=iAGtxkCqomrBrW8fKsMJtZruQbcuX3oV6XZQdfkIGRo="
                        }
                        alt="Profile Picture"
                      />
                    </div>

                    <div className={workStyles.worktitle}>
                      {workRequest.jobTypes.length > 0 && (
                        <div style={{ color: "black", fontWeight: "bold" }}>
                          {workRequest.jobTypes[0].type}
                        </div>
                      )}

                      <div style={{ color: "black" }}>
                        {consumerData.firstName} {consumerData.lastName}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flex: "1",
                    }}
                  >
                    <WorkRequestStatus status={workRequest.status} />
                  </div>
                  <div
                    className={workStyles.workbudget}
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    RS Budget :{workRequest.budjet}
                  </div>
                  <div
                    className={workStyles.workdate}
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faClock}
                      className={workStyles.clockIcon}
                    />
                    {workRequest.startingDate}
                  </div>
                  <div
                    className={workStyles.worklocation}
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      className={workStyles.locationIcon}
                    />{" "}
                    {workRequest.city}
                  </div>
                  <div
                    className={workStyles.workview}
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      className={workStyles.viewbutton}
                      onClick={() => handleShow(workRequest)}
                    >
                      View
                    </button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        ))}

      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={workRequests && workRequests.length}
        paginate={paginate}
      />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        style={{ border: "none" }}
      >
        <ConsumerWorkView
          consumerData={consumerData}
          workRequestData={selectedWorkRequest}
          onClose={handleClose}
        />
      </Modal>
    </div>
  );
};

interface PaginationProps {
  itemsPerPage: number;
  totalItems: number;
  paginate: (pageNumber: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  itemsPerPage,
  totalItems,
  paginate,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav style={{ marginTop: "1rem" }}>
      <ul
        className="pagination"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        {pageNumbers.map((number) => (
          <li key={number} className="page-item">
            <a
              onClick={() => paginate(number)}
              href="#"
              className="page-link"
              // style={{ color: "black" }}
            >
              {number}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default ConsumerWorkInfor;
