import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Product } from "../type";

interface ProductState {
  products: {
    title: string,
    subProducts:  Product[]
  };
  loading: boolean;
  error: string | null;
}

const initialState: ProductState = {
  products: {
    title: 'products',
    subProducts: []
  },
  loading: false,
  error: null,
};

const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProducts(state, action: PayloadAction<Product[]>) {
      state.loading = false;
      state.error = null;
      state.products.title = "products";
      state.products.subProducts = action.payload
    },
    setProductsLoading(state, action: PayloadAction<boolean>) {
      state.loading = true;
      state.error = null;
      state.products = {
        title: 'products',
        subProducts: []
      }
    },
    setProductsError(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setProducts,
  setProductsLoading,
  setProductsError,
} = productSlice.actions;

export default productSlice.reducer;
