import React, { ChangeEvent, useEffect, useState, useRef } from "react";
import { Col, Form, Row, InputGroup, FormControl } from "react-bootstrap";
import CountryDropdown from "react-flags-select";
import formStyle from "../../styles/Technician/TechnicianRegister.module.css";
import Button from "../../components/Button";
import upload from "../../assets/upload.png";
import styles from "../../styles/WorkRequest.module.css";
import { useDispatch, useSelector } from "react-redux";
import { addTechnician } from "../../services/technicianService";
import { getJobTypes } from "../../services/jobTypeService";
import { setTechniciansLoading } from "../../features/technicianSlice";
import { getCityData } from "../../services/cityService";
import SpinnerLoading from "../../components/SpinnerLoading";
import Popup from "../../components/Popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import close_png from "../../assets/close.png";
import { BASE_URL_FOR_IMAGE } from "../../constants/appConstants";

interface FormData {
  title: string;
  firstName: string;
  lastName: string;
  address: string;
  district: string;
  city: string;
  nic: string;
  dateOfBirth: string;
  phoneNumber: string;
  minimalEducation: string;
  noOfWorkers: any;
  avgWorkNo: any;
  profileImageUrl: string;
  nicFrontImageUrl: string;
  nicBackImageUrl: string;
  jobType: string;
  ExpYears: any;
  ExpMonths: any;
  workMainDistrict: string;
  workMainCity: string;
  workSubDistrict: string;
  workSubCity: string;
  company: string;
}

interface TechnicianCreate {
  title: string;
  firstName: string;
  lastName: string;
  address: string;
  district: string;
  city: string;
  dateOfBirth: string;
  nationalIdentityCardNo: string;
  contactNumberPrimary: string;
  // contactNumberSecondary: string;
  minimumEducation: string;
  numberOfWorkers: any;
  numberOfAverageWorkers: any;
  profileImageUrl: string;
  nicFrontImageUrl: string;
  nicBackImageUrl: string;
  registrationSource: string;

  workExperience?: any;
}

interface Props {
  onCancel: () => void;
  // onSaveSuccess: () => void;
}

declare const FileList: any; // Add this import to fix the type error

const createFileList = (files: File[]) => {
  const fileList = new DataTransfer();
  files.forEach((file) => fileList.items.add(file));
  return fileList.files;
};

interface JobExperience {
  id: string;
  experienceInYears: number;
  experienceInMonths: number;
}

const TechnicianRegister: React.FC<Props> = ({ onCancel }) => {
  const dispatch = useDispatch<any>();
  const [selectedCountry, setSelectedCountry] = useState("+94");
  // const jobTypesBe = useSelector((state: any) => state.jobTypes.jobTypes);
  const jobTypesBe = useSelector((state: any) =>
    state.jobTypes.jobTypes.filter((jobType: any) => jobType.status === 1)
  );

  const tenantsBe = useSelector((state: any) => state.tenants.tenants);
  const [showSccessMsg, setShowSuccessMessage] = useState(false);
  const [workImages, setWorkImages] = useState<any>([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [numJobTypeDropdowns, setNumJobTypeDropdowns] = useState(1);
  const [numSubWorkLocationDropdowns, setNumSubWorkLocationDropdowns] =
    useState(1);
  const [numCompaniesDropdowns, setNumCompaniesDropdowns] = useState(1);
  const [files, setFiles] = useState<File[]>([]);
  const [showPopup, setShowPopup] = useState<boolean>(true);
  const [selectedTanents, setSelectedTanents] = useState<string[]>([]);
  const initialJobExperiences = Array(numJobTypeDropdowns).fill({
    id: "",
    experienceInYears: 0,
    experienceInMonths: 0,
  });
  const [selectedjobExperiences, setSelectedjobExperiences] = useState(
    initialJobExperiences
  );

  const initialSubWorkingLocation = Array(numSubWorkLocationDropdowns).fill({
    district: "",
    city: "",
  });
  const [selectedSubWorkAreas, setSelectedSubWorkAreas] = useState(
    initialSubWorkingLocation
  );

  const [nicFrontPhotoUrlDispay, setnicFrontPhotoUrlDispay] = useState<
    string | null
  >(null);
  const [nicBackPhotoUrlDispay, setnicBackPhotoUrlDispay] = useState<
    string | null
  >(null);
  const [profileImageUrlDispay, setprofileImageUrlDispay] = useState<
    string | null
  >(null);
  const errorTechnicianRegister = useSelector(
    (state: any) => state.technicians.error
  );
  const successTechnician = useSelector(
    (state: any) => state.technicians.success
  );
  const loading = useSelector((state: any) => state.technicians.loading);
  const cityData = useSelector((state: any) => state.locationData.cityData);
  console.log("setSelectedTanents", selectedTanents);
  useEffect(() => {
    dispatch(getCityData());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getJobTypes());
  }, [dispatch]);

  const clearInputs = () => {
    if (errorTechnicianRegister || successTechnician) {
      dispatch(setTechniciansLoading(true));
      dispatch(setTechniciansLoading(false));
    }
  };

  useEffect(() => {
    clearInputs();

    const newFileList = createFileList(files);
    setWorkImages(newFileList);
  }, [files]);

  const [formData, setFormData] = useState<FormData>({
    // Initialize form fields with default values
    title: "",
    firstName: "",
    lastName: "",
    address: "",
    district: "",
    city: "",
    nic: "",
    dateOfBirth: "",
    phoneNumber: "",
    minimalEducation: "",
    noOfWorkers: "",
    avgWorkNo: "",
    profileImageUrl: "",
    nicFrontImageUrl: "",
    nicBackImageUrl: "",
    jobType: "",
    ExpYears: "",
    ExpMonths: "",
    workMainDistrict: "",
    workMainCity: "",
    workSubDistrict: "",
    workSubCity: "",
    company: "",
  });

  const [errors, setErrors] = useState<Partial<FormData>>({});
  const [selectedTenantName, setSelectedTenantName] = useState("");
  const [profileImageDimensionsError, setProfileImageDimensionsError] =
    useState<string | null>(null);
  const handleCountryChange = (country: string) => {
    setSelectedCountry(country);
  };

  const handleFileInputChange = (e: any) => {
    changeMessages();
    const fileList = e.target.files;
    // const filesWithBlob: File[] = [];
    // for (const file of fileList) {
    //   filesWithBlob.push(new File([file], file.name, { type: file.type }));
    // }
    // setFiles(filesWithBlob);
    const filesWithBlob: File[] = [];
    for (const file of fileList) {
      if (filesWithBlob.length < 10) {
        const extension = file.name.split(".").pop()?.toLowerCase();
        if (
          extension === "jpeg" ||
          extension === "png" ||
          extension === "jpg"
        ) {
          filesWithBlob.push(new File([file], file.name, { type: file.type }));
        }
      } else {
        console.log("Upload limit reached. Only 10 files can be uploaded.");
        break; // Exit the loop if the limit is reached
      }
    }
    setFiles(filesWithBlob);
  };

  const validateForm = () => {
    const newErrors: any = {};

    // Title
    if (!formData.title) {
      newErrors.title = "Please select a title.";
    }

    // Full name
    if (!formData.firstName) {
      newErrors.firstName = "Please enter your first name.";
    }

    if (!formData.lastName) {
      newErrors.lastName = "Please enter your last name.";
    }

    // Address
    if (!formData.address) {
      newErrors.address = "Please enter your address.";
    }

    // District and city
    // District
    if (!formData.district) {
      newErrors.district = "Please select your district.";
    }

    // City
    if (!formData.city) {
      newErrors.city = "Please select your city.";
    }
    // NIC number validation
    if (!formData.nic) {
      newErrors.nic = "Please enter your NIC number.";
    } else if (
      !(/^\d{9}[Vv]$/.test(formData.nic) || /^\d{12}$/.test(formData.nic))
    ) {
      newErrors.nic = "Please enter a valid NIC number.";
    }

    // Date of birth validation
    if (!formData.dateOfBirth) {
      newErrors.dateOfBirth = "Please enter your date of birth.";
    } else if (!/^\d{4}-\d{2}-\d{2}$/.test(formData.dateOfBirth)) {
      newErrors.dateOfBirth =
        "Please enter a valid date of birth in the format YYYY-MM-DD.";
    } else {
      const dob = new Date(formData.dateOfBirth);
      const currentDate = new Date();
      const minAgeDate = new Date(
        currentDate.getFullYear() - 18,
        currentDate.getMonth(),
        currentDate.getDate()
      );

      if (dob > minAgeDate) {
        newErrors.dateOfBirth = "You must be at least 18 years old.";
      }
    }

    // Phone number
    //this validation should be change after ajust the selection flags error
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Please enter your phone number.";
    }

    // Minimal education
    if (!formData.minimalEducation) {
      newErrors.minimalEducation = "Please select your Minimal Education.";
    }

    // Workers number
    if (!formData.noOfWorkers) {
      newErrors.noOfWorkers = "Please enter your number of workers.";
    }

    // Avg. work number
    if (!formData.avgWorkNo) {
      newErrors.avgWorkNo = "Please enter your avgerage work number.";
    }
    if (!formData.profileImageUrl) {
      newErrors.profileImageUrl = "Please upload profile image.";
    }
    if (!formData.nicFrontImageUrl) {
      newErrors.nicFrontImageUrl = "Please upload NIC front image.";
    }
    if (!formData.nicBackImageUrl) {
      newErrors.nicBackImageUrl = "Please upload NIC back image.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isValid = validateForm(); // Get validation result
    if (isValid) {
      const workExperienceData: any = {
        jobTypeExperiences: selectedjobExperiences,
        mainWorkingArea: {
          district: formData.workMainDistrict,
          city: formData.workMainCity,
        },
        // subWorkingAreas: [
        //   {
        //     district: formData.workSubDistrict,
        //     city: formData.workSubCity,
        //   },
        // ],
        subWorkingAreas: selectedSubWorkAreas,
        tanentIds: selectedTanents,
        imagesOfWork: [workImages],
      };

      const requestObj: TechnicianCreate = {
        title: formData.title,
        firstName: formData.firstName,
        lastName: formData.lastName,
        address: formData.address,
        district: formData.district,
        city: formData.city,
        dateOfBirth: formData.dateOfBirth,
        nationalIdentityCardNo: formData.nic,
        contactNumberPrimary: "+94" + formData.phoneNumber,
        minimumEducation: formData.minimalEducation,
        numberOfWorkers: parseInt(formData.noOfWorkers),
        numberOfAverageWorkers: parseInt(formData.avgWorkNo),
        profileImageUrl: formData.profileImageUrl,
        nicFrontImageUrl: formData.nicFrontImageUrl,
        nicBackImageUrl: formData.nicBackImageUrl,
        registrationSource: "website",
      };

      if (selectedjobExperiences.length > 0 && selectedjobExperiences[0].id) {
        const requestObjWithWorkExp = {
          ...requestObj,
          workExperience: workExperienceData,
        };
        dispatch(addTechnician(requestObjWithWorkExp));
      } else {
        dispatch(addTechnician(requestObj));
      }
      setShowSuccessMessage(true);
      setShowErrorMessage(true);
      setShowPopup(true);
    } else {
      // Form has errors, display error messages
      console.log("Form submission failed due to errors:", errors);
    }
  };

  const changeMessages = () => {
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
  };

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    changeMessages();
    const { name, value } = event.target;
    const newErrors = { ...errors };
    if (name in errors) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
    }

    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors(newErrors);
  };

  const MAX_WIDTH = 800;
  const MIN_WIDTH = 100;
  const MAX_HEIGHT = 800;
  const MIN_HEIGHT = 100;

  const handleImageUpload = (event: any) => {
    changeMessages();
    const file = event.target.files && event.target.files[0];
    if (file) {
      const { name, value } = event.target;
      const newErrors: { [key: string]: string | null } = { ...errors };

      // Create an image object to get the dimensions
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const width = img.width;
        const height = img.height;

        if (name === "profileImageUrl") {
          // Check if the image meets the minimum and maximum width requirements
          if (width > MAX_WIDTH || height > MAX_HEIGHT) {
            setProfileImageDimensionsError(
              `Please ensure that your profile image does not exceed the maximum dimensions of ${MAX_WIDTH} pixels by ${MAX_HEIGHT} pixels.`
            );
          } else if (width < MIN_WIDTH || height < MIN_HEIGHT) {
            setProfileImageDimensionsError(
              `Please ensure that your profile image meets the minimum dimensions of ${MIN_WIDTH} pixels by ${MIN_HEIGHT} pixels.`
            );
          } else {
            setProfileImageDimensionsError(null);
          }
        }
        // Set the displayed image URL
        if (name === "nicFrontImageUrl") {
          setnicFrontPhotoUrlDispay(URL.createObjectURL(file));
        } else if (name === "nicBackImageUrl") {
          setnicBackPhotoUrlDispay(URL.createObjectURL(file));
        } else if (name === "profileImageUrl") {
          setprofileImageUrlDispay(URL.createObjectURL(file));
        }

        setErrors(newErrors);
      };

      // Clear error for the edited field if it exists
      if (name in errors) {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
      }

      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));
    }
  };

  if (loading) {
    return <SpinnerLoading />;
  }

  const addJobTypeDropdown = () => {
    setNumJobTypeDropdowns(numJobTypeDropdowns + 1);
  };

  const deleteJobTypeDropdown = () => {
    if (numJobTypeDropdowns !== 1) {
      setNumJobTypeDropdowns(numJobTypeDropdowns - 1);
    }
  };

  const addWorkLocationDropdown = () => {
    setNumSubWorkLocationDropdowns(numSubWorkLocationDropdowns + 1);
  };

  const deleteWorkLocationDropdown = () => {
    if (numSubWorkLocationDropdowns !== 1) {
      setNumSubWorkLocationDropdowns(numSubWorkLocationDropdowns - 1);
    }
  };

  const addCompaniesDropdown = () => {
    setNumCompaniesDropdowns(numCompaniesDropdowns + 1);
    setSelectedTanents((prevSelected) => [...prevSelected, ""]);
  };

  const deleteCompaniesDropdown = (indexToDelete: number) => {
    if (numCompaniesDropdowns !== 1) {
      setNumCompaniesDropdowns(numCompaniesDropdowns - 1);
      setSelectedTanents((prevSelected) => {
        const updatedSelected = [...prevSelected];
        updatedSelected.splice(indexToDelete, 1);
        return updatedSelected;
      });
    }
  };

  const handleRemoveProfileImage = () => {
    setprofileImageUrlDispay(null);
    const imageFileElement = document.getElementById(
      "profile-image-file"
    ) as HTMLInputElement | null;
    if (imageFileElement) {
      imageFileElement.value = "";
      formData.profileImageUrl = ""; // Reset the profileImageUrl
    }
  };

  const handleRemoveNicFrontImage = () => {
    setnicFrontPhotoUrlDispay(null);
    const imageFileElement = document.getElementById(
      "nic-front-image-file"
    ) as HTMLInputElement | null;
    if (imageFileElement) {
      imageFileElement.value = "";
      formData.nicFrontImageUrl = "";
    }
  };

  const handleRemoveNicBackImage = () => {
    setnicBackPhotoUrlDispay(null);
    const imageFileElement = document.getElementById(
      "nic-back-image-file"
    ) as HTMLInputElement | null;
    if (imageFileElement) {
      imageFileElement.value = "";
      formData.nicBackImageUrl = "";
    }
  };

  const handleRemoveAllImages = () => {
    setWorkImages(null);
  };

  const handleRemoveNewUploadImg = (idToRemove: number) => {
    const updatedUploadImages = [...workImages];
    updatedUploadImages.splice(idToRemove, 1);

    setWorkImages(updatedUploadImages);
  };

  return (
    <div className="technician-register-container">
      <div>
        <h4>
          <b>Register Technician</b>
        </h4>
      </div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Group
              controlId="formTitle"
              className={formStyle.formgroupStyles}
            >
              <Form.Label className={formStyle.formlabelStyles}>
                Title
              </Form.Label>
              <div>
                <Form.Select
                  as="select"
                  name="title"
                  value={formData.title}
                  style={{ width: "600px" }}
                  onChange={handleInputChange}
                >
                  <option>Choose Title</option>
                  <option>Mrs</option>
                  <option>Miss</option>
                  <option>Ms</option>
                  <option>Mr</option>
                </Form.Select>
                {errors.title && (
                  <span className={formStyle.inputerrorMessage}>
                    {errors.title}
                  </span>
                )}
              </div>
            </Form.Group>
          </Col>
        </Row>

        <br></br>

        <div
          style={{ display: "flex", alignItems: "center" }}
          className={formStyle.formgroupStyles}
        >
          <div>
            <Form.Label className={formStyle.formlabelStyles}>
              Full Name
            </Form.Label>
          </div>

          <div>
            <Col>
              <Form.Group controlId="formfirstName">
                <Form.Control
                  placeholder="First Name"
                  name="firstName"
                  value={formData.firstName}
                  style={{ width: "296px" }}
                  onChange={handleInputChange}
                ></Form.Control>
                {errors.firstName && (
                  <span className={formStyle.inputerrorMessage}>
                    {errors.firstName}
                  </span>
                )}
              </Form.Group>
            </Col>
          </div>
          <div>
            <Col>
              <Form.Group controlId="formlastName">
                <Form.Control
                  style={{ marginLeft: "10px", width: "296px" }}
                  placeholder="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                ></Form.Control>
                {errors.lastName && (
                  <span className={formStyle.inputerrorMessage}>
                    {errors.lastName}
                  </span>
                )}
              </Form.Group>
            </Col>
          </div>
        </div>

        <br></br>

        <Row className={formStyle.addresslabel}>
          <Col>
            <Form.Group
              controlId="formAddress"
              className={formStyle.formgroupStyles}
            >
              <Form.Label className={formStyle.formlabelStyles}>
                Address
              </Form.Label>
              <div>
                <Form.Control
                  placeholder="Enter your address here"
                  name="address"
                  style={{ width: "600px" }}
                  value={formData.address}
                  onChange={handleInputChange}
                />
                {errors.address && (
                  <span className={formStyle.inputerrorMessage}>
                    {errors.address}
                  </span>
                )}
              </div>
            </Form.Group>
          </Col>
        </Row>

        <div
          style={{ display: "flex", alignItems: "center", marginLeft: "9rem" }}
        >
          <Form.Group
            controlId="formCity"
            className={formStyle.formgroupStyles}
          >
            <div>
              <Form.Select
                as="select"
                name="district"
                style={{ marginLeft: "7rem", width: "296px" }}
                value={formData.district}
                onChange={handleInputChange}
              >
                <option value="">District</option>
                {cityData.map((districtData: any) => (
                  <option
                    key={districtData.district.id}
                    value={districtData.district.name}
                  >
                    {districtData.district.name}
                  </option>
                ))}
              </Form.Select>
              <div style={{ marginLeft: "115px" }}>
                {errors.district && (
                  <span className={formStyle.inputerrorMessage}>
                    {errors.district}
                  </span>
                )}
              </div>
            </div>
          </Form.Group>

          <Form.Group
            controlId="formDistrict"
            className={formStyle.formgroupStyles}
          >
            <div>
              <Form.Select
                as="select"
                name="city"
                value={formData.city}
                style={{ marginLeft: "10px", width: "296px" }}
                onChange={handleInputChange}
              >
                <option value="">City</option>
                {formData.district &&
                  cityData
                    .find(
                      (districtData: any) =>
                        districtData.district.name === formData.district
                    )
                    ?.district.cities.map((city: any) => (
                      <option key={city.id} value={city.name}>
                        {city.name}
                      </option>
                    ))}
              </Form.Select>
              {errors.city && (
                <span className={formStyle.inputerrorMessage}>
                  {errors.city}
                </span>
              )}
            </div>
          </Form.Group>
        </div>
        <br></br>

        <Row>
          <Col>
            <Form.Group
              controlId="formDateOfBirth"
              className={formStyle.formgroupStyles}
            >
              <Form.Label className={formStyle.formlabelStyles}>
                Date of Birth
              </Form.Label>
              <div>
                <Form.Control
                  type="date"
                  placeholder="Enter Date of Birth"
                  name="dateOfBirth"
                  style={{ width: "296px" }}
                  value={formData.dateOfBirth}
                  onChange={handleInputChange}
                />
                {errors.dateOfBirth && (
                  <span className={formStyle.inputerrorMessage}>
                    {errors.dateOfBirth}
                  </span>
                )}
              </div>
            </Form.Group>
          </Col>
          <Col></Col>
        </Row>
        <br></br>

        <Row>
          <Col>
            <Form.Group
              controlId="formNIC"
              className={formStyle.formgroupStyles}
            >
              <Form.Label className={formStyle.formlabelStyles}>
                NIC Number
              </Form.Label>
              <div>
                <Form.Control
                  placeholder="Enter NIC Number"
                  name="nic"
                  style={{ width: "296px" }}
                  value={formData.nic}
                  onChange={handleInputChange}
                />
                {errors.nic && (
                  <span className={formStyle.inputerrorMessage}>
                    {errors.nic}
                  </span>
                )}
              </div>
            </Form.Group>
          </Col>
        </Row>
        <br></br>

        <Row>
          <Col>
            <Form.Group
              controlId="formPhoneNumber"
              className={formStyle.formgroupStyles}
            >
              <Form.Label className={formStyle.formlabelStyles}>
                Contact No
              </Form.Label>
              <div>
                <InputGroup>
                  {/* <CountryDropdown
                    selected={selectedCountry || "+94"} // Provide a default country code
                    onSelect={(countryCode: string) =>
                      handleCountryChange(countryCode)
                    }
                    showSelectedLabel={true} // Hide the selected country label
                  /> */}

                  <FormControl
                    placeholder="77 777 777"
                    type="tel"
                    name="phoneNumber"
                    style={{ width: "296px" }}
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                    aria-label="Phone Number"
                    aria-describedby="countryCode"
                  />
                </InputGroup>
                {errors.phoneNumber && (
                  <span className={formStyle.inputerrorMessage}>
                    {errors.phoneNumber}
                  </span>
                )}
              </div>
            </Form.Group>
          </Col>
        </Row>
        <br></br>

        <Row>
          <Form.Group
            controlId="formMinimalEducation"
            className={formStyle.formgroupStyles}
          >
            <Form.Label className={formStyle.formlabelStyles}>
              Minimal Education
            </Form.Label>
            <div>
              <Form.Select
                as="select"
                name="minimalEducation"
                style={{ width: "296px" }}
                value={formData.minimalEducation}
                onChange={handleInputChange}
              >
                <option value="">Select Your Education level</option>
                <option value="PRIMARY">PRIMARY</option>
                <option value="GCE OL">Ordinary Level</option>
                <option value="GCE AL">Advanced level </option>
              </Form.Select>
              {errors.minimalEducation && (
                <span className={formStyle.inputerrorMessage}>
                  {errors.minimalEducation}
                </span>
              )}
            </div>
          </Form.Group>
        </Row>
        <br></br>

        <Form.Group
          as={Col}
          controlId="formNoOfWorkers"
          className={formStyle.formgroupStyles}
        >
          <Form.Label className={formStyle.formlabelStyles}>
            No. of Workers
          </Form.Label>
          <Col sm="3">
            <div>
              <Form.Control
                type="number"
                placeholder="Enter number of workers under you"
                name="noOfWorkers"
                value={formData.noOfWorkers}
                style={{ width: "296px", fontSize: "14px" }}
                className={formStyle.inputField}
                onChange={handleInputChange}
              />
              {errors.noOfWorkers && (
                <span className={formStyle.inputerrorMessage}>
                  {errors.noOfWorkers}
                </span>
              )}
            </div>
          </Col>
        </Form.Group>

        <Form.Group
          as={Col}
          controlId="formAvgWorkNo"
          className={formStyle.formgroupStyles}
        >
          <Form.Label className={formStyle.formlabelStyles}>
            Average Number of Work
          </Form.Label>
          <Col sm="3">
            <div className={formStyle.inputField}>
              <Form.Control
                type="number"
                placeholder="Enter your monthly work total"
                name="avgWorkNo"
                style={{ width: "296px", fontSize: "14px" }}
                value={formData.avgWorkNo}
                className={formStyle.inputField}
                onChange={handleInputChange}
              />
              {errors.avgWorkNo && (
                <span className={formStyle.inputerrorMessage}>
                  {errors.avgWorkNo}
                </span>
              )}
            </div>
          </Col>
        </Form.Group>

        <div className={formStyle.formgroupStyles}>
          <Form.Label className={formStyle.formlabelStyles}>
            Uploads Photos
          </Form.Label>
          <div style={{ display: "flex", marginRight: "2rem" }}>
            <Form.Group as={Col} controlId="formPlaintextFile">
              <Col
                style={{
                  width: "12rem",
                  marginTop: "1rem",
                }}
              >
                <div
                  className={styles.fileInputContainer}
                  style={{ width: "100%" }}
                >
                  {profileImageUrlDispay && (
                    <img
                      src={close_png}
                      style={{
                        height: "15px",
                        width: "15px",
                        alignSelf: "flex-end",
                      }}
                      alt="Close"
                      onClick={() => handleRemoveProfileImage()}
                    />
                  )}

                  <label htmlFor="fileInput" className={styles.customFileLabel}>
                    <span className={styles.uploadIcon}>
                      {profileImageUrlDispay ? (
                        <img
                          src={profileImageUrlDispay}
                          style={{
                            cursor: "pointer",
                            width: "8rem",
                            height: "5rem",
                          }}
                        />
                      ) : (
                        <img
                          src={upload}
                          className={styles.inputIconTechnician}
                        />
                      )}
                    </span>{" "}
                    Profile Picture
                  </label>
                  <Form.Control
                    type="file"
                    id="profile-image-file"
                    name="profileImageUrl"
                    className={styles.fileInput}
                    onChange={handleImageUpload}
                  />
                </div>
                {errors.profileImageUrl && (
                  <span className={formStyle.inputerrorMessage}>
                    {errors.profileImageUrl}
                  </span>
                )}
                {profileImageDimensionsError && (
                  <span className={formStyle.inputerrorMessage}>
                    {profileImageDimensionsError}
                  </span>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Col} controlId="formPlaintextFile">
              <Col
                style={{
                  width: "12rem",
                  marginLeft: "20px",
                  marginTop: "1rem",
                }}
              >
                <div
                  className={styles.fileInputContainer}
                  style={{ width: "100%" }}
                >
                  {nicFrontPhotoUrlDispay && (
                    <img
                      src={close_png}
                      style={{
                        height: "15px",
                        width: "15px",
                        alignSelf: "flex-end",
                      }}
                      alt="Close"
                      onClick={() => handleRemoveNicFrontImage()}
                    />
                  )}

                  <label htmlFor="fileInput" className={styles.customFileLabel}>
                    <span className={styles.uploadIcon}>
                      {nicFrontPhotoUrlDispay ? (
                        <img
                          src={nicFrontPhotoUrlDispay}
                          style={{
                            cursor: "pointer",
                            width: "8rem",
                            height: "5rem",
                          }}
                        />
                      ) : (
                        <img
                          src={upload}
                          className={styles.inputIconTechnician}
                        />
                      )}
                    </span>{" "}
                    NIC Front Picture
                  </label>
                  <Form.Control
                    type="file"
                    id="nic-front-image-file"
                    name="nicFrontImageUrl"
                    className={styles.fileInput}
                    onChange={handleImageUpload}
                  />
                </div>
                {errors.nicFrontImageUrl && (
                  <span className={formStyle.inputerrorMessage}>
                    {errors.nicFrontImageUrl}
                  </span>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Col} controlId="formPlaintextFile">
              <Col
                sm="12"
                style={{
                  width: "12rem",
                  marginLeft: "20px",
                  marginTop: "1rem",
                }}
              >
                <div
                  className={styles.fileInputContainer}
                  style={{ width: "100%" }}
                >
                  {nicBackPhotoUrlDispay && (
                    <img
                      src={close_png}
                      style={{
                        height: "15px",
                        width: "15px",
                        alignSelf: "flex-end",
                      }}
                      alt="Close"
                      onClick={() => handleRemoveNicBackImage()}
                    />
                  )}
                  <label htmlFor="fileInput" className={styles.customFileLabel}>
                    <span className={styles.uploadIcon}>
                      {nicBackPhotoUrlDispay ? (
                        <img
                          src={nicBackPhotoUrlDispay}
                          style={{
                            cursor: "pointer",
                            width: "8rem",
                            height: "5rem",
                          }}
                        />
                      ) : (
                        <img
                          src={upload}
                          className={styles.inputIconTechnician}
                        />
                      )}
                    </span>{" "}
                    NIC Back Picture
                  </label>
                  <Form.Control
                    type="file"
                    id="nic-back-image-file"
                    name="nicBackImageUrl"
                    className={styles.fileInput}
                    onChange={handleImageUpload}
                  />
                </div>
                {errors.nicBackImageUrl && (
                  <span className={formStyle.inputerrorMessage}>
                    {errors.nicBackImageUrl}
                  </span>
                )}
              </Col>
            </Form.Group>
          </div>
        </div>

        <div
          style={{
            border: "1px solid #D7D7D7",
            padding: "10px",
            borderRadius: "1.5rem",
            marginTop: "2rem",
            width: "56rem",
            marginLeft: "1rem",
            paddingBottom: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex" }}>
            <div className="circular-icon" onClick={addJobTypeDropdown}>
              <FontAwesomeIcon
                icon={faPlus}
                style={{ fontSize: "1rem", color: "white", cursor: "pointer" }}
              />
            </div>

            <div className="circular-icon" onClick={deleteJobTypeDropdown}>
              <FontAwesomeIcon
                icon={faMinus}
                style={{ fontSize: "1rem", color: "white", cursor: "pointer" }}
              />
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            {[...Array(numJobTypeDropdowns)].map((_, index) => (
              <div key={index}>
                <div>
                  <Form.Group
                    controlId="formJobType"
                    className={formStyle.formgroupStyles}
                  >
                    <Form.Label className={formStyle.formlabelNew}>
                      Job type
                    </Form.Label>
                    <div>
                      <Form.Select
                        as="select"
                        name="jobType"
                        style={{ width: "34.5625rem" }}
                        value={selectedjobExperiences[index]?.id || ""}
                        onChange={(e: any) => {
                          const jobId = e.target.value;
                          setSelectedjobExperiences((prevExperiences) => {
                            const updatedExperiences = [...prevExperiences];
                            updatedExperiences[index] = {
                              ...updatedExperiences[index],
                              id: jobId,
                            };
                            return updatedExperiences;
                          });
                        }}
                      >
                        <option value="">Select job type</option>
                        {jobTypesBe.map((jobTypes: any) => (
                          <option key={jobTypes.id} value={jobTypes.id}>
                            {jobTypes.type}
                          </option>
                        ))}
                      </Form.Select>
                      {errors.jobType && (
                        <span className={formStyle.inputerrorMessage}>
                          {errors.jobType}
                        </span>
                      )}
                    </div>
                  </Form.Group>
                </div>

                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className={formStyle.formgroupStyles}
                >
                  <Form.Label className={formStyle.formlabelNew}>
                    Experience
                  </Form.Label>
                  <Row style={{ display: "flex" }}>
                    <Form.Group
                      as={Col}
                      controlId="formExperienceYears"
                      className={formStyle.formgroupStyles}
                      style={{ width: "295px" }}
                    >
                      <Col sm="12">
                        <div className={formStyle.inputField}>
                          <Form.Control
                            type="number"
                            placeholder="Years"
                            name={"ExpYears"}
                            value={
                              selectedjobExperiences[index]
                                ?.experienceInYears || ""
                            }
                            onChange={(e: any) => {
                              const expYears = parseInt(e.target.value) || 0;
                              const updatedSelectedJobExperiences = [
                                ...selectedjobExperiences,
                              ];
                              updatedSelectedJobExperiences[
                                index
                              ].experienceInYears = expYears;
                              setSelectedjobExperiences(
                                updatedSelectedJobExperiences
                              );
                            }}
                          />
                          {errors.ExpYears && (
                            <div className={formStyle.errorText}>
                              {errors.ExpYears}
                            </div>
                          )}
                        </div>
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      controlId="formExperienceMonths"
                      className={formStyle.formgroupStyles}
                      style={{ width: "282px", marginLeft: "0" }}
                    >
                      <Col sm="12">
                        <div className={formStyle.inputField}>
                          <Form.Control
                            type="number"
                            placeholder="Months"
                            name={"ExpMonths"}
                            className={formStyle.inputField}
                            value={
                              selectedjobExperiences[index]
                                ?.experienceInMonths || ""
                            }
                            onChange={(e: any) => {
                              const expMonths = parseInt(e.target.value) || 0;
                              const updatedSelectedJobExperiences = [
                                ...selectedjobExperiences,
                              ];
                              updatedSelectedJobExperiences[
                                index
                              ].experienceInMonths = expMonths;
                              setSelectedjobExperiences(
                                updatedSelectedJobExperiences
                              );
                            }}
                          />

                          {errors.ExpMonths && (
                            <div className={formStyle.errorText}>
                              {errors.ExpMonths}
                            </div>
                          )}
                        </div>
                      </Col>
                    </Form.Group>
                  </Row>
                </div>
                <br></br>
              </div>
            ))}
          </div>
        </div>

        <div
          style={{
            border: "1px solid #D7D7D7",
            padding: "10px",
            borderRadius: "1.5rem",
            marginTop: "1rem",
            width: "56rem",
            marginLeft: "1rem",
            paddingBottom: "20px",
          }}
        >
          <div>
            <div style={{ display: "flex" }}>
              <div className="circular-icon" onClick={addWorkLocationDropdown}>
                <FontAwesomeIcon
                  icon={faPlus}
                  style={{
                    fontSize: "1rem",
                    color: "white",
                    cursor: "pointer",
                  }}
                />
              </div>

              <div
                className="circular-icon"
                onClick={deleteWorkLocationDropdown}
              >
                <FontAwesomeIcon
                  icon={faMinus}
                  style={{
                    fontSize: "1rem",
                    color: "white",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>

            <Form.Label className={formStyle.formlabelNew}>
              <b>Working Location</b>
            </Form.Label>
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <Row>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Form.Label style={{ marginLeft: "30px", marginRight: "80px" }}>
                  Main Location
                </Form.Label>
                <Form.Group
                  controlId="formMainWorkingLocationDistrict"
                  className={formStyle.formgroupStyles}
                >
                  <div>
                    <Form.Select
                      as="select"
                      name="workMainDistrict"
                      value={formData.workMainDistrict}
                      onChange={handleInputChange}
                      style={{ width: "298px", marginBottom: "10px" }}
                    >
                      <option value="">District</option>
                      {cityData.map((districtData: any) => (
                        <option
                          key={districtData.district.id}
                          value={districtData.district.name}
                        >
                          {districtData.district.name}
                        </option>
                      ))}
                    </Form.Select>
                    {errors.workMainDistrict && (
                      <span className={formStyle.inputerrorMessage}>
                        {errors.workMainDistrict}
                      </span>
                    )}
                  </div>
                </Form.Group>
                <div>
                  <Form.Group
                    controlId="formMainWorkingLocationCity"
                    className={formStyle.formgroupStyles}
                  >
                    <Form.Select
                      as="select"
                      name="workMainCity"
                      style={{
                        width: "295px",
                        marginLeft: "20px",
                        marginBottom: "10px",
                      }}
                      value={formData.workMainCity}
                      onChange={handleInputChange}
                    >
                      <option value="">City</option>
                      {formData.workMainDistrict &&
                        cityData
                          .find(
                            (districtData: any) =>
                              districtData.district.name ===
                              formData.workMainDistrict
                          )
                          ?.district.cities.map((city: any) => (
                            <option key={city.id} value={city.name}>
                              {city.name}
                            </option>
                          ))}
                    </Form.Select>
                    {errors.workMainCity && (
                      <span className={formStyle.inputerrorMessage}>
                        {errors.workMainCity}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </div>
            </Row>

            {[...Array(numSubWorkLocationDropdowns)].map((_, index) => (
              <div key={index}>
                <Row>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Form.Label
                      style={{ marginLeft: "30px", marginRight: "89px" }}
                    >
                      Sub Location
                    </Form.Label>
                    <Form.Group
                      controlId="formSubWorkingLocationDistrict"
                      className={formStyle.formgroupStyles}
                    >
                      <div>
                        <Form.Select
                          as="select"
                          name="workSubDistrict"
                          // value={formData.workSubDistrict}
                          // onChange={handleInputChange}

                          value={selectedSubWorkAreas[index]?.district || ""}
                          onChange={(e: any) => {
                            const subWorkDistrict = e.target.value;
                            setSelectedSubWorkAreas((prevAreas) => {
                              const newAreas = [...prevAreas];
                              newAreas[index] = {
                                ...newAreas[index],
                                district: subWorkDistrict,
                              };
                              return newAreas;
                            });
                          }}
                          style={{ width: "298px", marginBottom: "10px" }}
                        >
                          <option value="">District</option>
                          {cityData.map((districtData: any) => (
                            <option
                              key={districtData.district.id}
                              value={districtData.district.name}
                            >
                              {districtData.district.name}
                            </option>
                          ))}
                        </Form.Select>
                        {errors.workSubDistrict && (
                          <span className={formStyle.inputerrorMessage}>
                            {errors.workSubDistrict}
                          </span>
                        )}
                      </div>
                    </Form.Group>
                    <div>
                      <Form.Group
                        controlId="formSubWorkingLocationCity"
                        className={formStyle.formgroupStyles}
                      >
                        <Form.Select
                          as="select"
                          name="workSubCity"
                          style={{
                            width: "295px",
                            marginLeft: "20px",
                            marginBottom: "10px",
                          }}
                          // value={formData.workSubCity}
                          // onChange={handleInputChange}
                          value={selectedSubWorkAreas[index]?.city || ""}
                          onChange={(e: any) => {
                            const subWorkCity = e.target.value;
                            const updatedSelectedSubWorkAreas = [
                              ...selectedSubWorkAreas,
                            ];
                            updatedSelectedSubWorkAreas[index].city =
                              subWorkCity;
                            setSelectedSubWorkAreas(
                              updatedSelectedSubWorkAreas
                            );
                          }}
                        >
                          <option value="">City</option>
                          {selectedSubWorkAreas.length > 0 &&
                            cityData
                              .find(
                                (districtData: any) =>
                                  districtData.district.name ===
                                  selectedSubWorkAreas[index]?.district
                              )
                              ?.district.cities.map((city: any) => (
                                <option key={city.id} value={city.name}>
                                  {city.name}
                                </option>
                              ))}
                        </Form.Select>
                        {errors.workSubCity && (
                          <span className={formStyle.inputerrorMessage}>
                            {errors.workSubCity}
                          </span>
                        )}
                      </Form.Group>
                    </div>
                  </div>
                </Row>
                <br></br>
              </div>
            ))}
          </div>
        </div>

        <div
          style={{
            border: "1px solid #D7D7D7",
            padding: "10px",
            borderRadius: "1rem",
            marginTop: "1.5rem",
            width: "56rem",
            marginLeft: "1rem",
            paddingBottom: "15px",
          }}
        >
          <div>
            <div style={{ display: "flex" }}>
              <div className="circular-icon" onClick={addCompaniesDropdown}>
                <FontAwesomeIcon
                  icon={faPlus}
                  style={{
                    fontSize: "1rem",
                    color: "white",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            {Array.from({ length: numCompaniesDropdowns }).map((_, index) => (
              <div key={index}>
                <Row>
                  <Form.Group
                    controlId={`formTenets-${index}`}
                    className={formStyle.formgroupStyles}
                    style={{ position: "relative" }} // Add position: relative to allow absolute positioning of the icon
                  >
                    <Form.Label
                      className={formStyle.formlabelNew}
                      style={{ marginRight: "50px", marginTop: "20px" }}
                    >
                      Company
                    </Form.Label>
                    <div style={{ position: "relative" }}>
                      <Form.Select
                        as="select"
                        name={`company-${index}`}
                        style={{ width: "620px", marginTop: "15px" }}
                        value={selectedTanents[index] || ""}
                        onChange={(e: any) => {
                          const tenantId = e.target.value;
                          const updatedSelectedTanents = [...selectedTanents];
                          const previouslySelectedTenants =
                            updatedSelectedTanents.slice(0, index);
                          if (previouslySelectedTenants.includes(tenantId)) {
                            return;
                          }

                          updatedSelectedTanents[index] = tenantId;
                          setSelectedTanents(updatedSelectedTanents);
                        }}
                      >
                        <option value="">Select company</option>
                        {tenantsBe.map(
                          (tenant: any) =>
                            // Exclude already selected products from the options
                            !selectedTanents
                              .slice(0, index)
                              .includes(tenant.id) && (
                              <option key={tenant.id} value={tenant.id}>
                                {tenant.name}
                              </option>
                            )
                        )}
                      </Form.Select>
                      {numCompaniesDropdowns > 1 && ( // Conditionally render minus circle icon
                        <div
                          className="circular-icon minus"
                          onClick={() => deleteCompaniesDropdown(index)}
                          style={{
                            position: "absolute",
                            top: "60%",
                            right: "-40px", // Adjust the position as needed
                            transform: "translateY(-50%)",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faMinus}
                            style={{
                              fontSize: "1rem",
                              color: "white",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      )}
                    </div>
                    {errors.company && (
                      <span className={formStyle.inputerrorMessage}>
                        {errors.company}
                      </span>
                    )}
                  </Form.Group>
                </Row>
                <br></br>
              </div>
            ))}
          </div>
        </div>

        <div
          style={{
            border: "1px solid #D7D7D7",
            padding: "10px",
            borderRadius: "1rem",
            marginTop: "1.5rem",
            width: "56rem",
            marginLeft: "1rem",
            paddingBottom: "15px",
          }}
        >
          <Form.Group
            controlId="formJobType"
            className={formStyle.formgroupStyles}
          >
            <Form.Label className={formStyle.formlabelNew}>
              Photos of Finished Work
            </Form.Label>

            <Col sm="12" style={{ width: "39rem" }}>
              {workImages && workImages.length > 0 ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {Array.from(workImages).map((file: any, index) => (
                    <div
                      key={index}
                      style={{
                        position: "relative",
                        display: "inline-block",
                        marginBottom: "0.5rem",
                        marginLeft: "0.25rem",
                      }}
                    >
                      <img
                        src={close_png}
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          height: "15px",
                          width: "15px",
                        }}
                        alt="Close"
                        onClick={() => handleRemoveNewUploadImg(index)}
                      />

                      <img
                        key={index}
                        src={URL.createObjectURL(file)}
                        alt={`Uploaded image ${index}`}
                        style={{
                          maxWidth: "5rem",
                          marginTop: "1rem",
                          cursor: "pointer",
                          marginLeft: index > 0 ? "0.1rem" : "0",
                          height: "3.5rem",
                          width: "3.5rem",
                        }}
                      />
                    </div>
                  ))}
                </div>
              ) : null}

              <div
                className={styles.fileInputContainer}
                style={{ width: "100%" }}
              >
                <label htmlFor="fileInput" className={styles.customFileLabel}>
                  <span className={styles.uploadIcon}>
                    <img src={upload} className={styles.inputIconTechnician} />
                  </span>{" "}
                  Upload Photos of Work
                </label>
                <input
                  id="fileInput"
                  type="file"
                  name="selectedImages"
                  className="fileInput"
                  value=""
                  onChange={handleFileInputChange}
                  multiple
                  style={{ display: "none" }}
                />
              </div>
            </Col>
          </Form.Group>
        </div>
        {showSccessMsg && successTechnician && (
          <Popup
            show={showPopup}
            onHide={() => {
              setShowPopup(false);
              onCancel();
            }}
            actionButton={false}
            item={"success"}
            modaltitle={"Success!"}
            content={"Successfully Created!"}
          />
        )}

        {errorTechnicianRegister && showErrorMessage && (
          <Popup
            show={showPopup}
            onHide={() => {
              setErrors({});
              setShowPopup(false);
            }}
            actionButton={false}
            modaltitle={"Error!"}
            content={errorTechnicianRegister}
          />
        )}

        <div className="button-container">
          <Button
            style={{
              width: "25vh",
              backgroundColor: "black",
              marginTop: "4vh",
              padding: "0.7rem",
              marginRight: "2vh",
            }}
            onClick={onCancel}
            children={"Back"}
          />
          <Button
            style={{
              width: "25vh",
              backgroundColor: "#FFC600",
              marginTop: "4vh",
              padding: "0.7rem",
            }}
            onClick={() => console.log("ok")}
            children={"Save"}
          />
        </div>
      </Form>
    </div>
  );
};

export default TechnicianRegister;
