import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row, InputGroup, FormControl } from "react-bootstrap";
import formStyle from "../../styles/Technician/TechnicianEdit.module.css";
import Button from "../../components/Button";
import upload from "../../assets/upload.png";
import styles from "../../styles/WorkRequest.module.css";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import close_png from "../../assets/close.png";
import { BASE_URL_FOR_IMAGE } from "../../constants/appConstants";
import { editTechnician } from "../../services/technicianService";
import SpinnerLoading from "../../components/SpinnerLoading";
import Popup from "../../components/Popup";

interface JobTypeExperience {
  jobType: {
    id: string;
    type: string;
  };
  experienceInYears: number;
  experienceInMonths: number;
}

interface FormData {
  profileImageUrl: string;
  address: string;
  district: string;
  city: string;
  jobType: any;
  ExpYears: number;
  ExpMonths: number;
  noOfWorkers: number;
  avgWorkNo: number;
  company: any;
  workMainDistrict: string;
  workMainCity: string;
  workSubDistrict: string;
  workSubCity: string;
  workImages: any;
}

interface Props {
  onCancel: () => void;
  onSaveSuccess: () => void;
}

interface Tenant {
  id: string;
  name: string;
  logo: string;
}

const TechnicianEdit: React.FC<Props> = ({ onCancel, onSaveSuccess }) => {
  const dispatch = useDispatch<any>();
  const singleTechnicianData = useSelector(
    (state: any) => state.selectedTechnican.singleTechnician
  );

  const tenantsBe = useSelector((state: any) => state.tenants.tenants);
  // const jobTypesBe = useSelector((state: any) => state.jobTypes.jobTypes);
  const jobTypesBe = useSelector((state: any) => state.jobTypes.jobTypes.filter((jobType: any) => jobType.status === 1));

  const cityData = useSelector((state: any) => state.locationData.cityData);

  const filteredTenantsBe = tenantsBe.filter((tenantBe: Tenant) => {
    // Check if the tenantBe's id exists in singleTechnicianData?.workExperience?.tanents array
    return !singleTechnicianData?.workExperience?.tanents.some(
      (tenant: Tenant) => tenant.id === tenantBe.id
    );
  });

  const [files, setFiles] = useState<File[]>([]);
  const [showSccessMsg, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [workImages, setWorkImages] = useState<any>([]);
  const [numCompaniesDropdowns, setNumCompaniesDropdowns] = useState(1);
  const [numSubWorkLocationDropdowns, setNumSubWorkLocationDropdowns] =
    useState(1);
  const [selectedTanents, setSelectedTanents] = useState<string[]>([]);
  const [savedTanents, setSavedTanents] = useState<string[]>([]);
  const [numJobTypeDropdowns, setNumJobTypeDropdowns] = useState(1);
  const initialJobExperiences = Array(numJobTypeDropdowns).fill({
    id: "",
    experienceInYears: 0,
    experienceInMonths: 0,
  });
  const [selectedjobExperiences, setSelectedjobExperiences] = useState(
    initialJobExperiences
  );
  const [savedjobExperiences, setSavedjobExperiences] = useState<any>([]);

  const initialSubWorkingLocation = Array(numSubWorkLocationDropdowns).fill({
    district: "",
    city: "",
  });
  const [selectedSubWorkAreas, setSelectedSubWorkAreas] = useState(
    initialSubWorkingLocation
  );
  const [savedSubWorkAreas, setSavedSubWorkAreas] = useState<any>([]);

  const [MainWorkDistrict, setMainWorkDistrict] = useState<string | null>("");
  const [MainWorkCity, setMainWorkCity] = useState<string | null>("");

  const [profileImageUrlDispay, setprofileImageUrlDispay] = useState<
    string | null
  >(null);
  const [profileImageUrl, setprofileImageUrl] = useState<string | null>(null);
  const [newUploadImagefile, setNewUploadImagefile] = useState<any | null>(
    null
  );

  const [savedWorkImages, setSavedWorkImages] = useState<any>([]);
  const [noGalleryImages, setNoGalleryImages] = useState<any>(10);

  const errorTechnicianEdit = useSelector(
    (state: any) => state.technicians.error
  );
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const successTechnician = useSelector(
    (state: any) => state.technicians.success
  );
  const loading = useSelector((state: any) => state.technicians.loading);

  useEffect(() => {
    setprofileImageUrl(singleTechnicianData?.profileImage?.url);

    setFormData({
      profileImageUrl: singleTechnicianData?.profileImage.url || "",
      address: singleTechnicianData?.address || "",
      district: singleTechnicianData?.district || "",
      city: singleTechnicianData?.city || "",
      jobType: "",
      ExpYears: 0,
      ExpMonths: 0,
      workSubDistrict: "",
      workSubCity: "",
      company: "",
      workMainDistrict: "",
      workMainCity: "",
      noOfWorkers: singleTechnicianData?.noOfWorkers || 0,
      avgWorkNo: singleTechnicianData?.numberOfAverageWorkers || 0,
      workImages: singleTechnicianData?.workExperience?.imagesOfWork || [],
    });

    setSavedSubWorkAreas(
      singleTechnicianData?.workExperience?.subWorkingAreas || []
    );

    setSavedWorkImages(
      singleTechnicianData?.workExperience?.imagesOfWork || []
    );

    const registerdTenants: any = [];

    singleTechnicianData?.workExperience?.tanents.forEach((tenant: any) => {
      registerdTenants.push(tenant.id);
    });
    setSavedTanents(registerdTenants);

    const extractedData: {
      id: string;
      type: string;
      experienceInYears: number;
      experienceInMonths: number;
    }[] = singleTechnicianData?.workExperience?.jobTypeExperiences?.map(
      ({
        jobType: { id, type },
        experienceInYears,
        experienceInMonths,
      }: JobTypeExperience) => ({
        id,
        type,
        experienceInYears,
        experienceInMonths,
      })
    );

    setSavedjobExperiences(extractedData);
    if (singleTechnicianData?.workExperience?.imagesOfWork?.length > 0) {
      setNoGalleryImages(
        10 - singleTechnicianData?.workExperience?.imagesOfWork?.length
      );
    }

    if (singleTechnicianData?.workExperience?.mainWorkingArea) {
      setMainWorkCity(
        singleTechnicianData?.workExperience?.mainWorkingArea?.city
      );
      setMainWorkDistrict(
        singleTechnicianData?.workExperience?.mainWorkingArea?.district
      );
    }
  }, [singleTechnicianData]);

  useEffect(() => {
    const newFileList = createFileList(files);
    setWorkImages(newFileList);
  }, [files]);

  const createFileList = (files: File[]) => {
    const fileList = new DataTransfer();
    files.forEach((file) => fileList.items.add(file));
    return fileList.files;
  };

  const [formData, setFormData] = useState<FormData>({
    // Initialize form fields with default values

    profileImageUrl: singleTechnicianData?.profileImage.url,
    address: singleTechnicianData?.address,
    district: singleTechnicianData?.district,
    city: singleTechnicianData?.city,
    jobType: "",
    ExpYears: 0,
    ExpMonths: 0,
    workMainDistrict: "",
    workMainCity: "",
    workSubDistrict: "",
    workSubCity: "",
    company: "",
    noOfWorkers: singleTechnicianData?.noOfWorkers,
    avgWorkNo: singleTechnicianData?.numberOfAverageWorkers,
    workImages: singleTechnicianData?.workExperience?.imagesOfWork,
  });

  const handleFileInputChange = (e: any) => {
    changeMessages();
    const fileList = e.target.files;

    const filesWithBlob: File[] = [];

    for (const file of fileList) {
      if (filesWithBlob.length < noGalleryImages) {
        const extension = file.name.split(".").pop()?.toLowerCase();
        if (
          extension === "jpeg" ||
          extension === "png" ||
          extension === "jpg"
        ) {
          filesWithBlob.push(new File([file], file.name, { type: file.type }));
        }
      } else {
        console.log("Upload limit reached. Only 10 files can be uploaded.");
        break;
      }
    }

    setFiles(filesWithBlob);
  };

  //set errors to profile picture validation
  const [profileImageDimensionsError, setProfileImageDimensionsError] = useState<string | null>(null);
  const MAX_WIDTH = 800;
  const MIN_WIDTH = 100;
  const MAX_HEIGHT = 800;
  const MIN_HEIGHT = 100;

  const handleImageUpload = (event: any) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const { name } = event.target;

      // Create an image object to get the dimensions
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const width = img.width;
        const height = img.height;

        // Check if the image meets the minimum and maximum width and height requirements
        if (width > MAX_WIDTH || height > MAX_HEIGHT) {
          setProfileImageDimensionsError(`Please ensure that your profile image does not exceed the maximum dimensions of ${MAX_WIDTH} pixels by ${MAX_HEIGHT} pixels.`);
        } else if (width < MIN_WIDTH || height < MIN_HEIGHT) {
          setProfileImageDimensionsError(`Please ensure that your profile image meets the minimum dimensions of ${MIN_WIDTH} pixels by ${MIN_HEIGHT} pixels.`);
        } else {
          setProfileImageDimensionsError(null);

          // Update profile image URL and file
          if (name === "profileImageUrl") {
            setprofileImageUrl(null);
            setprofileImageUrlDispay(URL.createObjectURL(file));
            setNewUploadImagefile(file);
          }

          // Clear error for the edited field if it exists
          if (name in errors) {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
          }

          // Update form data with the selected file
          setFormData((prevData) => ({
            ...prevData,
            [name]: file,
          }));
        }
      };
    }
  };

  const changeMessages = () => {
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
  };

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    changeMessages();
    const { name, value } = event.target;
    const newErrors = { ...errors };
    if (name in errors) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
    }

    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors(newErrors);
  };

  const [errors, setErrors] = useState<Partial<FormData>>({});


  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isValid = validateForm(); // Get validation result

    let filteredMergedExperiences: any = [];

    if (savedjobExperiences) {
      const mergedExperiences = [
        ...savedjobExperiences.map(
          ({ id, experienceInYears, experienceInMonths }: any) => ({
            id,
            experienceInYears,
            experienceInMonths,
          })
        ),
        ...selectedjobExperiences.map(
          ({ id, experienceInYears, experienceInMonths }: any) => ({
            id,
            experienceInYears,
            experienceInMonths,
          })
        ),
      ];
      filteredMergedExperiences = mergedExperiences.filter(
        (experience) => experience.id !== ""
      );
    } else {
      filteredMergedExperiences = selectedjobExperiences.map(
        ({ id, experienceInYears, experienceInMonths }: any) => ({
          id,
          experienceInYears,
          experienceInMonths,
        })
      );
    }

    const combinedAreas = [...selectedSubWorkAreas, ...savedSubWorkAreas];
    const mergedAreas = combinedAreas.filter(
      (area, index, self) =>
        index ===
        self.findIndex(
          (a) => a.district === area.district && a.city === area.city
        )
    );

    const filteredmergedAreas = mergedAreas.filter(
      (area) => area.district !== ""
    );

    const finalizedMainWorkingArea: any = {};

    if (!singleTechnicianData?.workExperience?.mainWorkingArea) {
      finalizedMainWorkingArea.district = formData?.workMainDistrict;
      finalizedMainWorkingArea.city = formData?.workMainCity;
    } else {
      finalizedMainWorkingArea.district =
        singleTechnicianData?.workExperience?.mainWorkingArea?.district;
      finalizedMainWorkingArea.city =
        singleTechnicianData?.workExperience?.mainWorkingArea?.city;
    }

    const workExperienceData: any = {
      jobTypeExperiences: filteredMergedExperiences,
      mainWorkingArea: finalizedMainWorkingArea,
      subWorkingAreas: filteredmergedAreas,
      tanentIds: [...selectedTanents, ...savedTanents],
      imagesOfWork: workImages,
      savedImagesOfWork: savedWorkImages,
    };

    const requestObj: any = {
      address: formData?.address,
      district: formData?.district,
      city: formData?.city,
      company: selectedTanents,
      noOfWorkers: formData?.noOfWorkers,
      avgWorkNo: formData?.avgWorkNo,
      workExperienceData: workExperienceData,
    };

    if (newUploadImagefile) {
      const requestObjWithProfilePic = {
        ...requestObj,
        profileImage: newUploadImagefile,
      };
      dispatch(
        editTechnician(singleTechnicianData?.id, requestObjWithProfilePic)
      );
    } else {
      dispatch(editTechnician(singleTechnicianData?.id, requestObj));
    }
    setShowSuccessMessage(true);
    setShowErrorMessage(true);
    setShowPopup(true);
  };

  const validateForm = () => {
    const newErrors: any = {};

    // Address
    if (!formData.address) {
      newErrors.address = "Please enter your address.";
    }

    // District
    if (!formData.district) {
      newErrors.district = "Please select your district.";
    }

    // City
    if (!formData.city) {
      newErrors.city = "Please select your city.";
    }

    // Job type
    if (!formData.jobType) {
      newErrors.jobType = "Please select your job type.";
    }

    // Company
    // if (!formData.company) {
    //     newErrors.company = "Please enter your company name.";
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const addCompanyDropdown = () => {
    setNumCompaniesDropdowns(numCompaniesDropdowns + 1);
    setSelectedTanents((prevSelected) => [...prevSelected, ""]);
  };

  const deleteCompanyDropdown = (indexToDelete: number) => {
    if (numCompaniesDropdowns !== 1) {
      setNumCompaniesDropdowns(numCompaniesDropdowns - 1);
      setSelectedTanents((prevSelected) => {
        const updatedSelectedTanents = [...prevSelected];
        updatedSelectedTanents.splice(indexToDelete, 1);

        // If the first dropdown is being deleted, reset its selected value to ""
        if (indexToDelete === 0) {
          updatedSelectedTanents[0] = "";
        }

        return updatedSelectedTanents;
      });
    }
  };



  const addJobTypeDropdown = () => {
    setNumJobTypeDropdowns(numJobTypeDropdowns + 1);
  };

  const deleteJobTypeDropdown = () => {
    if (numJobTypeDropdowns !== 1) {
      setNumJobTypeDropdowns(numJobTypeDropdowns - 1);
    }
  };

  const addWorkLocationDropdown = () => {
    setNumSubWorkLocationDropdowns(numSubWorkLocationDropdowns + 1);
  };

  const deleteWorkLocationDropdown = () => {
    if (numSubWorkLocationDropdowns !== 1) {
      setNumSubWorkLocationDropdowns(numSubWorkLocationDropdowns - 1);
    }
  };

  const deleteSelectedJobExperience = (idToRemove: string) => {
    const updatedExperiences = savedjobExperiences.filter(
      (experience: any) => experience.id !== idToRemove
    );

    setSavedjobExperiences(updatedExperiences);
  };

  const deleteSelectedSavedSubArea = (indexToRemove: number) => {
    const updatedSubWorkAreas = [...savedSubWorkAreas];
    updatedSubWorkAreas.splice(indexToRemove, 1);

    setSavedSubWorkAreas(updatedSubWorkAreas);
  };

  const handleRemoveAllImages = () => {
    setWorkImages(null);
  };

  const handleRemoveProfileImage = () => {
    setprofileImageUrl(null);
    setprofileImageUrlDispay(null);
    const imageFileElement = document.getElementById(
      "profile-image-file"
    ) as HTMLInputElement | null;
    if (imageFileElement) {
      imageFileElement.value = "";
      formData.profileImageUrl = ""; // Reset the profileImageUrl
    }
  };

  const handleRemoveWorkImage = (linkToRemove: string) => {
    const updatedImages = savedWorkImages.filter(
      (image: any) => image.url !== linkToRemove
    );
    setSavedWorkImages(updatedImages);
    setNoGalleryImages(10 - updatedImages.length);
  };

  const handleRemoveNewUploadImg = (idToRemove: number) => {
    const updatedUploadImages = [...workImages];
    updatedUploadImages.splice(idToRemove, 1); // Corrected to remove one element at the specified index

    setWorkImages(updatedUploadImages);
  };

  if (loading) {
    return <SpinnerLoading />;
  }

  return (
    <div className="technician_edit_container">
      <div
        className="page_title"
        style={{ fontWeight: "bold", fontSize: "18px", marginBottom: "10px" }}
      >
        Edit Technician Details
      </div>
      <Form onSubmit={handleSubmit}>
        <div className="technician_edit_form" style={{ display: "flex" }}>
          <div
            className="technician_edit_left"
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "1.3rem",
              width: "30rem",
            }}
          >
            <div className="technician_profile_picture">
              <Form.Group as={Col} controlId="formPlaintextFile">
                <Col
                  style={{
                    width: "12rem",
                    marginTop: "1rem",
                  }}
                >
                  <div
                    className={styles.fileInputContainer}
                    style={{ width: "100%" }}
                  >

                    <label
                      // htmlFor="fileInput"
                      className={styles.customFileLabel}
                    >
                      <span className={styles.uploadIcon}>
                        {profileImageUrl ? (
                          <>
                            <img
                              src={BASE_URL_FOR_IMAGE + profileImageUrl}
                              style={{
                                cursor: "pointer",
                                width: "100%",
                                height: "100%",
                              }}
                            />
                            <input
                              type="file"
                              id="profile-image-file"
                              name="profileImageUrl"
                              className={styles.fileInput}
                              onChange={handleImageUpload}
                            />
                          </>
                        ) : profileImageUrlDispay ? (
                          <>
                            <img
                              src={profileImageUrlDispay}
                              style={{
                                cursor: "pointer",
                                width: "100%",
                                height: "100%",
                              }}
                            />
                            <input
                              type="file"
                              id="profile-image-file"
                              name="profileImageUrl"
                              className={styles.fileInput}
                              onChange={handleImageUpload}
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src={upload}
                              className={styles.inputIconTechnician}
                            />
                            <input
                              type="file"
                              id="profile-image-file"
                              name="profileImageUrl"
                              className={styles.fileInput}
                              onChange={handleImageUpload}
                            />
                          </>
                        )}
                      </span>{" "}
                      Profile Picture
                    </label>
                  </div>
                  {profileImageDimensionsError && (
                    <span className={formStyle.inputerrorMessage}>{profileImageDimensionsError}</span>
                  )}
                </Col>
              </Form.Group>
            </div>
          </div>

          <div
            className="technician_edit_middle"
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "1.3rem",
            }}
          >
            <div className="No-of-workers">
              <Form.Label className={formStyle.formlabelStyles}>
                No. of Workers{" "}
              </Form.Label>
              <Form.Group
                controlId="formNoOfWorkers"
                className={formStyle.formgroupStyles}
              >
                <div>
                  <Form.Control
                    type="number"
                    placeholder="Enter number of workers under you"
                    name="noOfWorkers"
                    value={formData.noOfWorkers}
                    className={formStyle.inputField}
                    onChange={handleInputChange}
                  />
                  {errors.noOfWorkers && (
                    <span className={formStyle.inputerrorMessage}>
                      {errors.noOfWorkers}
                    </span>
                  )}
                </div>
              </Form.Group>
            </div>

            <div className="Avg-Monthly-Work">
              <Form.Label className={formStyle.formlabelStyles}>
                Average Number of Work{" "}
              </Form.Label>
              <Form.Group
                controlId="Avg-Monthly-Work"
                className={formStyle.formgroupStyles}
              >
                <div>
                  <Form.Control
                    type="number"
                    placeholder="Enter your monthly work total"
                    name="avgWorkNo"
                    value={formData.avgWorkNo}
                    className={formStyle.inputField}
                    onChange={handleInputChange}
                  />
                  {errors.avgWorkNo && (
                    <span className={formStyle.inputerrorMessage}>
                      {errors.avgWorkNo}
                    </span>
                  )}
                </div>
              </Form.Group>
            </div>

            <div className="Address">
              <div className="Address_top">
                <Form.Group
                  controlId="formAddress"
                  className={formStyle.formgroupStyles}
                >
                  <Form.Label className={formStyle.formlabelStyles}>
                    Address
                  </Form.Label>
                  <div>
                    <Form.Control
                      placeholder="Enter your address here"
                      name="address"
                      value={formData.address}
                      onChange={handleInputChange}
                    />
                    {errors.address && (
                      <span className={formStyle.inputerrorMessage}>
                        {errors.address}
                      </span>
                    )}
                  </div>
                </Form.Group>
              </div>

              <div className="Address_bottom" style={{ display: "flex" }}>
                <Form.Group
                  controlId="formDistrict"
                  className={formStyle.formgroupStyles}
                  style={{ width: "100%" }}
                >
                  <Form.Control
                    as="select"
                    name="district"
                    value={formData.district}
                    onChange={handleInputChange}
                  >
                    <option value="">{singleTechnicianData?.district}</option>
                    {cityData.map((districtData: any) => (
                      <option
                        key={districtData.district.id}
                        value={districtData.district.name}
                      >
                        {districtData.district.name}
                      </option>
                    ))}
                  </Form.Control>
                  {errors.district && (
                    <span className={formStyle.inputerrorMessage}>
                      {errors.district}
                    </span>
                  )}
                </Form.Group>

                <Form.Group
                  controlId="formDistrict"
                  className={formStyle.formgroupStyles}
                  style={{ width: "100%" }}
                >
                  <Form.Control
                    as="select"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                  >
                    <option value="">{singleTechnicianData?.city}</option>
                    {formData.district &&
                      cityData
                        .find(
                          (districtData: any) =>
                            districtData.district.name === formData.district
                        )
                        ?.district.cities.map((city: any) => (
                          <option key={city.id} value={city.name}>
                            {city.name}
                          </option>
                        ))}
                  </Form.Control>
                  {errors.city && (
                    <span className={formStyle.inputerrorMessage}>
                      {errors.city}
                    </span>
                  )}
                </Form.Group>
              </div>
            </div>

            <div className="Companies" style={{ border: "1px solid black", borderRadius: "5px", padding: "10px", marginBottom: "1rem" }}>
              <Form.Label className={formStyle.formlabelStyles}>Company</Form.Label>
              {singleTechnicianData?.workExperience?.tanents.map((tanent: any, index: number) => (
                <div key={index} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <div>{tanent.name}</div>
                </div>
              ))}
              <br />

              <div>
                {[...Array(numCompaniesDropdowns)].map((_, index) => (
                  <div key={index}>
                    <Form.Group controlId={`Company${index}`} className={formStyle.formgroupStyles}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Form.Select
                          as="select"
                          name={`company${index}`}
                          style={{ width: "620px" }}
                          value={selectedTanents[index] || ""}
                          onChange={(e: any) => {
                            const tanentId = e.target.value;
                            const updatedSelectedTanents = [...selectedTanents];
                            updatedSelectedTanents[index] = tanentId; // Update the value at the specific index
                            setSelectedTanents(updatedSelectedTanents);
                          }}
                        >
                          <option value="">Select company</option>
                          {filteredTenantsBe.map((tenant: any) => (
                            <option key={tenant.id} value={tenant.id}>{tenant.name}</option>
                          ))}
                        </Form.Select>
                        {numCompaniesDropdowns > 1 && ( // Conditionally render minus circle icon
                          <div className="circular-icon minus" onClick={() => deleteCompanyDropdown(index)}>
                            <FontAwesomeIcon icon={faMinus} style={{ fontSize: "1rem", color: "white", cursor: "pointer" }} />
                          </div>
                        )}
                      </div>
                    </Form.Group>
                  </div>
                ))}
              </div>

              <div>
                <div style={{ display: "flex" }}>
                  <div className="circular-icon" onClick={addCompanyDropdown}>
                    <FontAwesomeIcon icon={faPlus} style={{ fontSize: "1rem", color: "white", cursor: "pointer" }} />
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="technician_edit_right">
            <div
              style={{
                border: "1px solid black",
                borderRadius: "5px",
                padding: "10px",
                marginBottom: "1rem",
              }}
            >
              <Form.Label className={formStyle.formlabelNew}>
                <b>Job Experience</b>
              </Form.Label>

              {savedjobExperiences?.map((jobExperience: any) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <tr>
                    <td style={{ width: "19rem" }}>
                      <div>{jobExperience.type} </div>
                    </td>
                    <td style={{ width: "5rem" }}>
                      <div>{jobExperience.experienceInYears} Years</div>
                    </td>
                    <td style={{ width: "12rem" }}>
                      <div>{jobExperience.experienceInMonths} Months</div>
                    </td>
                    <td>
                      <div
                        className="circular-icon"
                        onClick={() =>
                          deleteSelectedJobExperience(jobExperience.id)
                        }
                      >
                        <FontAwesomeIcon
                          icon={faMinus}
                          style={{
                            fontSize: "1rem",
                            color: "white",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                </div>
              ))}
              {[...Array(numJobTypeDropdowns)].map((_, index) => (
                <div key={index} style={{ padding: "1rem" }}>
                  <div>
                    <Form.Group
                      controlId="formJobType"
                      className={formStyle.formgroupStyles}
                    >
                      <div>
                        <Form.Control
                          as="select"
                          name="jobType"
                          style={{ width: "34.5625rem" }}
                          value={selectedjobExperiences[index]?.id || ""}
                          onChange={(e: any) => {
                            const jobId = e.target.value;
                            setSelectedjobExperiences((prevExperiences) => {
                              const updatedExperiences = [...prevExperiences];
                              updatedExperiences[index] = {
                                ...updatedExperiences[index],
                                id: jobId,
                              };
                              return updatedExperiences;
                            });
                          }}
                        >
                          <option value="">Select job type</option>
                          {jobTypesBe.map((jobTypes: any) => (
                            <option key={jobTypes.id} value={jobTypes.id}>
                              {jobTypes.type}
                            </option>
                          ))}
                        </Form.Control>
                      </div>
                    </Form.Group>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "-2rem",
                    }}
                    className={formStyle.formgroupStyles}
                  >
                    <Row style={{ display: "flex" }}>
                      <Form.Group
                        as={Col}
                        controlId="formExperienceYears"
                        className={formStyle.formgroupStyles}
                        style={{ width: "295px" }}
                      >
                        <Col sm="12">
                          <div className={formStyle.inputField}>
                            <Form.Control
                              type="number"
                              placeholder="Years"
                              name={"ExpYears"}
                              value={
                                selectedjobExperiences[index]
                                  ?.experienceInYears || 0
                              }
                              onChange={(e: any) => {
                                const expYears = parseInt(e.target.value) || 0;
                                const updatedSelectedJobExperiences = [
                                  ...selectedjobExperiences,
                                ];
                                updatedSelectedJobExperiences[
                                  index
                                ].experienceInYears = expYears;
                                setSelectedjobExperiences(
                                  updatedSelectedJobExperiences
                                );
                              }}
                            />
                          </div>
                        </Col>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        controlId="formExperienceMonths"
                        className={formStyle.formgroupStyles}
                        style={{ width: "282px", marginLeft: "0" }}
                      >
                        <Col sm="12">
                          <div className={formStyle.inputField}>
                            <Form.Control
                              type="number"
                              placeholder="Months"
                              name={"ExpMonths"}
                              className={formStyle.inputField}
                              value={
                                selectedjobExperiences[index]
                                  ?.experienceInMonths || 0
                              }
                              onChange={(e: any) => {
                                const expMonths = parseInt(e.target.value) || 0;
                                const updatedSelectedJobExperiences = [
                                  ...selectedjobExperiences,
                                ];
                                updatedSelectedJobExperiences[
                                  index
                                ].experienceInMonths = expMonths;
                                setSelectedjobExperiences(
                                  updatedSelectedJobExperiences
                                );
                              }}
                            />
                          </div>
                        </Col>
                      </Form.Group>
                    </Row>
                  </div>
                </div>
              ))}

              <div style={{ display: "flex", padding: "1rem" }}>
                <div className="circular-icon" onClick={addJobTypeDropdown}>
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{
                      fontSize: "1rem",
                      color: "white",
                      cursor: "pointer",
                    }}
                  />
                </div>

                <div className="circular-icon" onClick={deleteJobTypeDropdown}>
                  <FontAwesomeIcon
                    icon={faMinus}
                    style={{
                      fontSize: "1rem",
                      color: "white",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            </div>

            <div
              className="working_location"
              style={{
                border: "1px solid black",
                borderRadius: "5px",
                padding: "10px",
                marginBottom: "1rem",
              }}
            >
              {!singleTechnicianData?.workExperience?.mainWorkingArea ? (
                <div>
                  <Form.Label className={formStyle.formlabelStyles}>
                    Main Working Location
                  </Form.Label>

                  <Row>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Form.Group
                        controlId="formMainWorkingLocationDistrict"
                        className={formStyle.formgroupStyles}
                      >
                        <div>
                          <Form.Select
                            as="select"
                            name="workMainDistrict"
                            value={formData.workMainDistrict}
                            onChange={handleInputChange}
                            style={{ width: "298px", marginBottom: "10px" }}
                          >
                            <option value="">District</option>
                            {cityData.map((districtData: any) => (
                              <option
                                key={districtData.district.id}
                                value={districtData.district.name}
                              >
                                {districtData.district.name}
                              </option>
                            ))}
                          </Form.Select>
                        </div>
                      </Form.Group>
                      <div>
                        <Form.Group
                          controlId="formMainWorkingLocationCity"
                          className={formStyle.formgroupStyles}
                        >
                          <Form.Select
                            as="select"
                            name="workMainCity"
                            style={{
                              width: "295px",
                              marginLeft: "20px",
                              marginBottom: "10px",
                            }}
                            value={formData.workMainCity}
                            onChange={handleInputChange}
                          >
                            <option value="">City</option>
                            {formData.workMainDistrict &&
                              cityData
                                .find(
                                  (districtData: any) =>
                                    districtData.district.name ===
                                    formData.workMainDistrict
                                )
                                ?.district.cities.map((city: any) => (
                                  <option key={city.id} value={city.name}>
                                    {city.name}
                                  </option>
                                ))}
                          </Form.Select>
                        </Form.Group>
                      </div>
                    </div>
                  </Row>
                </div>
              ) : (
                <div>
                  <Form.Label className={formStyle.formlabelStyles}>
                    Main Working Location
                  </Form.Label>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <tr>
                      <td style={{ width: "10rem" }}>
                        <div>{MainWorkDistrict} </div>
                      </td>
                      <td style={{ width: "25rem" }}>
                        <div>{MainWorkCity}</div>
                      </td>
                    </tr>
                  </div>
                </div>
              )}

              <br />

              <Form.Label className={formStyle.formlabelStyles}>
                Sub Working Location
              </Form.Label>

              {savedSubWorkAreas?.map((subArea: any, index: any) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <tr>
                    <td style={{ width: "10rem" }}>
                      <div>{subArea.district} </div>
                    </td>
                    <td style={{ width: "25rem" }}>
                      <div>{subArea.city}</div>
                    </td>
                    <td>
                      <div
                        className="circular-icon"
                        onClick={() => deleteSelectedSavedSubArea(index)}
                      >
                        <FontAwesomeIcon
                          icon={faMinus}
                          style={{
                            fontSize: "1rem",
                            color: "white",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                </div>
              ))}

              <div style={{ display: "flex", flexDirection: "column" }}>
                {[...Array(numSubWorkLocationDropdowns)].map((_, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      padding: "1rem",
                      paddingBottom: "0",
                    }}
                  >
                    <Form.Group
                      controlId="formMainWorkingLocationDistrict"
                      className={formStyle.formgroupStyles}
                      style={{ width: "100%" }}
                    >
                      <Form.Control
                        as="select"
                        name="workDistrict"
                        value={selectedSubWorkAreas[index]?.district || ""}
                        onChange={(e: any) => {
                          const subWorkDistrict = e.target.value;
                          setSelectedSubWorkAreas((prevAreas) => {
                            const newAreas = [...prevAreas];
                            newAreas[index] = {
                              ...newAreas[index],
                              district: subWorkDistrict,
                            };
                            return newAreas;
                          });
                        }}
                      >
                        <option value="">District</option>
                        {cityData.map((districtData: any) => (
                          <option
                            key={districtData.district.id}
                            value={districtData.district.name}
                          >
                            {districtData.district.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group
                      controlId="formMainWorkingLocationCity"
                      className={formStyle.formgroupStyles}
                      style={{ width: "100%" }}
                    >
                      <Form.Control
                        as="select"
                        name="workCity"
                        // value={formData.workCity}
                        value={selectedSubWorkAreas[index]?.city || ""}
                        onChange={(e: any) => {
                          const subWorkCity = e.target.value;
                          const updatedSelectedSubWorkAreas = [
                            ...selectedSubWorkAreas,
                          ];
                          updatedSelectedSubWorkAreas[index].city = subWorkCity;
                          setSelectedSubWorkAreas(updatedSelectedSubWorkAreas);
                        }}
                      >
                        <option value="">City</option>
                        {selectedSubWorkAreas.length > 0 &&
                          cityData
                            .find(
                              (districtData: any) =>
                                districtData.district.name ===
                                selectedSubWorkAreas[index]?.district
                            )
                            ?.district.cities.map((city: any) => (
                              <option key={city.id} value={city.name}>
                                {city.name}
                              </option>
                            ))}
                      </Form.Control>
                      {/* {errors.workCity && (
                                        <span className={formStyle.inputerrorMessage}>
                                            {errors.workCity}
                                        </span>
                                    )} */}
                    </Form.Group>
                  </div>
                ))}

                <div style={{ display: "flex" }}>
                  <div
                    className="circular-icon"
                    onClick={addWorkLocationDropdown}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      style={{
                        fontSize: "1rem",
                        color: "white",
                        cursor: "pointer",
                      }}
                    />
                  </div>

                  <div
                    className="circular-icon"
                    onClick={deleteWorkLocationDropdown}
                  >
                    <FontAwesomeIcon
                      icon={faMinus}
                      style={{
                        fontSize: "1rem",
                        color: "white",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                border: "1px solid black",
                borderRadius: "5px",
                padding: "10px",
                marginBottom: "1rem",
              }}
            >
              <Form.Label className={formStyle.formlabelNew}>
                <b>Work Gallery</b>
              </Form.Label>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  overflowX: "auto",
                }}
              >
                {savedWorkImages &&
                  savedWorkImages.map((image: any) => (
                    <div
                      key={image.id}
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <img
                        src={BASE_URL_FOR_IMAGE + image.url}
                        style={{
                          width: "3rem",
                          height: "3rem",
                          margin: "0.2rem",
                        }}
                        alt={image.id}
                      />
                      <img
                        src={close_png}
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          height: "15px",
                          width: "15px",
                        }}
                        alt="Close"
                        onClick={() => handleRemoveWorkImage(image.url)}
                      />
                    </div>
                  ))}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  overflowX: "auto",
                }}
              >
                {workImages && workImages.length > 0 ? (
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {Array.from(workImages).map((file: any, index) => (
                      <div
                        key={index}
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        <img
                          key={index}
                          src={URL.createObjectURL(file)}
                          style={{
                            width: "3rem",
                            height: "3rem",
                            margin: "0.2rem",
                          }}
                          alt={`Uploaded image ${index}`}
                        />
                        <img
                          src={close_png}
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            height: "15px",
                            width: "15px",
                          }}
                          alt="Close"
                          onClick={() => handleRemoveNewUploadImg(index)}
                        />
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>

              <div
                className={styles.fileInputContainer}
                style={{ width: "100%" }}
              >
                <label htmlFor="fileInput" className={styles.customFileLabel}>
                  <span className={styles.uploadIcon}>
                    {noGalleryImages.length > 0 ? (
                      <img
                        src={upload}
                        className={styles.inputIconTechnician}
                      />
                    ) : (
                      <img
                        src={upload}
                        className={styles.inputIconTechnician}
                      />
                    )}
                  </span>{" "}
                  Upload Photos of Work
                </label>

                <input
                  id="fileInput"
                  type="file"
                  name="selectedImages"
                  className="fileInput"
                  value=""
                  onChange={handleFileInputChange}
                  multiple
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </div>
        </div>

        {showSccessMsg && successTechnician && (
          <Popup
            show={showPopup}
            onHide={() => {
              setShowPopup(false);
              onCancel();
            }}
            actionButton={false}
            item={"success"}
            modaltitle={"Success!"}
            content={"Record Successfully Updated!"}
          />
        )}

        {errorTechnicianEdit && showErrorMessage && (
          <Popup
            show={showPopup}
            onHide={() => {
              setErrors({});
              setShowPopup(false)
            }}
            actionButton={false}
            modaltitle={"Error!"}
            content={errorTechnicianEdit}
          />
        )}

        {/* Submit buttons */}

        <div className="button-container">
          <Button
            style={{
              width: "25vh",
              backgroundColor: "black",
              marginTop: "4vh",
              padding: "0.7rem",
              marginRight: "2vh",
            }}
            onClick={onCancel}
            children={"Back"}
          />
          <Button
            style={{
              width: "25vh",
              backgroundColor: "#FFC600",
              marginTop: "4vh",
              padding: "0.7rem",
            }}
            onClick={() => console.log("ok")}
            children={"Update"}
          />
        </div>
      </Form>
    </div>
  );
};

export default TechnicianEdit;
