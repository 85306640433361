// Header.js
import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import "../styles/Styles.scss";
import bell from "../assets/bell.png";
import profileIcon from "../assets/profileIcon.png";

const Header = () => {
  const theme = "dark";
  return (
    <Navbar className="main-header custom-navbar" expand="lg">
      <div className="fixed-header-container">
      <Navbar.Brand href="#home"></Navbar.Brand>
        <div className="item-container">
          <div>
            <img src={bell} alt="Logo" className="bell-icon" />
          </div>
          <div>
            <img src={profileIcon} alt="Logo" className="profile-icon" />
          </div>
        </div>
      </div>
    </Navbar>
  );
};

export default Header;
