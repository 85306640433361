import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "./Button";
import SearchIcon from "../assets/filter-serach.png";
import ResetIcon from "../assets/reset.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import chevronLeft from "../assets/chevron-left.png";
import chevronRight from "../assets/chevron-right.png";
import { useDispatch, useSelector } from "react-redux";
import {
  filterTechnicians,
  getTechnicians,
  serachTechnicianByValues,
} from "../services/technicianService";
import { TECHNICIANS_PER_PAGE } from "../constants/appConstants";

interface Props {
  buttonName?: string;
  buttonAction?: () => void;
  viewAction?: any;
  viewActionMethod?: (item: any) => void;
  hideFilter?: boolean;
  columns: (keyof any)[];
  columnLabels: string[];
  statusAction?: any;
  statusActionMethod?: (item: any) => void;
  editAction?: any;
  editActionMethod?: (item: any) => void;
}

const searchInputStyle: React.CSSProperties = {
  paddingTop: "0.3rem",
  paddingBottom: "0.4rem",
  borderRadius: "5px",
  border: "1px solid #ccc",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  marginTop: "4vh",
  paddingLeft: "5px",
  maxWidth: "150px",
};

const searchDropDownStyle: React.CSSProperties = {
  paddingTop: "0.3rem",
  paddingBottom: "0.3rem",
  borderRadius: "5px",
  border: "1px solid #ccc",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  marginTop: "4vh",
  width: "20px",
};

const TableTech: React.FC<Props> = ({
  buttonName,
  buttonAction,
  viewAction,
  viewActionMethod,
  hideFilter,
  columns,
  columnLabels,
  statusAction,
  statusActionMethod,
  editAction,
  editActionMethod,
}) => {
  const dispatch = useDispatch<any>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [disbaleSerach, setDisbaleSerach] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [nextPage, setNextPage] = useState<number>(0);
  const [tenants, setTenants] = useState<{ id: string; name: string }[]>([]);
  const [itemsPerPage] = useState<number>(TECHNICIANS_PER_PAGE);
  const [selectedFilters, setSelectedFilters] = useState<{
    [key: number]: any;
  }>({});
  const [appliedFilters, setAppliedFilters] = useState<{
    [key: string]: string;
  }>({});

  const technicianList = useSelector(
    (state: any) => state.technicians.technicians
  );
  const techniciansCount = useSelector((state: any) => state.technicians.count);
  const tenantsList = useSelector((state: any) => state.tenants.tenants);
  const tenantNames = tenantsList.map((tenant: any) => tenant.name);
  useEffect(() => {
    if (tenantsList && tenantsList.length > 0) {
      const mappedTenants = tenantsList.map((tenant: any) => ({
        id: tenant.id,
        name: tenant.name,
      }));
      setTenants(mappedTenants);
    }
  }, [tenantsList]);
  useEffect(() => {
    if (nextPage > 0  && Object.keys(selectedFilters).length === 0) {
      dispatch(getTechnicians(nextPage, TECHNICIANS_PER_PAGE));
    }
  }, [nextPage]);

  const advancedFilters = [
    { label: "Level", key: "leveledUp", values: ["true", "false"] },
    { label: "Tenant", key: "tanentId", values: tenantNames },
    { label: "Source", key: "registrationSource", values: ["website", "mobile"]  },
  ];
  const filters = [
    
    { label: "Area", key: "mainWorkingArea" },
  ];

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const traget = event.target.value;
    setSearchTerm(event.target.value);
    if (selectedOption === "nic" && traget.length >= 5) {
      setDisbaleSerach(false);
    } else if (selectedOption !== "nic" && traget.length >= 3) {
      setDisbaleSerach(false);
    } else {
      setDisbaleSerach(true);
    }
    setCurrentPage(1); // Reset current page to 1 when the search term changes
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const paginate = (pageNumber: number) => {
    const pageNext = pageNumber - 1;
    setNextPage(pageNext);
    setCurrentPage(pageNumber);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setSelectedFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setCurrentPage(1);
  };

  const serachTechnician = () => {
    const searchBy = selectedOption === "nic" ? "nic" : "name";
    dispatch(serachTechnicianByValues(searchBy, searchTerm));
  };

  const processedData =
    technicianList &&
    technicianList.length > 0 &&
    technicianList.map((technician: any) => ({
      ...technician,
      fullName: `${technician.firstName} ${technician.lastName}`,
      mainWorkingArea: technician.workExperience
        ? `${technician.workExperience.mainWorkingArea.city}`
        : "N/A",
    }));

  // const currentItems = processedData;
  const currentItems =
    searchTerm || Object.keys(selectedFilters).length > 0
      ? processedData &&
        processedData
          .slice(indexOfFirstItem, indexOfLastItem)
      : processedData;
  const showCount = (currentItems && currentItems.length) || 0;

  const filteredItems =
    searchTerm || Object.keys(selectedFilters).length > 0
      ? currentItems
      : processedData;

  const totalCount = (filteredItems && filteredItems.length) || 0;
  const totalPages = Math.ceil(techniciansCount / itemsPerPage);

  const handleFilterReset = () => {
    setSearchTerm("");
    setSelectedFilters({});
    setAppliedFilters({}); // Reset applied filters
    dispatch(getTechnicians(0, TECHNICIANS_PER_PAGE));
  };

  const handleFilterTechnician = () => {
    const filtersToSend = Object.entries(selectedFilters).reduce(
      (acc: any, [key, value]) => {
        if (key === "tenantId") {
          // Use tenantId instead of tenantName
          const tenant = tenants.find((tenant) => tenant.name === value);
          if (tenant) {
            acc[key] = tenant.id;
          }
        } else {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );
    dispatch(filterTechnicians(filtersToSend));
  };
  
  return (
    <div style={{ margin: "0 2vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        {buttonName ? (
          <Button
            style={{
              width: "25vh",
              backgroundColor: "black",
              marginTop: "4vh",
              padding: "0.5rem",
            }}
            onClick={buttonAction}
            children={buttonName}
          />
        ) : null}

        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          {/* {advancedFilters &&
            advancedFilters.map((filter: any, index: number) => {
                const uniqueValues = filter.values;

                uniqueValues.sort((a: any, b: any) => {
                  // Check if both a and b are strings before using localeCompare
                  if (typeof a === "string" && typeof b === "string") {
                    return a.localeCompare(b);
                  }
                  // If a or b is not a string, fallback to default comparison
                  return a - b;
                });
                return (
                  <select
                    key={index}
                    style={{
                      padding: "0.4rem",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                      marginRight: "10px",
                      marginTop: "4vh",
                      maxWidth: "100px",
                    }}
                    name={filter.key}
                    value={selectedFilters[filter.key] || ""}
                    onChange={handleFilterChange}
                  >
                    <option value="">{filter.label}</option>
                    {uniqueValues.map((value: any, index:number) => (
                      <option key={index} value={value}>
                        {value === 1
                          ? "Active"
                          : value === 0
                          ? "Inactive"
                          : value}
                      </option>
                    ))}
                  </select>
                );
              
            })} */}
          {advancedFilters &&
            advancedFilters.map((filter: any, index: number) => {
              if (filter.key === "tanentId") {
                return (
                  <select
                    key={index}
                    style={{
                      padding: "0.3rem",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                      marginRight: "10px",
                      marginTop: "4vh",
                      maxWidth: "100px",
                    }}
                    name={filter.key}
                    value={selectedFilters[filter.key] || ""}
                    onChange={handleFilterChange}
                  >
                    <option value="">{filter.label}</option>
                    {tenants.map((tenant) => (
                      <option key={tenant.id} value={tenant.id}>
                        {tenant.name}
                      </option>
                    ))}
                  </select>
                );
              } else {
                const uniqueValues = filter.values;
                uniqueValues.sort((a: any, b: any) => {
                  // Check if both a and b are strings before using localeCompare
                  if (typeof a === "string" && typeof b === "string") {
                    return a.localeCompare(b);
                  }
                  // If a or b is not a string, fallback to default comparison
                  return a - b;
                });
                return (
                  <select
                    key={index}
                    style={{
                      padding: "0.3rem",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                      marginRight: "10px",
                      marginTop: "4vh",
                      maxWidth: "100px",
                    }}
                    name={filter.key}
                    value={selectedFilters[filter.key] || ""}
                    onChange={handleFilterChange}
                  >
                    <option value="">{filter.label}</option>
                    {uniqueValues.map((value: any, index: number) => (
                      <option key={index} value={value}>
                        {value === 1
                          ? "Active"
                          : value === 0
                          ? "Inactive"
                          : value}
                      </option>
                    ))}
                  </select>
                );
              }
            })}

          {filters &&
            filters.map((filter: any, index: number) => {
              if (processedData && processedData.length > 0) {
                const uniqueValues = [
                  ...new Set(
                    processedData
                      .filter((item: any) => item[filter.key] !== "N/A")
                      .map((item: any) => item[filter.key])
                  ),
                ];

                uniqueValues.sort((a: any, b: any) => {
                  // Check if both a and b are strings before using localeCompare
                  if (typeof a === "string" && typeof b === "string") {
                    return a.localeCompare(b);
                  }
                  // If a or b is not a string, fallback to default comparison
                  return a - b;
                });
                return (
                  <select
                    key={index}
                    style={{
                      padding: "0.3rem",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                      marginRight: "10px",
                      marginTop: "4vh",
                      maxWidth: "100px",
                    }}
                    name={filter.key}
                    value={selectedFilters[filter.key] || ""}
                    onChange={handleFilterChange}
                  >
                    <option value="">{filter.label}</option>
                    {uniqueValues.map((value: any, index) => (
                      <option key={index} value={value}>
                        {value === 1
                          ? "Active"
                          : value === 0
                          ? "Inactive"
                          : value}
                      </option>
                    ))}
                  </select>
                );
              }
            })}

          <div
            style={{
              display: "flex",
              flex: 1,
              marginTop: "4vh",
              marginRight: "3vh",
            }}
          >
            <img
              src={SearchIcon}
              style={{
                borderRadius: "5px",
                width: "34px",
              }}
              onClick={handleFilterTechnician}
            ></img>
            <FontAwesomeIcon
              icon={faSyncAlt}
              onClick={handleFilterReset}
              style={{
                backgroundColor: "#777777",
                color: "white",
                borderRadius: "5px",
                padding: "0.4rem",
                border: "2px solid #777777",
                marginLeft: "10px",
              }}
            />
          </div>
          <div style={{ display: "flex", alignItems: "flex-start" }}>
            <input
              type="text"
              placeholder={
                selectedOption === "nic" ? "Search by nic" : "Search by name"
              }
              style={searchInputStyle}
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <select
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
              style={searchDropDownStyle}
            >
              <option></option>
              <option value="name">Search By Name</option>
              <option value="nic">Search By NIC</option>
            </select>
            <div>
              <Button
                style={{
                  width: "15vh",
                  backgroundColor: disbaleSerach ? "grey" : "black",
                  marginTop: "4vh",
                  padding: "0.4rem",
                  marginLeft: "15px",
                }}
                disabledButton={disbaleSerach}
                onClick={() => serachTechnician()}
                children={"Search"}
              />
            </div>
          </div>
        </div>
      </div>
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            {columnLabels.map((label, index) => (
              <th key={index}>{label}</th>
            ))}
            {viewAction ? <th>Actions</th> : <></>}
          </tr>
        </thead>
        <tbody>
          {currentItems &&
            currentItems.length > 0 &&
            currentItems.map((item: any) => (
              <tr key={item.id}>
                {columns.map((columnName, index) => {
                  let cellValue = item[columnName];
                  let cellColor = "black";

                  // Check the column name and cell value to determine the color
                  if (columnName === "status") {
                    if (cellValue === "In-Review") {
                      cellColor = "orange";
                    } else if (cellValue === "Active" || cellValue === 1) {
                      cellColor = "green";
                    } else if (cellValue === "Inactive" || cellValue === 0) {
                      cellColor = "red";
                    }

                    if (cellValue === 1) {
                      cellValue = "Active";
                    } else if (cellValue === 0) {
                      cellValue = "Inactive";
                    }
                  }

                  return (
                    <td key={index} style={{ color: cellColor }}>
                      {Array.isArray(cellValue)
                        ? cellValue.map(
                            (
                              product: { name?: string; type?: string },
                              index: number
                            ) => {
                              // Check if 'name' or 'type' exists and display accordingly
                              const value = product.name
                                ? product.name
                                : product.type
                                ? product.type
                                : "";
                              return (
                                value +
                                (index !== cellValue.length - 1 ? ", " : "")
                              );
                            }
                          )
                        : typeof cellValue === "object" // Check if cellValue is an object
                        ? Object.entries(cellValue).map(
                            ([key, value]: [string, any], index: number) => {
                              if (key === "name") {
                                return <div key={index}>{value}</div>;
                              }
                              return null;
                            }
                          )
                        : cellValue}
                    </td>
                  );
                })}
                {viewAction ? (
                  <td>
                    <button
                      style={{
                        border: "none",
                        background: "transparent",
                        width: "3vh",
                      }}
                      onClick={() => viewActionMethod && viewActionMethod(item)}
                    >
                      {viewAction}
                    </button>
                    {editAction ? (
                      <button
                        style={{
                          border: "none",
                          width: "3vh",
                          background: "transparent",
                          cursor: "pointer",
                          marginLeft: "2vh",
                        }}
                        onClick={() =>
                          editActionMethod && editActionMethod(item)
                        }
                      >
                        {editAction}
                      </button>
                    ) : (
                      <></>
                    )}
                    {statusAction ? (
                      <button
                        style={{
                          border: "none",
                          width: "3vh",
                          background: "transparent",
                          cursor: "pointer",
                          marginLeft: "2vh",
                        }}
                        onClick={() =>
                          statusActionMethod && statusActionMethod(item)
                        }
                      >
                        {statusAction}
                      </button>
                    ) : (
                      <></>
                    )}
                  </td>
                ) : (
                  <></>
                )}
              </tr>
            ))}
        </tbody>
      </Table>
      <div
        style={{
          marginLeft: "20px",
          display: "flex",
          alignItems: "center",
          gap: "2",
        }}
      >
        <p style={{ marginRight: "5px" }}>
          {currentItems && currentItems.length > 0
            ? `Show ${showCount} out of ${totalCount} results`
            : "No results found"}
        </p>

        <p>
          <img
            src={chevronLeft}
            style={{ marginRight: "5px", height: "20px", width: "20px" }}
          />
        </p>
        <p>
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={totalCount}
            currentPage={currentPage}
            paginate={paginate}
            totalPages={totalPages}
          />
        </p>

        <p>
          <img
            src={chevronRight}
            style={{ height: "10px", width: "10px", marginLeft: "-10px" }}
          />
        </p>
      </div>
    </div>
  );
};
interface PaginationProps {
  itemsPerPage: number;
  totalItems: number;
  currentPage: number;
  paginate: (pageNumber: number) => void;
  totalPages: number;
}

const Pagination: React.FC<PaginationProps> = ({
  itemsPerPage,
  totalItems,
  currentPage,
  paginate,
  totalPages,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <nav style={{ marginTop: "1rem" }}>
      <ul
        className="pagination"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "1rem",
        }}
      >
        {pageNumbers.map((number) => (
          <li
            key={number}
            className="page-item"
            style={{ marginRight: "10px" }}
          >
            <a
              onClick={() => paginate(number)}
              href="#"
              className={`page-link ${
                number === currentPage ? "current-page" : ""
              }`}
              style={{
                color: "black",
                boxShadow:
                  number === currentPage ? "0px 0px 5px #888888" : "none",
              }}
            >
              {number}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default TableTech;
