import { Dispatch } from "redux";
import { fetchProductInquiries, fetchProductInquiryByID } from "../api/productInquiriesAPI";
import {
  setProductInquiries,
  setProductInquiriesLoading,
  setProductInquiriesError,
  setSingleInqury,
} from "../features/productInquiriesSlice";

export const getProductInquiries = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setProductInquiriesLoading(true));
      const productInquiries = await fetchProductInquiries();
      dispatch(setProductInquiries(productInquiries));
    } catch (error) {
      console.error("Error fetching product inquiries:", error);
      dispatch(
        setProductInquiriesError(
          "Failed to fetch product inquiries. Please try again!"
        )
      );
    }
  };
};

export const getProductInquriesByID = (productInqID: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setProductInquiriesLoading(true));
      const productInquiries = await fetchProductInquiryByID(productInqID);
      dispatch(setProductInquiriesLoading(true));
      dispatch(setSingleInqury(productInquiries));
    } catch (error) {
      console.error("Error fetching product inquiries:", error);
      dispatch(
        setProductInquiriesError(
          "Failed to fetch product inquiries. Please try again!"
        )
      );
    }
  };
};
