import React, { useEffect, useState } from "react";
import CardCustom from "../../components/CardCustom";
import p1 from "../../assets/p1.png";
import p2 from "../../assets/p2.png";
import p3 from "../../assets/p3.png";
import pro1 from "../../assets/Pro1.png";
import pro2 from "../../assets/Pro2.png";
import pro3 from "../../assets/Pro3.png";
import "../../styles/CardCustom.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProductsFromProductTypes,
  getProductsByJobTypes,
  getProductsForInqury,
} from "../../services/productService";
import { Product } from "../../type";
import Button from "../../components/Button";
import { setSelectedProduts } from "../../features/productInquiriesSlice";
import MyList from "../../components/MyList";
import { clearMyItems } from "../../features/myItemsSlice";
import { createProductInquiryForWorkRequest } from "../../services/workRequestService";
import ReferralTechnician from "./ReferralTechnician";
import { BASE_URL_FOR_IMAGE } from "../../constants/appConstants";
import Rating from "../../components/RatingStars";
import Popup from "../../components/Popup";

interface ProductDisplayProps {
  onCancelVieProduct?: () => void;
  fromEdit?: boolean;
}

const getImageUrlForItem = (item: any) => {
  const imageUrlLookupTable: Record<string, string> = {
    Purlins: p1,
    Planks: p2,
    p3: p3,
    pro1: pro1,
    pro2: pro2,
    pro3: pro3,
  };

  return imageUrlLookupTable[item];
};

interface cardItem {
  imageUrl?: string;
  description?: string;
  name?: string;
}

interface CartItem extends cardItem {
  quantity?: number;
}

const ProductDisplay: React.FC<ProductDisplayProps> = ({ onCancelVieProduct, fromEdit }) => {
  const dispatch = useDispatch<any>();
  const productsData = useSelector(
    (state: any) => state.products.products.subProducts
  );
  const productInqJobTypes = useSelector(
    (state: any) => state.productInquiries.selectedjobTypes
  );
  const [modifyDataArray, setModifyDataArray] = useState<any>(productsData);
  const [dataArray, setDataArray] = useState<any>(productsData);
  const allProducts: any = [];
  const [inquryButtonEnable, setInquryButtonEnable] = useState<boolean>(false);
  const [showReferralPopup, setShowReferralPopup] = useState<boolean>(false);
  const [showInqurySucessMsg, setShowInqurySucessMsg] = useState<boolean>(false);
  const [singleView, setSingleView] = useState<boolean>(false);
  const buttonColour = inquryButtonEnable ? "#FFC600" : "#777777";
  // const [myItemsArray, setMyItemsArray] = useState<cardItem[]>([]);
  const myItemsArray = useSelector((state: any) => state.myItems.myItems);
  const [showSingleCardView, setShowSingleCardView] = useState<boolean>(false);
  const [editMyListItem, setEditMyListItem] = useState<boolean>(false);
  const selectedProductsFroInqury = useSelector(
    (state: any) => state.productInquiries.selectedProduts
  );
  const workRequestData = useSelector(
    (state: any) => state.productInquiries.workRequest
  );
  const assignedTechnicianData = useSelector(
    (state: any) => state.selectedTechnican.singleTechnician
  );
  const errorJobTypes = useSelector((state: any) => state.productInquiries.error);
  const successJobTypes = useSelector((state: any) => state.productInquiries.success);
  const [showSccessMsg, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  useEffect(() => {
    if (myItemsArray.length > 0) {
      setInquryButtonEnable(true);
    } else {
      setInquryButtonEnable(false);
    }
  }, [myItemsArray]);

  useEffect(() => {
    const fetchData = async () => {
      const allProducts = [];

      if (productInqJobTypes.length > 0) {
        for (const item of productInqJobTypes) {
          const productData = await dispatch(getProductsByJobTypes(item.id));
          allProducts.push(productData);
        }
      }
      setModifyDataArray(allProducts);
    };
    fetchData();
  }, []);

  const setReferral = (isChecked:boolean) => {
    const inquryData = myItemsArray.map((item: any) => ({
      productId: item.id,
      quantity: item.quantity,
    }));
    const workRequestId = workRequestData && workRequestData.id;
    dispatch(createProductInquiryForWorkRequest(workRequestId, inquryData, isChecked));
    dispatch(clearMyItems())
    setShowSuccessMessage(true);
    setShowErrorMessage(true);
    setShowPopup(true);
    setShowReferralPopup(false);
  };

  const sendInqury = () => {
    const inquryData = myItemsArray.map((item: any) => ({
      productId: item.id,
      quantity: item.quantity,
    }));
    
    if(fromEdit){
      setShowReferralPopup(true);
    } else {
      dispatch(setSelectedProduts(inquryData));
      dispatch(clearMyItems())
      if(onCancelVieProduct) {
        onCancelVieProduct();
      }
    }
    
  };

  return (
    
    <div className="product-container">
      {showSccessMsg && successJobTypes && 
        (
          <>
              <Popup
                show={showPopup}
                onHide={() => {
                  setShowPopup(false);
                  if(onCancelVieProduct) {
                    onCancelVieProduct();
                  }
    
                }}
                actionButton={false}
                item={"normal"}
                modaltitle={"Product Inquiry Sent!"}
                content={"Thank you for your product inquiry submission our agents will contact you shortly."}
              />
            </>
        )
      }
      {errorJobTypes && showErrorMessage && (
        <Popup
          show={showPopup}
          onHide={() => {
            setShowPopup(false);
            if(onCancelVieProduct) {
              onCancelVieProduct();
            }
          }}
          actionButton={false}
          modaltitle={"Error!"}
          content={errorJobTypes}
        />
      )}
      {showReferralPopup && (
      <>
      <ReferralTechnician
        show={true}
        item={"rate"}
        inputItem={true}
        actionButton={"Save"}
        actionMethod={(isChecked:boolean) => {
          setReferral(isChecked);
        }}
        displayDiv={
          <div className="profile-layer" style={{marginTop: "-10px", paddingLeft: "20px", borderRadius: "5px", paddingRight: "20px", paddingTop: "10px", paddingBottom: "10px", border: "1px solid #777777"}}>
            <div
              className="profile-info"
              style={{ display: "flex", alignItems: "flex-start" }}
            >
              <div className="profile-img">
                <img
                  src={
                    BASE_URL_FOR_IMAGE +
                    workRequestData.assignedTechnician.profileImage.url
                  }
                  alt="Profile Picture"
                />
              </div>

              <div className="profile-details" >
                <div className="profile-name-rate">
                  <div className="profile-name-rate-name">
                    {workRequestData.assignedTechnician.firstName}{" "}
                    {workRequestData.assignedTechnician.lastName}
                  </div>
                </div>
                <div className="profile-rating-rate" style={{fontSize: "10px", marginTop: "-20px"}}>
                  <div style={{fontSize: "10px"}}>
                  <Rating rating={assignedTechnicianData.averageRating || 0 }  size={14}/>
                  </div>
                  <div className="profile-rating-value">{assignedTechnicianData.averageRating || 0}</div>
                </div>
              </div>
            </div>
          </div>
        }
        modaltitle={"Add Referral"}
        modalSubTitle={""}
      ></ReferralTechnician>
    </>
    )}
      {!singleView && (
        <div className="button-container">
          <Button
            style={{
              width: "25vh",
              backgroundColor: "black",
              marginRight: "3.5vh",
              marginTop: "0.5rem",
              marginBottom: "0.1rem",
            }}
            onClick={onCancelVieProduct}
          >
            Back
          </Button>
          <Button
            style={{
              width: "25vh",
              marginRight: "3.5vh",
              marginTop: "0.5rem",
              marginBottom: "0.1rem",
              backgroundColor: buttonColour,
            }}
            onClick={sendInqury}
            disabledButton={!inquryButtonEnable}
          >
            Send Inquiry
          </Button>
        </div>
      )}
      <>
      <MyList setEditMyListItem={setEditMyListItem}></MyList>
      </>
      {modifyDataArray && modifyDataArray.length > 0 ? (
        modifyDataArray.map((product: any, index: number) => {
          if (product.length > 0) {
            const jobName = productInqJobTypes[index].type;
            const data = {
              title: `${jobName}`,
              items: product,
            };
            return (
              <CardCustom
                key={index}
                data={data}
                onCancel={onCancelVieProduct}
                setSingleView={setSingleView}
              />
            );
          }
          return null;
        })
      ) : (
        <div style={{color: 'grey', width: '50px', height: '50px'}}>No data available</div>
      )}
    </div>
  );
};
export default ProductDisplay;
