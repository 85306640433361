interface LayoutProps {
    children: any;
  }


const Layout: React.FC<LayoutProps> = ({ children }) => {
  return <div className="main-wrapper">{children}</div>;
};

export default Layout;
