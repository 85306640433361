import React from 'react';

interface PaginationProps {
    itemsPerPage: number;
    totalItems: number;
    currentPage: number;
    paginate: (pageNumber: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
      }
    
      return (
        <nav style={{ marginTop: "1rem" }}>
          <ul
            className="pagination"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "1rem",
            }}
          >
            {pageNumbers.map((number) => (
              <li key={number} className="page-item" style={{ marginRight: "10px" }}>
                <a
                  onClick={() => paginate(number)}
                  href="#"
                  className={`page-link ${number === currentPage ? 'current-page' : ''}`}
                  style={{ color: "black", boxShadow: number === currentPage ? '0px 0px 5px #888888' : 'none' }}
                >
                  {number}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      );
};

export default Pagination;
