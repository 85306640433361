import React, { useEffect } from "react";
import ApplicationManagement from "./Admin/ApplicationManagement";
import "../styles/Styles.scss";
import TechnicianManagement from "./Technician/TechnicianManagement";
import WorkRequestPage from "./WorkRequest/WorkRequestPage";
import RegisteredConsumerListView from "./Consumers/RegisteredConsumerListView";
import ProductInquryList from "./WorkRequest/ProductInquryList";
import ProductInquirySingle from "./WorkRequest/ViewSingleInqury";
import DashboardMainView from "./DashboardMainView";
import ProductDisplay from "./WorkRequest/ProductDisplay";
import { useNavigate } from "react-router-dom";

interface MainContainerProps {
  location: string;
  activeButton: string;
  children?: any;
}

const MainContainer: React.FC<MainContainerProps> = ({ location, activeButton, children }) => {
  const navigate = useNavigate();
  const onCancelVieProduct = () => {
    navigate("/work-request");
  }
  
  return (
    <div>
      <div className="location-path">{location}</div>
      <div className="main-container container-fluidt">
        {activeButton === "menu_dashboard" && (
          <DashboardMainView></DashboardMainView>
        )}
        {activeButton === "sum_menu_admin_mange" && (
          <div>Admin page</div>
        )}
        {activeButton === "menu_application_setups" && (
          <ApplicationManagement></ApplicationManagement>
        )}
        {activeButton === "menu_tenants" && <div>Tenants page</div>}
        {activeButton === "menu_technician" && (
          <TechnicianManagement></TechnicianManagement>
        )}
        {activeButton === "menu_consumer" && <div><RegisteredConsumerListView></RegisteredConsumerListView></div>}
        {activeButton === "sum_menu_workrequest" && <div><WorkRequestPage></WorkRequestPage></div>}
        {activeButton === "sum_menu_product_inq_list" && <div><ProductInquryList></ProductInquryList></div>}
        {activeButton === "view_product" && <div><ProductDisplay onCancelVieProduct={onCancelVieProduct} fromEdit={true}></ProductDisplay></div>}
        {activeButton === "product-inquiry-single-view" && <div><ProductInquirySingle></ProductInquirySingle></div>}
        {activeButton === "account" && <div>Account Content</div>}
        {activeButton === "settings" && <div>Settings Content</div>}
        {activeButton === "help" && <div>Help & Support Content</div>}
        {activeButton === "logout" && <div>Log Out Content</div>}
      </div>
    </div>
  );
};

export default MainContainer;
