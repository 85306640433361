import { request } from "http";
import { BASE_URL } from "../constants/appConstants";
import { profileImageUrl, Consumer } from "../type";
import { uploadImage, fetchWithToken } from "../utils/utils";

export const fetchConsumerList = async (page:number,items:number) => {
  try {
    const end_url = BASE_URL + `/consumers?page=${page}&size=${items}`;
    const response = await fetchWithToken(end_url);
    if (!response.ok) {
      throw new Error("Failed to fetch consumers");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Failed to fetch consumers");
  }
};

// Function to register consumer with image URL
export const createConsumer = async (consumerDetails: any) => {
  try {
    const end_url = BASE_URL + "/consumers/registration";
    const imageUrl = await uploadImage(consumerDetails.profileImageUrl);
    if (imageUrl.errorCode) {
      return imageUrl;
    } else {
      const updatedConsumerDetails = {
        ...consumerDetails,
        profileImageUrl: imageUrl.url,
      };
      const response = await fetchWithToken(end_url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedConsumerDetails),
      });
      const newConsumer = await response;
      return newConsumer;
    }
  } catch (error) {
    console.error("Error creating consumere:", error);
    throw new Error("Failed to consumer");
  }
};

// Function to fetch consumer details by ID
export const getConsumerById = async (consumerId: string) => {
  try {
    const end_url = `${BASE_URL}/consumers/${consumerId}`;
    const response = await fetchWithToken(end_url);
    if (!response.ok) {
      throw new Error("Failed to fetch consumer details");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error("Failed to fetch consumer details");
  }
};

// Function to search consumers by NIC number
export const searchConsumersByNIC = async (nic: string) => {
  try {
    const endUrl = `${BASE_URL}/consumers/search?searchBy=nic&searchValue=${nic}`;
    const response = await fetchWithToken(endUrl);
    if (!response.ok) {
      throw new Error("Failed to search consumers by NIC");
    }
    const data = await response.json();
    return data.results;
  } catch (error) {
    throw new Error("Failed to search consumers by NIC");
  }
};

// Function to fetch work requests for a consumer
export const fetchWorkRequestsForConsumer = async (consumerId: string) => {
  try {
    const endUrl = `${BASE_URL}/work-requests/consumer/${consumerId}?page=0&size=0`;
    const response = await fetchWithToken(endUrl);
    if (!response.ok) {
      throw new Error("Failed to fetch work requests for the consumer");
    }
    const data = await response.json();
    return data.results;
  } catch (error) {
    throw new Error("Failed to fetch work requests for the consumer");
  }
};

// Function to edit consumer details
export const editConsumer = async (consumerId: string, updatedConsumerDetails: any, profilePicChanged: boolean) => {
  try {
    const end_url = `${BASE_URL}/consumers/${consumerId}`;
    if (profilePicChanged) {
      const imageUrl = await uploadImage(updatedConsumerDetails.profileImageUrl);

      if (imageUrl.errorCode) {
        return imageUrl;
      } else {
        updatedConsumerDetails.profileImageUrl = imageUrl.url;
      }
    }

    const response = await fetchWithToken(end_url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedConsumerDetails),
    });

    const editConsumer = await response.json();
    return editConsumer;
  } catch (error) {
    throw new Error("Failed to edit consumer");
  }
};


// Function to search consumers by NIC number or name
export const searchConsumers = async (searchBy: string, searchValue: string) => {
  try {
    const endUrl = `${BASE_URL}/consumers/search?searchBy=${searchBy}&searchValue=${searchValue}`;
    const response = await fetchWithToken(endUrl);
    if (!response.ok) {
      throw new Error(`Failed to search consumers by ${searchBy}`);
    }
    const data = await response.json();
    return data.results;
  } catch (error) {
    throw new Error(`Failed to search consumers by ${searchBy}`);
  }
};

//filter consumers
export const filterConsumerAPI = async (filterValues: Record<string, any>) => {
  try {
    // Construct the base URL
    let end_url = `${BASE_URL}/consumers/advanced-filtered?page=0&size=0`;

    // Iterate over the filter values and append non-empty ones to the URL
    Object.entries(filterValues).forEach(([key, value]) => {
      if (value !== undefined && value !== null && value !== '') {
        end_url += `&${key}=${encodeURIComponent(value)}`;
      }
    });

    const response = await fetchWithToken(end_url);
    if (!response.ok) {
      throw new Error("Failed to fetch consumers");
    }
    const filteredConsumers = await response.json();
    console.log("Filtered consumer:", filteredConsumers);
    return filteredConsumers;
  } catch (error) {
    throw new Error("Failed to filter consumers");
  }
};
