import React, { useEffect, useState } from "react";
import profileIcon from "../../assets/profileIcon.png";
import TableView from "../../components/TableView";
import Button from "../../components/Button";
import "../../styles/ViewSingleInquiry.scss";
import Rating from "../../components/RatingStars";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getTechniciansByID } from "../../services/technicianService";
import { BASE_URL_FOR_IMAGE } from "../../constants/appConstants";
import { getConsumerByIdAction } from "../../services/consumerService";

const iconStyle: React.CSSProperties = {
  width: "8vh",
  height: "8vh",
  marginRight: "1.5vh",
  borderRadius: "50px",
};

interface ViewSingleInquryProps {}

const ViewSingleInqury: React.FC<ViewSingleInquryProps> = () => {
  const [displayData, setDisplayData] = useState<any>([]);
  const [showTech, setShowTech] = useState<boolean>(false);
  const [showConsumer, setShowConsumer] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const loading = useSelector((state: any) => state.productInquiries.loading);
  const singleInqury = useSelector(
    (state: any) => state.productInquiries.singleInqury
  );
  const selectedConsumer = useSelector(
    (state: any) => state.consumers.selectedConsumer
  );
  const selectedTechnican = useSelector(
    (state: any) => state.selectedTechnican.singleTechnician
  );

  const onCancel = () => {
    navigate("/product-inquiry-list");
  };

  useEffect(() => {
    if (singleInqury?.inquiryEntries) {
      const inquiriesData = singleInqury.inquiryEntries.map((entry: any) => ({
        inqID: singleInqury.id,
        jobID: singleInqury.workRequestId,
        productId: entry.product.productCode,
        product: entry.product.name,
        productType: entry.product.productType.name,
        quantity: entry.quantity,
        contacts: singleInqury.consumer.contactNumberPrimary,
        status: singleInqury.status,
      }));
      setDisplayData(inquiriesData);
    }
    if (singleInqury && singleInqury.referredByTechnician) {
      dispatch(getTechniciansByID(singleInqury.referredByTechnician.id));
      setShowTech(true);
    }
    if (singleInqury && singleInqury.consumer) {
      dispatch(getConsumerByIdAction(singleInqury.consumer.id));
      setShowConsumer(true);
    }
  }, [singleInqury]);

  useEffect(() => {
    console.log("Selected Consumer:", selectedConsumer);
    console.log("Selected Technician:", selectedTechnican);
  }, [selectedConsumer, selectedTechnican]);

  return (
    <div style={{ margin: "0 2vh" }}>
      {singleInqury && (
        <>
          <div className="view-header">
            <div className="view-inquiry">View Inquiry</div>
            <div className="image-container">
              {showConsumer && (
                <div className="image-container-left">
                  <img
                    src={
                      selectedConsumer && selectedConsumer.profileImage.url
                        ? BASE_URL_FOR_IMAGE + selectedConsumer.profileImage.url
                        : profileIcon
                    }
                    style={iconStyle}
                  />
                  <div className="customer-name">
                    {singleInqury.consumer.firstName +
                      " " +
                      singleInqury.consumer.lastName}{" "}
                    <Rating
                      rating={singleInqury.consumer.averageRating}
                      size={15}
                    />
                  </div>
                </div>
              )}

              {showTech &&
                selectedTechnican &&
                selectedTechnican.profileImage.url && (
                  <div className="image-container-right">
                    <img
                      src={
                        selectedTechnican &&
                        selectedTechnican.profileImage.url &&
                        BASE_URL_FOR_IMAGE + selectedTechnican.profileImage.url
                      }
                      style={iconStyle}
                    />
                    <div className="customer-name">
                      {selectedTechnican.firstName +
                        " " +
                        selectedTechnican.lastName}{" "}
                      <Rating
                        rating={
                          selectedTechnican && selectedTechnican.averageRating
                        }
                        size={15}
                      />
                    </div>
                  </div>
                )}
            </div>
            <div className="date">{singleInqury.createdDate}</div>
          </div>
          <TableView
            data={displayData}
            columns={[
              // "inqID",
              // "jobID",
              //"address",
              "contacts",
              "productType",
              //"brand",
              "product",
              "quantity",
            ]}
            columnLabels={[
              // "Inquiry ID",
              // "Work Request ID",
              //"Address",
              "Contact",
              "Product type",
              //"Brand",
              "Product",
              "Quantity",
            ]}
            hideFilter={true}
            hideSerach={true}
            itemsPage={10}
          />
          <Button
            style={{
              width: "25vh",
              backgroundColor: "black",
              marginTop: "4vh",
              padding: "0.7rem",
              marginLeft: "2vh",
            }}
            onClick={onCancel}
            children={"Back"}
          />
        </>
      )}
    </div>
  );
};

export default ViewSingleInqury;
