import { Dispatch } from "redux";
import { setCityData, setLocationDataLoading, setLocationDataError } from "../features/citySlice";
import { fetchCityData } from "../api/cityAPI";
// Action creator to fetch city data
export const getCityData = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLocationDataLoading(true));
      const cityData = await fetchCityData();
      dispatch(setLocationDataLoading(false));
      dispatch(setCityData(cityData));
    } catch (error) {
      console.error("Error fetching job types:", error);
      dispatch(
        setLocationDataError("Failed to fetch job types. Please try again!")
      );
    }
  };
};