import React, { useState } from 'react';
import { FaStar } from 'react-icons/fa';

interface RatingProps {
  size?: number;
  onRatingChange: (rating: number) => void;
}

const RateManipulator: React.FC<RatingProps> = ({ size = 15,  onRatingChange}) => {
  const [rating, setRating] = useState<number>(0);

  const handleStarClick = (selectedRating: number) => {
    setRating(selectedRating);
    onRatingChange(selectedRating);
  };

  const starIcons = Array(5)
    .fill('')
    .map((_, index) => (
      <FaStar
        key={index}
        size={size}
        color={index < rating ? 'yellow' : 'gray'}
        style={{ marginRight: 4, cursor: 'pointer' }}
        onClick={() => handleStarClick(index + 1)}
      />
    ));

    let label = '';
    if (rating === 1) {
      label = 'Bad';
    } else if (rating === 2) {
      label = 'Poor';
    } else if (rating === 3) {
      label = 'Average';
    } else if (rating === 4) {
      label = 'Good';
    } else if (rating === 5) {
      label = 'Excellent';
    }
  
    return (
      <div>
        <div>{starIcons}</div>
        <div style={{marginTop: "5px"}}>{label}</div>
      </div>
    );
};

export default RateManipulator;
