import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, Modal } from "react-bootstrap";
import styles from "../../styles/WorkRequestList.module.scss";
import Button from "../../components/Button";
import briefCase from "../../assets/briefcase.png";
import phone from "../../assets/phone.png";
import clock from "../../assets/clock.png";
import Rating from "../../components/RatingStars";
import edit from "../../assets/edit-icon.png";
import editDisabled from "../../assets/edit-work-request-list.png";
import view from "../../assets/blue-eye.png";
import trash from "../../assets/trash.png";
import trashDisabled from "../../assets/trash-disabled.png";
import useDummyImage from "../../assets/consumerImage.png";
import { BASE_URL_FOR_IMAGE } from "../../constants/appConstants";
import { useDispatch } from "react-redux";
import { deleteWorkRequest } from "../../services/workRequestService";
import WorkRequestStatus from "../../components/WorkRequestStatus";

interface SingleWorkRequestProps {
  data: any;
  currentPage: number;
  itemsPerPage: number;
  viewActionMethod?: (item: any) => void;
  viewAssignTechnician?: (item: any) => void;
  reloadData: () => void;
}

const SingleWorkRequestComponent: React.FC<SingleWorkRequestProps> = ({
  data,
  currentPage,
  itemsPerPage,
  viewActionMethod,
  viewAssignTechnician,
  reloadData,
}) => {
  // useEffect(() => {
  //   reloadData();
  // }, [data]);

  const dispatch = useDispatch<any>();

  const handleCancelWorkRequest = (
    workRequestId: string,
    consumerId: string
  ) => {
    dispatch(deleteWorkRequest(workRequestId, consumerId));
  };

  const [showModal, setShowModal] = useState(false);
  const [workRequestIdToDelete, setWorkRequestIdToDelete] = useState("");
  const [consumerIdToDelete, setConsumerIdToDelete] = useState("");

  const handleShowModal = (workRequestId: string, consumerId: string) => {
    setWorkRequestIdToDelete(workRequestId);
    setConsumerIdToDelete(consumerId);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setWorkRequestIdToDelete("");
    setShowModal(false);
  };

  const handleConfirmDelete = () => {
    if (workRequestIdToDelete && consumerIdToDelete) {
      dispatch(deleteWorkRequest(workRequestIdToDelete, consumerIdToDelete));
      handleCloseModal();
    }
  };

  return (
    <Container
      className="px-10 mb-10"
      style={{ width: "95%", maxWidth: "2000px" }}
    >
      {data.length > 0 &&
        data.map(
          (row: any) =>
            row && (
              <Row
                key={row.id}
                className={`mb-3 p-4 d-flex ${styles.customBorder}`}
              >
                <Col
                  className="d-flex align-items-center  "
                  style={{ flex: 2 }}
                >
                  <div className={styles.imageTextView}>
                    <Image
                      src={
                        row.consumer && row.consumer.profileImage.url
                          ? BASE_URL_FOR_IMAGE + row.consumer.profileImage.url
                          : useDummyImage
                      }
                      alt={row.firstName}
                      roundedCircle
                      fluid
                      className={`mr-3 ${styles.profilePicture}`}
                      style={{
                        marginRight: "30px",
                        width: "7vh",
                        height: "7vh",
                      }}
                    />
                    <div>
                      <strong className="mr-3">
                        {row.consumer &&
                          row.consumer.firstName &&
                          row.consumer.lastName &&
                          row.consumer.firstName + " " + row.consumer.lastName}
                      </strong>
                      {row.consumer && row.consumer.averageRating && (
                        <>
                          <div className={styles.ratingSection}>
                            <Rating
                              rating={row.consumer && row.consumer.averageRating}
                              size={12}
                            />
                            {row.consumer && row.consumer.averageRating}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Col>

                <Col
                  className={`d-flex align-items-center ${styles.dataColumn}`}
                >
                  <img
                    src={briefCase}
                    style={{
                      marginRight: "10px",
                      height: "20px",
                      width: "20px",
                    }}
                  />
                  {Array.isArray(row.jobTypes)
                    ? row.jobTypes.map(
                        (
                          items: { name?: string; type?: string },
                          index: number
                        ) => {
                          // Check if 'name' or 'type' exists and display accordingly
                          const value = items.name
                            ? items.name
                            : items.type
                            ? items.type
                            : "";
                          return (
                            value +
                            (index !== row.jobTypes.length - 1 ? ", " : "")
                          );
                        }
                      )
                    : row.jobTypes}
                </Col>
                <Col
                  className={`d-flex align-items-center ${styles.dataColumn}`}
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={phone}
                    style={{
                      marginRight: "10px",
                      height: "20px",
                      width: "20px",
                    }}
                  />
                  {row.consumer &&
                    row.consumer.contactNumberPrimary &&
                    row.consumer.contactNumberPrimary}
                </Col>
                <Col
                  className={`d-flex align-items-center ${styles.dataColumn}`}
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {row.status && (
                    <>
                      <WorkRequestStatus status={row.status} />
                    </>
                  )}
                </Col>

                <Col
                  className={`d-flex align-items-center ${styles.dataColumn}`}
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={clock}
                    style={{
                      marginRight: "10px",
                      height: "20px",
                      width: "20px",
                    }}
                  />
                  {new Date(row.startingDate).toLocaleDateString("en-GB")}
                </Col>

                <div
                  // className={`d-flex align-items-center ${styles.dataColumn}`}
                  style={{
                    justifyContent: "space-around",
                    alignItems: "center",
                    width: "150px",
                    flex: 0.8,
                  }}
                >
                  {!row.assignedTechnician ? (
                    <img
                      src={edit}
                      onClick={() => viewActionMethod && viewActionMethod(row)}
                      style={{
                        marginRight: "15px",
                        height: "20px",
                        width: "20px",
                      }}
                    />
                  ) : (
                    <img
                      src={editDisabled}
                      style={{
                        marginRight: "15px",
                        height: "20px",
                        width: "20px",
                      }}
                    />
                  )}

                  <img
                    src={view}
                    onClick={() =>
                      viewAssignTechnician && viewAssignTechnician(row)
                    }
                    style={{
                      marginRight: "15px",
                      height: "20px",
                      width: "20px",
                    }}
                  />

                  {!row.assignedTechnician ? (
                    <img
                      src={trash}
                      onClick={() => handleShowModal(row.id, row.consumer.id)}
                      style={{
                        marginRight: "0px",
                        height: "20px",
                        width: "20px",
                      }}
                    />
                  ) : (
                    <img
                      src={trashDisabled}
                      style={{
                        marginRight: "0px",
                        height: "20px",
                        width: "20px",
                      }}
                    />
                  )}
                </div>
              </Row>
            )
        )}

      {/* Confirmation Modal */}
      <Modal centered show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to cancel this work request?
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button
            onClick={() => handleConfirmDelete()}
            style={{ backgroundColor: "#f2184b", color: "#ffffff" }}
          >
            Confirm Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default SingleWorkRequestComponent;
