import { Dispatch } from "redux";
import { TechLevel } from "../type";
import {
  setTechLevels,
  setTechLevelsError,
  setTechLevelsLoading,
  addTechLevelsState,
  editTechLevelsState,
} from "../features/techLevelSlice";
import {
  fetchTechLevels,
  createTechLevel,
  updateTechLevel,
} from "../api/techLevelAPI";

export const getTechLevels = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setTechLevelsLoading(true));
      const techLevels = await fetchTechLevels();
      dispatch(setTechLevels(techLevels));
    } catch (error) {
      console.error("Error fetching tech levels:", error);
      dispatch(
        setTechLevelsError("Failed to fetch tech levels. Please try again!")
      );
    }
  };
};

export const addTechLevel = (techLevelData: TechLevel) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setTechLevelsLoading(true));
      const newTechLevel = await createTechLevel(techLevelData);
      dispatch(setTechLevelsLoading(false)); // Reset loading state
      if (newTechLevel.errorCode) {
        dispatch(setTechLevelsError(newTechLevel.errorDescription));
      } else {
        dispatch(addTechLevelsState(newTechLevel));
      }
    } catch (error) {
      console.error("Error creating tech level:", error);
      dispatch(
        setTechLevelsError("Failed to create tech level. Please try again!")
      );
    }
  };
};

export const editTechLevel = (
  techLevelId: string,
  updatedTechLevelData: TechLevel
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setTechLevelsLoading(true));
      const updatedTechLevels = await updateTechLevel(
        techLevelId,
        updatedTechLevelData
      );
      dispatch(setTechLevelsLoading(false)); // Reset loading state
      if (updatedTechLevels.errorCode) {
        dispatch(setTechLevelsError(updatedTechLevels.errorDescription));
      } else {
        dispatch(editTechLevelsState({ id: techLevelId, updatedTechLevels }));
      }
    } catch (error) {
      console.error("Error updating tech level:", error);
      dispatch(
        setTechLevelsError("Failed to update tech level. Please try again!")
      );
    }
  };
};
