import { Dispatch } from "redux";
import {
  setProductTypes,
  setProductTypesError,
  setProductTypesLoading,
} from "../features/productTypeSlice";
import {
  setProducts,
  setProductsLoading,
  setProductsError,
} from "../features/productSlice"
import { fetchProductTypes, fetchProducts, fetchProductsByJobTypes } from "../api/productAPI";

export const getProductTypes = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setProductTypesLoading(true));
      const productTypes = await fetchProductTypes();
      dispatch(setProductTypes(productTypes));
    } catch (error) {
      console.error("Error fetching product types:", error);
      dispatch(
        setProductTypesError("Failed to fetch product types. Please try again!")
      );
    }
  };
};


export const getAllProductsFromProductTypes = (productType: string, productTypeName : string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setProductsLoading(true));
      const productTypes = await fetchProducts(productType); 
      dispatch(setProducts(productTypes));
    } catch (error) {
      console.error("Error fetching product types:", error);
      dispatch(
        setProductsError("Failed to fetch product types. Please try again!")
      );
    }
  };
};

export const getProductsForInqury = (productType: string, productTypeName : string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setProductsLoading(true));
      const productTypes = await fetchProducts(productType); 
      dispatch(setProducts(productTypes));
      return productTypes;
    } catch (error) {
      console.error("Error fetching product types:", error);
      dispatch(
        setProductsError("Failed to fetch product types. Please try again!")
      );
      return false;
    }
  };
};

export const getProductsByJobTypes = (jobTypeID: string ) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setProductsLoading(true));
      const productTypes = await fetchProductsByJobTypes(jobTypeID); 
      dispatch(setProducts(productTypes));
      return productTypes;
    } catch (error) {
      console.error("Error fetching product types:", error);
      dispatch(
        setProductsError("Failed to fetch product types. Please try again!")
      );
      return false;
    }
  };
};
