import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductInquiry } from "../type"; // Assuming you have a ProductInquiry type defined

interface ProductInquiriesState {
  inquiries: ProductInquiry[] | null;
  loading: boolean;
  error: string | null;
  success: boolean | null;
  selectedProduts?: any;
  selectedjobTypes?:any;
  singleInqury?: any;
  workRequest?: any;
}

const initialState: ProductInquiriesState = {
  inquiries: [],
  loading: false,
  error: null,
  success: false,
  selectedProduts: [],
  selectedjobTypes: [],
  singleInqury: [],
  workRequest: [],
};

const productInquiriesSlice = createSlice({
  name: "productInquiries",
  initialState,
  reducers: {
    setProductJobTypes (state, action: PayloadAction<any>) {
      state.selectedProduts = [];
      state.inquiries = null;
      state.loading = false;
      state.success = false;
      state.error = null;
      state.selectedjobTypes = action.payload;
      state.singleInqury = null;
    },
    setWorkRequestForInquiries(state, action: PayloadAction<any>) {
      return {
        ...state,
        workRequest : action.payload,
        loading : false,
        error : null,
        success: false,
      };
    },
    setProductInquiries(state, action: PayloadAction<ProductInquiry[]>) {
      return {
        ...state,
        inquiries : action.payload,
        loading : false,
        error : null,
        success: false,
      };
    },
    setProductInquiriesLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
      state.error = null;
    },
    setProductInquiriesError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    setSelectedProduts(state, action: PayloadAction<any>) {
      state.selectedProduts = action.payload;
      state.inquiries = null;
      state.loading = false;
      state.error = null;
      state.success = false;
    },
    unSetSelectedProduts(state, action: PayloadAction<boolean>) {
      state.selectedProduts = [];
      state.inquiries = null;
      state.loading = false;
      state.error = null;
      state.success = false;
    },
    setSendInqury(state, action: PayloadAction<boolean>) {
      state.success = true;
      state.loading = false;
      state.error = null;
    },
    setSingleInqury(state, action: PayloadAction<string>) {
      state.singleInqury = action.payload;
      state.loading = false;
      state.error = null;
    }, 

  },
});

export const {
  setProductJobTypes,
  setProductInquiries,
  setProductInquiriesLoading,
  setProductInquiriesError,
  setSelectedProduts,
  unSetSelectedProduts,
  setSingleInqury,
  setWorkRequestForInquiries,
  setSendInqury,
} = productInquiriesSlice.actions;

export default productInquiriesSlice.reducer;
