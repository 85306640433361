import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from '../type';

const authSlice = createSlice({
  name: 'auth',
  initialState: <initialState>  {
    solidUser: null,
    accessToken: null,
    loading: false,
    error: null,
  },
  reducers: {
    loginRequest: (state, action: PayloadAction<boolean>) => {
      state.loading = true;
      state.error = null;
    },
    loginSuccess: (state, action: PayloadAction<any>) => {
      state.solidUser = action.payload;
      state.loading = false;
      state.error = null;
      state.accessToken = action.payload.access_token;
      localStorage.setItem('solidClubAccessToken', action.payload.access_token);
    },
    loginFailure: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      localStorage.removeItem('solidClubAccessToken');
    },
    logout: (state) => {
      state.solidUser = null;
      state.loading = false;
      state.error = null;
      localStorage.removeItem('solidClubAccessToken');
    },
  },
});

export const { loginRequest, loginSuccess, loginFailure, logout } = authSlice.actions;
export default authSlice.reducer;
