import React, { useEffect } from "react";
import TabView from "../../components/TabView";
import TechnicalJobs from "./TechnicalJobs";
import ScLevels from "./ScLevels";
import ScLevelCriteria from "./ScLevelCriteria";
import { getJobTypes } from "../../services/jobTypeService";
import { useDispatch, useSelector } from "react-redux";
import { getTechLevels } from "../../services/techLevelService";
import { getLevelCriteria } from "../../services/levelCriteriaService";

const ApplicationManagement: React.FC = () => {
  const dispatch = useDispatch<any>();
  const jobTypesBe = useSelector(
    (state: any) => state.jobTypes.jobTypes
  );

  useEffect(() => {
    reloadData();
  }, [dispatch]);
  
  const reloadData = () => {
    dispatch(getJobTypes());
  };

  const tabs = [
    {
      eventKey: "tJobs",
      title: "Technician Job Types",
      component: <TechnicalJobs data={jobTypesBe} reloadData={reloadData} />,
    },
    {
      eventKey: "scLevels",
      title: "Solid Club Levels",
      component: <ScLevels/>,
    },
    {
      eventKey: "scLevelC",
      title: "Solid Club Level Criteria",
      component: <ScLevelCriteria />,
    },
  ];

  return (
    <div>
      <TabView tabs={tabs} />
    </div>
  );
};

export default ApplicationManagement;
