import { BASE_URL } from "../constants/appConstants";
import { fetchWithToken } from "../utils/utils";

export const fetchCityData = async () => {
  try {
    const end_url = BASE_URL + "/common/city-data";
    const response = await fetchWithToken(end_url);
    if (!response.ok) {
      throw new Error("Failed to fetch city data");
    }
    const data = await response.json(); 
    return data; 
  } catch (error) {
    console.error("Error fetching city data:", error);
    throw new Error("Failed to fetch city data");
  }
};