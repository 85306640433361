// App.js
import React, { useEffect, useState } from "react";
import "./App.css";
import Login from "./pages/Login";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home";
import TechnicianPage from "./pages/TechnicianPage";
import AuthRoute from "./routes/AuthRoutes";
import "@fontsource/poppins";
import { useSelector } from "react-redux";
import ConsumerPage from "./pages/ConsumerPage";
import WorkRequestPage from "./pages/WorkRequestPage";
import ApplicationPage from "./pages/ApplicationPage";
import ProductInquiryPage from "./pages/ProductInquiryPage";
import ViewProductPage from "./pages/ViewProductPage";
import ProductInquirySingle from "./pages/ProductInquirySingle";

const App: React.FC = () => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const userData = useSelector((state: any) => state.auth.solidUser);
  const fetchUserData = async (userData: object) => {
    try {
      if (userData) {
        setLoggedIn(true);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData(userData);
  });

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            !isLoggedIn ? (
              <Login setLoggedIn={setLoggedIn} />
            ) : (
              <Navigate to="/dashboard" replace />
            )
          }
        />
        <Route
          path="/dashboard"
          element={<AuthRoute setLoggedIn={setLoggedIn} />}
        >
          <Route path="/dashboard" element={<Home />} />
        </Route>
        <Route
          path="/appication-setups"
          element={<AuthRoute setLoggedIn={setLoggedIn} />}
        >
          <Route path="/appication-setups" element={<ApplicationPage />} />
        </Route>
        <Route
          path="/technician"
          element={<AuthRoute setLoggedIn={setLoggedIn} />}
        >
          <Route path="/technician" element={<TechnicianPage />} />
        </Route>
        <Route
          path="/consumer"
          element={<AuthRoute setLoggedIn={setLoggedIn} />}
        >
          <Route path="/consumer" element={<ConsumerPage />} />
        </Route>
        <Route
          path="/work-request"
          element={<AuthRoute setLoggedIn={setLoggedIn} />}
        >
          <Route path="/work-request" element={<WorkRequestPage />} />
        </Route>
        <Route
          path="/product-inquiry-list"
          element={<AuthRoute setLoggedIn={setLoggedIn} />}
        >
          <Route
            path="/product-inquiry-list"
            element={<ProductInquiryPage />}
          />
        </Route>
        <Route
          path="/product-inquiry-single-view/:productInqId"
          element={<AuthRoute setLoggedIn={setLoggedIn} />}
        >
          <Route path="/product-inquiry-single-view/:productInqId" element={<ProductInquirySingle />} />
        </Route>

        <Route
          path="/view-product-list"
          element={<AuthRoute setLoggedIn={setLoggedIn} />}
        >
          <Route path="/view-product-list" element={<ViewProductPage />} />
        </Route>
        <Route path="/reset-page" />
      </Routes>
    </Router>
  );
};

export default App;
