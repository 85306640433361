import { Card, Row } from "react-bootstrap";
import "../styles/CardCustom.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import CustomCard from "./SingleCard";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { CustomNextArrow, CustomPrevArrow } from "./CardCustom";
import { editMyItems, removeFromMyItems, setMyItems } from "../features/myItemsSlice";
import ColorSelection from "./ColorSelection";
import Button from "./Button";
import QuantityDropdown from "./QuantityDropdown";

interface cardItem {
  parameterView?: string;
  description?: string;
  name?: string;
}

interface CartItem extends cardItem {
  quantity: number;
  unitPrice: any;
}

interface MyListProps {
    setEditMyListItem: (status:boolean) => void;
}

const settings = {
  dots: false,
  infinite: false,
  arrows: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: <CustomNextArrow />,
  prevArrow: <CustomPrevArrow />,
};

const MyList: React.FC<MyListProps> = ({
    setEditMyListItem,
}) => {
    const dispatch = useDispatch<any>();
  const myItemsArray = useSelector((state: any) => state.myItems.myItems);
  const [quentity, setQuentity] = useState<number>(1);
  const [selectedCard, setSelectedCard] = useState<CartItem | null>(null);
  const [showSingleCardView, setShowSingleCardView] = useState<boolean>(false);

  const editItems = (editItem: any) => {
    const index = myItemsArray.findIndex((item: any) => item.id === editItem.id);
    if (index !== -1) {
      const updatedItem = { ...editItem, quantity: quentity };
      dispatch(editMyItems({ index, updatedItem }));
      setEditMyListItem(false);
    }
    setShowSingleCardView(false);
  };

  const editItemsView = (editItem: any) => {
    setShowSingleCardView(true);
    setSelectedCard(editItem);
  };
 

  const deleteItems = (indexToDelete: number): void => {
    dispatch(removeFromMyItems(indexToDelete));
  };
  const colors = ["red", "blue", "green", "yellow"];
  
  const handleQuantityChange = (newQuantity: number) => {
    if (newQuantity >= 1) {
      setQuentity(newQuantity);
    }
    if (selectedCard) {
        setSelectedCard(prevState => ({
            ...prevState!,
            quantity: newQuantity
        }));
    }
  };

  return (
    <>
     {showSingleCardView && selectedCard ? (
        <div>
          <div className="single-view">
            <img
              src={selectedCard.parameterView ? selectedCard.parameterView : ""}
              className="product-image"
              alt="Card"
            />
            <div className="wording-box">
              <p className="wording-name">{selectedCard.name}</p>
              <p className="wording-desc">{selectedCard.description}</p>
              <div>
                <p className="wording-lines">Unit Price</p>
                <p className="wording-qty">Rs : {selectedCard.unitPrice}</p>
              </div>
              <p className="wording-lines">Choose Colors</p>
              <ColorSelection colors={colors} />
              <div className="qty-box">
                <p className="wording-qty">Qty</p>
                <QuantityDropdown
                  quantity={selectedCard.quantity}
                  onChange={handleQuantityChange}
                />
              </div>
            </div>
          </div>
          <div className="button-container">
            <Button
              style={{
                width: "25vh",
                backgroundColor: "black",
                marginRight: "3.5vh",
                marginTop: "0.5rem",
              }}
              onClick={()=> setShowSingleCardView(false)}
            >
              Cancel
            </Button>
            <Button
              style={{
                width: "25vh",
                marginRight: "3.5vh",
                marginTop: "0.5rem",
              }}
              onClick={() => {
                editItems(selectedCard);
              }}
            >
              Edit
            </Button>
          </div>
        </div>
      ) : (
        <>
            {myItemsArray && myItemsArray.length > 0 ? (
        <Row>
          <div className="product-type">My Inquiry</div>
          <Slider {...settings} className="slick-slider">
            {myItemsArray.map((item: CartItem, index: number) => (
              <Row key={index}>
                <div
                  className="card-container"
                  style={{ justifyContent: "flex-start" }}
                >
                  <CustomCard
                    parameterView={item.parameterView}
                    description={item.name}
                    quantity={item.quantity}
                    deleteIcon={true}
                    cardHeight={"50vh"}
                    deleteAction={() => deleteItems(index)}
                    editIcon={true}
                    editAction={() => editItemsView(item)}
                  />
                </div>
              </Row>
            ))}
          </Slider>
        </Row>
      ) : null}
        </>
      )}
      
    </>
  );
};
export default MyList;
