import React, { useState } from "react";
import Button from "../../components/Button";
import Modal from "react-bootstrap/Modal";
import "../../styles/ReferralTechnician.scss";
import { Form } from "react-bootstrap";

interface ReferralTechnicianProps {
  show: boolean;
  onHide?: () => void;
  modaltitle: string | null;
  modalSubTitle?: string | null;
  actionButton?: string | null;
  actionMethod?: (isChecked:boolean) => void;
  imgIcon?: any;
  item?: string;
  inputItem?: boolean;
  displayDiv?:any;
}

const ReferralTechnician: React.FC<ReferralTechnicianProps> = (props) => {
    const [isChecked, setIsChecked] = useState<boolean>(false);

  return (
    <Modal
      {...props}
      size="sm"
      centered
      backdrop="static"
      className="success-modal"
    >
      <Modal.Header
        style={{
          border: "none",
          fontSize: "14px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="modal-title-normal">{props.modaltitle}</div>
      </Modal.Header>
      <Modal.Body className="modal-body" style={{ border: "none" , marginTop : "-10px"}}>
        <div className="modal-body-sub">
          {props.modalSubTitle !== "" && props.modalSubTitle}
        </div>
        {props.displayDiv  && (
          props.displayDiv
        )}
        <Form.Check
          type="checkbox"
          label="Select the technician as a referral."
          checked={isChecked}
          onChange={(e) => setIsChecked(e.target.checked)}
          style={{ marginTop: "10px" , fontSize: "14px", color: "#777777"}}
        />
      </Modal.Body>
      <Modal.Footer style={{ border: "none" }}>
        {props.actionButton ? (
          <Button
            style={{
              justifyContent: "flex-start",
              alignSelf: "flex-start",
              width: "100%",
              height: "50px",
              marginBottom: "10px",
            }}
            onClick={() => props.actionMethod && props.actionMethod(isChecked)}
          >
            {props.actionButton}
          </Button>
        ) : null}
        {
          props.onHide && (
            <Button
              style={{
                backgroundColor: "black",
                color: "#FFFFFF",
                justifyContent: "flex-start",
                alignSelf: "flex-start",
                width: "100%",
                height: "50px",
                marginBottom: "10px",
              }}
              onClick={props.onHide}
            >
              Cancel
            </Button>
          )
        }
      </Modal.Footer>
    </Modal>
  );
};

export default ReferralTechnician;
