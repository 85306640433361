import React, { useEffect, useState } from "react";
import blueEye from "../../assets/blue-eye.png";
import userIcon from "../../assets/userIcon.png";
import groupUser from "../../assets/Groupuser.png";
import TableTech from "../../components/TableTech";
import Popup from "../../components/Popup";
import TechnicianInfo from "./TechnicianInfo";
import TechnicianRegister from "./TechnicianRegister";
import EditIcon from "../../assets/edit-icon.png";
import TechnicianEdit from "./TechnicianEdit";
import { useDispatch, useSelector } from "react-redux";
import {
  getTechnicians,
  getTechniciansByID,
  getWorkRequestsByTechnicianID,
} from "../../services/technicianService";
import { TECHNICIANS_PER_PAGE } from "../../constants/appConstants";
import { getTenants } from "../../services/tenantService";



const iconStyle: React.CSSProperties = {
  width: "2.5vh",
  height: "2.5vh",
  marginRight: "0.5vh",
};

const imgStyle: React.CSSProperties = {
  width: "20vh",
  height: "20vh",
  //   marginLeft: "45vh",
};
const TechnicianManagement: React.FC = () => {
  const dispatch = useDispatch<any>();
  const [showTechnician, setTechnicianView] = useState<boolean>(false);
  const [showEditForm, setShowEditForm] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [selectedLevelData, setSelectedLevelData] = useState<any>(null);
  const [showTechRegistration, setShowTechRegistration] =
    useState<boolean>(false);
  const singleTechnician = useSelector(
    (state: any) => state.technicians.singleTechnician
  );

  useEffect(() => {
    reloadData();
    dispatch(getTenants());
  }, [dispatch]);
  
  const reloadData = () => {
    dispatch(getTechnicians(0,TECHNICIANS_PER_PAGE));
  };
  const openEditForm = (technicianData: any) => {
    const id = technicianData.id;
    dispatch(getTechniciansByID(technicianData.id));
    setShowEditForm(true);
  };
  const toggleRegisterForm = () => {
    setShowTechRegistration(!showTechRegistration);
  };

  const openCreatejobs = () => {
    //setTechnicianView(true);
  };
  const viewTechnician = (technicianData: any) => {
    const id = technicianData.id;
    dispatch(getTechniciansByID(id));
    dispatch(getWorkRequestsByTechnicianID(id));
    setTechnicianView(true);
  };

  const closeTechnicianView = () => {
    setTechnicianView(false);
    // removed selected technician from stroe
  };

  const openStatusPopup = (selectedData: any) => {
    setSelectedLevelData(selectedData);
    setShowPopup(true);
  };

  const activateTech = () => {
    setSelectedLevelData(null);
    setShowPopup(false);
  };

  return (
    <div style={{ margin: "0 2vh" }}>
      {showEditForm ? (
        <TechnicianEdit
          onCancel={() => {setShowEditForm(false)
            reloadData()}}
          onSaveSuccess={() => setShowEditForm(false)}
        />
      ) : showTechRegistration ? (
        <TechnicianRegister onCancel={() => {
          setShowTechRegistration(false)
          reloadData();
        }} />
      ) : (
        <>
          {showTechnician ? (
            <TechnicianInfo onClose={closeTechnicianView} />
          ) : (
            <>
              <Popup
                show={showPopup}
                onHide={() => setShowPopup(false)}
                actionButton={true}
                actionMethod={activateTech}
                imgIcon={<img src={groupUser} style={imgStyle} />}
                modaltitle={"Change Technician Status"}
                content={
                  "Are you sure you want to change status of the selected technician?"
                }
              />
              <TableTech
                buttonName={"Register Technician"}
                columns={[
                  "fullName",
                  "nationalIdentityCardNo",
                  "contactNumberPrimary",
                  "registrationSource",
                  "mainWorkingArea",
                  "status",
                ]}
                columnLabels={[
                  "Technician’s Name",
                  "NIC Number",
                  "Contact",
                  "Source",
                  "Main Working Area",
                  "Status",
                ]}
                buttonAction={toggleRegisterForm}
                viewAction={<img src={blueEye} style={iconStyle} />}
                viewActionMethod={viewTechnician}
                editAction={<img src={EditIcon} style={iconStyle}></img>}
                editActionMethod={openEditForm}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default TechnicianManagement;
