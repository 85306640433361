import React, { useState } from "react";
import styles from "../styles/Home.module.css";
import Layout from "../components/Layout";
import Header from "../components/Header";
import SideBar from "../components/SideBar";
import Main from "../components/Main";
import { useParams } from "react-router-dom";
import { getProductInquriesByID } from "../services/productInquirieService";
import { useDispatch } from "react-redux";

const ProductInquirySingle: React.FC = () => {
  const dispatch = useDispatch<any>();
  const { productInqId }: { productInqId?: string } = useParams<{
    productInqId: string;
  }>();

  const productInquiryId = productInqId?.split("=")[1];
  console.log("Product ID:", productInquiryId);
  const [activeButton, setActiveButton] = useState<any>(
    "product-inquiry-single-view"
  );

  if (productInquiryId) {
    dispatch(getProductInquriesByID(productInquiryId));
  }

  return (
    <div className={styles.appContainer}>
      <Layout>
        <Header></Header>
        <SideBar
          activeButton={activeButton}
          setActiveButton={setActiveButton}
        ></SideBar>
        <Main activeButton={activeButton} children={productInquiryId} />
      </Layout>
    </div>
  );
};

export default ProductInquirySingle;
