import React, { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import Button from "../../components/Button";
import pwdStyles from '../../styles/ForgotPassward.module.scss';
import ResetPasswordModal from "./ResetPasswordModel";
import { sendEmailForOTP } from '../../services/forgotPasswordService';
import { ForgotPasswordEmailVerify } from '../../type';
import { useDispatch } from "react-redux";

interface OtpVerificationModalProps {
    show: boolean;
    handleClose: () => void;
    email: string;
}

const OtpVerificationModal: React.FC<OtpVerificationModalProps> = ({ show, email, handleClose }) => {

    const dispatch = useDispatch<any>();

    const [otp, setOtp] = useState<string[]>(Array(6).fill(""));
    const [countdown, setCountdown] = useState(60);
    const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [countdownOver, setCountdownOver] = useState(false);

    const handleVerifyOtp = () => {
        const filled = otp.every(val => val !== "");
        if (!filled) {
            setErrorMessage("Please enter your OTP.");
            return;
        }
        setResetPasswordModalOpen(true);
    };

    const reSendOTP = () => {
        const emailData: ForgotPasswordEmailVerify = {
            email: email
        };
        dispatch(sendEmailForOTP(emailData));
        setOtp(Array(6).fill(""));
    }

    const handleChangeOtp = (index: number, value: string) => {
        const newOtp = [...otp];
        if (/^\d*$/.test(value) && value.length <= 1) {
            newOtp[index] = value;
            setOtp(newOtp);
        }
    };

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;
        if (show) {
            timer = setInterval(() => {
                setCountdown(prevCountdown => {
                    if (prevCountdown > 0) {
                        return prevCountdown - 1;
                    } else {
                        setCountdownOver(true);
                        return 0;
                    }
                });
            }, 1000);
        }
        return () => {
            if (timer) clearInterval(timer);
        };
    }, [show]);

    useEffect(() => {
        if (countdownOver) {
            // Clear OTP data when countdown is over
            setOtp(Array(6).fill(""));
        }
    }, [countdownOver]);

    return (
        <>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Forgot Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={pwdStyles.otpText}>
                        Please submit the OTP that you received by an Email, to Reset your Password.
                    </div>

                    <div className={pwdStyles.countdown}>
                        {countdownOver ? (
                            <span style={{ color: "red" }}>Time is over! Please click on Resend OTP</span>
                        ) : (
                            countdown
                        )}
                    </div>
                    <Form>
                        <Form.Group controlId="formPlaintextOtp">
                            <div className={pwdStyles.otpContainer}>
                                {otp.map((val, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        maxLength={1}
                                        value={val}
                                        onChange={(e) => handleChangeOtp(index, e.target.value)}
                                        className={pwdStyles.otpbox}
                                    />
                                ))}
                            </div>
                            {errorMessage && (
                                <p style={{ color: "red", margin: "5px 0 0", fontSize: "0.9em" }}>{errorMessage}</p>
                            )}
                        </Form.Group>
                    </Form>

                    <div className="justify-content-center">
                        <Button
                            style={{
                                width: "30vh",
                                backgroundColor: "#FFC600",
                                padding: "0.7rem",
                            }}
                            onClick={handleVerifyOtp}
                            children={"Submit"}
                        />
                    </div>

                    <div>
                        <div style={{ color: '#777777' }}>Didn't Received?
                            <span
                                style={{ color: "black", fontWeight: 'bold', cursor: "pointer", textDecoration: "underline" }}
                                onClick={reSendOTP}
                            >
                                Resend OTP
                            </span>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {resetPasswordModalOpen && <ResetPasswordModal show={true} otp={otp.join("")} email={email} handleClose={() => setResetPasswordModalOpen(false)} />}
        </>
    );
};

export default OtpVerificationModal;
