import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WorkRequest } from "../type";

interface WorkRequestState {
  workRequests: WorkRequest[];
  loading: boolean;
  error: string | null;
  success: boolean;
  workRequestsForConsumer?: any;
  successMsg?: string | null;
  assignLoading?: boolean;
}

const initialState: WorkRequestState = {
  workRequests: [],
  loading: false,
  error: null,
  success: false,
  workRequestsForConsumer: {},
  successMsg: null,
  assignLoading: false,
};

const workRequestSlice = createSlice({
  name: "workRequests",
  initialState,
  reducers: {
    setWorkRequests(state, action: PayloadAction<WorkRequest[]>) {
      state.loading = false;
      state.error = null;
      state.success = false;
      state.successMsg = null;
      state.workRequests = action.payload;
    },
    setWorkRequestsLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
      state.error = null;
      state.success = false;
      state.successMsg = null;
    },
    setWorkRequestsAssignLoading(state, action: PayloadAction<boolean>) {
      state.loading = false;
      state.assignLoading = action.payload;
      state.error = null;
      state.success = false;
      state.successMsg = null;
    },
    setWorkRequestsError(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
      state.successMsg = null;
    },
    addWorkRequestState(state, action: PayloadAction<WorkRequest>) {
      state.workRequests.push(action.payload);
      state.loading = false;
      state.error = null;
      state.success = true;
      state.successMsg = null;
    },
    setWorkRequestsForConsumer(state, action: PayloadAction<WorkRequest[]>) {
      state.workRequestsForConsumer = action.payload;
    },
    // editJobTypeState(state, action: PayloadAction<{ id: string; updatedJobType: JobType }>) {
    //   const { id, updatedJobType } = action.payload;
    //   const index = state.jobTypes.findIndex(jobType => jobType.id === id);
    //   if (index !== -1) {
    //     state.jobTypes[index] = updatedJobType;
    //     state.loading = false;
    //     state.error = null;
    //     state.success = true;
    //   }
    // },
    assignTechnicianState(
      state,
      action: PayloadAction<{ id: string; updatedWorkRequest: WorkRequest }>
    ) {
      const { id, updatedWorkRequest } = action.payload;
      const index = state.workRequests.findIndex((work: any) => work.id === id);

      if (index !== -1) {
        state.workRequests[index] = updatedWorkRequest;
        state.loading = false;
        state.error = null;
        state.success = true;
        state.successMsg =
          "Technician is successfully assigned to the work request.";
      }
    },
    unAssignTechnicianState(
      state,
      action: PayloadAction<{ id: string; updatedWorkRequest: WorkRequest }>
    ) {
      const { id, updatedWorkRequest } = action.payload;
      const index = state.workRequests.findIndex((work: any) => work.id === id);

      if (index !== -1) {
        state.workRequests[index] = updatedWorkRequest;
        state.loading = false;
        state.error = null;
        state.success = true;
        state.successMsg =
          "Technician is successfully Un-Assigned from the work request.";
      }
    },
    deleteWorkRequestState(state, action: PayloadAction<string>) {
      const workRequestId = action.payload;
      state.workRequests = state.workRequests.filter(
        (work) => work.id !== workRequestId
      );
      state.loading = false;
      state.error = null;
      state.success = true;
      state.successMsg = "Work request is successfully deleted.";
    },
    updateWorkRequestState(
      state,
      action: PayloadAction<{ id: string; updateWorkRequest: WorkRequest }>
    ) {
      const { id, updateWorkRequest } = action.payload;
      console.log("updatedWorkRequest service slice:", action.payload);
      const index = state.workRequests.findIndex((work: any) => work.id === id);
      if (index !== -1) {
        state.workRequests[index] = updateWorkRequest;
        state.loading = false;
        state.error = null;
        state.success = true;
        state.successMsg = "Work request is successfully updated.";
      }
    },
    statusUpdated(state) {
      state.loading = false;
      state.error = null;
      state.success = true;
    },
  },
});

export const {
  setWorkRequests,
  setWorkRequestsLoading,
  setWorkRequestsError,
  addWorkRequestState,
  setWorkRequestsForConsumer,
  assignTechnicianState,
  unAssignTechnicianState,
  deleteWorkRequestState,
  updateWorkRequestState,
  statusUpdated,
  setWorkRequestsAssignLoading,
} = workRequestSlice.actions;

export default workRequestSlice.reducer;
