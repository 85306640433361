import React from 'react'
import '../styles/DisplayCounts.scss'
import Technician_Dash_Icon from '../assets/Technician_Dash_Icon.png'
import Consumer_Dash_Icon from '../assets/Consumer_Dash_Icon.png'
import WorkRequest_Dash_Icon from '../assets/WorkRequest_Dash_Icon.png'
import Products_Dash_Icon from '../assets/Products_Dash_Icon.png'

interface DisplayCountsProps {
    displayType?: string,
    displayCount?: any
}
const DisplayCounts: React.FC<DisplayCountsProps> = ({ displayType, displayCount }) => {


    return (
        <div className='container'>
            <div className='top'>{displayType}</div>
            <div className='bottem'>
                <div className='icon'>
                    <div className='icon'>
                        {displayType === 'Technicians' && <img src={Technician_Dash_Icon} alt="bell" />}
                        {displayType === 'Consumers' && <img src={Consumer_Dash_Icon} alt="bell" />}
                        {displayType === 'Work Requests' && <img src={WorkRequest_Dash_Icon} alt="bell" />}
                        {displayType === 'Product Inquiries' && <img src={Products_Dash_Icon} alt="bell" />}
                    </div>
                </div>
                <div className='count'>
                    <div>{displayCount}</div>
                </div>
            </div>
        </div>
    )
}

export default DisplayCounts