import { BASE_URL } from '../constants/appConstants';


export const emailVerify = async (email: any) => {
  try {
    const end_url = BASE_URL + "/auth/email/verification/otp/initiate";
    const response = await fetch(end_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(email),
    });
    const newdata = await response.json();
    return newdata;
  } catch (error) {
    throw new Error('Failed to sending otp');
  }
};

//function to rest password
export const resetPassword = async (resetDetails: any) => {
  try {
    const end_url = BASE_URL + "/auth/email/reset-password";
    const response = await fetch(end_url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(resetDetails),
    });
    if (response.status === 201 || response.status === 200) {
      return true;
    } else {
      const newTechnician = await response.json();
      return newTechnician;
    }
  } catch (error) {
    throw new Error('Failed to reset password');
  }
};