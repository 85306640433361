import React, { useEffect, useState } from "react";
import styles from "../../styles/SelectTechnician.module.scss";
import { Col, Image, Modal, Row, Table } from "react-bootstrap";
import Rating from "../../components/RatingStars";
import phone from "../../assets/phone-call.png";
import location from "../../assets/map-pin.png";
import clock from "../../assets/clock-dark.png";
import book from "../../assets/book-open.png";
import briefcase from "../../assets/briefcase-dark.png";
import dollar from "../../assets/dollar-sign-dark.png";
import paperClip from "../../assets/paperclip-dark.png";
import workRequestAssign from "../../assets/work-request-assign.png";
import Button from "../../components/Button";
import { JobType } from "../../type";
import { useDispatch, useSelector } from "react-redux";
import { getTechnicians } from "../../services/technicianService";
import { AnyAaaaRecord } from "dns";
import useDummyImage from "../../assets/consumerImage.png";
import { BASE_URL_FOR_IMAGE } from "../../constants/appConstants";
import WorkRequestStatus from "../../components/WorkRequestStatus";
import chevronLeft from "../../assets/chevron-left.png";
import chevronRight from "../../assets/chevron-right.png";
import pdfFile from "../../assets/pdf-file.png";
import xlsFile from "../../assets/xls-file.png";

interface WorkRequest {
  id: number;
  name: string;
  rating: number;
  status: string;
  phone: string;
  date: string;
  imageUrl: string;
  budget: number;
  workDescription: string;
  address: string;
  district: string;
  city: string;
  jobType: string[];
}

interface Technician {
  id: number;
  name: string;
  nic: string;
  education: string;
  address: string;
  imageUrl: string;
  workingArea: string;
  primary: string;
  mobile: string;
  jobType: string;
  rating: number;
}

interface Props {
  workRequestData: any;
  onCancel: () => void;
  // onAssignTechnician: (selectedTechnician: Technician[]) => void;
  onAssignTechnician: (
    // selectedTechnician: Technician | Technician[] | null
    selectedTechnician: any
  ) => void;
  // onSaveSuccess: () => void;
}

// const jobTitles: string[] = ["Electrician", "Plumber", "Carpenter", "Painter"];

const SelectTechnician: React.FC<Props> = ({
  onCancel,
  workRequestData,
  onAssignTechnician,
}) => {
  const dispatch = useDispatch<any>();
  const [selectedTechnician, setSelectedTechnician] = useState<any>(null);

  const filteredTechnicianData = useSelector(
    (state: any) => state.technicians.techniciansForJobTypes
  );

  // const [selectedJob, setSelectedJob] = useState<string>(technicianData.workExperience);
  const [selectedJob, setSelectedJob] = useState<string>(
    workRequestData.jobTypes[0].id || ""
  );

  const [jobTypes, setJobTypes] = useState<JobType[]>([]);

  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const jobTypesBe = useSelector((state: any) => state.jobTypes.jobTypes.filter((jobType: any) => jobType.status === 1));

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage] = useState<number>(15);

  const [clickedImage, setClickedImage] = useState<any>(null);

  // const handleTechnicianSelect = (technician: Technician) => {
  //   setSelectedTechnician(technician);
  // };

  useEffect(() => {
    setLatitude(workRequestData.latitude);
    setLongitude(workRequestData.longitude);
  }, []);

  const googleMapsLink = `https://www.google.com/maps?q=${latitude},${longitude}`;

  useEffect(() => {
    setJobTypes(jobTypesBe);
  }, [dispatch]);

  const handleTechnicianSelect = (technician: any) => {
    const isSelected =
      selectedTechnician && selectedTechnician.id === technician.id;

    if (isSelected) {
      // If the technician is already selected, remove them
      setSelectedTechnician(null);
    } else {
      // If the technician is not selected, set them as the selected technician
      setSelectedTechnician(technician);
    }
  };

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const handleJobChange = (event: any) => {
    setSelectedJob(event.target.value);
    // You can add additional logic here, such as filtering your table data based on the selected job title
  };

  // Handle image click
  const handleImageClick = (imageUrl: string) => {
    setClickedImage(imageUrl);
  };

  const handleAssignTechnician = () => {
    // Check if technicians are selected
    // if (selectedTechnicians.length > 0) {
    //   // Call the onAssignTechnician prop with the selected technicians
    onAssignTechnician(selectedTechnician);
    //   // Clear the selection
    //   setSelectedTechnicians([]);
    // } else {
    //   // Handle the case where no technician is selected
    //   console.error("Please select at least one technician before assigning.");
    //   // You may want to display a message or take other actions
    // }
  };

  // const filteredTechnicianData = selectedJob
  //   ? technicianData.filter((technician) =>
  //       technician.jobType.includes(selectedJob)
  //     )
  //   : technicianData;

  // const filteredTechnicianData = technicianData.filter((technician: any) => {
  //   const selectedJobType = workRequestData.jobTypes[0];

  //   if (selectedJobType && technician?.workExperience?.jobTypeExperiences) {
  //     const selectedJobId = selectedJobType.id;

  //     return technician.workExperience.jobTypeExperiences.some(
  //       (jobType: any) => jobType.id === selectedJobId
  //     );
  //   }

  //   // return false;
  // });

  const currentItems =
    filteredTechnicianData &&
    filteredTechnicianData.slice(indexOfFirstItem, indexOfLastItem);
  const showCount = (currentItems && currentItems.length) || 0;

  const totalCount =
    (filteredTechnicianData && filteredTechnicianData.length) || 0;
  return (
    <div className={styles.appContainer}>
      {
        <Modal
          show={clickedImage !== null}
          onHide={() => setClickedImage(null)}
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Image src={clickedImage} fluid />
          </Modal.Body>
        </Modal>
      }

      <div className={styles.appTitle}>Assign a Technician</div>

      <div className={styles.mainOuterSection}>
        <div className={styles.mainSection}>
          <div className={styles.mainSectionLeft}>
            <Col style={{display: "flex"}}>
              <div className={styles.imageView}>
                <Image
                  // src={workRequestData.consumer.profileImage.url}
                  src={
                    workRequestData.consumer &&
                    workRequestData.consumer.profileImage.url
                      ? BASE_URL_FOR_IMAGE +
                        workRequestData.consumer.profileImage.url
                      : useDummyImage
                  }
                  alt={workRequestData.consumer.firstName}
                  roundedCircle
                  fluid
                  className={`${styles.profilePicture}`}
                  style={{ marginRight: "25px" }}
                />
                <div>
                  <div style={{ display: "flex", gap: "0.3rem" }}>
                    <strong className="mr-2" style={{ fontSize: "1rem" }}>
                      {workRequestData.consumer.firstName}
                    </strong>
                    <strong className="mr-2" style={{ fontSize: "1rem" }}>
                      {workRequestData.consumer.lastName}
                    </strong>
                  </div>
                  <div className={styles.ratingSection}>
                    <Rating rating={workRequestData.consumer.averageRating} size={15} />
                    {workRequestData.consumer.averageRating}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: "1",
                  justifyContent: "flex-end",
                  flexDirection: "row",
                  alignSelf: "flex-start",
                  justifyItems: "flex-end",
                  marginRight: "10px",
                }}
              >
                <WorkRequestStatus status={workRequestData.status} />
              </div>
            </Col>

            <div className={styles.mainSectionLeftContent}>
              <Col className={`align-items-center ${styles.dataField}`}>
                <div
                  style={{
                    color: "#777777",
                    fontSize: "0.8rem",
                    marginBottom: "0.2rem",
                  }}
                >
                  Job Type
                </div>
                <div className={styles.dataFieldText}>
                  <div style={{ marginRight: "1rem" }}>
                    <img
                      src={location}
                      style={{ height: "1.2rem", width: "1.2rem" }}
                    />
                  </div>
                  <div>
                    {workRequestData.jobTypes &&
                      workRequestData.jobTypes.length > 0 &&
                      workRequestData.jobTypes[0].type}
                  </div>
                </div>
              </Col>

              <Col className={`align-items-center ${styles.dataField}`}>
                <div
                  style={{
                    color: "#777777",
                    fontSize: "0.8rem",
                    marginBottom: "0.2rem",
                  }}
                >
                  Contact Number
                </div>
                <div className={styles.dataFieldText}>
                  <div style={{ marginRight: "1rem" }}>
                    <img
                      src={phone}
                      style={{ height: "1.2rem", width: "1.2rem" }}
                    />
                  </div>
                  <div>{workRequestData.consumer.contactNumberPrimary}</div>
                </div>
              </Col>

              <Col className={`align-items-center ${styles.dataField}`}>
                <div
                  style={{
                    color: "#777777",
                    fontSize: "0.8rem",
                    marginBottom: "0.2rem",
                  }}
                >
                  Address
                </div>
                <div className={styles.dataFieldText}>
                  <div style={{ marginRight: "1rem" }}>
                    <img
                      src={location}
                      style={{ height: "1.2rem", width: "1.2rem" }}
                    />
                  </div>
                  <div>{workRequestData.address}</div>
                </div>
              </Col>

              <Col className={`align-items-center ${styles.dataField}`}>
                <div
                  style={{
                    color: "#777777",
                    fontSize: "0.8rem",
                    marginBottom: "0.2rem",
                  }}
                >
                  Location
                </div>
                <div className={styles.dataFieldText}>
                  <div style={{ marginRight: "1rem" }}>
                    <img
                      src={location}
                      style={{ height: "1.2rem", width: "1.2rem" }}
                    />
                  </div>
                  <div>
                    <a
                      href={googleMapsLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View on Google Maps
                    </a>
                  </div>
                </div>
              </Col>

              <Col className={`align-items-center ${styles.dataField}`}>
                <div
                  style={{
                    color: "#777777",
                    fontSize: "0.8rem",
                    marginBottom: "0.2rem",
                  }}
                >
                  Expected starting date
                </div>
                <div className={styles.dataFieldText}>
                  <div style={{ marginRight: "1rem" }}>
                    <img
                      src={clock}
                      style={{ height: "1.2rem", width: "1.2rem" }}
                    />
                  </div>
                  <div>{workRequestData.startingDate}</div>
                </div>
              </Col>

              <Col className={`align-items-center ${styles.dataField}`}>
                <div
                  style={{
                    color: "#777777",
                    fontSize: "0.8rem",
                    marginBottom: "0.2rem",
                  }}
                >
                  Expected budget
                </div>
                <div className={styles.dataFieldText}>
                  <div style={{ marginRight: "1rem" }}>
                    <strong>RS:</strong>
                  </div>
                  <div>{workRequestData.budjet}</div>
                </div>
              </Col>

              <Col className={`align-items-center ${styles.dataField}`}>
                <div
                  style={{
                    color: "#777777",
                    fontSize: "0.8rem",
                    marginBottom: "0.2rem",
                  }}
                >
                  Attachments
                </div>
                <div className={styles.dataFieldText}>
                  <div style={{ marginRight: "1rem" }}>
                    <img
                      src={paperClip}
                      style={{ height: "1.2rem", width: "1.2rem" }}
                    />
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    {workRequestData &&
                    workRequestData.workRequestImages &&
                    workRequestData.workRequestImages.length > 0 ? (
                      workRequestData.workRequestImages.map(
                        (image: any, index: any) => (
                          // <Image
                          //   key={index}
                          //   src={BASE_URL_FOR_IMAGE + image.url}
                          //   alt={`Image ${index + 1}`}
                          //   style={{
                          //     marginRight: "10px",
                          //     width: "2rem",
                          //     height: "2rem",
                          //   }}
                          // />
                          <div key={index}>
                            {image.url.endsWith(".pdf") ? (
                              <a
                                href={BASE_URL_FOR_IMAGE + image.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={pdfFile}
                                  alt={`PDF ${index + 1}`}
                                  style={{
                                    width: "2rem",
                                    height: "2rem",
                                  }}
                                />
                              </a>
                            ) : image.url.endsWith(".xls") ||
                              image.url.endsWith(".xlsx") ? (
                              <a
                                href={BASE_URL_FOR_IMAGE + image.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={xlsFile}
                                  alt={`Excel ${index + 1}`}
                                  style={{
                                    width: "2rem",
                                    height: "2rem",
                                  }}
                                />
                              </a>
                            ) : (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleImageClick(
                                    BASE_URL_FOR_IMAGE + image.url
                                  )
                                }
                              >
                                <Image
                                  src={BASE_URL_FOR_IMAGE + image.url}
                                  alt={`Image ${index + 1}`}
                                  style={{
                                    width: "2rem",
                                    height: "2rem",
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        )
                      )
                    ) : (
                      <div>No attachments available..!</div>
                    )}
                  </div>
                </div>
              </Col>
            </div>
          </div>

          <div
            className={styles.mainSectionRight}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
           
            {currentItems && currentItems.length > 0 && selectedJob ? (
              <>
                <Table striped bordered hover>
                  <thead style={{ background: "#ECEFF4" }}>
                    <tr>
                      <th style={{ padding: "1rem" }}></th>
                      <th style={{ padding: "1rem" }}>Name</th>
                      <th style={{ padding: "1rem" }}>Contact</th>
                      <th style={{ padding: "1rem" }}>Job Type</th>
                      <th style={{ padding: "1rem" }}>Rating</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((technician: any, index: any) => (
                      <tr
                        key={technician.id}
                        style={{
                          background: index % 2 === 0 ? "#FFFFFF" : "#F2F4FF",
                        }}
                      >
                        <td>
                          <div className="custom-radio">
                            <input
                              type="radio"
                              id={`checkbox-${technician.id}`}
                              onChange={() =>
                                handleTechnicianSelect(technician)
                              }
                              checked={
                                selectedTechnician &&
                                selectedTechnician.id === technician.id
                              }
                            />

                            <label htmlFor={`radio-${technician.id}`} />
                          </div>
                        </td>
                        <td>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Image
                              src={
                                technician && technician.profileImage.url
                                  ? BASE_URL_FOR_IMAGE +
                                    technician.profileImage.url
                                  : useDummyImage
                              }
                              alt={technician.name}
                              roundedCircle
                              fluid
                              className={`mr-3 ${styles.profilePictureTable}`}
                              style={{ marginRight: "1.5rem" }}
                            />
                            <div style={{ display: "flex", gap: "0.2rem" }}>
                              <strong>{technician.firstName}</strong>
                              <strong>{technician.lastName}</strong>
                            </div>
                          </div>
                        </td>
                        <td>{technician.contactNumberPrimary}</td>
                        <td>
                          {
                            technician.workExperience.jobTypeExperiences[0]
                              .jobType.type
                          }
                        </td>
                        <td>
                          <div style={{ fontSize: "10px", display: "flex" }}>
                            <Rating rating={technician.averageRating} size={10} />
                            {technician.averageRating}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div
                  style={{
                    marginLeft: "20px",
                    display: "flex",
                    alignItems: "center",
                    gap: "2",
                  }}
                >
                  <p style={{ marginRight: "5px" }}>
                    {currentItems && currentItems.length > 0
                      ? `Show ${showCount} out of ${totalCount} results`
                      : "No results found"}
                  </p>

                  <p>
                    <img
                      src={chevronLeft}
                      style={{
                        marginRight: "10px",
                        height: "10px",
                        width: "10px",
                      }}
                    />
                  </p>
                  <p>
                    <Pagination
                      itemsPerPage={itemsPerPage}
                      totalItems={totalCount}
                      currentPage={currentPage}
                      paginate={paginate}
                    />
                  </p>

                  <p>
                    <img
                      src={chevronRight}
                      style={{
                        height: "5px",
                        width: "5px",
                        marginLeft: "-10px",
                      }}
                    />
                  </p>
                </div>
              </>
            ) : filteredTechnicianData &&
              filteredTechnicianData.length === 0 &&
              selectedJob ? (
              <div
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  textAlign: "center",
                  color: "grey",
                }}
              >
                No technicians available for this kind of job types.
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "1rem",
                  fontWeight: "bold",
                  color: "grey",
                }}
              >
                Please select a job type to view technicians.
              </div>
            )}

            <div className={styles.mainSectionBottom}>
              <Col sm="8" className={styles.buttonContainer}>
                <Button
                  style={{
                    backgroundColor: "black",
                    color: "#ffffff",
                    width:
                      filteredTechnicianData &&
                      filteredTechnicianData.length > 0
                        ? "95%"
                        : "50%",
                    marginTop: "2rem",
                    padding: "10px",
                    justifySelf: "flex-end",
                  }}
                  onClick={onCancel}
                  children={"Cancel"}
                  bIcon=""
                />
                {filteredTechnicianData &&
                  filteredTechnicianData.length > 0 && (
                    <Button
                      style={{
                        backgroundColor: "#FFC600",
                        color: "#ffffff",
                        width: "100%",
                        marginTop: "2rem",
                        padding: "10px",
                        marginLeft: "2rem",
                      }}
                      onClick={handleAssignTechnician}
                      children={"Assign Technician"}
                      bIcon=""
                    />
                  )}
              </Col>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface PaginationProps {
  itemsPerPage: number;
  totalItems: number;
  currentPage: number;
  paginate: (pageNumber: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  itemsPerPage,
  totalItems,
  currentPage,
  paginate,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav style={{ marginTop: "1rem" }}>
      <ul
        className="pagination"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginRight: "1rem",
          alignItems: "center",
        }}
      >
        {pageNumbers.map((number) => (
          <li
            key={number}
            className="page-item"
            style={{ marginRight: "10px", fontSize: "10px" }}
          >
            <a
              onClick={() => paginate(number)}
              href="#"
              className={`page-link ${
                number === currentPage ? "current-page" : ""
              }`}
              style={{
                color: "black",
                width: "15px",
                height: "25px",
                boxShadow:
                  number === currentPage ? "0px 0px 5px #888888" : "none",
              }}
            >
              {number}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default SelectTechnician;
