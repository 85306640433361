
import { Card } from "react-bootstrap";
import conumerRating from "../../styles/Consumer/Consumer.rating.module.scss"
import { FaStar } from "react-icons/fa";
import Rating from "../../components/RatingStars";
import four_star from "../../assets/4-star-rating.png";

const ConsumerRating = () => {



  const ratingCount = {
    all: 200,
    five: 100,
    four: 60,
    three: 2,
    two: 11,
    one: 13,
  };

  return (


    <div>
      <div className={conumerRating.rtbodymiddle}>
        <div className={conumerRating.rtsortreviews}>
          <Card>
            <Card.Body>
              <h6>Sort Reviews By</h6>
              <div className={conumerRating.starcount}>
                <div className={conumerRating.starcounttop}>
                  <button>
                    <p>
                      <FaStar
                        size={20}
                        color={"yellow"}
                        style={{ marginRight: 4 }}
                      />
                      All ({ratingCount.all})
                    </p>
                  </button>

                  <button>
                    <p>
                      <FaStar
                        size={20}
                        color={"yellow"}
                        style={{ marginRight: 4 }}
                      />
                      5 ({ratingCount.five})
                    </p>
                  </button>

                  <button>
                    <p>
                      <FaStar
                        size={20}
                        color={"yellow"}
                        style={{ marginRight: 4 }}
                      />
                      4 ({ratingCount.four})
                    </p>
                  </button>
                </div>
                <div className={conumerRating.starcountbottem}>
                  <button>
                    <p>
                      <FaStar
                        size={20}
                        color={"yellow"}
                        style={{ marginRight: 4 }}
                      />
                      3 ({ratingCount.three})
                    </p>
                  </button>

                  <button>
                    <p>
                      <FaStar
                        size={20}
                        color={"yellow"}
                        style={{ marginRight: 4 }}
                      />
                      2 ({ratingCount.two})
                    </p>
                  </button>

                  <button>
                    <p>
                      <FaStar
                        size={20}
                        color={"yellow"}
                        style={{ marginRight: 4 }}
                      />
                      1 ({ratingCount.one})
                    </p>
                  </button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>

        <div className={conumerRating.rtstarcount}>
          <Card>
            <Card.Body>
              <h6>Reviews by Technician</h6>
              <div className={conumerRating.countdetails}>
                <div className={conumerRating.countdetail}>

                  <div className={conumerRating.ratingsummery}>
                    <div className={conumerRating.ratingname}>Excelent</div>
                    <div className={conumerRating.ratingstars}>
                      <Rating rating={5} />
                    </div>
                    <div className={conumerRating.ratingcount}>{ratingCount.five}</div>
                  </div>

                  <div className={conumerRating.ratingsummery}>
                    <div className={conumerRating.ratingname}>Good</div>
                    <div className={conumerRating.ratingstars}>
                      <Rating rating={4} />
                    </div>
                    <div className={conumerRating.ratingcount}>{ratingCount.four}</div>
                  </div>

                  <div className={conumerRating.ratingsummery}>
                    <div className={conumerRating.ratingname}>Average</div>
                    <div className={conumerRating.ratingstars}>
                      <Rating rating={3} />
                    </div>
                    <div className={conumerRating.ratingcount}>{ratingCount.three}</div>
                  </div>

                  <div className={conumerRating.ratingsummery}>
                    <div className={conumerRating.ratingname}>Poor</div>
                    <div className={conumerRating.ratingstars}>
                      <Rating rating={2} />
                    </div>
                    <div className={conumerRating.ratingcount}>{ratingCount.two}</div>
                  </div>

                  <div className={conumerRating.ratingsummery}>
                    <div className={conumerRating.ratingname}>Bad</div>
                    <div className={conumerRating.ratingstars}>
                      <Rating rating={1} />
                    </div>
                    <div className={conumerRating.ratingcount}>{ratingCount.one}</div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>


    </div>
  );
};

export default ConsumerRating;



