import React, { useEffect, useState } from "react";
import styles from "../../styles/AssignTechnician.module.scss";
import { Col, Image, Modal, Row } from "react-bootstrap";
import Rating from "../../components/RatingStars";
import phone from "../../assets/phone-call.png";
import location from "../../assets/map-pin.png";
import clock from "../../assets/clock-dark.png";
import book from "../../assets/book-open.png";
import briefcase from "../../assets/briefcase-dark.png";
import dollar from "../../assets/dollar-sign-dark.png";
import paperClip from "../../assets/paperclip-dark.png";
import workRequestAssign from "../../assets/work-request-assign.png";
import Button from "../../components/Button";
import {
  assignTechnician,
  getWorkRequestHistoryById,
  unAssignTechnician,
} from "../../services/workRequestService";
import { useDispatch, useSelector } from "react-redux";
import { setWorkRequestsLoading } from "../../features/workRequestSlice";
import useDummyImage from "../../assets/consumerImage.png";
import { BASE_URL_FOR_IMAGE } from "../../constants/appConstants";
import { getTechniciansByID } from "../../services/technicianService";
import { useNavigate } from "react-router-dom";
import pdfFile from "../../assets/pdf-file.png";
import xlsFile from "../../assets/xls-file.png";
import WorkRequestStatus from "../../components/WorkRequestStatus";
import SpinnerLoading from "../../components/SpinnerLoading";
import TableView from "../../components/TableView";
import { setProductJobTypes, setWorkRequestForInquiries } from "../../features/productInquiriesSlice";

interface Technician {
  id: string;
  name: string;
  nic: string;
  education: string;
  address: string;
  imageUrl: string;
  workingArea: string;
  primary: string;
  mobile: string;
  jobType: string;
  rating: number;
}

interface Props {
  workRequestData: any;
  onCancel: () => void;
  selectTechnicianViewAction: (item: any) => void;
  removeTechnician: (workRequestId: number) => void;
  selectedTechnicianData: any | null;
  onCloseAssignedTechnician: () => void;
  onAssignTechnician: (selectedTechnician: Technician[] | null) => void;
  setShowAssignTechnician: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedTechnicianData: (items: any) => void;
}

const AssignTechnician: React.FC<Props> = ({
  onCancel,
  workRequestData,
  selectTechnicianViewAction,
  removeTechnician,
  selectedTechnicianData,
  onCloseAssignedTechnician,
  onAssignTechnician,
  setShowAssignTechnician,
  setSelectedTechnicianData,
}) => {
  
  const dispatch = useDispatch<any>();
  const [expandedTechnician, setExpandedTechnician] = useState<number | null>(
    null
  );
  const [showAssignModal, setShowAssignModal] = useState<boolean>(false);
  const [showProductView, setShowProductView] = useState<boolean>(false);
  const [showProductInqury, setShowProductInqury] = useState<boolean>(false);
  const [showUnAssignConfirmModal, setShowUnAssignConfirmModal] =
    useState<boolean>(false);
  const [show, setShow] = useState(false);
  const [showSccessMsg, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const errorAssign = useSelector((state: any) => state.workRequests.error);
  const successAssign = useSelector((state: any) => state.workRequests.success);
  const loading = useSelector((state: any) => state.workRequests.assignLoading);
  const successMsg = useSelector((state: any) => state.workRequests.successMsg);
  const [showMapModal, setShowMapModal] = useState(false);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [assignedTechnician, setAssignedTechnician] = useState<
    any | undefined
  >();
  const [clickedImage, setClickedImage] = useState<any>(null);
  const navigate = useNavigate();

  const assignedTechnicianData = useSelector(
    (state: any) => state.selectedTechnican.singleTechnician
  );
  
  const workHistroy = useSelector(
    (state: any) => state.singleworkRequest.workHistroy
  );
  const formattedWorkHistory =
    workHistroy &&
    workHistroy.length > 0 &&
    workHistroy.map((item: any) => {
      const formattedItem: { [key: string]: string } = {};
      for (const key in item) {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          formattedItem[key] = item[key]
            ? item[key].replace(/\b\w/g, (char: string) => char.toUpperCase())
            : "-";
        }
      }
      return formattedItem;
    });

  useEffect(() => {
    if (
      workRequestData.isAssigned &&
      workRequestData.status !== "assigned" &&
      workRequestData.status !== "completed" &&
      workRequestData.status !== "rejected"
    ) {
      
      if (!workRequestData.hasProductInquiry) {
        setShowProductView(true);
      }
    }
    if(workRequestData.hasProductInquiry) {
      setShowProductInqury(true);
    }
  }, [dispatch]);

  useEffect(() => {
    if (workRequestData?.assignedTechnician?.id) {
      dispatch(getTechniciansByID(workRequestData.assignedTechnician.id));
      setAssignedTechnician(assignedTechnicianData);
    } else {
      // No assigned technician for the current work request, set to null
      setAssignedTechnician(null);
    }
  }, [dispatch, workRequestData]);

  useEffect(() => {
    setLatitude(workRequestData.latitude);
    setLongitude(workRequestData.longitude);
  }, []);

  const googleMapsLink = `https://www.google.com/maps?q=${latitude},${longitude}`;

  const clearInputs = () => {
    if (errorAssign || successAssign) {
      dispatch(setWorkRequestsLoading(true));
    }
  };

  const handleClose = () => setShow(false);

  const handleOpen = () => {
    dispatch(getWorkRequestHistoryById(workRequestData.id));
    setShow(true);
  };

  const openProductList = () => {
    dispatch(setWorkRequestForInquiries(workRequestData));
    dispatch(setProductJobTypes([workRequestData.jobTypes[0]]));
    navigate("/view-product-list");
  };
  const openProductInquiryList = () => {
    navigate(`/product-inquiry-single-view/:productInqId=${workRequestData.productInquiryId}`);
  };

  useEffect(() => {
    clearInputs();
  }, []);

  const handleRemoveTechnician = () => {
    removeTechnician(workRequestData.id);
  };

  const handleConfirmAssignTechnicianModal = () => {
    if (selectedTechnicianData) {
      const technicianID = selectedTechnicianData.id;
      const workRequestID = workRequestData.id;
      dispatch(assignTechnician(technicianID, workRequestID));
      setShowSuccessMessage(true); // Show success message
      setShowErrorMessage(true);
      setShowAssignModal(true);
      onAssignTechnician(selectedTechnicianData);
      navigate("/work-request");
    } else {
      console.error("NotAssigned");
    }
  };

  const closeAssignScreen = () => {
    onCloseAssignedTechnician();
    setShowAssignTechnician(true);
    onAssignTechnician(null);
    onCancel();
    navigate("/work-request");
  };

  const handleAssignModalClose = () => {
    setShowAssignModal(false);
    setShowAssignTechnician(false);
    onCloseAssignedTechnician();
    onCancel();
    onAssignTechnician(null);
  };

  const handleOpenConfirmUnAssignTechnicianModal = () => {
    const workRequestID = workRequestData.id;
    dispatch(unAssignTechnician(workRequestID));
    setShowSuccessMessage(true); // Show success message
    setShowErrorMessage(true);
    setShowAssignModal(true);
    setSelectedTechnicianData(null);
    // onAssignTechnician(selectedTechnicianData);
  };

  const handleConfirmUnAssignTechnicianModal = () => {
    handleRemoveTechnician();
    setShowUnAssignConfirmModal(false);
  };

  const handleConfirmUnAssignModalClose = () => {
    setShowUnAssignConfirmModal(false);
    // setShowAssignTechnician(false);
  };

  // Handle image click
  const handleImageClick = (imageUrl: string) => {
    setClickedImage(imageUrl);
  };

  if (loading) {
    return <SpinnerLoading />;
  }

  return (
    <div className={styles.appContainer}>
      {showSccessMsg && successAssign && (
        <Modal centered show onHide={handleAssignModalClose}>
          {/* Content of the success modal */}
          <Modal.Body>
            <h2 style={{ color: "green" }}>Successful!</h2>
            <p style={{ textAlign: "center" }}>
              {successMsg ? successMsg : "Record successfully updated!"}
            </p>
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: "center" }}>
            <Button
              onClick={handleAssignModalClose}
              style={{
                backgroundColor: "green",
                color: "#ffffff",
                // width: "40%",
                // marginTop: "2rem",
                // padding: "10px",
                // fontSize: "1rem",
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {errorAssign && showErrorMessage && (
        <Modal centered show onHide={handleAssignModalClose}>
          {/* Content of the success modal */}
          <Modal.Body>
            <h2 style={{ color: "red" }}>Error!</h2>
            <p style={{ textAlign: "center" }}>{errorAssign}</p>
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: "center" }}>
            <Button
              onClick={handleAssignModalClose}
              style={{
                backgroundColor: "red",
                color: "#ffffff",
                // width: "40%",
                // marginTop: "2rem",
                // padding: "10px",
                // fontSize: "1rem",
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {
        <Modal
          show={clickedImage !== null}
          onHide={() => setClickedImage(null)}
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Image src={clickedImage} fluid />
          </Modal.Body>
        </Modal>
      }
      <div>
        <Modal
          style={{ borderRadius: "15px" }}
          centered
          show={showUnAssignConfirmModal}
          onHide={handleConfirmUnAssignModalClose}
        >
          {/* Content of the success modal */}
          <Modal.Body>
            <h2>Un Assign Technician</h2>
            <p style={{ textAlign: "center" }}>
              Are you sure you want to Un-Assign the Technician
            </p>
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: "center" }}>
            <Button
              onClick={handleConfirmUnAssignTechnicianModal}
              style={{
                backgroundColor: "#FFC600",
                color: "#ffffff",
                // width: "40%",
                marginTop: "1rem",
                padding: "10px",
                fontSize: "0.9rem",
              }}
            >
              Confirm
            </Button>
            <Button
              onClick={handleConfirmUnAssignModalClose}
              style={{
                backgroundColor: "black",
                color: "#ffffff",
                // width: "40%",
                marginTop: "1rem",
                padding: "10px",
                fontSize: "1rem",
              }}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <div className={styles.appTitle}>Assign a Technician</div>

      <div className={styles.mainOuterSection}>
        <div className={styles.mainSection}>
          <div className={styles.mainSectionLeft}>
            <Col style={{ display: "flex" }}>
              <div className={styles.imageView}>
                <Image
                  src={
                    workRequestData.consumer &&
                    workRequestData.consumer.profileImage.url
                      ? BASE_URL_FOR_IMAGE +
                        workRequestData.consumer.profileImage.url
                      : useDummyImage
                  }
                  alt={workRequestData.name}
                  roundedCircle
                  fluid
                  // className={`${styles.profilePicture}`}
                  style={{
                    marginRight: "20px",
                    width: "5vh",
                    height: "5vh",
                  }}
                />
                <div>
                  <div style={{ display: "flex", gap: "0.3rem" }}>
                    <strong className="mr-2" style={{ fontSize: "1rem" }}>
                      {workRequestData.consumer &&
                        workRequestData.consumer.firstName}
                    </strong>
                    <strong className="ml-2 mr-2" style={{ fontSize: "1rem" }}>
                      {workRequestData.consumer &&
                        workRequestData.consumer.lastName}
                    </strong>
                  </div>

                  { workRequestData.consumer && workRequestData.consumer.averageRating&& (
                    <div className={styles.ratingSection}>
                    <Rating rating={workRequestData.consumer.averageRating} size={15} />
                    {workRequestData.consumer.averageRating}
                  </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: "0.5",
                  justifyContent: "flex-end",
                  flexDirection: "row",
                  alignSelf: "flex-start",
                  justifyItems: "flex-end",
                  marginRight: "10px",
                }}
              >
                <WorkRequestStatus status={workRequestData.status} />
              </div>
            </Col>

            <div className={styles.mainSectionLeftContent}>
              <div className={styles.left}>
                <Col className={`align-items-center ${styles.dataField}`}>
                  <div
                    style={{
                      color: "#777777",
                      fontSize: "0.8rem",
                      marginBottom: "0.2rem",
                    }}
                  >
                    Contact Number
                  </div>
                  <div className={styles.dataFieldText}>
                    <div style={{ marginRight: "1rem" }}>
                      <img
                        src={phone}
                        style={{ height: "1.2rem", width: "1.2rem" }}
                      />
                    </div>
                    <div>
                      {workRequestData.consumer &&
                        workRequestData.consumer.contactNumberPrimary}
                    </div>
                  </div>
                </Col>

                <Col className={`align-items-center ${styles.dataField}`}>
                  <div
                    style={{
                      color: "#777777",
                      fontSize: "0.8rem",
                      marginBottom: "0.2rem",
                    }}
                  >
                    Location
                  </div>
                  <div className={styles.dataFieldText}>
                    <div style={{ marginRight: "1rem" }}>
                      <img
                        src={location}
                        style={{ height: "1.2rem", width: "1.2rem" }}
                      />
                    </div>
                    <div>
                      <a
                        href={googleMapsLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View on Google Maps
                      </a>
                    </div>
                  </div>
                </Col>

                <Col className={`align-items-center ${styles.dataField}`}>
                  <div
                    style={{
                      color: "#777777",
                      fontSize: "0.8rem",
                      marginBottom: "0.2rem",
                    }}
                  >
                    Expected starting date
                  </div>
                  <div className={styles.dataFieldText}>
                    <div style={{ marginRight: "1rem" }}>
                      <img
                        src={clock}
                        style={{ height: "1.2rem", width: "1.2rem" }}
                      />
                    </div>
                    <div>{workRequestData.startingDate}</div>
                  </div>
                </Col>

                <Col className={`align-items-center ${styles.dataField}`}>
                  <div
                    style={{
                      color: "#777777",
                      fontSize: "0.8rem",
                      marginBottom: "0.2rem",
                    }}
                  >
                    Notes for technician
                  </div>
                  <div className={styles.dataFieldText}>
                    <div style={{ marginRight: "1rem" }}>
                      <img
                        src={book}
                        style={{ height: "1.2rem", width: "1.2rem" }}
                      />
                    </div>
                    <div>{workRequestData.workDescription}</div>
                  </div>
                </Col>
              </div>
              <div className={styles.right}>
                <Col className={`align-items-center ${styles.dataField}`}>
                  <div
                    style={{
                      color: "#777777",
                      fontSize: "0.8rem",
                      marginBottom: "0.2rem",
                    }}
                  >
                    Address
                  </div>
                  <div className={styles.dataFieldText}>
                    <div style={{ marginRight: "1rem" }}>
                      <img
                        src={location}
                        style={{ height: "1.2rem", width: "1.2rem" }}
                      />
                    </div>
                    <div>{workRequestData.address}</div>
                  </div>
                </Col>

                <Col className={`align-items-center ${styles.dataField}`}>
                  <div
                    style={{
                      color: "#777777",
                      fontSize: "0.8rem",
                      marginBottom: "0.2rem",
                    }}
                  >
                    Type of work
                  </div>
                  <div className={styles.dataFieldText}>
                    <div style={{ marginRight: "1rem" }}>
                      <img
                        src={briefcase}
                        style={{ height: "1.2rem", width: "1.2rem" }}
                      />
                    </div>
                    <div>
                      {workRequestData.jobTypes[0] &&
                        workRequestData.jobTypes[0].type}
                    </div>
                  </div>
                </Col>

                <Col className={`align-items-center ${styles.dataField}`}>
                  <div
                    style={{
                      color: "#777777",
                      fontSize: "0.8rem",
                      marginBottom: "0.2rem",
                    }}
                  >
                    Expected budget
                  </div>
                  <div className={styles.dataFieldText}>
                    <div style={{ marginRight: "1rem" }}>
                      <strong>RS:</strong>
                    </div>
                    <div>{workRequestData.budjet}</div>
                  </div>
                </Col>

                <Col className={`align-items-center ${styles.dataField}`}>
                  <div
                    style={{
                      color: "#777777",
                      fontSize: "0.8rem",
                      marginBottom: "0.2rem",
                    }}
                  >
                    Attachments
                  </div>
                  <div className={styles.dataFieldText}>
                    <div style={{ marginRight: "1rem" }}>
                      <img
                        src={paperClip}
                        style={{ height: "1.2rem", width: "1.2rem" }}
                      />
                    </div>
                    <div style={{ display: "flex", gap: "10px" }}>
                      {workRequestData &&
                      workRequestData.workRequestImages &&
                      workRequestData.workRequestImages.length > 0 ? (
                        workRequestData.workRequestImages.map(
                          (image: any, index: any) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",
                              }}
                            >
                              {image.url.endsWith(".pdf") ? (
                                <a
                                  href={BASE_URL_FOR_IMAGE + image.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    src={pdfFile}
                                    alt={`PDF ${index + 1}`}
                                    style={{
                                      width: "2rem",
                                      height: "2rem",
                                    }}
                                  />
                                </a>
                              ) : image.url.endsWith(".xls") ||
                                image.url.endsWith(".xlsx") ? (
                                <a
                                  href={BASE_URL_FOR_IMAGE + image.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    src={xlsFile}
                                    alt={`Excel ${index + 1}`}
                                    style={{
                                      width: "2rem",
                                      height: "2rem",
                                    }}
                                  />
                                </a>
                              ) : (
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleImageClick(
                                      BASE_URL_FOR_IMAGE + image.url
                                    )
                                  }
                                >
                                  <Image
                                    src={BASE_URL_FOR_IMAGE + image.url}
                                    alt={`Image ${index + 1}`}
                                    style={{
                                      width: "2rem",
                                      height: "2rem",
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          )
                        )
                      ) : (
                        <div>No attachments available..!</div>
                      )}
                    </div>
                  </div>
                </Col>
              </div>
            </div>
            <div>
              {/* {!selectedTechnicianData && ( */}
                <>
                  <Col sm="12" className={styles.buttonContainer}>
                    {showProductView && (
                      <Button
                        style={{
                          color: "#000000",
                          backgroundColor: "white",
                          borderColor: "black",
                          border: "2px solid #000000",
                          width: "150px",
                          marginTop: "2rem",
                          padding: "10px",
                          marginRight: "20px",
                          marginLeft: "30px",
                        }}
                        onClick={openProductList}
                        children={"View Products"}
                        bIcon=""
                      />
                    )}
                    {showProductInqury && (
                      <Button
                        style={{
                          color: "#000000",
                          backgroundColor: "white",
                          borderColor: "black",
                          border: "2px solid #000000",
                          width: "150px",
                          marginTop: "2rem",
                          padding: "10px",
                          marginRight: "20px",
                          marginLeft: "30px",
                        }}
                        onClick={openProductInquiryList}
                        children={"View Inquiry"}
                        bIcon=""
                      />
                    )}
                    <Button
                      style={{
                        backgroundColor: "black",
                        color: "#ffffff",
                        width: "150px",
                        marginTop: "2rem",
                        padding: "10px",
                        marginRight: "20px",
                      }}
                      onClick={onCancel}
                      children={"Back to List"}
                      bIcon=""
                    />
                    <Button
                      style={{
                        color: "#ffffff",
                        width: "150px",
                        marginTop: "2rem",
                        padding: "10px",
                        marginRight: "15px",
                      }}
                      onClick={handleOpen}
                      children={"History"}
                      bIcon=""
                    />
                  </Col>
                </>
              {/* )} */}
            </div>
          </div>
          {workRequestData.assignedTechnician &&
          Object.keys(workRequestData.assignedTechnician).length > 0 ? (
            <div className={styles.mainSectionRightWithoutBorder}>
              <Col className={`align-items-center`}>
                <div key={0}>
                  <div
                    className={styles.assignTechnicianDiv}
                    onClick={() => setExpandedTechnician(0)}
                  >
                    <div className={styles.imageView}>
                      <Image
                        src={
                          workRequestData.assignedTechnician &&
                          workRequestData.assignedTechnician.profileImage.url
                            ? BASE_URL_FOR_IMAGE +
                              workRequestData.assignedTechnician.profileImage
                                .url
                            : useDummyImage
                        }
                        alt={workRequestData.assignedTechnician.firstName}
                        roundedCircle
                        fluid
                        className={`${styles.profilePicture}`}
                        style={{
                          marginRight: "25px",
                          width: "5vh",
                          height: "5vh",
                        }}
                      />
                      <div>
                        <div style={{ display: "flex", gap: "0.2rem" }}>
                          <strong
                            className="mr-2"
                            style={{ fontSize: "0.7rem" }}
                          >
                            {workRequestData.assignedTechnician.firstName}
                          </strong>
                          <strong
                            className="mr-2"
                            style={{ fontSize: "0.7rem" }}
                          >
                            {workRequestData.assignedTechnician.lastName}
                          </strong>
                        </div>
                        { assignedTechnicianData && assignedTechnicianData.averageRating && (
                          <div className={styles.ratingSection} >
                          <Rating
                            rating={assignedTechnicianData.averageRating}
                            size={12}
                          />
                          <div style={{fontSize: "10px", color: "#777777", marginTop: "6px", marginLeft: "5px"}}>{assignedTechnicianData.averageRating}</div>
                        </div>
                        )}
                      </div>
                    </div>

                    {expandedTechnician === 0 && (
                      <div className={styles.technicianSection}>
                        <div
                          style={{
                            display: "flex",
                            marginBottom: "1rem",
                            width: "100%",
                          }}
                        >
                          <div style={{ flex: "0.5" }}>Full name:</div>
                          <div
                            style={{
                              flex: "0.5",
                              gap: "0.2rem",
                              display: "flex",
                            }}
                          >
                            <div>{assignedTechnicianData?.firstName}</div>
                            <div>{assignedTechnicianData?.lastName}</div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginBottom: "1rem",
                          }}
                        >
                          <div style={{ flex: "0.5" }}>Nic number:</div>
                          <div style={{ flex: "0.5" }}>
                            {assignedTechnicianData?.nationalIdentityCardNo}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginBottom: "1rem",
                          }}
                        >
                          <div style={{ flex: "1" }}>Minimal education:</div>
                          <div style={{ flex: "1" }}>
                            {assignedTechnicianData?.minimumEducation}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginBottom: "1rem",
                          }}
                        >
                          <div style={{ flex: "0.5" }}>Address:</div>
                          <div style={{ flex: "0.5" }}>
                            {assignedTechnicianData?.address}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginBottom: "1rem",
                          }}
                        >
                          <div style={{ flex: "0.5" }}>Main working area:</div>
                          <div style={{ flex: "0.5" }}>
                            {assignedTechnicianData?.city}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginBottom: "1rem",
                          }}
                        >
                          <div style={{ flex: "0.5" }}>Primary:</div>
                          <div style={{ flex: "0.5" }}>
                            {assignedTechnicianData?.contactNumberPrimary}
                          </div>
                        </div>
                        {assignedTechnicianData?.mobile && (
                          <div
                            style={{
                              display: "flex",
                              marginBottom: "1rem",
                            }}
                          >
                            <div style={{ flex: "0.5" }}>Mobile:</div>
                            <div style={{ flex: "0.5" }}>
                              {assignedTechnicianData?.mobile}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </Col>

              <div className={styles.mainSectionBottom}>
                <Col
                  sm="12"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingLeft: "0.5rem",
                    paddingRight: "0.5rem",
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: "black",
                      color: "#ffffff",
                      marginTop: "2rem",
                      padding: "10px",
                      fontSize: "0.7rem",
                    }}
                    onClick={() => closeAssignScreen()}
                    children={"Cancel"}
                    bIcon=""
                  />
                  <Button
                    style={{
                      backgroundColor: "#FFC600",
                      color: "#ffffff",
                      marginTop: "2rem",
                      padding: "10px",
                      marginLeft: "2rem",
                      fontSize: "0.7rem",
                    }}
                    children={"Un Assign"}
                    onClick={handleOpenConfirmUnAssignTechnicianModal}
                    bIcon=""
                  />
                </Col>
              </div>
            </div>
          ) : selectedTechnicianData ? (
            <div className={styles.mainSectionRightWithoutBorder}>
              <div>
                <Col className={`align-items-center`}>
                  <div
                    className={styles.assignTechnicianDiv}
                    onClick={() => setExpandedTechnician(0)}
                  >
                    <div className={styles.imageView}>
                      <Image
                        src={
                          selectedTechnicianData &&
                          selectedTechnicianData.profileImage.url
                            ? BASE_URL_FOR_IMAGE +
                              selectedTechnicianData.profileImage.url
                            : useDummyImage
                        }
                        alt={selectedTechnicianData.name}
                        roundedCircle
                        fluid
                        className={`${styles.profilePicture}`}
                        style={{ marginRight: "10px" }}
                      />
                      <div>
                        <div style={{ display: "flex", gap: "0.2rem" }}>
                          <strong
                            className="mr-2"
                            style={{ fontSize: "0.7rem" }}
                          >
                            {selectedTechnicianData.firstName}
                          </strong>
                          <strong
                            className="mr-2"
                            style={{ fontSize: "0.7rem" }}
                          >
                            {selectedTechnicianData.lastName}
                          </strong>
                        </div>
                        <div className={styles.ratingSection}>
                          <Rating
                            rating={selectedTechnicianData.averageRating}
                            size={12}
                          />
                          {selectedTechnicianData.averageRating}
                        </div>
                      </div>
                    </div>
                    {expandedTechnician === 0 && (
                      <div className={styles.technicianSection}>
                        <div
                          style={{
                            display: "flex",
                            marginBottom: "1rem",
                            width: "100%",
                          }}
                        >
                          <div style={{ flex: "0.5" }}>Full name:</div>

                          <div
                            style={{
                              flex: "0.5",
                              gap: "0.2rem",
                              display: "flex",
                            }}
                          >
                            <div>{selectedTechnicianData.firstName}</div>
                            <div>{selectedTechnicianData.lastName}</div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginBottom: "1rem",
                            width: "100%",
                          }}
                        >
                          <div style={{ flex: "0.5" }}>Nic number:</div>
                          <div style={{ flex: "0.5" }}>
                            {selectedTechnicianData.nationalIdentityCardNo}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginBottom: "1rem",
                            width: "100%",
                          }}
                        >
                          <div style={{ flex: "0.5" }}>Minimal education:</div>
                          <div style={{ flex: "0.5" }}>
                            {selectedTechnicianData.minimumEducation}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginBottom: "1rem",
                            width: "100%",
                          }}
                        >
                          <div style={{ flex: "0.5" }}>Address:</div>
                          <div style={{ flex: "0.5" }}>
                            {selectedTechnicianData.address}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginBottom: "1rem",
                            width: "100%",
                          }}
                        >
                          <div style={{ flex: "0.5" }}>Main working area:</div>
                          <div style={{ flex: "0.5" }}>
                            {
                              selectedTechnicianData.workExperience
                                .mainWorkingArea.city
                            }
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginBottom: "1rem",
                            width: "100%",
                          }}
                        >
                          <div style={{ flex: "0.5" }}>Primary:</div>
                          <div style={{ flex: "0.5" }}>
                            {selectedTechnicianData.contactNumberPrimary}
                          </div>
                        </div>
                        {selectedTechnicianData.mobile && (
                          <div
                            style={{
                              display: "flex",
                              marginBottom: "1rem",
                              width: "100%",
                            }}
                          >
                            <div style={{ flex: "0.5" }}>Mobile:</div>
                            <div style={{ flex: "0.5" }}>
                              {selectedTechnicianData.contactNumberSecondary}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </Col>
              </div>
              <div className={styles.mainSectionBottom}>
                <Col
                  sm="12"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingLeft: "0.5rem",
                    paddingRight: "0.5rem",
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: "black",
                      color: "#ffffff",
                      marginTop: "2rem",
                      padding: "10px",
                      fontSize: "0.7rem",
                    }}
                    onClick={closeAssignScreen}
                    children={"Cancel"}
                    bIcon=""
                  />
                  <Button
                    style={{
                      backgroundColor: "#FFC600",
                      color: "#ffffff",
                      marginTop: "2rem",
                      padding: "10px",
                      marginLeft: "2rem",
                      fontSize: "0.7rem",
                    }}
                    children={"Confirm Assign"}
                    onClick={handleConfirmAssignTechnicianModal}
                    bIcon=""
                  />
                </Col>
              </div>
            </div>
          ) : (
            <div className={styles.mainSectionRight}>
              <Col className={styles.mainSectionRightContent}>
                <div
                  style={{
                    fontSize: "1rem",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  No Technicians are assigned to this Work Request
                </div>
                <div className={styles.technicianImage}>
                  <img
                    src={workRequestAssign}
                    style={{ height: "12rem", width: "12rem" }}
                  />
                </div>
                <Button
                  style={{
                    backgroundColor: "#FFC600",
                    color: "#ffffff",
                    width: "95%",
                    padding: "10px",
                    marginBottom: "10px",
                    justifySelf: "flex-end",
                  }}
                  onClick={() =>
                    selectTechnicianViewAction &&
                    selectTechnicianViewAction(workRequestData)
                  }
                  children={"Choose Technician"}
                  bIcon=""
                />
              </Col>
            </div>
          )}
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        style={{ border: "none", display: "flex" }}
      >
        <Modal.Header style={{ border: "none" }} closeButton>
          <Modal.Title
            style={{
              display: "flex",
              flexDirection: "row",
              alignSelf: "center",
              marginLeft: "250px",
            }}
          >
            Work Request History
          </Modal.Title>
        </Modal.Header>

        <TableView
          data={formattedWorkHistory}
          columns={["action", "executedOn", "nameOfExecutedUser", "reason"]}
          columnLabels={["Actions", "Date & Time", "User", "Reason"]}
          itemsPage={25}
          hideSerach={true}
          hideFilter={true}
        />
      </Modal>
    </div>
  );
};

export default AssignTechnician;
