import React, { useEffect, useState, ChangeEvent } from "react";
import Form from "react-bootstrap/Form";
import Button from "../../components/Button";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../styles/CreateJobTypeForm.scss";
import { useDispatch, useSelector } from "react-redux";
import { setLevelCriteriaLoading } from "../../features/levelCriteriaSlice";
import { LevelCriteria, LevelCriteriaCreate } from "../../type";
import { addLevelCriteria } from "../../services/levelCriteriaService";
import SpinnerLoading from "../../components/SpinnerLoading";
import Popup from "../../components/Popup";

interface Props {
  onCancel: () => void;
}

interface FormErrors {
  tenant: string;
  outCome: string;
  levelCriteria: string;
  level: string;
}
interface Product {
  id: number;
  name: string;
}

const CreateLevelCriteria: React.FC<Props> = ({ onCancel }) => {
  const dispatch = useDispatch<any>();
  const [tenant, setTenant] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [outCome, setOutCome] = useState("");
  const [levelCriteria, setLevelCriteria] = useState("");
  const [level, setLevel] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [showSccessMsg, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [tenantError, setTenantError] = useState<string | null>(null);
  const [outComeError, setOutComeError] = useState<string | null>(null);
  const [levelError, setLevelError] = useState<string | null>(null);
  const [levelCriteriaError, setLevelCriteriaError] = useState<string | null>(
    null
  );
  const [errors, setErrors] = useState<FormErrors>({
    tenant: "",
    outCome: "",
    levelCriteria: "",
    level: "",
  });
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const errorLevelC = useSelector((state: any) => state.levelCriteria.error);
  const successJobTypes = useSelector(
    (state: any) => state.levelCriteria.success
  );
  const loading = useSelector((state: any) => state.levelCriteria.loading);

  useEffect(() => {
    const fieldsToValidate: Array<keyof FormErrors> = [
      "tenant",
      "outCome",
      "levelCriteria",
      "level",
    ];

    fieldsToValidate.forEach((field: keyof FormErrors) => {
      const value = eval(field); // Access value dynamically
      validateField(field, value);
    });
  }, [tenant, outCome, level, levelCriteria]);

  const tenantsBe = useSelector((state: any) => state.tenants.tenants);
  const techLevels = useSelector((state: any) => state.techLevels.techLevels);

  const changeMessages = () => {
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
    setLevelError(null);
    setTenantError(null);
    setLevelCriteriaError(null);
    setOutComeError(null);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const text = event.target.value;
    setOutCome(text);
    const count = text.length;
    setWordCount(count);
    setOutComeError(null);
    changeMessages();
    if (count > 300) {
      setOutComeError("description not exceed 300 words.");
    }
  };

  const validateField = (fieldName: string, value: string) => {
    let errors: any = {};
    if (!tenant) {
      errors.level = "Tenant is required";
    }
    if (!outCome || outComeError) {
      errors.outCome = "Expected Outcome is required";
    }
    if (!level) {
      errors.level = "Level is required";
    }
    if (!levelCriteria) {
      errors.levelCriteria = "Level Criteria is required";
    }
    setIsFormValid(Object.keys(errors).length === 0);
  };

  const clearInputs = () => {
    if (errorLevelC || successJobTypes) {
      dispatch(setLevelCriteriaLoading(true));
      setLevelError(null);
      setTenantError(null);
      setLevelCriteriaError(null);
      setOutComeError(null);
    }
  };

  useEffect(() => {
    clearInputs();
  }, []);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!level) {
      setLevelError("Level is required");
    }
    if (!tenant) {
      setTenantError("Tenant is required");
    }
    if (!levelCriteria) {
      setLevelCriteriaError("Level Criteria is required");
    }
    if (!outCome) {
      setOutComeError("Expected Outcome is required");
    }
    if (isFormValid) {
      // Perform form submission
      console.log("Form submitted with data:", {
        tenant,
        outCome,
        level,
        levelCriteria,
      });
      const requestObj: any = {
        levelCriteria: levelCriteria,
        tanentId: tenant,
        levelId: level,
        expectedOutcome: outCome,
      };
      console.log("request", requestObj);
      dispatch(addLevelCriteria(requestObj));
      setShowSuccessMessage(true);
      setShowErrorMessage(true);
      setShowPopup(true);
    }
  };

  return (
    <>
      {showSccessMsg && successJobTypes && (
        <Popup
          show={showPopup}
          onHide={() => {
            setShowPopup(false);
            onCancel();
          }}
          actionButton={false}
          item={"success"}
          modaltitle={"Success!"}
          content={"Record Successfully Created!"}
        />
      )}
      {errorLevelC && showErrorMessage && (
        <Popup
          show={showPopup}
          onHide={() => {
            setShowPopup(false);
            changeMessages();
          }}
          actionButton={false}
          modaltitle={"Error!"}
          content={errorLevelC}
        />
      )}
      <Form onSubmit={handleSubmit}>
        <div className="create-job-type-title">Create Level Criteria</div>
        <Form.Group controlId="tenant" className="mb-3 left-fields">
          <Form.Label className="left-type-label">Tenant</Form.Label>
          <Form.Select
            as="select"
            value={tenant}
            onChange={(e) => {
              setTenant(e.target.value);
              setTenantError(null);
              changeMessages();
            }}
          >
            <option value="">Tenant</option>
            {tenantsBe.map((tenant: any) => (
              <option key={tenant.id} value={tenant.id}>
                {tenant.name}
              </option>
            ))}
          </Form.Select>
          {tenantError && <p className="error-message">{tenantError}</p>}
        </Form.Group>
        <Form.Group controlId="levelCriteria" className="mb-3 right-fields">
          <Form.Label className="right-type-label">Level</Form.Label>
          <Form.Select
            as="select"
            value={level}
            onChange={(e) => {
              setLevel(e.target.value);
              setLevelError(null);
              changeMessages();
            }}
          >
            <option value="">Level</option>techLevels
            {techLevels.map((level: any) => (
              <option key={level.id} value={level.id}>
                {level.name}
              </option>
            ))}
          </Form.Select>
          {levelError && <p className="error-message">{levelError}</p>}
        </Form.Group>
        <Form.Group controlId="level" className="mb-3 right-fields">
          <Form.Label className="left-type-label">Expected Outcome</Form.Label>
          <Form.Control
            as="textarea" // Use textarea for multiline input
            rows={4}
            placeholder="Enter job outCome here"
            value={outCome}
            onChange={handleDescriptionChange}
          />
          <p>{wordCount}/300</p>

          {outComeError && <p className="error-message">{outComeError}</p>}
        </Form.Group>
        <Form.Group controlId="outCome" className="mb-3 left-fields">
          <Form.Label className="right-type-label">Level Criteria</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter level criteria"
            value={levelCriteria}
            onChange={(e) => {
              setLevelCriteria(e.target.value);
              setLevelCriteriaError(null);
              changeMessages();
            }}
          />
          {levelCriteriaError && (
            <p className="error-message">{levelCriteriaError}</p>
          )}
        </Form.Group>
        <div className="button-container">
          <Button
            style={{
              width: "25vh",
              backgroundColor: "black",
              marginTop: "4vh",
              padding: "0.7rem",
              marginRight: "2vh",
            }}
            onClick={onCancel}
            children={"Back"}
          />
          <Button
            style={{
              width: "25vh",
              backgroundColor: "#FFC600",
              marginTop: "4vh",
              padding: "0.7rem",
            }}
            type="submit"
            children={"Save"}
          />
        </div>
      </Form>
    </>
  );
};

export default CreateLevelCriteria;
