import { Card, Modal } from "react-bootstrap";
import "../../styles/Technician/Technician.RatingInfo.page.scss";
import { FaStar } from "react-icons/fa";
import Rating from "../../components/RatingStars";
import star_purple from "../../assets/star_purple.png";
import TechnicianDowngrade from "./TechnicianDowngrade";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL_FOR_IMAGE } from "../../constants/appConstants";
import { getTechniciansRatingsReviews } from "../../services/technicianService";

const TechnicianRatingInfo = () => {
  const ratingCount = {
    all: 200,
    five: 100,
    four: 60,
    three: 2,
    two: 11,
    one: 13,
  };

  const dispatch = useDispatch<any>();

  const singleTechnicianData = useSelector(
    (state: any) => state.selectedTechnican.singleTechnician
  );
  console.log("singleTechnicianData", singleTechnicianData);
  useEffect(() => {
    if (singleTechnicianData) {
      console.log("getTechniciansRatingsReviews");
      dispatch(getTechniciansRatingsReviews(singleTechnicianData.id));
    }
  }, [singleTechnicianData]);

  const technicianRatingsRiviews = useSelector(
    (state: any) => state.technicians.ratings
  );

  const [showDowngradeModal, setShowDowngradeModal] = useState(false);

  const handleDowngradeButtonClick = () => {
    setShowDowngradeModal(true);
  };

  const handleCloseDowngradeModal = () => {
    setShowDowngradeModal(false);
  };

  const profile_pic =
    BASE_URL_FOR_IMAGE + singleTechnicianData?.profileImage.url;

  return (
    <div className="technician-rating-tab">
      <div className="rt-profile">
        <div className="rt-profile-img">
          <img src={profile_pic} alt="Profile Picture" />
        </div>
        <div>
          <div>
            <h4>
              {singleTechnicianData && singleTechnicianData.firstName}{" "}
              {singleTechnicianData && singleTechnicianData.lastName}
            </h4>
            {singleTechnicianData && singleTechnicianData.averageRating && (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Rating rating={parseInt(singleTechnicianData.averageRating)} />
                <div
                  style={{
                    display: "flex",
                    width: "3rem",
                    marginLeft: "15px",
                    fontSize: "18px",
                    alignItems: "center",
                    color: "grey",
                  }}
                >
                  {singleTechnicianData.averageRating &&
                  singleTechnicianData.averageRating !== "NaN"
                    ? singleTechnicianData.averageRating
                    : ""}
                </div>
              </div>
            )}
          </div>
          <div>
            <div className="profile-status">
              <button className="rt-active-button">Active</button>
            </div>
          </div>
        </div>
        <div className="flex-end-button">
          <div>
            <button
              className="downgrade-button"
              onClick={handleDowngradeButtonClick}
            >
              Downgrade
            </button>
            <Modal
              show={showDowngradeModal}
              onHide={handleCloseDowngradeModal}
              backdrop="static"
              keyboard={false}
              dialogClassName="modal-70w"
              centered
            >
              <Modal.Header closeButton style={{ border: "none" }}>
                <h4 style={{ border: "none", marginLeft: "110px" }}>
                  Downgrade Technician
                </h4>
              </Modal.Header>

              <TechnicianDowngrade
                closeModal={handleCloseDowngradeModal}
                singleTechnicianData={singleTechnicianData}
              />
            </Modal>
          </div>
        </div>
      </div>
      <div className="rt-body-middle">
        <div className="rt-sort-reviews">
          <Card>
            <Card.Body>
              <h6>Sort Reviews By</h6>
              <div className="star-count">
                <div className="star-count-top">
                  <button>
                    <p>
                      <FaStar
                        size={20}
                        color={"yellow"}
                        style={{ marginRight: 4 }}
                      />
                      All ({ratingCount.all})
                    </p>
                  </button>

                  <button>
                    <p>
                      <FaStar
                        size={20}
                        color={"yellow"}
                        style={{ marginRight: 4 }}
                      />
                      5 ({ratingCount.five})
                    </p>
                  </button>

                  <button>
                    <p>
                      <FaStar
                        size={20}
                        color={"yellow"}
                        style={{ marginRight: 4 }}
                      />
                      4 ({ratingCount.four})
                    </p>
                  </button>
                </div>
                <div className="star-count-bottem">
                  <button>
                    <p>
                      <FaStar
                        size={20}
                        color={"yellow"}
                        style={{ marginRight: 4 }}
                      />
                      3 ({ratingCount.three})
                    </p>
                  </button>

                  <button>
                    <p>
                      <FaStar
                        size={20}
                        color={"yellow"}
                        style={{ marginRight: 4 }}
                      />
                      2 ({ratingCount.two})
                    </p>
                  </button>

                  <button>
                    <p>
                      <FaStar
                        size={20}
                        color={"yellow"}
                        style={{ marginRight: 4 }}
                      />
                      1 ({ratingCount.one})
                    </p>
                  </button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>

        <div className="rt-star-count">
          <Card>
            <Card.Body>
              <h6>Reviews by customers</h6>
              <div className="count-details">
                <div className="count-detail">
                  <div className="rating-summery">
                    <div className="rating-name">Excelent</div>
                    <div className="rating-stars">
                      <Rating rating={5} />
                    </div>
                    <div className="rating-count">{ratingCount.five}</div>
                  </div>

                  <div className="rating-summery">
                    <div className="rating-name">Good</div>
                    <div className="rating-stars">
                      <Rating rating={4} />
                    </div>
                    <div className="rating-count">{ratingCount.four}</div>
                  </div>

                  <div className="rating-summery">
                    <div className="rating-name">Average</div>
                    <div className="rating-stars">
                      <Rating rating={3} />
                    </div>
                    <div className="rating-count">{ratingCount.three}</div>
                  </div>

                  <div className="rating-summery">
                    <div className="rating-name">Poor</div>
                    <div className="rating-stars">
                      <Rating rating={2} />
                    </div>
                    <div className="rating-count">{ratingCount.two}</div>
                  </div>

                  <div className="rating-summery">
                    <div className="rating-name">Bad</div>
                    <div className="rating-stars">
                      <Rating rating={1} />
                    </div>
                    <div className="rating-count">{ratingCount.one}</div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>

      <div className="rt-reviews">
        <Card>
          <Card.Body>
            <div className="reviews-top">
              <div>
                <h6>Consumer Feedback</h6>
              </div>

              <div className="rating">
                <div className="bottem-rating-stars">
                  {singleTechnicianData &&
                    singleTechnicianData.averageRating && (
                      <Rating
                        rating={parseInt(singleTechnicianData.averageRating)}
                      />
                    )}
                </div>
                <div className="bottem-rating-value">
                  {singleTechnicianData?.averageRating &&
                  singleTechnicianData?.averageRating !== "NaN"
                    ? singleTechnicianData.averageRating
                    : ""}
                </div>
                <div className="bottem-rating-count">
                  (
                  {technicianRatingsRiviews &&
                    technicianRatingsRiviews.length &&
                    technicianRatingsRiviews.length}{" "}
                  reviews)
                </div>
              </div>
              <hr />
            </div>

            <div className="reviews-bottem">
              <div className="review-list">
                {technicianRatingsRiviews &&
                  technicianRatingsRiviews.length > 0 &&
                  technicianRatingsRiviews.map((review: any) => (
                    <div key={review.id} className="review">
                      <div
                        className="rating-star-img"
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        <img src={star_purple} alt="four star" />
                        <span
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            color: "black",
                            fontSize: "10px"
                          }}
                        >
                          {singleTechnicianData?.averageRating &&
                          singleTechnicianData?.averageRating !== "NaN"
                            ? singleTechnicianData.averageRating
                            : ""}
                        </span>
                      </div>

                      <div className="review-details">
                        <div>{review.comment}</div>
                        <div className="reviewer-details">
                          {review.ratedBy && (
                            <>
                              <div className="reviewer-image">
                                <img
                                  src={
                                    BASE_URL_FOR_IMAGE +
                                    review.ratedBy.profileImage.url
                                  }
                                  alt="reviewer image"
                                />
                              </div>
                              <div>
                                {review.ratedBy.userAccount.firstName}{" "}
                                {review.ratedBy.userAccount.lastName}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button className="view-all-reviews-button">
                  See More Reviews
                </button>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default TechnicianRatingInfo;
