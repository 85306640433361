import React, { useEffect, useState } from "react";
import WorkRequest from "./WorkRequest";
import WorkRequestList from "./WorkRequestList";
import EditWorkRequest from "./EditWorkRequest";
import AssignTechnician from "./AssignTechnician";
import SelectTechnician from "./SelectTechnician";
import ProductDisplay from "./ProductDisplay";
import { getWorkRequests } from "../../services/workRequestService";
import { workRequestType, workRequestCreateType } from "../../type";
import { useDispatch, useSelector } from "react-redux";
import { getTechnicians, getTechniciansByJobID } from "../../services/technicianService";
import { getJobTypes } from "../../services/jobTypeService";
import { setProductJobTypes } from "../../features/productInquiriesSlice";
import { clearFormData } from "../../features/workRequestCreateSlice";
import { clearMyItems } from "../../features/myItemsSlice";

interface Technician {
  id: number;
  name: string;
  nic: string;
  education: string;
  address: string;
  imageUrl: string;
  workingArea: string;
  primary: string;
  mobile: string;
  jobType: string;
  rating: number;
}

interface WorkRequestProps {
  data: any;
}

interface TechnicianProps {
  technicianData: Technician[];
}

const technicianData = {
  id: 10,
  name: "Sophia Rodriguez",
  nic: "321987654Q",
  education: "Landscaping Certification",
  address: "505 Pine Lane",
  imageUrl: "https://placekitten.com/200/200?image=10",
  workingArea: "City J",
  primary: "775-456-4854",
  mobile: "123-456-10",
  jobType: "Landscaper",
  rating: 3.6,
};

const WorkRequestPage = () => {
  const [showCreateForm, setShowCreateForm] = useState<boolean>(false);
  const [showProductList, setShowProductList] = useState<boolean>(false);
  const [showEditForm, setShowEditForm] = useState<boolean>(false);
  const workRequestsBe = useSelector((state: any) => state.workRequests.workRequests);
  const [showAssignTechnician, setShowAssignTechnician] =
    useState<boolean>(false);
  const [showSelectTechnician, setShowSelectTechnician] =
    useState<boolean>(false);
  const [selectedWorkRequestData, setSelectedWorkRequestData] =
    useState<any>(null);
  const [selectedTechnicianData, setSelectedTechnicianData] = useState<any>(null);
  const [workRequestDataList, setWorkRequestDataList] =
    useState<WorkRequestProps>({ data: workRequestsBe });
  const dispatch = useDispatch<any>();
  
  useEffect(() => {
    dispatch(getJobTypes());
    dispatch(clearMyItems())
    reloadData();
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearFormData(true));
  }, []);
  

  const openCreateWorkRequestForm = () => {
    setShowCreateForm(true);
  };

  const openEditWorkRequestForm = (workRequestData: WorkRequestProps) => {
    setSelectedWorkRequestData(workRequestData);
    setShowEditForm(true);
  };

  const openAssignTechnician = (workRequestData: any) => {
    setSelectedWorkRequestData(workRequestData);
    dispatch(getTechniciansByJobID(workRequestData.jobTypes[0].id))
    setShowAssignTechnician(true);
    // setSelectedTechnicianData(selectedTechnicianData);
  };

  const openSelectTechnician = (workRequestData: WorkRequestProps) => {
    setSelectedWorkRequestData(workRequestData);
    setShowAssignTechnician(false);
    setShowSelectTechnician(true);
  };

  const reloadData = () => {
    dispatch(getWorkRequests());
  };
  const closeCreateWorkRequestForm = () => {
    setShowCreateForm(false);
    reloadData();
  };

  const closeProductListView = () => {
    setShowProductList(false);
  };

  const openProductListView = (selectedJobTypesForProduct:any) => {
    setShowProductList(true);
    dispatch(setProductJobTypes(selectedJobTypesForProduct))
  };

  const closeEditWorkRequestForm = () => {
    setSelectedWorkRequestData(null);
    setShowEditForm(false);
  };

  const closeAssignTechnician = () => {
    setSelectedWorkRequestData(null);
    setShowAssignTechnician(false);
    setSelectedTechnicianData(null);
  };

  const closeSelectTechnician = () => {
    setSelectedWorkRequestData(null);
    setShowSelectTechnician(false);
  };

  const closeSelectedTechnicianAssignedView = () => {
    // setSelectedWorkRequestData(null);
    setShowSelectTechnician(false);
  };

  const handleSaveSuccess = () => {
    closeCreateWorkRequestForm();
  };

  const removeTechnician = (workRequestId: number) => {
    setWorkRequestDataList((prevData) => {
      const updatedWorkRequests = prevData.data.map((workRequest:any) => {
        if (workRequest.id === workRequestId) {
          return {
            ...workRequest,
            technician: [], // Remove all technicians for this work request
          };
        }

        return workRequest;
      });
      setShowAssignTechnician(false);
      return { data: updatedWorkRequests };
    });
  };

  const handleAssignTechnician = (selectedTechnician: any) => {
    setSelectedTechnicianData(selectedTechnician);
    setShowAssignTechnician(true);
  };
  

  const confirmHandleAssignTechnician = (
    selectedTechnician: any
  ) => {
    reloadData()
  };
  return (
    <div style={{ backgroundColor: "white", marginTop: "-1rem" }}>
      {showProductList ? (
        <ProductDisplay onCancelVieProduct={closeProductListView}/>
      ) : showCreateForm ? (
        <WorkRequest
          onCancel={closeCreateWorkRequestForm}
          openProductList={openProductListView}
        />
      ) : showEditForm ? (
        <EditWorkRequest
          editWorkRequestData={selectedWorkRequestData}
          onCancel={closeEditWorkRequestForm}
        />
      ) : showAssignTechnician ? (
        <AssignTechnician
          workRequestData={selectedWorkRequestData}
          onCancel={closeAssignTechnician}
          selectTechnicianViewAction={openSelectTechnician}
          removeTechnician={removeTechnician}
          selectedTechnicianData={selectedTechnicianData}
          onCloseAssignedTechnician={closeSelectedTechnicianAssignedView}
          onAssignTechnician={confirmHandleAssignTechnician}
          setShowAssignTechnician={setShowAssignTechnician}
          setSelectedTechnicianData={setSelectedTechnicianData}
        />
      ) : showSelectTechnician ? (
        <SelectTechnician
          workRequestData={selectedWorkRequestData}
          onCancel={closeSelectTechnician}
          onAssignTechnician={handleAssignTechnician}
        />
      ) : (
        <>
        <WorkRequestList
          buttonAction={openCreateWorkRequestForm}
          data={workRequestsBe}
          viewActionMethod={openEditWorkRequestForm}
          viewAssignTechnician={openAssignTechnician}
          reloadData={reloadData}
        />
        </>
        
      )}
    </div>
  );
};

export default WorkRequestPage;
