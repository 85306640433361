import React, { useEffect } from "react";
import DisplayCounts from "../components/DisplayCounts";
import { Card } from "react-bootstrap";
import "../styles/DashboardMainView.scss";
import BarChart from "../components/BarChart";
import BasicBars from "../components/BarChart";
import CustomPieChart from "../components/PieChart";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashJobTypesBarChartData,
  getDashPieChartCounts,
  getDashTechConsumersCounts,
} from "../services/dashboardService";
import { useNavigate } from "react-router-dom";
import { getTenants } from "../services/tenantService";

const DashboardMainView = () => {
  const dispatch = useDispatch<any>();
  const dashboardtechniciansCount = useSelector(
    (state: any) => state.dashboardData.techniciansCount
  );

  const dashboardconsumersCount = useSelector(
    (state: any) => state.dashboardData.consumersCount
  );

  const dashboardPieChartData = useSelector(
    (state: any) => state.dashboardData.pieChartCount
  );

  const dashboardBarChartData = useSelector(
    (state: any) => state.dashboardData.barChartCount
  );

  useEffect(() => {
    dispatch(getDashTechConsumersCounts());
    dispatch(getDashPieChartCounts());
    dispatch(getDashJobTypesBarChartData());
    dispatch(getTenants());
  }, [dispatch]);

  const navigate = useNavigate();

  const handleButtonClick = (item: string) => {
    if (item === "technicians") {
      navigate("/technician");
    } else if (item === "consumers") {
      navigate("/consumer");
    }
  };
  const formattedDisplayTech = dashboardtechniciansCount && dashboardtechniciansCount.period
    ? `${dashboardtechniciansCount.period.charAt(0).toUpperCase()}${dashboardtechniciansCount.period.slice(1).toLowerCase()}`
    : '';
const formattedDisplayCon = dashboardconsumersCount && dashboardconsumersCount.period
    ? `${dashboardconsumersCount.period.charAt(0).toUpperCase()}${dashboardconsumersCount.period.slice(1).toLowerCase()}`
    : '';


  return (
    <div>
      <div className="dashboard">
        {dashboardtechniciansCount && dashboardconsumersCount && (
          <div className="dashboard-counts">
            <Card className="count-card">
              <Card.Body
                className="count-card-body"
                onClick={() => handleButtonClick("technicians")}
              >
                <DisplayCounts
                  displayType="Technicians"
                  displayCount={
                    <div>
                        {dashboardtechniciansCount.count}
                        <br />
                        <div style={{fontSize: "12px"}}>This {formattedDisplayTech}</div>
                    </div>
                }
                ></DisplayCounts>
              </Card.Body>
            </Card>

            <Card className="count-card">
              <Card.Body
                className="count-card-body"
                onClick={() => handleButtonClick("consumers")}
              >
                <DisplayCounts
                  displayType="Consumers"
                  displayCount={
                    <>
                        {dashboardconsumersCount.count}
                        <br />
                        <div style={{fontSize: "12px"}}>This {formattedDisplayCon}</div>
                    </>
                }
                />
              </Card.Body>
            </Card>
          </div>
        )}

        <div className="dashboard-mid">
          <Card className="Bar-Chart-card">
            <Card.Header
              style={{
                marginBottom: "1rem",
                alignSelf: "center",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "white",
                flexDirection: "row",
                border: "none",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              Work Requests by Job Type <div style={{marginTop: "0.3rem", marginLeft: "0.5rem", fontSize: "12px", fontWeight: "normal"}}>(This Month)</div>
            </Card.Header>
            <BasicBars
              dashboardBarChartData={dashboardBarChartData}
            ></BasicBars>
          </Card>

          <Card className="Pie-Chart-card">
            <Card.Header
              style={{
                marginBottom: "1rem",
                alignSelf: "center",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "white",
                flexDirection: "row",
                border: "none",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              Work Requests by Status <div style={{marginTop: "0.3rem", marginLeft: "0.5rem", fontSize: "12px", fontWeight: "normal"}}>(This Month)</div>
            </Card.Header>
            <CustomPieChart
              dashboardPieChartData={dashboardPieChartData}
            ></CustomPieChart>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default DashboardMainView;
