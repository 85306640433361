import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DashboardState {
  loading: boolean;
  error: string | null;
  success: boolean;

  techniciansCount: any;
  consumersCount: any;
  pieChartCount: any;
  barChartCount: any;
}

const initialState: DashboardState = {
  loading: false,
  error: null,
  success: false,

  techniciansCount: null,
  consumersCount: null,
  pieChartCount: null,
  barChartCount: null,
};

const dashboardSlice = createSlice({
  name: "dashboardData",
  initialState,
  reducers: {
    setDashTechniciansLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
      state.error = null;
      state.success = false;
    },

    setDashTechniciansError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
      state.success = true;
    },

    setDashTechniciansState(state, action: PayloadAction<DashboardState>) {
      state.techniciansCount = action.payload;
      state.loading = false;
      state.error = null;
      state.success = true;
    },

    setDashConsumersLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
      state.error = null;
      state.success = false;
    },

    setDashConsumersError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
      state.success = true;
    },

    setDashConsumersState(state, action: PayloadAction<DashboardState>) {
      state.consumersCount = action.payload;
      state.loading = false;
      state.error = null;
      state.success = true;
    },

    setDashPieChartCountLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
      state.error = null;
      state.success = false;
    },

    setDashPieChartCountError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
      state.success = true;
    },

    setDashPieChartCountState(state, action: PayloadAction<DashboardState>) {
      state.pieChartCount = action.payload;
      state.loading = false;
      state.error = null;
      state.success = true;
    },

    setDashBarChartCountLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
      state.error = null;
      state.success = false;
    },

    setDashBarChartCountError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
      state.success = true;
    },

    setDashBarChartCountState(state, action: PayloadAction<DashboardState>) {
      state.barChartCount = action.payload;
      state.loading = false;
      state.error = null;
      state.success = true;
    },

    
  },
});

export const {
  setDashConsumersLoading,
  setDashConsumersError,
  setDashConsumersState,
  setDashTechniciansLoading,
  setDashTechniciansState,
  setDashTechniciansError,
  setDashPieChartCountState,
  setDashPieChartCountError,
  setDashPieChartCountLoading,
  setDashBarChartCountState,
  setDashBarChartCountError,
  setDashBarChartCountLoading
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
