import React, { useEffect, useState } from "react";
import { Form, InputGroup, FormControl } from "react-bootstrap";
import Button from "../../components/Button";

interface ConsumerSearchProps {
  handleSearch: (field: string, value: string) => void;
  reloadData: () => void;
}

const ConsumerSearch: React.FC<ConsumerSearchProps> = ({ handleSearch, reloadData }) => {
  const [searchValue, setSearchValue] = useState("");
  const [selectedOption, setSelectedOption] = useState("name");
  const [disbaleSerach, setDisbaleSerach] = useState<boolean>(true);


  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);

    if (selectedOption === "nic" && value.length >= 5) {
      setDisbaleSerach(false);
    } else if (selectedOption !== "nic" && value.length >= 3) {
      setDisbaleSerach(false);
    } else {
      setDisbaleSerach(true);
    }
  };

  useEffect(() => {
    // Clear search value and disable search when reloadData is called
    setSearchValue("");
    setDisbaleSerach(true);
  }, [reloadData]);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setSelectedOption(value);

  };
  const handleSearchClick = () => {
    handleSearch(selectedOption, searchValue);
  };

  return (
    <div style={{ display: 'flex' }}>
      <InputGroup>
        <FormControl
          type="text"
          placeholder={`Search by ${selectedOption === "nic" ? "NIC" : "Name"}`}
          onChange={handleSearchChange}
          value={searchValue}
          style={{ width: "10rem", height: "2.3rem" }}
        />
        <select
          value={selectedOption}
          onChange={handleSelectChange}
          style={{
            marginLeft: "1px",
            marginRight: "1px",
            width: "1.5rem",
            height: "2.3rem",
            borderRadius: "5px",
          }}
        >
          <option value="name">Search By Name</option>
          <option value="nic">Search By NIC</option>

        </select>
      </InputGroup>

      <div style={{ marginLeft: "1rem" }}>
        <Button
          style={{
            width: "15vh",
            backgroundColor: disbaleSerach ? "grey" : "black",
            padding: "0.4rem",
            marginTop: "0rem",
          }}
          disabledButton={disbaleSerach}
          onClick={() => handleSearch(selectedOption, searchValue)}
          children={"Search"}
        />
      </div>
    </div>
  );
};

export default ConsumerSearch;
