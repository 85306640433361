import Spinner from 'react-bootstrap/Spinner';
import backgroundImage from '../assets/backgroundImage.png';

interface SpinnerProps {
  children?: any;
}

const SpinnerLoading: React.FC<SpinnerProps> = ({ children}) => {
  return (
    <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh',
        backgroundImage: 'url(backgroundImage)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backdropFilter: 'blur(10px)',
      }}>
        <Spinner animation="border" variant="warning" />
      </div>
  );
};

export default SpinnerLoading;
