import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TechnicianType } from "../type";

interface TechnicianState {
  technicians: TechnicianType[];
  loading: boolean;
  error: string | null;
  success: boolean;
  singleTechnician: any;
  technicianTanants:any
  promoteSuccess?: boolean,
  promoteError?:string | null,
  workRequests?:any,
  selectedTechnician?:string | null,
  techniciansForJobTypes?: any,
  ratings?: any,
  count?: number,
}

const initialState: TechnicianState = {
  technicians: [],
  loading: false,
  error: null,
  success: false,
  promoteSuccess: false,
  promoteError:null,
  singleTechnician: {},
  technicianTanants: null,
  workRequests: null,
  selectedTechnician: null,
  techniciansForJobTypes: [],
  ratings: [],
  count: 0,
};

const technicianSlice = createSlice({
  name: "technicians",
  initialState,
  reducers: {
    setTechnicians(state, action: PayloadAction<TechnicianType[]>) {
      return {
        ...state,
        technicians : action.payload,
        loading : false,
        error : null,
        success: false,
        promoteSuccess: false,
        promoteError: null,
        selectedTechnician: null,

      };
    },
    setTechniciansCount(state, action: any) {
      return {
        ...state,
        count : action.payload,
      };
    },
    setTechniciansLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
      state.error = null;
      state.success= false;
      state.promoteSuccess = false;
      state.promoteError = null;
      state.selectedTechnician = null;
      state.techniciansForJobTypes = null;
    },
    setTechniciansError(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.success= false;
      state.error = action.payload;
      state.promoteSuccess = false;
      state.promoteError = null;
      state.selectedTechnician = null;
      state.techniciansForJobTypes = null;
    },
    addTechnicianState(state, action: PayloadAction<any>) {
    state.technicians.push(action.payload);
      state.loading = false;
      state.error = null;
      state.success= true;
      state.promoteSuccess = false;
      state.promoteError = null;
      state.selectedTechnician = null;
    },
    editTechnicianState(state, action: PayloadAction<{ id: string; updatedTechnician: TechnicianType }>) {
      const { id, updatedTechnician } = action.payload;
      state.selectedTechnician = id;
      const index = state.technicians.findIndex(jobType => jobType.id === id);
      if (index !== -1) {
        state.technicians[index] = updatedTechnician;
        state.loading = false;
        state.error = null;
        state.success = true;
      }
    },
    setTechniciansForJobs(state, action: PayloadAction<TechnicianType[]>) {
      return {
        ...state,
        techniciansForJobTypes : action.payload,
        loading : false,
        error : null,
      };
    },

    setTechnicianRatingsReviewsLoading(state, action: PayloadAction<boolean>){
      state.loading = action.payload;
      state.error = null;
      state.success= false;
    },
    setTechnicianRatingsReviewsError(state, action: PayloadAction<string | null>){
      state.loading = false;
      state.success= false;
      state.error = action.payload;
    },
    setTechnicianRatingsReviewsState(state, action: PayloadAction<any>){
      state.ratings = action.payload;
      state.loading = false;
      state.success= false;
      state.error = null;
    }
  }
});

export const {
  setTechnicians,
  setTechniciansLoading,
  setTechniciansError,
  addTechnicianState,
  editTechnicianState,
  setTechniciansForJobs,
  setTechnicianRatingsReviewsState,
  setTechnicianRatingsReviewsError,
  setTechnicianRatingsReviewsLoading,
  setTechniciansCount,
} = technicianSlice.actions;

export default technicianSlice.reducer;
