import { BASE_URL } from '../constants/appConstants';
import { fetchWithToken } from '../utils/utils';

export const fetchJobTypes = async () => {
  try {
    const end_url = BASE_URL + "/job-types?page=0&size=0";
    const response = await fetchWithToken(end_url);
    if (!response.ok) {
      throw new Error('Failed to fetch job types');
    }
    const jobTypes = await response.json();
    return jobTypes.results;
  } catch (error) {
    throw new Error('Failed to fetch job types');
  }
};

export const createJobType = async (jobTypeData: any) => {
  try {
    const end_url = BASE_URL + "/job-types";
    const response = await fetchWithToken(end_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jobTypeData),
    });
    const newJobType = await response.json();
    return newJobType;
  } catch (error) {
    throw new Error('Failed to create job type');
  }
};

export const updateJobTypes = async (jobTypeId: string, updatedData: any) => {
  try {
    const end_url = `${BASE_URL}/job-types/${jobTypeId}`;
    const response = await fetchWithToken(end_url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedData),
    });
    const updatedJobType = await response.json();
    return updatedJobType;
  } catch (error) {
    throw new Error('Failed to update job type');
  }
};

