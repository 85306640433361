import { BASE_URL } from "../constants/appConstants";
import { fetchWithToken } from "../utils/utils";
export const fetchConsumerReviewById = async (consumerID: string) => {
  try {
    const endUrl = `${BASE_URL}/review-entries/consumer/${consumerID}?page=0&size=0`;
    const response = await fetchWithToken(endUrl);
    if (!response.ok) {
      throw new Error('Failed to fetch review');
    }
    const review = await response.json();
    console.log("consumer data reviws apoi", review)
    return review;
  } catch (error) {
    throw new Error('Failed to fetch review');
  }
}