import React from "react";
import { Card } from "react-bootstrap";
import "../styles/Technician/TechnicianID.scss";
import phoneIcon from "../assets/phone__icon.png";
import solidClubIcon from "../assets/Solid_club_icon.png";
import mailIcon from "../assets/mail.png";
import { QrCode } from "./QrCode";
import ID_Frame from "../assets/ID_Frame.png";

interface TechnicianDetails {
  fullName: string;
  nic: string;
  mobile: string;
  address: string;
  profilePicture: string;
}

interface TechnicianIDProps {
  technician: TechnicianDetails;
}

const TechnicianID: React.FC<TechnicianIDProps> = ({ technician }) => {
  //Only for development purpose

  const company_details = {
    email: "info@sharecolombo.com",
    phone: "+94 77 777 7777",
  };

  const concatenatedQrString = `Full name : ${technician.fullName}, 
  Mobile No : ${technician.mobile}, 
  NIC No : ${technician.nic}, 
  Address : ${technician.address}`;

  return (
    <div className="technician-id">
      <Card >
        <Card.Body style={{
          width: '50rem',
          height: '30rem',
          backgroundImage: `url(${ID_Frame})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }} className="technician-card-id-body" >

          <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div className="technician-id-body-top">
              <div className="technician-id-picture">
                <img src={technician.profilePicture} alt="Profile Picture" />
              </div>
              <h4 className="profile-name" style={{ color: 'white' }}>{technician.fullName}</h4>
            </div>

            <div className="technician-id-body-mid">
              <div className="user-details">
                <h6 style={{ color: 'white' }}>{technician.nic}</h6>
                <h6 style={{ color: 'white' }}>{technician.address}</h6>
                <h6 style={{ color: 'white' }}>{technician.mobile}</h6>
              </div>

              <div className="qr-code-background">
                <div className="qr-code">
                  <QrCode qr_string={concatenatedQrString} />
                </div>
              </div>
            </div>

            <div className="technician-id-body-bottom">
              <div className="company-details">
                <p className="company-detail">
                  <div>
                    <img
                      src={phoneIcon}
                      alt="telephone"
                      className="telephone-icon"
                    ></img>
                  </div>
                  <div style={{ color: 'white' }}>{company_details.phone}</div>
                </p>
                <p className="company-detail">
                  <div>
                    <img
                      src={mailIcon}
                      alt="telephone"
                      className="telephone-icon"
                    ></img>
                  </div>
                  <div style={{ color: 'white' }}>{company_details.email}</div>
                </p>
              </div>
            </div>

          </div>

        </Card.Body>
      </Card>
    </div>
  );
};

export default TechnicianID;
