import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TechnicianType } from "../type";

interface TechnicianState {
  loading: boolean;
  error: string | null;
  success: boolean;
  singleTechnician: any;
  technicianTanants: any;
  workRequests?: any;
  selectedTechnician?: string | null;
}

const initialState: TechnicianState = {
  loading: false,
  error: null,
  success: false,
  singleTechnician: {},
  technicianTanants: null,
  workRequests: null,
  selectedTechnician: null,
};

const selectedTechnicanSlice = createSlice({
  name: "selectedTechnican",
  initialState,
  reducers: {
    setSelectedTechniciansLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
      state.error = null;
      state.success = false;
      state.singleTechnician = null;
      state.technicianTanants = null;
      state.workRequests = null;
      state.selectedTechnician = null;
    },
    setSelectedTechniciansError(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
      state.singleTechnician = null;
      state.technicianTanants = null;
      state.workRequests = null;
      state.selectedTechnician = null;
    },

    setSingleTechnician(state, action: PayloadAction<any>) {
      return {
        ...state,
        loading: false,
        error: null,
        success: false,
        singleTechnician : action.payload,
      };
    },

    setSelectedTechnican(state, action: PayloadAction<string>) {
      state.selectedTechnician = action.payload;
      state.loading = false;
      state.error = null;
      state.success = false;
      state.singleTechnician = null;
      state.technicianTanants = null;
      state.workRequests = null;
    },

    promoteTechnicianState(state, action: PayloadAction<TechnicianType>) {
      return {
        ...state,
        loading: false,
        success: action.payload,
        error: null
      };
    },

    setPromoteTechniciansError(state, action: PayloadAction<string | null>) {
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    },

    setPromoteTchniciansLoading(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        loading: action.payload,
        error: null,
        success: false,
      };
    },

    setTechnicianTenetLevelLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
      state.error = null;
      state.success = false;
    },

    setTechnicianTenentLevelError(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.error = action.payload;
      state.success = true;
    },

    setTechnicianTenentLevelState(
      state,
      action: PayloadAction<TechnicianType>
    ) {
      state.technicianTanants = action.payload;
      state.loading = false;
      state.error = null;
      state.success = true;
    },

    setSingleTechnicianWorkRequestsLoading(
      state,
      action: PayloadAction<boolean>
    ) {
      state.loading = action.payload;
      state.error = null;
      state.success = false;
    },

    setSingleTechniciansWorkRequestsError(
      state,
      action: PayloadAction<string | null>
    ) {
      state.loading = false;
      state.error = action.payload;
      state.success = true;
    },

    setSingleTechniciansWorkRequestsState(
      state,
      action: PayloadAction<TechnicianType>
    ) {
      state.workRequests = action.payload;
      state.loading = false;
      state.error = null;
      state.success = true;
    },
  },
});

export const {
  setSelectedTechniciansLoading,
  setSelectedTechniciansError,
  setPromoteTechniciansError,
  setSingleTechnician,
  setPromoteTchniciansLoading,
  promoteTechnicianState,
  setSelectedTechnican,
  setTechnicianTenetLevelLoading,
  setTechnicianTenentLevelError,
  setTechnicianTenentLevelState,
  setSingleTechnicianWorkRequestsLoading,
  setSingleTechniciansWorkRequestsError,
  setSingleTechniciansWorkRequestsState,
} = selectedTechnicanSlice.actions;

export default selectedTechnicanSlice.reducer;
