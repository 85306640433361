import React, { useState } from "react";
import editIcon from "../../assets/edit-icon.png";
import TableView from "../../components/TableView";
import CreateJobTypeForm from "./CreateJobTypeForm";
import EditJobTypeForm from "./EditJobTypeForm";

interface DataItem {
  id: number;
  jobType: string;
  description: string;
  productType: any;
  jobStatus: string;
}

const iconStyle: React.CSSProperties = {
  width: "2.5vh",
  height: "2.5vh",
  marginRight: "1.5vh",
};

interface TechnicalJobsProps {
  data: DataItem[];
  reloadData?: () => void;
}
const filters = [
  { label: "Job Status", key: "status" },
];

const TechnicalJobs: React.FC<TechnicalJobsProps> = ({ data, reloadData }) => {
  const [showCreateForm, setShowCreateForm] = useState<boolean>(false);
  const [showEditForm, setShowEditForm] = useState<boolean>(false);
  const [selectedJobTypeData, setSelectedJobTypeData] = useState<any>(null);


  const openCreatejobs = () => {
    setShowCreateForm(true);
  };
  const closeCreateJobForm = () => {
    setShowCreateForm(false);
    if (reloadData) {
      reloadData();
    }
  };
  
  const onClickView = () => {
    setShowEditForm(true);
  };

  const openEditJobForm = (jobTypeData: DataItem) => {
    setSelectedJobTypeData(jobTypeData);
    setShowEditForm(true);
  };

  const closeEditJobForm = () => {
    setSelectedJobTypeData(null);
    setShowEditForm(false);
    if (reloadData) {
      reloadData();
    }
  };

  const iconStyle: React.CSSProperties = {
    width: "2.5vh",
    height: "2.5vh",
    marginLeft: "2vh",
  };

  return (
    <div style={{ margin: "0 2vh" }}>
      {showCreateForm ? (
        <CreateJobTypeForm onCancel={closeCreateJobForm} />
      ) : showEditForm ? (
        <EditJobTypeForm jobTypeData={selectedJobTypeData} onCancel={closeEditJobForm} />
      ) : (
        <>
          <TableView
            data={data}
            buttonName={"Create Job Type"}
            columns={["type", "description", "productTypes", "status"]}
            columnLabels={["Job Type", "Description", "Product Type", "Job Status"]}
            buttonAction={openCreatejobs}
            viewAction={<img src={editIcon} style={iconStyle}/>}
            viewActionMethod={openEditJobForm}
            filters={filters}
            reloadData={reloadData}
            itemsPage={10}
          />
        </>
      )}
    </div>
  );
};

export default TechnicalJobs;
