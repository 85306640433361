import React from 'react';
import styles from "../styles/Home.module.css";
import Template from '../components/Template';

const Home: React.FC = () => {
  return (
    <div className={styles.appContainer}>
      <Template />
    </div>
    
  )
}

export default Home;