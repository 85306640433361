interface WorkRequestStatusProps {
  status?: string | null;
}

const workStatusStyle = {
  // Define your CSS properties here
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // Add more CSS properties as needed
};

const acceptButtonStyle = {
  backgroundColor: "transparent",
  borderRadius: "5px",
  width: "100px",
  color: "#27ae60",
  padding: "5px 10px",
  fontSize: "12px",
  cursor: "pointer",
  marginTop: "1rem",
  border: "1px solid #27ae60",
};

const statusColors: any = {
  accepted: "#50C955",
  rejected: "#E45E56",
  "on-hold": "#E7A23C",
  completed: "#5055C9",
  assigned: "#064CFF",
  "in-review": "#4C0D9C",
  "in-progress" : "#006633",
};

const displayName: any = {
  accepted: "Accepted",
  rejected: "Rejected",
  "on-hold": "On Hold",
  completed: "Completed",
  assigned: "Assigned",
  "in-review": "New",
  "in-progress": "In Progress",
};

const getStatusColor = (status: string) => {
  return statusColors[status] || "#4C0D9C";
};

const getDisplayName = (status: string) => {
  return displayName[status] || status;
};

const WorkRequestStatus: React.FC<WorkRequestStatusProps> = ({ status }) => {
  const colour = status && getStatusColor(status);
  const displayName = status && getDisplayName(status);
  return (
    <div style={workStatusStyle}>
      <button
        style={{
          ...acceptButtonStyle,
          color: colour,
          borderColor: colour,
        }}
      >
        {displayName}
      </button>
    </div>
  );
};

export default WorkRequestStatus;
