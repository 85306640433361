import React, { useEffect } from "react";
import genralInforStyle from "../../styles/Consumer/Consumer.GeneralInfor.module.scss";
import Rating from "../../components/RatingStars";
import Card from "react-bootstrap/Card";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL_FOR_IMAGE } from "../../constants/appConstants";
import ConsumerRating from "./ConsumerRating";
import TechnicianFeedback from "./TechnicianFeedback";
import { useNavigate } from "react-router-dom";
import { getConsumerReviewById } from "../../services/consumerReviewService";

interface ReviewResult {
  count: number;
  id: string;
  rating: number;
  comment: string;
  ratedBy: {
    id: string;
    profileImage: {
      url: string;
    };
    userAccount: {
      firstName: string;
      lastName: string;
    };
  };
  createdDate: string;
}

const ConsumerGeneralinfor: React.FC<{
  onClose: () => void;
  consumerData: any;
}> = ({ onClose, consumerData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const review: ReviewResult[] = useSelector(
    (state: any) => state.consumerReview?.consumerReviews
  );
  
  useEffect(() => {
    dispatch(getConsumerReviewById(consumerData.id));
  }, [dispatch]);

  const handleButtonClick = () => {
    navigate("/technician");
  };

  return (
    <div>
      <div className={genralInforStyle.profilecard}>
        <div className={genralInforStyle.profilecardheader}>
          <div className={genralInforStyle.profilecontainer}>
            <div>
              <img
                src={
                  consumerData.profileImage
                    ? BASE_URL_FOR_IMAGE + consumerData.profileImage.url
                    : "https://media.istockphoto.com/id/519977141/photo/daydreaming-about-her-future.jpg?s=2048x2048&w=is&k=20&c=iAGtxkCqomrBrW8fKsMJtZruQbcuX3oV6XZQdfkIGRo="
                }
                alt="Profile Picture"
              />
            </div>
            <div>
              <h4>
                {consumerData.firstName} {consumerData.lastName}
              </h4>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "10px",
                }}
              >
                <Rating rating={consumerData.averageRating} />
                <div
                  style={{
                    display: "flex",
                    width: "3rem",
                    marginLeft: "15px",
                    fontSize: "18px",
                    alignItems: "center",
                    color: "grey",
                  }}
                >
                  {consumerData.averageRating &&
                  consumerData.averageRating !== "NaN"
                    ? consumerData.averageRating
                    : ""}
                </div>
              </div>
              <button
                className={genralInforStyle.switchtotech}
                onClick={handleButtonClick}
              >
                Switch To Technician
              </button>
            </div>
          </div>

          <div className={genralInforStyle.ratingcards}>
            <ConsumerRating />
          </div>
        </div>

        <div className={genralInforStyle.profilecardmidbody}>
          <div className={genralInforStyle.profilecardGeninfo}>
            <Card>
              <h6>General Information</h6>
              <Card.Body>
                <table>
                  <tbody>
                    <tr>
                      <td>Full Name:</td>
                      <td>
                        {consumerData.firstName} {consumerData.lastName}
                      </td>
                    </tr>
                    <tr>
                      <td>Date of birth:</td>
                      <td>{consumerData.dateOfBirth}</td>
                    </tr>
                    <tr>
                      <td>NIC number:</td>
                      <td>{consumerData.nationalIdentityCardNumber}</td>
                    </tr>
                    <tr>
                      <td>Contact Number:</td>
                      <td>{consumerData.contactNumberPrimary}</td>
                    </tr>
                    <tr>
                      <td>Email:</td>
                      <td
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "180px",
                        }}
                      >
                        {consumerData.email}
                      </td>
                    </tr>

                    <br />
                  </tbody>
                </table>
              </Card.Body>
            </Card>
          </div>

          <div className={genralInforStyle.techFeedback}>
            <TechnicianFeedback
              consumerData={consumerData}
              review={review}
              count={0}
            />
          </div>
        </div>

        <div className={genralInforStyle.profilecardmidbody}>
          <div className={genralInforStyle.locationInfor}>
            <Card>
              <h6>Location Information</h6>
              <Card.Body>
                <table>
                  <tbody>
                    <tr>
                      <td>Address:</td>
                      <td
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "180px",
                        }}
                      >
                        {consumerData.address}
                      </td>
                    </tr>
                    <tr>
                      <td>District:</td>
                      <td>{consumerData.district}</td>
                    </tr>
                    <tr>
                      <td>City:</td>
                      <td>{consumerData.city}</td>
                    </tr>
                  </tbody>
                </table>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>

      <div className={genralInforStyle.canclebuttonContainer}>
        <button
          onClick={() => onClose()}
          className={genralInforStyle.cancleButton}
        >
          Cancel
        </button>
        <button className={genralInforStyle.seeMoreButton}>More Reviews</button>
      </div>
    </div>
  );
};

export default ConsumerGeneralinfor;
