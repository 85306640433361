import React, { useEffect, useState } from "react";
import "../../styles/Technician/Technician.GenInfo.page.css";
import Rating from "../../components/RatingStars";
import Card from "react-bootstrap/Card";
import { Modal } from "react-bootstrap";
import TechnicianPromote from "./TechnicianPromote";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL_FOR_IMAGE } from "../../constants/appConstants";
import { getTechniciansByID, technicianTenentLevels } from "../../services/technicianService";
import Default_Image from "../../assets/tenants/company.jpg";

//Tanents
// import { getTenantImage } from "../../utils/tanentImages";
import ImagesPopUp from "../../components/ImagesPopUp";
import ImageGalleryCarousel from "../../components/ImageGalleryCarousel";

const iconStyle: React.CSSProperties = {
  width: "8vh",
  height: "8vh",
  border: "1px solid #FFC600",
  borderRadius: "30px",
  borderColor: "yellow",
};



const TechnicianGenInfo: React.FC<{
  onClose: () => void;
  reloadData: () => void;
}> = ({ onClose, reloadData }) => {
  const dispatch = useDispatch<any>();
  const [show, setShow] = useState(false);
  const [showImgGallery, setShowImgGallery] = useState(false);
  const [selectedImage, setSelectedImage] = useState<any>({ id: null });
  const [workGallery, setWorkGallery] = useState<any>([]);
  const tenantsBe = useSelector((state: any) => state.tenants.tenants);
  const singleTechnicianData = useSelector(
    (state: any) => state.selectedTechnican.singleTechnician
  );
  
  const getTenantImage = (tenantID: string): string => {
    const foundTenant = tenantsBe.find((tenant:any) => tenant.id === tenantID);
    return foundTenant ? foundTenant.logo : Default_Image;
  };

  const Nic_Image_front =
    BASE_URL_FOR_IMAGE + singleTechnicianData?.nicFrontImage.url;
  const Nic_Image_back =
    BASE_URL_FOR_IMAGE + singleTechnicianData?.nicBackImage.url;
  const profile_pic =
    BASE_URL_FOR_IMAGE + singleTechnicianData?.profileImage.url;

  useEffect(() => {
    if (singleTechnicianData) {
      const workGalleryLocal =
        (singleTechnicianData.workExperience &&
          singleTechnicianData.workExperience.imagesOfWork) ||
        [];

      setWorkGallery(workGalleryLocal);
    }
  }, [singleTechnicianData]);

  const handleClose = () => {
    console.log("handle colsesingleTechnicianData",singleTechnicianData)
    setShow(false);
    reloadData();
  };
  const handleShow = () => {
    setShow(true);
    dispatch(technicianTenentLevels(singleTechnicianData.id));
  };

  const viewImage = (id: any, workGallery: any) => {
    setShowImgGallery(true);
    setSelectedImage(id);
    setWorkGallery(workGallery);
  };

  const handleCloseImgGallery = () => {
    setShowImgGallery(false);
  };

  return (
    <div>
      <div className="profile-card">
        <div className="profile-card__header">
          <div className="profile-container">
            <div>
              <img src={profile_pic} alt="Profile Picture" />
            </div>
            <div>
              <h4>
                {singleTechnicianData && singleTechnicianData.firstName}{" "}
                {singleTechnicianData && singleTechnicianData.lastName}
              </h4>
              {singleTechnicianData && singleTechnicianData.averageRating && (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Rating
                    rating={parseInt(singleTechnicianData.averageRating)}
                  />
                  <div style={{ display: "flex", width: "3rem", marginLeft: "15px", fontSize: "18px", alignItems: "center", color: "grey" }}>
                    {singleTechnicianData.averageRating && singleTechnicianData.averageRating !== "NaN" ? singleTechnicianData.averageRating : ""}
                  </div>
                </div>
              )}

              <button className="active-button">Active</button>
            </div>
          </div>

          <div className="flex-companies">
            {singleTechnicianData &&
              singleTechnicianData.technicianTanentLevel.length > 0 && (
                <div style={{ display: "flex", justifyContent: "center", alignSelf: "center", alignItems: 'flex-end' }}>
                  {singleTechnicianData?.technicianTanentLevel?.map(
                    (item: any, index: any) => (
                      <div key={index}>
                        <div>
                          <img
                            src={getTenantImage(item.tanent.id)}
                            alt={item.name}
                            style={iconStyle}
                          />
                        </div>
                        <div>
                          <h6>{item.technicianLevel.name}</h6>
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}
          </div>

          <div className="flex-end-button">
            <div>
              <button className="promote-button" onClick={handleShow}>
                Promote
              </button>
            </div>
            <div>
              <button className="back-to-list-button" onClick={onClose}>
                Back to List
              </button>
            </div>
          </div>
        </div>

        <div className="profile-card__mid_body">
          <div className="profile-card__Gen_info">
            <Card>
              <h6>General Information</h6>
              <Card.Body>
                <table>
                  <tbody>
                    <tr>
                      <td>Full Name:</td>
                      <td>
                        {singleTechnicianData && singleTechnicianData.firstName}{" "}
                        {singleTechnicianData && singleTechnicianData.lastName}
                      </td>
                    </tr>
                    <tr>
                      <td>Date of birth:</td>
                      <td>
                        {singleTechnicianData &&
                          singleTechnicianData.dateOfBirth}
                      </td>
                    </tr>
                    <tr>
                      <td>NIC number:</td>
                      <td>
                        {singleTechnicianData &&
                          singleTechnicianData.nationalIdentityCardNo}
                      </td>
                    </tr>
                    <tr>
                      <td>Minimal education:</td>
                      <td>
                        {singleTechnicianData &&
                          singleTechnicianData.minimumEducation}
                      </td>
                    </tr>
                    <tr>
                      <td>Primary Contact:</td>
                      <td>
                        {singleTechnicianData &&
                          singleTechnicianData.contactNumberPrimary}
                      </td>
                    </tr>

                    <br />
                    <tr>
                      <td>NIC Images :</td>
                    </tr>
                  </tbody>
                </table>

                <div className="Nic_Images">
                  <div>
                    <img
                      src={Nic_Image_front}
                      alt="NIC_Img_front"
                      className="Nic_Image"
                    />
                  </div>
                  <div>
                    <img
                      src={Nic_Image_back}
                      alt="NIC_Img_back"
                      className="Nic_Image"
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>

          <div className="profile-card__Work_info">
            <Card>
              <h6>Work Information</h6>
              <Card.Body>
                <table>
                  <tbody>
                    <tr>
                      <td>Number of workers:</td>
                      <td>
                        {singleTechnicianData &&
                          singleTechnicianData.noOfWorkers}
                      </td>
                    </tr>
                    <br />

                    <tr>
                      <td>Average monthly work:</td>
                      <td>
                        {singleTechnicianData &&
                          singleTechnicianData.numberOfAverageWorkers}
                      </td>
                    </tr>
                    <br />

                    <tr>
                      <td>Type of work:</td>
                      <td>Years:</td>
                      <td>Months:</td>
                    </tr>
                    {singleTechnicianData?.workExperience?.jobTypeExperiences.map(
                      (item: any, index: any) => (
                        <tr key={index}>
                          <td>{item.jobType.type}</td>
                          <td>{item.experienceInYears}</td>
                          <td>{item.experienceInMonths}</td>
                        </tr>
                      )
                    )}
                    <br />

                    <tr>
                      <td>Working location</td>
                    </tr>

                    <tr>
                      <td>district:</td>
                      <td>
                        {singleTechnicianData &&
                          singleTechnicianData.workExperience?.mainWorkingArea
                            .district}
                      </td>
                    </tr>

                    <tr>
                      <td>City:</td>
                      <td>
                        {singleTechnicianData &&
                          singleTechnicianData.workExperience?.mainWorkingArea
                            .city}
                      </td>
                    </tr>
                    <br />

                    {singleTechnicianData?.workExperience?.tanents.map(
                      (item: any, index: any) => (
                        <tr key={index}>
                          <td>Company:</td>
                          <td>{item.name}</td>{" "}
                          {/* Render the 'name' property of the 'item' object */}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </Card.Body>
            </Card>
          </div>
        </div>

        <div className="profile-card__mid_body">
          <div className="profile-card__Gen_info">
            <Card>
              <h6>Location Information</h6>
              <Card.Body>
                <table>
                  <tbody>
                    <tr>
                      <td>Address:</td>
                      <td>
                        {singleTechnicianData && singleTechnicianData.address}
                      </td>
                    </tr>
                    <tr>
                      <td>District:</td>
                      <td>
                        {singleTechnicianData && singleTechnicianData.district}
                      </td>
                    </tr>
                    <tr>
                      <td>City:</td>
                      <td>
                        {singleTechnicianData && singleTechnicianData.city}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card.Body>
            </Card>
          </div>

          <div className="profile-card__Work_Gallery">
            <Card>
              <h6>Work Gallery</h6>
              <Card.Body>
                {/* <div className="work-gallery-row">
                  {workGallery.length > 0 &&
                    workGallery.map((item: any, index: number) => {
                      const imageUrl = BASE_URL_FOR_IMAGE + item.url;
                      return (
                        // Add the return statement here
                        <div key={index} className="work-gallery-pics">
                          <img
                            className="gallery-image"
                            src={imageUrl}
                            alt={item.id}
                            onClick={() => viewImage(index, workGallery)}
                          />
                        </div>
                      );
                    })}
                </div> */}

                <div className="work-gallery-row">
                  {workGallery.length > 0 &&
                    workGallery.map((item: any, index: number) => {
                      const imageUrl = BASE_URL_FOR_IMAGE + item.url;
                      return (
                        <div key={index} className="work-gallery-pics">
                          <img
                            className="gallery-image"
                            src={imageUrl}
                            alt={item.id}
                            onClick={() => viewImage(index, workGallery)}
                          />
                        </div>
                      );
                    })}
                </div>

              </Card.Body>
            </Card>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <h4>Promote Technician</h4>
        </Modal.Header>

        <TechnicianPromote
          singleTechnicianData={singleTechnicianData}
          closeModal={handleClose}
        />
      </Modal>

      <Modal
        show={showImgGallery}
        onHide={handleCloseImgGallery}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <h4>Work Gallery</h4>
        </Modal.Header>

        {/* <ImagesPopUp images={workGallery} selectedImage={selectedImage} /> */}

        <ImageGalleryCarousel
          images={workGallery}
          selectedImage={selectedImage}
        />
      </Modal>
    </div>
  );
};

export default TechnicianGenInfo;
