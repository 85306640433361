import React, { useEffect, useState } from "react";
import editicon from "../../assets/edit-icon.png";
import SearchIcon from "../../assets/search.png";
import TableView from "../../components/TableView";
import CreateLevelCriteria from "./CreateLevelCriteria";
import EditLevelCriteriaForm from "./EditLevelCriteriaForm";
import { useDispatch, useSelector } from "react-redux";
import { getLevelCriteria } from "../../services/levelCriteriaService";

const iconStyle: React.CSSProperties = {
  width: "2.5vh",
  height: "2.5vh",
  marginRight: "1.5vh",
};

interface ScLevelCriteriaProps {
  //reloadData: () => void;
}

const filters = [
  { label: "Tenant", key: "tanent" },
  { label: "Level", key: "level" },
];
const ScLevelCriteria: React.FC<ScLevelCriteriaProps> = () => {
  const dispatch = useDispatch<any>();
  const [showCreateForm, setShowCreateForm] = useState<boolean>(false);
  const [showEditForm, setShowEditForm] = useState<boolean>(false);
  const [selectedLevelData, setSelectedLevelData] = useState<any>(null);
  const [flattenedData, setFlattenedData] = useState<any[]>([]);

  useEffect(() => {
    reloadLevelCriteria();
  }, [dispatch]);

  const reloadLevelCriteria = () => {
    dispatch(getLevelCriteria());
  };

  const levelCriteriaBe = useSelector(
    (state: any) => state.levelCriteria.levelCriteria
  );

  useEffect(() => {
    // Flatten the nested data
    const flattened = levelCriteriaBe.map((item: any) => ({
      ...item,
      tanent: item.tanent.name,
      level: item.level.name,
      tanentID: item.tanent.id,
      levelID: item.level.id,
    }));
    setFlattenedData(flattened);
  }, [levelCriteriaBe]);



  const openCreatejobs = () => {
    setShowCreateForm(true);
  };
  const closeCriteriaForm = () => {
    setShowCreateForm(false);
    reloadLevelCriteria();
  };

  const onClickView = () => {
    setShowEditForm(true);
  };

  const openEditJobForm = (jobTypeData: any) => {
    setSelectedLevelData(jobTypeData);
    setShowEditForm(true);
    reloadLevelCriteria();
  };

  const closeEditCriteriaForm = () => {
    setSelectedLevelData(null);
    setShowEditForm(false);
  };

  const iconStyle: React.CSSProperties = {
    width: "2.5vh",
    height: "2.5vh",
    marginLeft: "5vh",
  };

  return (
    <div style={{ margin: "0 2vh" }}>
      {showCreateForm ? (
        <CreateLevelCriteria onCancel={closeCriteriaForm} />
      )
        : showEditForm ? (
          <EditLevelCriteriaForm levelData={selectedLevelData} onCancel={closeEditCriteriaForm} />
        )
          :
          (
            <>
              <TableView
                data={flattenedData}
                columns={["tanent", "level", "levelCriteria", "expectedOutcome"]}
                columnLabels={["Tenant", "Level", "Level Criteria", "Expected Outcome"]}
                buttonName={"Create Level Criteria"}
                buttonAction={openCreatejobs}
                viewAction={<img src={editicon} style={iconStyle} />}
                viewActionMethod={openEditJobForm}
                reloadData={reloadLevelCriteria}
                itemsPage={10}
                filters={filters}
              />
            </>
          )}
    </div>
  );
};

export default ScLevelCriteria;
