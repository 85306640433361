import { BASE_URL } from '../constants/appConstants';
import { LevelCriteriaCreate } from '../type';
import { fetchWithToken } from '../utils/utils';

export const fetchLevelCriteria = async () => {
  try {
    const end_url = BASE_URL + "/level-criterias?page=0&size=0";
    const response = await fetchWithToken(end_url);
    if (!response.ok) {
      throw new Error('Failed to fetch level criteria');
    }
    const levelCriteria = await response.json();
    return levelCriteria.results;
  } catch (error) {
    throw new Error('Failed to fetch level criteria');
  }
};

export const createLevelCriteria = async (levelCriteriaData: any) => {
  try {
    const end_url = BASE_URL + "/level-criterias";
    const response = await fetchWithToken(end_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(levelCriteriaData),
    });
    const newLevelCriteria = await response.json();
    return newLevelCriteria;
  } catch (error) {
    throw new Error('Failed to create level criteria');
  }
};

export const updateLevelCriteria = async (levelCriteriaId: string, updatedData: LevelCriteriaCreate) => {
  try {
    const end_url = `${BASE_URL}/level-criterias/${levelCriteriaId}`;
    const response = await fetchWithToken(end_url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedData),
    });
    const updatedLevelCriteria = await response.json();
    return updatedLevelCriteria;
    
  } catch (error) {
    throw new Error('Failed to update level criteria');
  }
};
