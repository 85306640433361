import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { InputGroup, FormControl, Form } from "react-bootstrap";
import viewlistStyle from "../../styles/Consumer/Consumer.viewlist.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import viewIcon from "../../assets/blue-eye.png";
import editIcon from "../../assets/edit-icon.png";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/PaginationConsumer";
import chevronLeft from "../../assets/chevron-left.png";
import chevronRight from "../../assets/chevron-right.png";
import RegisterConsumer from "./RegisterConsumer";
import EditConsumer from "./EditConsumerForm";
import ConsumerInfor from "./ConsumerInfor";
import Button from "../../components/Button";
import SearchIcon from "../../assets/filter-serach.png";
import {
  getConsumers,
  searchConsumersAction,
  filterConsumers,
} from "../../services/consumerService";
import {
  setConsumers,
  setConsumersError,
  setConsumersLoading,
} from "../../features/consumerSlice";
import { getCityData } from "../../services/cityService";
import ConsumerSearch from "./ConsumerSearch";
import { CONSUMER_PER_PAGE } from "../../constants/appConstants";

interface Consumer {
  id: string;
  title: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  district: string;
  dateOfBirth: string;
  contactNumberPrimary: string;
  contactNumberSecondary?: string | null;
  nationalIdentityCardNumber: string;
  email: string;
  profileImageUrl: {
    id: string;
    url: string;
  };
  registrationSource: string;
}

const RegisteredConsumerListView: React.FC = () => {
  const dispatch = useDispatch<any>();
  const [selectedSource, setSelectedSource] = useState<string>("");
  const [selectedArea, setSelectedArea] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<boolean>(false);

  const [filteredConsumers, setFilteredConsumers] = useState<any[]>([]);
  const [enableFilter, setEnableFilter] = useState<boolean>(false);
  const [selectedConsumer, setSelectedConsumer] = useState<any>(null);
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [showEditForm, setshowEditForm] = useState(false);
  const [showConsumerInfor, setshowConsumerInfor] = useState(false);
  const loading = useSelector((state: any) => state.consumers.loading);
  const consumersCount = useSelector((state: any) => state.consumers.count);
  const cityData = useSelector((state: any) => state.locationData.cityData);
  const [nextPage, setNextPage] = useState<number>(0);

  useEffect(() => {
    dispatch(getConsumers(0, CONSUMER_PER_PAGE));
    dispatch(getCityData());
  }, []);

  useEffect(() => {
    dispatch(getConsumers(nextPage, CONSUMER_PER_PAGE));
  }, [nextPage]);

  const reloadData = () => {
    dispatch(getConsumers(0, CONSUMER_PER_PAGE));
  };

  const consumers = useSelector((state: any) => state.consumers.consumers);

  const handleSearchByNIC = (field: string, value: string) => {
    setSearchTerm(true);
    setEnableFilter(false);
    dispatch(searchConsumersAction(field, value));
  };

  // Function to reset filters and pagination
  const resetFiltersAndPagination = () => {
    setSelectedArea("");
    setSelectedSource("");
    setCurrentPage(1);
    setEnableFilter(false);
    setFilteredConsumers([]);
    setSearchTerm(false);
    reloadData();
  };

  // Function to handle refresh button click
  const handleRefreshClick = () => {
    resetFiltersAndPagination();
  };

  // Function to handle pagination
  const paginate = (pageNumber: number) => {
    const pageNext = pageNumber - 1;
    setNextPage(pageNext);
    setCurrentPage(pageNumber);
  };

  const filterConsumersBySourceArea = async () => {
    try {
      const filteredValues: any = {};
      if (selectedSource) filteredValues.source = selectedSource;
      if (selectedArea) filteredValues.area = selectedArea;
      setEnableFilter(true);
      setSearchTerm(false);
      const filteredResponse = await filterConsumers(filteredValues);
      setFilteredConsumers(filteredResponse);
    } catch (error) {
      console.error("Error filtering consumers:", error);
    }
  };

  // useEffect(() => {
  //   filterConsumersBySourceArea(); // Call filter service when source or area changes
  // }, [selectedSource, selectedArea]);

  // Logic to calculate pagination
  const indexOfLastConsumer: number = currentPage * CONSUMER_PER_PAGE;
  const indexOfFirstConsumer: number = indexOfLastConsumer - CONSUMER_PER_PAGE;

  const currentConsumers = enableFilter
    ? filteredConsumers.slice(indexOfFirstConsumer, indexOfLastConsumer)
    : searchTerm
    ? consumers.slice(indexOfFirstConsumer, indexOfLastConsumer)
    : consumers;

  // Handle filtering when source or area changes
  useEffect(() => {
    setCurrentPage(1); // Reset to first page when filtering
  }, [selectedSource, selectedArea]);

  // Function to toggle the display of the registration form
  const toggleRegisterForm = () => {
    setShowRegisterForm(!showRegisterForm);
    if (reloadData) {
      reloadData();
    }
  };

  // to display edit form
  const toggleEditForm = (consumer: any) => {
    setSelectedConsumer(consumer);
    setshowEditForm(!showEditForm);
  };

  const toggleConsumerInfor = (consumer: Consumer) => {
    setSelectedConsumer(consumer);
    setshowConsumerInfor(true);
  };

  const cancelConsumerInfor = () => {
    setSelectedConsumer(null);
    setshowConsumerInfor(false);
  };

  return (
    <div style={{ margin: "0 2vh" }}>
      {showRegisterForm ? (
        <RegisterConsumer closeRegistrationFrom={toggleRegisterForm} />
      ) : (
        <>
          {showEditForm ? (
            <EditConsumer
              consumer={selectedConsumer}
              setShowEditForm={setshowEditForm}
              profileImg={selectedConsumer.profileImg?.url}
            />
          ) : (
            <>
              {showConsumerInfor ? (
                <ConsumerInfor
                  onClose={() => cancelConsumerInfor()}
                  consumerData={selectedConsumer} // Pass the selected consumer data
                />
              ) : (
                <>
                  <div>
                    <div className={viewlistStyle.Container}>
                      <div style={{ marginTop: "-2rem" }}>
                        <Button
                          style={{
                            width: "180px",
                            backgroundColor: "black",
                            padding: "0.4rem",
                          }}
                          onClick={toggleRegisterForm}
                          children={"Register consumer"}
                        />
                      </div>
                      <div className={viewlistStyle.dropdowns}>
                        <select
                          className={viewlistStyle.SourceSelectoption}
                          value={selectedSource}
                          onChange={(e) => setSelectedSource(e.target.value)}
                        >
                          <option value="">Source</option>
                          <option value="website">Website</option>
                          <option value="mobile">Mobile</option>
                        </select>

                        <select
                          className={viewlistStyle.SourceSelectoption}
                          value={selectedArea}
                          onChange={(e) => setSelectedArea(e.target.value)}
                        >
                          <option value="">Area</option>
                          {cityData.map((districtData: any) => (
                            <option
                              key={districtData.district.id}
                              value={districtData.district.name}
                            >
                              {districtData.district.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          marginRight: "3vh",
                        }}
                      >
                        <img
                          src={SearchIcon}
                          style={{
                            borderRadius: "5px",
                            width: "34px",
                            marginLeft: "10px",
                            height: "35px",
                          }}
                          onClick={filterConsumersBySourceArea}
                        ></img>
                        <FontAwesomeIcon
                          icon={faSyncAlt}
                          onClick={handleRefreshClick}
                          className={viewlistStyle.refreshicon}
                        />
                      </div>

                      <div>
                        <ConsumerSearch
                          handleSearch={handleSearchByNIC}
                          reloadData={reloadData}
                        />
                      </div>
                    </div>

                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Consumer's Name</th>
                          <th style={{ width: "300px" }}>Address</th>
                          <th>Area</th>
                          <th>Source</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentConsumers.length > 0 &&
                          currentConsumers.map(
                            (consumer: any, index: number) => (
                              <tr key={index}>
                                <td>{`${consumer?.firstName} ${consumer?.lastName}`}</td>
                                <td
                                  style={{
                                    width: "300px",
                                    height: "50px",
                                    maxWidth: "400px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {consumer?.address}
                                </td>
                                <td>{consumer?.district}</td>
                                <td>{consumer?.registrationSource}</td>
                                <td>
                                  <img
                                    src={viewIcon}
                                    alt="View"
                                    onClick={() =>
                                      toggleConsumerInfor(consumer)
                                    } // Pass the consumer data to toggleConsumerInfor
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "10px",
                                      width: "2.5vh",
                                      height: "2.5vh",
                                    }}
                                  />

                                  <img
                                    src={editIcon}
                                    alt="View"
                                    onClick={() => toggleEditForm(consumer)}
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "10px",
                                      width: "2.5vh",
                                      height: "2.5vh",
                                    }}
                                  />
                                </td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </Table>
                    <div
                      style={{
                        marginLeft: "20px",
                        display: "flex",
                        alignItems: "center",
                        gap: "2",
                      }}
                    >
                      <p style={{ marginRight: "5px" }}>
                        {"Show " + currentConsumers &&
                          currentConsumers.length + " out of " + consumers &&
                          consumers.length + " results"}
                      </p>

                      <p>
                        <img
                          src={chevronLeft}
                          style={{
                            marginRight: "5px",
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      </p>
                      <p>
                        <Pagination
                          itemsPerPage={CONSUMER_PER_PAGE}
                          totalItems={consumersCount}
                          paginate={paginate}
                          currentPage={currentPage}
                        />
                      </p>

                      <p>
                        <img
                          src={chevronRight}
                          style={{
                            height: "10px",
                            width: "10px",
                            marginLeft: "-10px",
                          }}
                        />
                      </p>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default RegisteredConsumerListView;
