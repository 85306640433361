import React, { useState } from "react";
import "../../styles/Technician/TechnicianWorkView.scss";
import { Card, Modal, Image } from "react-bootstrap";
import Rating from "../../components/RatingStars";
import phone_icon from "../../assets/phone__icon(2).png";
import map_pin_icon from "../../assets/map-pin.png";
import breafcase from "../../assets/briefcase.png";
import clock from "../../assets/clock.png";
import dollar from "../../assets/dollar-sign.png";
import paperclip from "../../assets/paperclip.png";
import book_open from "../../assets/book-open.png";
import { BASE_URL_FOR_IMAGE } from "../../constants/appConstants";
import WorkRequestStatus from "../../components/WorkRequestStatus";
import { useDispatch, useSelector } from "react-redux";
import {
  updateWorkRequestRatingForConsumerService,
  updateWorkRequestStatusService,
} from "../../services/workRequestService";
import ImageGalleryCarousel from "../../components/ImageGalleryCarousel";
import ActionPopup from "../../components/ActionPopup";
import close_icon from "../../assets/close.png";
import { datMapping } from "../../utils/utils";
import { getWorkRequestsByTechnicianID } from "../../services/technicianService";
import Popup from "../../components/Popup";
import SpinnerLoading from "../../components/SpinnerLoading";
import pdfFile from "../../assets/pdf-file.png";
import xlsFile from "../../assets/xls-file.png";
import RateManipulator from "../../components/RateManipulator";

interface TechnicianWorkViewProps {
  job_type: string;
  rating: number;
  profile_name: string;
  profile_picture: string;
  contact_number: string;
  google_map_location: any;
  starting_date: string;
  technician_note: string;
  adress: string;
  budget: number;
  attachment: string;
}

interface TechProps {
  workRequest: any;
  onClose: () => void;
}

const TechnicianWorkView: React.FC<TechProps> = ({ workRequest, onClose }) => {
  // const workRequest = {
  //   id: "4af2c93d-e044-456f-9e4a-625531ec99e6",
  //   startingDate: "2024-03-27",
  //   budjet: 69000,
  //   workDescription:
  //     "Tiling Work in Sri Lanka. House Construction in Sri Lanka,bathroom finishing work , slab formwork and concreting , total building construction work",
  //   address: "No.63,Green Lane",
  //   district: "Kilinochchi",
  //   city: "Karachchi",
  //   workRequestImages: [
  //     {
  //       id: "4dd02bfb-2af7-4004-811d-15ab6c237c94",
  //       url: "6854b03e-0fe2-406d-a4e5-81d52174120b.webp",
  //     },
  //     {
  //       id: "511d66da-a8e6-4003-82fe-480734f4d8c6",
  //       url: "c0f1dc68-f93f-43ea-ad25-eefc2dd834ed.webp",
  //     },
  //     {
  //       id: "9897cd26-3d1c-4f0d-bf33-5d48c4b0a92b",
  //       url: "243c66c5-ccce-40a6-9712-95515466fe1a.webp",
  //     },
  //   ],
  //   jobTypes: [
  //     {
  //       id: "46c0504f-d97e-454d-a2dc-aacd37ccac80",
  //       type: "plumbing",
  //       description:
  //         "Plumbing is any system that conveys fluids for a wide range of applications.",
  //       status: 1,
  //     },
  //   ],
  //   status: "accepted",
  //   longitude: 80.74221616927962,
  //   latitude: 7.3268042417885,
  //   consumer: {
  //     id: "681228a4-4748-4030-833f-b6aeee675042",
  //     firstName: "Hakime",
  //     lastName: "Aslam",
  //     contactNumberPrimary: "+94702626968",
  //     profileImage: {
  //       id: "c1e2a724-2dd5-4e2e-89aa-32b4acee1bde",
  //       url: "5e578edb-8703-4a8a-8479-193a76ceebc1.webp",
  //     },
  //     address: "125/kandy",
  //   },
  //   assignedTechnician: {
  //     id: "d46593cc-c90f-4407-beaf-037177ba2c65",
  //     firstName: "Ramen",
  //     lastName: "Jayawicrama",
  //     address: "512/3 sampaya mawatha enderamulla wattala",
  //     city: "Dimbulagala",
  //     contactNumberPrimary: "+94707716890",
  //     profileImage: {
  //       url: "7a97be52-2827-489e-a576-45613cd42289.webp",
  //     },
  //   },
  //   isAssigned: true,
  // };

  const [showModal, setShowModal] = useState(false);
  const [showActionModal, setActionModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [modaltitle, setmodaltitle] = useState<string | null>(null);
  const [modalSubTitle, setModalSubTitle] = useState<string | null>(null);
  const [content, setContent] = useState<string | null>(null);
  const [modalAction, setModalSAction] = useState<string | null>(null);
  const [modalActionButton, setModalActionButton] = useState<string | null>(
    null
  );
  const errorInfo = useSelector((state: any) => state.workRequests.error);
  const successInfo = useSelector((state: any) => state.workRequests.success);
  const loading = useSelector((state: any) => state.workRequests.loading);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showDialogBox, setShowDialogBox] = useState<boolean>(false);
  const [showSccessMsg, setShowSuccessMessage] = useState(false);
  const [sccessMsg, setSccessMsg] = useState<string | null>(null);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [rating, setRating] = useState<number>(0);
  const errorConsumerRatingInfo = useSelector(
    (state: any) => state.consumers.error
  );
  const successConsumerRatingInfo = useSelector(
    (state: any) => state.consumers.success
  );
  const handleRatingChange = (newRating: number) => {
    setRating(newRating);
  };

  const handleOpenModal = (imageUrl: any) => {
    setSelectedImage(imageUrl);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setShowModal(false);
  };

  const workRequestLocation = `https://www.google.com/maps?q=${workRequest?.latitude},${workRequest?.longitude}`;
  const dispatch = useDispatch<any>();

  const changeWorkRequestStatus = (newStatus: string) => {
    setModalSAction(newStatus);
    const getContent = datMapping[newStatus];
    getContent && getContent.title && setmodaltitle(getContent.title);
    getContent && getContent.subtitle && setModalSubTitle(getContent.subtitle);
    if (getContent && getContent.content) {
      setContent(getContent.content);
    } else {
      setContent(null);
    }
    getContent &&
      getContent.actionButton &&
      setModalActionButton(getContent.actionButton);
    getContent && getContent.successMsg && setSccessMsg(getContent.successMsg);
    setActionModal(true);
  };

  const updateStatus = (action: any, reason?: string) => {
    const newAction = action === "resume" ? "accepted" : action;
    const technicianID = workRequest.assignedTechnician.id;
    const workRequestId = workRequest.id;
    const workRequestData = {
      status: newAction,
      reason: reason,
    };
    if (action === "on-hold-before") {
      setShowDialogBox(true);
      changeWorkRequestStatus("on-hold");
      setActionModal(false);
    } else if (action === "before-rejected") {
      setShowDialogBox(true);
      changeWorkRequestStatus("rejected");
      setActionModal(false);
    } else if (action === "rate_consumer") {
      const consumerID = workRequest.consumer.id;
      if (rating > 0 && reason !== "") {
        const ratingData = {
          rating: rating,
          comment: reason,
        };
        dispatch(
          updateWorkRequestRatingForConsumerService(
            consumerID,
            workRequestId,
            ratingData
          )
        );
        setShowDialogBox(false);
        setShowSuccessMessage(true);
        setShowErrorMessage(true);
        setShowPopup(true);
        setActionModal(false);
        setModalSAction("rate_consumer");
      }
    }
    // else if (action === "completed") {
    //   setShowDialogBox(true);
    //   changeWorkRequestStatus("rejected");
    //   setActionModal(false);
    //}
    else {
      dispatch(
        updateWorkRequestStatusService(
          technicianID,
          workRequestId,
          workRequestData
        )
      );
      setShowDialogBox(false);
      setShowSuccessMessage(true);
      setShowErrorMessage(true);
      setShowPopup(true);
      setActionModal(false);
    }
  };

  const renderAttachment = (image: any, index: number) => {
    const handleClick = () => {
      if (
        image.url.endsWith(".pdf") ||
        image.url.endsWith(".xls") ||
        image.url.endsWith(".xlsx")
      ) {
        window.open(BASE_URL_FOR_IMAGE + image.url, "_blank");
      } else {
        handleOpenModal(index);
      }
    };

    return (
      <>
        {image.url.endsWith(".pdf") ? (
          <a
            href={BASE_URL_FOR_IMAGE + image.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={pdfFile}
              alt={`PDF ${index + 1}`}
              style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
            />
          </a>
        ) : image.url.endsWith(".xls") || image.url.endsWith(".xlsx") ? (
          <a
            href={BASE_URL_FOR_IMAGE + image.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={xlsFile}
              alt={`Excel ${index + 1}`}
              style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
            />
          </a>
        ) : (
          <img
            src={BASE_URL_FOR_IMAGE + image.url}
            alt={`Image ${index + 1}`}
            style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
            onClick={handleClick}
          />
        )}
      </>
    );
  };

  if (loading) {
    return <SpinnerLoading />;
  }

  return (
    <div>
      {showSccessMsg && (successInfo || successConsumerRatingInfo) && (
      // {errorInfo && showErrorMessage && (
        <>
          <div className="blur-dark-background-overlay"></div>
          {modalAction === "completed" ? (
            <>
              <ActionPopup
                show={true}
                item={"rate"}
                inputItem={true}
                actionButton={"Submit"}
                actionMethod={(reason) => updateStatus("rate_consumer", reason)}
                displayDiv={
                  <div className="profile-layer">
                    <div
                      className="profile-info"
                      style={{ display: "flex", alignItems: "flex-start" }}
                    >
                      <div className="profile-img">
                        <img
                          src={
                            BASE_URL_FOR_IMAGE +
                            workRequest.consumer.profileImage.url
                          }
                          alt="Profile Picture"
                        />
                      </div>

                      <div className="profile-details">
                        <div className="profile-name-rate">
                          <div className="profile-name-rate-name">
                            {workRequest.consumer.firstName}{" "}
                            {workRequest.consumer.lastName}
                          </div>
                          <div style={{ fontSize: "10px" }}>
                            {workRequest &&
                              workRequest.jobTypes[0].type
                                .charAt(0)
                                .toUpperCase() +
                                workRequest.jobTypes[0].type.slice(1)}
                          </div>
                        </div>
                        <div className="profile-rating-rate">
                          <div>
                            <RateManipulator
                              onRatingChange={handleRatingChange}
                            />{" "}
                          </div>
                          {/* <div className="profile-rating-value">{4}</div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                }
                modaltitle={"Rate Consumer"}
                modalSubTitle={""}
                content={
                  "Congratulations! on finishing the work request now it’s time to give a feedback for your consumer."
                }
              ></ActionPopup>
            </>
          ) : modalAction === "rate_consumer" ? (
            <>
              <Popup
                show={showPopup}
                onHide={() => {
                  setShowPopup(false);
                  dispatch(
                    getWorkRequestsByTechnicianID(
                      workRequest.assignedTechnician.id
                    )
                  );
                  onClose();
                }}
                actionButton={false}
                item={"rate"}
                modaltitle={"Successful!"}
                content={"Your feedback has been successfully Submitted."}
              />
            </>
          ) : (
            <>
              <Popup
                show={showPopup}
                onHide={() => {
                  setShowPopup(false);
                  dispatch(
                    getWorkRequestsByTechnicianID(
                      workRequest.assignedTechnician.id
                    )
                  );
                  onClose();
                }}
                actionButton={false}
                item={"normal"}
                modaltitle={"Successful!"}
                content={sccessMsg}
              />
            </>
          )}
        </>
      )}

      {errorConsumerRatingInfo && showErrorMessage && (
        <>
          <div className="blur-dark-background-overlay"></div>
          <Popup
            show={showPopup}
            onHide={() => {
              setShowPopup(false);
              dispatch(
                getWorkRequestsByTechnicianID(
                  workRequest.assignedTechnician.id
                )
              );
              onClose();
            }}
            actionButton={false}
            modaltitle={"Error!"}
            content={errorConsumerRatingInfo}
          />
        </>
      )}
      {errorInfo && showErrorMessage && (
        <>
        <div className="blur-dark-background-overlay"></div>
        <Popup
          show={showPopup}
          onHide={() => {setShowPopup(false);
            onClose();}}
          actionButton={false}
          modaltitle={"Error!"}
          content={errorInfo}
        />
        </>
      )}
      <Card style={{ border: "none" }}>
        <div className="close-icon-container" onClick={onClose}>
          <img src={close_icon} alt="Close Icon" className="close-icon" />
        </div>
        <Card.Title
          style={{ border: "none", fontWeight: "bold", marginLeft: "2rem" }}
        >
          {workRequest &&
            workRequest.jobTypes[0].type.charAt(0).toUpperCase() +
              workRequest.jobTypes[0].type.slice(1)}
        </Card.Title>
        <Card.Body style={{ marginTop: "0" }}>
          <div className="details">
            <div className="profile-layer">
              <div
                className="profile-info"
                style={{ display: "flex", alignItems: "baseline" }}
              >
                <div className="profile-img">
                  <img
                    src={
                      BASE_URL_FOR_IMAGE + workRequest.consumer.profileImage.url
                    }
                    alt="Profile Picture"
                  />
                </div>

                <div className="profile-details">
                  <div className="profile-name">
                    <p>
                      {workRequest.consumer.firstName}{" "}
                      {workRequest.consumer.lastName}
                    </p>
                  </div>
                  <div className="profile-rating">
                    <div>
                      <Rating rating={workRequest.consumer.averageRating} />{" "}
                    </div>
                    <div className="profile-rating-value">{4}</div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: "0.2",
                }}
              >
                <WorkRequestStatus status={workRequest.status} />
              </div>
            </div>

            <div className="detail-layer-one">
              <div className="contact-no-info">
                <div className="phone-title">Contact Number</div>
                <div className="phone-icon">
                  <img src={phone_icon} alt="Phone Icon" />
                  {workRequest.consumer.contactNumberPrimary}
                </div>
              </div>
              <div className="address-info">
                <div className="address-title">Address</div>
                <div className="address-icon">
                  <img src={map_pin_icon} alt="address Icon" />
                  {workRequest.consumer.address}
                </div>
              </div>
            </div>

            <div className="detail-layer-two">
              <div className="location-info">
                <div className="location-title">Location</div>
                <div className="location-icon">
                  <img src={map_pin_icon} alt="Phone Icon" />
                  <a
                    href={workRequestLocation}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View on Google Maps
                  </a>
                </div>
              </div>
              <div className="type-work-info">
                <div className="type-work-title">Type of the work</div>
                <div className="type-work-icon">
                  <img src={breafcase} alt="type-work Icon" />
                  {workRequest.jobTypes[0].type}
                </div>
              </div>
            </div>

            <div className="detail-layer-three">
              <div className="start-date-info">
                <div className="start-date-title">Expected starting date</div>
                <div className="start-date-icon">
                  <img src={clock} alt="start-date Icon" />
                  {workRequest.startingDate}
                </div>
              </div>
              <div className="budget-info">
                <div className="budget-title">Expected budget</div>
                <div className="budget-icon">
                  {/* <img src={dollar} alt="budget Icon" /> */}
                  RS: {workRequest.budjet}
                </div>
              </div>
            </div>

            <div className="detail-layer-four">
              <div className="note-info">
                <div className="note-title">Notes for technician</div>
                <div className="note-icon" style={{ maxWidth: "350px" }}>
                  <img src={book_open} alt="note Icon" />
                  {workRequest.workDescription}
                </div>
              </div>
              {/* <div className="attachment-info">
                <div className="attachment-title">Attachments</div>
                <div
                  className="attachment-icon"
                  style={{ display: "flex", flexDirection: "row"}}
                >
                  <img src={paperclip} alt="attachment Icon" />
                  {workRequest?.workRequestImages.length > 0 ? (
                    workRequest?.workRequestImages?.map(
                      (image: any, index: any) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          {image.url.endsWith(".pdf") ? (
                            <a
                              href={BASE_URL_FOR_IMAGE + image.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={pdfFile}
                                alt={`PDF ${index + 1}`}
                                style={{
                                  width: "2rem",
                                  height: "2rem",
                                }}
                              />
                            </a>
                          ) : image.url.endsWith(".xls") ||
                            image.url.endsWith(".xlsx") ? (
                            <a
                              href={BASE_URL_FOR_IMAGE + image.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={xlsFile}
                                alt={`Excel ${index + 1}`}
                                style={{
                                  width: "2rem",
                                  height: "2rem",
                                }}
                              />
                            </a>
                          ) : (
                            <div style={{ cursor: "pointer" }}>
                              <Image
                                src={BASE_URL_FOR_IMAGE + image.url}
                                alt={`Image ${index + 1}`}
                                style={{
                                  width: "2rem",
                                  height: "2rem",
                                }}
                                onClick={() => handleOpenModal(index)}
                              />
                            </div>
                          )}
                        </div>
                      )
                    )
                  ) : (
                    <div>No attachments available..!</div>
                  )}
                </div>
              </div> */}
              <div className="attachment-info">
                <div className="attachment-title">Attachments</div>
                <div
                  className="attachment-icon"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <img src={paperclip} alt="attachment Icon" />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {workRequest?.workRequestImages.length > 0 ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          {workRequest?.workRequestImages.map(
                            (image: any, index: any) => (
                              <div
                                key={index}
                                style={{
                                  marginRight: "0.2rem",
                                  marginBottom: "0.2rem",
                                }}
                              >
                                {renderAttachment(image, index)}
                              </div>
                            )
                          )}
                        </div>
                      </>
                    ) : (
                      <div>No attachments available..!</div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="detail-layer-buttons">
              {workRequest.status === "accepted" && (
                <>
                  <div
                    className="hold-btn"
                    onClick={() => changeWorkRequestStatus("on-hold-before")}
                  >
                    <button>Hold</button>
                  </div>
                  <div
                    className="completed-btn"
                    onClick={() => changeWorkRequestStatus("completed")}
                  >
                    <button>Mark as Completed</button>
                  </div>
                </>
              )}
              {workRequest.status === "assigned" && (
                <>
                  <div
                    className="hold-btn"
                    onClick={() => changeWorkRequestStatus("before-rejected")}
                  >
                    <button>Reject</button>
                  </div>
                  <div
                    className="completed-btn"
                    onClick={() => changeWorkRequestStatus("accepted")}
                  >
                    <button>Accept</button>
                  </div>
                </>
              )}
              {workRequest.status === "on-hold" && (
                <>
                  <div className="hold-btn">
                    <button disabled>Hold</button>
                  </div>
                  <div
                    className="completed-btn"
                    onClick={() => changeWorkRequestStatus("resume")}
                  >
                    <button>Continue</button>
                  </div>
                </>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <h4>Attachments</h4>
        </Modal.Header>
        <Modal.Body>
          <ImageGalleryCarousel
            images={workRequest?.workRequestImages || []}
            selectedImage={selectedImage}
          />
        </Modal.Body>
      </Modal>
      {showActionModal && (
        <>
          <div className="blur-dark-background-overlay"></div>
          <div className="blur-dark-background" style={{ display: "block" }}>
            <ActionPopup
              show={showActionModal}
              onHide={() => setActionModal(false)}
              actionButton={modalActionButton}
              actionMethod={() => updateStatus(modalAction)}
              modaltitle={modaltitle}
              modalSubTitle={modalSubTitle}
              content={content}
            ></ActionPopup>
          </div>
        </>
      )}
      {showDialogBox && (
        <>
          <div className="blur-dark-background-overlay"></div>
          <div className="blur-dark-background" style={{ display: "block" }}>
            <ActionPopup
              show={showDialogBox}
              inputItem={true}
              actionButton={modalActionButton}
              actionMethod={(reason) => updateStatus(modalAction, reason)}
              modaltitle={modaltitle}
              modalSubTitle={modalSubTitle}
              content={content}
            ></ActionPopup>
          </div>
        </>
      )}
    </div>
  );
};

export default TechnicianWorkView;
