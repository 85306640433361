import React, { useState } from "react";
import styles from "../styles/Home.module.css";
import Layout from "../components/Layout";
import Header from "../components/Header";
import SideBar from "../components/SideBar";
import Main from "../components/Main";

const ViewProductPage: React.FC = () => {
  const [activeButton, setActiveButton] = useState<any>("view_product");

  return (
    <div className={styles.appContainer}>
      <Layout>
        <Header></Header>
        <SideBar
          activeButton={activeButton}
          setActiveButton={setActiveButton}
        ></SideBar>
        <Main activeButton={activeButton} children={activeButton} />
      </Layout>
    </div>
  );
};

export default ViewProductPage;
