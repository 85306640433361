import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConsumerReviewData } from "../type";
interface ConsumerReviewState {
    consumerReviews: ConsumerReviewData | null;
    loading: boolean;
    error: string | null;
}
const initialState: ConsumerReviewState = {
    consumerReviews: null,
    loading: false,
    error: null,
};
const consumerReviewSlice = createSlice({
    name: "consumerReview",
    initialState,
    reducers: {
        setConsumerReviewState(state) {
            state.loading = true;
            state.error = null;
        },
        setConsumerReviewSuccess(state, action: PayloadAction<ConsumerReviewData>) {
            state.loading = false;
            state.error = null;
            state.consumerReviews = action.payload;
        },
        setConsumerReviewFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
        },
    },
});
export const { setConsumerReviewState,
    setConsumerReviewSuccess,
    setConsumerReviewFailure
} = consumerReviewSlice.actions;
export default consumerReviewSlice.reducer;
