// Sidebar.js
import React, { useState } from "react";
import Layout from "./Layout";
import Header from "./Header";
import "../styles/Styles.scss";
import Main from "./Main";
import SideBar from './SideBar';

const Template = () => {
  const theme = "dark";
  const [activeButton, setActiveButton] = useState<any>("menu_dashboard");

  return (
    <Layout>
      <Header></Header>
      <SideBar activeButton={activeButton}  setActiveButton={setActiveButton}></SideBar>
      <Main activeButton={activeButton} children={activeButton}/>
    </Layout>
  );
};

export default Template;
