import { combineReducers } from '@reduxjs/toolkit';
import authSlice from '../features/authSlice';
import productTypeSlice from '../features/productTypeSlice';
import productsSlice from '../features/productSlice';
import jobTypeSlice from '../features/JobTypeSlice';
import techLevelSlice from '../features/techLevelSlice';
import levelCriteriaSlice from '../features/levelCriteriaSlice';
import tenantsSlice from '../features/tenantsSlice';
import productInquiriesSlice from '../features/productInquiriesSlice';
import consumerSlice from '../features/consumerSlice';
import workRequestSlice from '../features/workRequestSlice';
import technicianSlice from '../features/technicianSlice';
import workRequestCreateSlice from '../features/workRequestCreateSlice';
import locationDataSlice from '../features/citySlice';
import selectedTechnicanSlice from '../features/selectedTechnicanSlice';
import dashboardDataSlice from '../features/dashboardSlice';
import myItemsSlice from '../features/myItemsSlice';
import singleWorkRequestSlice from '../features/singleWorkRequestSlice';
import ForgotPasswordSlice from '../features/ForgotPasswordSlice';
import consumerReviewSlice from '../features/consumerReviewSlice';


const rootReducer = combineReducers({
    auth: authSlice,
    productTypes: productTypeSlice,
    products: productsSlice,
    jobTypes: jobTypeSlice,
    techLevels: techLevelSlice,
    levelCriteria: levelCriteriaSlice,
    tenants: tenantsSlice,
    productInquiries: productInquiriesSlice,
    consumers: consumerSlice,
    workRequests: workRequestSlice,
    technicians: technicianSlice,
    workRequestCreate: workRequestCreateSlice,
    locationData: locationDataSlice,
    selectedTechnican: selectedTechnicanSlice,
    dashboardData: dashboardDataSlice,
    myItems: myItemsSlice,
    singleworkRequest: singleWorkRequestSlice,
    forgotPassword: ForgotPasswordSlice,
    consumerReview:consumerReviewSlice
});

export default rootReducer;
