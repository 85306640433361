export default {
  menu_dashboard: "Dashboard",
  sum_menu_admin_mange: "Admin Settings > Admin Management",
  menu_application_setups: "Application Setups",
  menu_tenants: "Tenants",
  menu_technician: "Technicians",
  sum_menu_product_inq_list: "Product Inquiry List",
  sum_menu_workrequest: "Work Request List",
  menu_consumer: 'Consumers'
};
