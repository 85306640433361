import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "../../components/Button";
import "../../styles/EditJobTypeFrom.scss";
import { useDispatch, useSelector } from "react-redux";
import { LevelCriteriaEdit } from "../../type";
import { editLevelCriteria } from "../../services/levelCriteriaService";
import SpinnerLoading from "../../components/SpinnerLoading";
import Popup from "../../components/Popup";

interface Props {
  levelData: LevelCriteriaEdit; // Assume LevelData is the data structure for a level
  onCancel: () => void;
}
interface TechnicalJob {
  id: number;
  name: string;
}

const EditLevelCriteriaForm: React.FC<Props> = ({ levelData, onCancel }) => {
  const dispatch = useDispatch<any>();
  const [tenantObj, setTenantObj] = useState<any>(levelData.tanentID);
  const [tenant, setTenant] = useState<string>(levelData.tanent);
  const [outCome, setOutcome] = useState(levelData.expectedOutcome);
  const [wordCount, setWordCount] = useState(levelData.expectedOutcome.length);
  const [levelCriteria, setLevelCriteria] = useState(
    levelData.levelCriteria.toString()
  );
  const [levelObj, setLevelObj] = useState<any>(levelData.levelID);
  const [showSccessMsg, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [tenantError, setTenantError] = useState<string | null>(null);
  const [outComeError, setOutComeError] = useState<string | null>(null);
  const [levelCriteriaError, setLevelCriteriaError] = useState<string | null>(
    null
  );
  const [levelError, setLevelError] = useState<string | null>(null);
  const [isFormModified, setIsFormModified] = useState(false);
  const errorLevelsCriteria = useSelector(
    (state: any) => state.levelCriteria.error
  );
  const successLevelsCriteria = useSelector(
    (state: any) => state.levelCriteria.success
  );
  const loading = useSelector((state: any) => state.levelCriteria.loading);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  useEffect(() => {
    if (
      tenant !== levelData.tanent.name ||
      outCome !== levelData.expectedOutcome ||
      levelCriteria !== levelData.levelCriteria.toString() ||
      levelObj !== levelData.levelID
    ) {
      setIsFormModified(true);
    } else {
      setIsFormModified(false);
    }
  }, [tenant, outCome, levelCriteria, levelObj, levelData]);

  const tenantsBe = useSelector((state: any) => state.tenants.tenants);
  const techLevels = useSelector((state: any) => state.techLevels.techLevels);
  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const text = event.target.value;
    setOutcome(text);
    const count = text.length;
    setWordCount(count);
    setOutComeError(null);
    changeMessages();
    if (count > 300) {
      setOutComeError("Description not exceed 300 words");
    }
  };

  const changeMessages = () => {
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Perform validation
    let isValid = true;

    if (!levelObj) {
      setLevelError("Level is required");
      isValid = false;
    }
    if (!tenant) {
      setTenantError("Tenant is required");
      isValid = false;
    }
    if (!levelCriteria) {
      setLevelCriteriaError("Level Criteria is required");
      isValid = false;
    }
    if (!outCome) {
      setOutComeError("Expected Outcome is required");
      isValid = false;
    }

    if (outComeError) {
      setOutComeError("Description not exceed 300 words");
      isValid = false;
    }
    if (isValid) {
      const requestObj: any = {
        levelCriteria: levelCriteria,
        tanentId: tenantObj,
        levelId: levelObj,
        expectedOutcome: outCome,
      };
      dispatch(editLevelCriteria(levelData.id, requestObj));
      setShowSuccessMessage(true);
      setShowErrorMessage(true);
      setShowPopup(true);
    }
  };

  if (loading) {
    return <SpinnerLoading />;
  }

  return (
    <>
      {showSccessMsg && successLevelsCriteria && (
        <Popup
          show={showPopup}
          onHide={() => {
            setShowPopup(false);
            onCancel();
          }}
          actionButton={false}
          item={"success"}
          modaltitle={"Success!"}
          content={"Record Successfully Updated!"}
        />
      )}
      {errorLevelsCriteria && showErrorMessage && (
        <Popup
          show={showPopup}
          onHide={() => {
            setShowPopup(false);
            changeMessages();
          }}
          actionButton={false}
          modaltitle={"Error!"}
          content={errorLevelsCriteria}
        />
      )}
      <Form onSubmit={handleSubmit}>
        <div className="edit-job-type-title">Edit Level Criteria</div>
        <Form.Group controlId="tenant" className="mb-3 left-fields">
          <Form.Label className="left-type-label">Tenant</Form.Label>
          <Form.Select
            as="select"
            value={tenantObj}
            onChange={(e: any) => {
              setTenant(e.target.value);
              setTenantObj(e.target.value);
              setTenantError(null);
              changeMessages();
            }}
          >
            <option value="">{tenant}</option>
            {tenantsBe.map((tenant: any) => (
              <option key={tenant.id} value={tenant.id}>
                {tenant.name}
              </option>
            ))}
          </Form.Select>
          {tenantError && <p className="error-message">{tenantError}</p>}
        </Form.Group>
        <Form.Group controlId="levelCriteria" className="mb-3 right-fields">
          <Form.Label className="right-type-label">Level</Form.Label>
          <Form.Select
            as="select"
            value={levelObj}
            onChange={(e: any) => {
              setLevelObj(e.target.value);
              setLevelError(null);
              changeMessages();
            }}
          >
            {techLevels.map((level: any) => (
              <option key={level.id} value={level.id}>
                {level.name}
              </option>
            ))}
          </Form.Select>
          {levelError && <p className="error-message">{levelError}</p>}
        </Form.Group>
        <Form.Group controlId="outCome" className="mb-3 right-fields">
          <Form.Label className="left-type-label">Expected Outcome</Form.Label>
          <Form.Control
            as="textarea" // Use textarea for multiline input
            rows={4}
            placeholder="Enter job outCome here"
            value={outCome}
            onChange={handleDescriptionChange}
          />
          <p>{wordCount}/300</p>

          {outComeError && <p className="error-message">{outComeError}</p>}
        </Form.Group>
        <Form.Group controlId="level" className="mb-3 left-fields">
          <Form.Label className="right-type-label">Level Criteria</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter level criteria"
            value={levelCriteria}
            onChange={(e) => {
              setLevelCriteria(e.target.value);
              setLevelCriteriaError(null);
              changeMessages();
            }}
          />
          {levelCriteriaError && (
            <p className="error-message">{levelCriteriaError}</p>
          )}
        </Form.Group>
        <div className="button-container">
          <Button
            style={{
              width: "25vh",
              backgroundColor: "black",
              marginRight: "2vh",
            }}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            style={{ width: "25vh", backgroundColor: "#FFC600" }}
            type="submit"
            disabledButton={!isFormModified}
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};

export default EditLevelCriteriaForm;
