import React, { useEffect, useState } from "react";
import { Col, Row, Image, Dropdown, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import styles from "../../styles/EditWorkRequest.module.scss";
import Button from "../../components/Button";
import upload from "../../assets/upload.png";
import close from "../../assets/close.png";
import Rating from "../../components/RatingStars";
import { WorkRequestUpdate, workRequestType } from "../../type";
import { BASE_URL_FOR_IMAGE } from "../../constants/appConstants";
import useDummyImage from "../../assets/consumerImage.png";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  Libraries,
} from "@react-google-maps/api";
import { GOOGLE_MAP_API_KEY } from "../../constants/appConstants";
import { useDispatch, useSelector } from "react-redux";
import { updateWorkRequest } from "../../services/workRequestService";
import Popup from "../../components/Popup";
import pdfFile from "../../assets/pdf-file.png";
import xlsFile from "../../assets/xls-file.png";

const libraries: Libraries = ["places"];

interface Props {
  editWorkRequestData: any;
  onCancel: () => void;
  // onSaveSuccess: () => void;
}

interface Props {
  onCancel: () => void;
  editWorkRequestData: any;
}

interface FormErrors {
  date: string;
  budget: string;
  workDescription: string;
  address: string;
  district: string;
  city: string;
}

interface FormData {
  date: string;
  budget: number;
  workDescription: string;
  address: string;
  district: string;
  city: string;
  latitude: number;
  longitude: number;
  workRequestImageUrls: any;
}

interface State {
  showMapModal: boolean;
  formData: FormData;
  errors: FormErrors;
  isUpdateWorkRequestClicked: boolean;
}

const EditWorkRequest: React.FC<Props> = ({
  onCancel,
  editWorkRequestData,
}) => {
  const center = {
    lat: editWorkRequestData.latitude,
    lng: editWorkRequestData.longitude,
  };

  const dispatch = useDispatch<any>();

  const [showMap, setShowMap] = useState(false);
  const [markerPosition, setMarkerPosition] = useState({
    lat: editWorkRequestData.latitude || center.lat,
    lng: editWorkRequestData.longitude || center.lng,
  });

  useEffect(() => {
    setMarkerPosition({
      lat: editWorkRequestData.latitude || center.lat,
      lng: editWorkRequestData.longitude || center.lng,
    });
  }, [editWorkRequestData]);

  const initialFormData: FormData = {
    date: editWorkRequestData.startingDate || "",
    budget: editWorkRequestData.budjet || 0,
    workDescription: editWorkRequestData.workDescription || "",
    address: editWorkRequestData.address || "",
    district: editWorkRequestData.district || "",
    city: editWorkRequestData.city || "",
    latitude: editWorkRequestData.latitude || 0,
    longitude: editWorkRequestData.longitude || 0,
    workRequestImageUrls: editWorkRequestData.workRequestImages || "",
  };

  const initialErrors: FormErrors = {
    date: "",
    budget: "",
    workDescription: "",
    address: "",
    district: "",
    city: "",
  };

  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [errors, setErrors] = useState<FormErrors>(initialErrors);

  const [showSccessMsg, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showPopup, setShowPopup] = useState<boolean>(true);

  const [files, setFiles] = useState<File[]>([]);
  const [workImages, setWorkImages] = useState<any>([]);
  const [noGalleryImages, setNoGalleryImages] = useState<any>(10);

  const errorWorkRequest = useSelector(
    (state: any) => state.workRequests.error
  );
  const successWorkRequest = useSelector(
    (state: any) => state.workRequests.success
  );

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: e.target.type === "number" ? parseFloat(value) : value,
    }));

    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  useEffect(() => {
    const newFileList = createFileList(files);
    setWorkImages(newFileList);
  }, [files]);

  const createFileList = (files: File[]) => {
    const fileList = new DataTransfer();
    files.forEach((file) => fileList.items.add(file));
    return fileList.files;
  };

  const handleFileChange = (e: any) => {
    const fileList = e.target.files;

    // Calculate the remaining slots for files
    const remainingSlots = noGalleryImages - files.length;
    const filesWithBlob: File[] = [];

    for (const file of fileList) {
      if (filesWithBlob.length < remainingSlots) {
        const extension = file.name.split(".").pop()?.toLowerCase();
        filesWithBlob.push(new File([file], file.name, { type: file.type }));
      } else {
        setShowErrorMessage(true);
        setTimeout(() => setShowErrorMessage(false), 3000);
        return; 
      }
    }

    setFiles((prevFiles) => [...prevFiles, ...filesWithBlob]);
  };

  const handleRemoveImage = (indexToRemove: number) => {
    setFiles((prevSelectedFiles) =>
      prevSelectedFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleRemoveExistingImage = (imageUrlToRemove: string) => {
    // Filter out the image to be removed
    const updatedImages = formData.workRequestImageUrls.filter(
      (imageUrl: string) => imageUrl !== imageUrlToRemove
    );

    // Update the state with the updated image URLs
    setFormData((prevFormData) => ({
      ...prevFormData,
      workRequestImageUrls: updatedImages,
    }));
  };

  const validateForm = (forUpdateWorkRequest: boolean) => {
    const newErrors: { [key: string]: string } = {};

    // Starting Date validation logic
    if (!formData.date) {
      newErrors.date = "Please select a starting date.";
    }

    // Budget validation logic
    if (!formData.budget) {
      newErrors.budget = "Please enter the budget.";
    }

    // Work Description validation logic
    if (!formData.workDescription) {
      newErrors.workDescription = "Please enter the work description.";
    }

    // Address validation logic
    if (!formData.address) {
      newErrors.address = "Please enter the address.";
    }

    // District validation logic
    if (!formData.district) {
      newErrors.district = "Please select a district.";
    }

    // City validation logic
    if (!formData.city) {
      newErrors.city = "Please select a city.";
    }

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
      isUpdateWorkRequestClicked: forUpdateWorkRequest,
    }));

    return Object.keys(newErrors).length === 0;
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries,
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  const handleMapClick = (event: google.maps.MouseEvent) => {
    const clickedLat = event.latLng?.lat() as number;
    const clickedLng = event.latLng?.lng() as number;

    setMarkerPosition({
      lat: clickedLat,
      lng: clickedLng,
    });
  };

  const handlePinLocationClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setShowMap(true);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isValid = validateForm(true);

    if (isValid) {
      // Extracting url values from existing and newly uploaded images
      const existingUrls = formData.workRequestImageUrls.map(
        (image: any) => image.url
      );

      const requestObj: any = {
        startingDate: formData.date,
        budjet: formData.budget,
        workRequestImageUrls: existingUrls,
        newImages: [workImages],
      };

      {
        formData.workRequestImageUrls.length + files.length <=
          noGalleryImages &&
          dispatch(updateWorkRequest(editWorkRequestData.id, requestObj));
      }

      setShowSuccessMessage(true);
      setShowErrorMessage(true);
      setShowPopup(true);
    } else {
      console.log("Form contains errors. Please fix them.");
    }
  };

  return (
    <div className={styles.appContainer}>
      {showSccessMsg && successWorkRequest && (
        <Popup
          show={showPopup}
          onHide={() => {
            setShowPopup(false);
            onCancel();
          }}
          actionButton={false}
          item={"success"}
          modaltitle={"Success!"}
          content={"Record Successfully Updated!"}
        />
      )}
      {errorWorkRequest && showErrorMessage && (
        <Popup
          show={showPopup}
          onHide={() => setShowPopup(false)}
          actionButton={false}
          modaltitle={"Error!"}
          content={errorWorkRequest}
        />
      )}

      <div className={styles.appTitle}>Edit Work Request</div>

      <div className={styles.mainOuterSection}>
        <Modal
          centered
          show={showMap}
          onHide={() => setShowMap(false)}
          // style={{ width: "80%", maxWidth: "1200px" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Google Map</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ width: "100%", height: "400px" }}>
              {isLoaded && showMap && (
                <GoogleMap
                  mapContainerStyle={{ width: "100%", height: "100%" }}
                  zoom={10}
                  center={center}
                  // onClick={(event) => handleMapClick(event)}
                >
                  <Marker position={markerPosition} draggable={false} />
                </GoogleMap>
              )}
            </div>
          </Modal.Body>
        </Modal>

        <div className={styles.mainSection}>
          <Col className="d-flex align-items-center">
            <div className={styles.imageView}>
              <Image
                src={
                  editWorkRequestData.consumer &&
                  editWorkRequestData.consumer.profileImage.url
                    ? BASE_URL_FOR_IMAGE +
                      editWorkRequestData.consumer.profileImage.url
                    : useDummyImage
                }
                alt={editWorkRequestData.id}
                roundedCircle
                fluid
                className={`${styles.profilePicture}`}
                style={{
                  marginRight: "30px",
                  width: "5vh",
                  height: "5vh",
                }}
              />
              <div style={{ display: "flex", gap: "0.3rem" }}>
                <strong className="mr-2" style={{ fontSize: "1rem" }}>
                  {editWorkRequestData?.consumer.firstName}
                </strong>
                <strong className="ml-2 mr-2" style={{ fontSize: "1rem" }}>
                  {editWorkRequestData?.consumer.lastName}
                </strong>
                {editWorkRequestData.consumer.rating && (
                  <div className={styles.ratingSection}>
                    <Rating
                      rating={
                        editWorkRequestData.consumer.rating &&
                        editWorkRequestData.consumer.rating
                      }
                      size={15}
                    />
                    {editWorkRequestData.consumer.rating}
                  </div>
                )}
              </div>
            </div>
          </Col>

          <div className={styles.formSection}>
            <Form className={styles.form} onSubmit={handleSubmit}>
              <div className={styles.formDiv}>
                <div className={styles.left}>
                  <Form.Group as={Col} controlId="formPlaintextDate">
                    <Form.Label column className={styles.inputLable}>
                      Starting Date
                    </Form.Label>
                    <Col sm="12">
                      <div className={styles.inputField}>
                        <Form.Control
                          type="date"
                          placeholder="500,000.00"
                          className={styles.inputField}
                          value={formData.date}
                          onChange={(e) => handleChange(e)}
                          name="date"
                        />
                        {errors.date && (
                          <div className={styles.errorText}>{errors.date}</div>
                        )}
                      </div>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Col} controlId="formPlaintextBudget">
                    <Form.Label column className={styles.inputLable}>
                      Budget
                    </Form.Label>
                    <Col sm="12">
                      <div className={styles.inputField}>
                        <Form.Control
                          type="number"
                          placeholder="500,000.00"
                          className={styles.inputField}
                          value={formData.budget}
                          onChange={(e) => handleChange(e)}
                          name="budget"
                        />
                        {errors.budget && (
                          <div className={styles.errorText}>
                            {errors.budget}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Col} controlId="formPlaintextDescription">
                    <Form.Label column className={styles.inputLable}>
                      Work Description
                    </Form.Label>
                    <Col sm="12">
                      <div className={styles.inputField}>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Enter Work Description"
                          className={styles.inputField}
                          value={formData.workDescription}
                          onChange={(e) => handleChange(e)}
                          name="workDescription"
                          disabled
                        />
                        {errors.workDescription && (
                          <div className={styles.errorText}>
                            {errors.workDescription}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Col} controlId="formPlaintextAddress">
                    <Form.Label column className={styles.inputLable}>
                      Address
                    </Form.Label>
                    <Col sm="12">
                      <div className={styles.inputField}>
                        <Form.Control
                          type="text"
                          name="address"
                          value={formData.address}
                          onChange={handleChange}
                          placeholder="Enter Address"
                          className={styles.inputField}
                          disabled
                        />
                        {errors.address && (
                          <div className={styles.errorText}>
                            {errors.address}
                          </div>
                        )}
                      </div>
                    </Col>

                    <div className={styles.addressLine}>
                      <Col sm="6">
                        <div className={styles.inputField}>
                          <Form.Select
                            aria-label="District"
                            className={styles.inputField}
                            disabled
                          >
                            <option value={editWorkRequestData.district}>
                              {editWorkRequestData.district}
                            </option>
                            <option value="district1">District 1</option>
                            <option value="district2">District 2</option>
                          </Form.Select>
                          {errors.city && (
                            <div className={styles.errorText}>
                              {errors.district}
                            </div>
                          )}
                        </div>
                      </Col>

                      <Col sm="6">
                        <div className={styles.inputField}>
                          <Form.Select
                            aria-label="City"
                            className={styles.inputField}
                            disabled
                          >
                            <option value={editWorkRequestData.district}>
                              {editWorkRequestData.city}
                            </option>
                            <option value="city1">City 1</option>
                            <option value="city2">City 2</option>
                          </Form.Select>
                          {errors.city && (
                            <div className={styles.errorText}>
                              {errors.city}
                            </div>
                          )}
                        </div>
                      </Col>
                    </div>
                  </Form.Group>
                </div>

                <div className={styles.right}>
                  <Form.Group as={Col} controlId="formPlaintextFile">
                    <Form.Label column className={styles.inputLable}>
                      Attachments
                    </Form.Label>
                    <Col sm="12">
                      <div className={styles.fileInputContainer}>
                        <label
                          htmlFor="fileInput"
                          className={styles.customFileLabel}
                        >
                          <span className={styles.uploadIcon}>
                            <img src={upload} className={styles.inputIcon} />
                          </span>{" "}
                          Upload Attachments
                        </label>
                        <input
                          id="fileInput"
                          type="file"
                          multiple
                          className={styles.fileInput}
                          onChange={(e) => handleFileChange(e)}
                        />
                        {/* <p className={styles.fileTypes}>Docs, Pdf, Xls, Jpeg</p> */}
                      </div>
                    </Col>
                    <div style={{ marginTop: "1rem" }}>
                      {/* Render selected images within the limit */}
                      {(formData.workRequestImageUrls &&
                        formData.workRequestImageUrls.length > 0) ||
                      (files && files.length > 0) ? (
                        <>
                          {formData.workRequestImageUrls &&
                            formData.workRequestImageUrls.map(
                              (imageUrl: any, index: number) =>
                                index < noGalleryImages ? (
                                  <div
                                    key={index}
                                    className={styles.selectedImageContainer}
                                    style={{ marginRight: "10px" }}
                                  >
                                    {/* Render image or file icon */}
                                    {imageUrl.url.endsWith(".pdf") ? (
                                      <img
                                        src={pdfFile}
                                        alt={`PDF ${index + 1}`}
                                        style={{
                                          marginRight: "10px",
                                          width: "2rem",
                                          height: "2rem",
                                        }}
                                      />
                                    ) : imageUrl.url.endsWith(".xls") ||
                                      imageUrl.url.endsWith(".xlsx") ? (
                                      <img
                                        src={xlsFile}
                                        alt={`Excel ${index + 1}`}
                                        style={{
                                          marginRight: "10px",
                                          width: "2rem",
                                          height: "2rem",
                                        }}
                                      />
                                    ) : (
                                      <Image
                                        src={`${BASE_URL_FOR_IMAGE}${imageUrl.url}`}
                                        alt={`Image ${index + 1}`}
                                        style={{
                                          marginRight: "10px",
                                          width: "2rem",
                                          height: "2rem",
                                        }}
                                      />
                                    )}
                                    {/* Remove image icon */}
                                    <span
                                      className={styles.removeImageIcon}
                                      onClick={() =>
                                        handleRemoveExistingImage(imageUrl)
                                      }
                                    >
                                      &#x2715;
                                    </span>
                                  </div>
                                ) : null
                            )}
                          {files &&
                            files.map((file, index) => (
                              <div
                                key={index}
                                className={styles.selectedImageContainer}
                                style={{ marginRight: "10px" }}
                              >
                                {/* Render file icon */}
                                {file.name.endsWith(".pdf") ? (
                                  <img
                                    src={pdfFile}
                                    alt={`PDF ${index + 1}`}
                                    style={{
                                      marginRight: "10px",
                                      width: "2rem",
                                      height: "2rem",
                                    }}
                                  />
                                ) : file.name.endsWith(".xls") ||
                                  file.name.endsWith(".xlsx") ? (
                                  <img
                                    src={xlsFile}
                                    alt={`Excel ${index + 1}`}
                                    style={{
                                      marginRight: "10px",
                                      width: "2rem",
                                      height: "2rem",
                                    }}
                                  />
                                ) : (
                                  <Image
                                    src={URL.createObjectURL(file)}
                                    alt={`Selected File ${index + 1}`}
                                    style={{
                                      marginRight: "10px",
                                      width: "2rem",
                                      height: "2rem",
                                    }}
                                  />
                                )}
                                {/* Remove image icon */}
                                <span
                                  className={styles.removeImageIcon}
                                  onClick={() => handleRemoveImage(index)}
                                >
                                  &#x2715;
                                </span>
                              </div>
                            ))}
                        </>
                      ) : null}

                      {formData.workRequestImageUrls.length + files.length >
                        noGalleryImages && (
                        <div
                          className={styles.errorMessage}
                          style={{ marginTop: "0.5rem" }}
                        >
                          You can only upload maximum 10 images.
                        </div>
                      )}
                    </div>
                  </Form.Group>

                  <Col sm="12">
                    <Button
                      style={{
                        backgroundColor: "black",
                        color: "#ffffff",
                        width: "100%",
                        marginTop: "20px",
                        padding: "10px",
                        marginBottom: "10px",
                      }}
                      onClick={handlePinLocationClick}
                      children={"View Site Location"}
                      bIcon=""
                    />
                  </Col>

                  <Form.Group as={Col} controlId="formPlaintextFile">
                    <Form.Label column className={styles.inputLable}>
                      Job Types
                    </Form.Label>
                    <Col sm="6">
                      <div className={styles.jobTypesContainer}>
                        {editWorkRequestData?.jobTypes &&
                          editWorkRequestData.jobTypes.map(
                            (type: any, index: number) => (
                              <div
                                key={index}
                                className={styles.existingJobType}
                              >
                                <span>{type.type}</span>
                              </div>
                            )
                          )}
                      </div>
                    </Col>
                  </Form.Group>

                  <div className={styles.buttons}>
                    <Col sm="6">
                      <Button
                        style={{
                          backgroundColor: "black",
                          color: "#ffffff",
                          width: "95%",
                          marginTop: "10px",
                          padding: "10px",
                        }}
                        onClick={onCancel}
                        children={"Cancel"}
                        bIcon=""
                      />
                    </Col>
                    <Col sm="6">
                      <Button
                        style={{
                          backgroundColor: "#FFC600",
                          color: "#ffffff",
                          width: "95%",
                          marginTop: "10px",
                          padding: "10px",
                        }}
                        // onClick={() => validateForm(false)}
                        children={"Update"}
                        bIcon=""
                        type="submit"
                      />
                    </Col>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditWorkRequest;
