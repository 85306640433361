import { Dispatch } from "redux";
import {
  loginFailure,
  loginRequest,
  loginSuccess,
  logout,
} from "../features/authSlice";
import { Credentials } from "../type";
import { authSignIn } from "../api/authAPI";

export const LoginUser = (
  credentials: Credentials,
  setLoggedIn: (status: boolean) => void
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(loginRequest(true));
      const loginUser = await authSignIn(credentials);
      dispatch(loginRequest(false));
      if (loginUser.errorCode) {
        dispatch(loginFailure(loginUser.errorDescription));
      } else {
        dispatch(loginSuccess(loginUser));
        setLoggedIn(true);
      }
    
    } catch (error: any) {
      dispatch(loginFailure("Sorry, something went wrong. Please try again!"));
    }
  };
};

export const LogoutUser = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(logout());
    } catch (error: any) {
      console.error("logout failed", error);
    }
  };
};
