import React, { useEffect } from "react";
import TabView from "../../components/TabView";
import TechnicianGenInfo from "./TechnicianGenInfo";
import TechnicianWorkInfo from "./TechnicianWorkInfo";
import TechnicianRatingInfo from "./TechnicianRatingInfo";
import TechinicianIdQrInfo from "./TechinicianIdQrInfo";
import { useDispatch, useSelector } from "react-redux";
import { getTechniciansByID } from "../../services/technicianService";

const TechnicianInfo: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const dispatch = useDispatch<any>();

  const selectedTechnician = useSelector(
    (state: any) => state.selectedTechnican.singleTechnician
  );

  const reloadData = () => {
    dispatch(getTechniciansByID(selectedTechnician.id));
  };

  useEffect(() => {
    console.log("Selected Technician:", selectedTechnician);
  }, [selectedTechnician]);

  const tabs = [
    {
      eventKey: "gInfo",
      title: "General Information",
      component: (
        <TechnicianGenInfo onClose={onClose} reloadData={reloadData} />
      ),
    },
    {
      eventKey: "wInfo",
      title: "Work Information",
      component: <TechnicianWorkInfo />,
    },
    {
      eventKey: "rInfo",
      title: "Rating & Review",
      component: <TechnicianRatingInfo />,
    },
    {
      eventKey: "iqInfo",
      title: "ID & QR",
      component: <TechinicianIdQrInfo />,
    },
  ];

  return (
    <div>
      <TabView tabs={tabs} />
    </div>
  );
};

export default TechnicianInfo;
