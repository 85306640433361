import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";

interface TabProps {
  tabs: { eventKey: string; title: string; component: any }[];
}

const TabView: React.FC<TabProps> = ({ tabs }) => {
  const [activeKey, setActiveKey] = useState(tabs[0].eventKey);

  const tabStyle = {
    fontFamily: "Poppins",
  };

  const activeTabStyle = {
    ...tabStyle,
  };

  const handleSelect = (key: any) => {
    setActiveKey(key);
  };
  return (
    <Tabs
      activeKey={activeKey}
      onSelect={handleSelect}
      id="justify-tab"
      justify
      className="md-3"
      style={tabStyle}
    >
      {tabs.map((tab) => (
        <Tab
          eventKey={tab.eventKey}
          title={tab.title}
          key={tab.eventKey}
          style={activeKey === tab.eventKey ? activeTabStyle : tabStyle} // Apply active tab style conditionally
        >
          {tab.component}
        </Tab>
      ))}
    </Tabs>
  );
};

export default TabView;
