import "../styles/Styles.scss";
import locationMap from '../constants/locationMap';
import MainContainer from "../pages/MainContainer";

interface MainProps {
  children?: any;
  activeButton: keyof typeof locationMap;
}

const Main: React.FC<MainProps> = ({ children, activeButton }) => {
  const location = locationMap[activeButton] || '';
  return (
    <div className="main-container container-fluid">
      <MainContainer location={location} activeButton={activeButton} children={children} />
    </div>
  );
};

export default Main;
