import { BASE_URL } from "../constants/appConstants";
import { fetchWithToken, uploadImage, uploadImageMuliple } from "../utils/utils";

export const fetchTechnicians = async (page:number,items:number) => {
  try {
    const end_url = `${BASE_URL}/technicians?page=${page}&size=${items}&filter=all&filterValue=1`;
    const response = await fetchWithToken(end_url);
    if (!response.ok) {
      throw new Error("Failed to fetch technicians");
    }
    const technician = await response.json();
    return technician;
  } catch (error) {
    throw new Error("Failed to fetch technicians");
  }
};

export const fetchTechnicianByID = async (technicianID: string) => {
  try {
    const end_url = `${BASE_URL}/technicians/${technicianID}`;
    const response = await fetchWithToken(end_url);
    if (!response.ok) {
      throw new Error("Failed to fetch technicians");
    }
    const technician = await response.json();
    return technician;
  } catch (error) {
    throw new Error("Failed to fetch technicians");
  }
};

export const createTechnician = async (technicianData: any) => {
  try {
    var dataBody = [];
    const end_url = BASE_URL + "/technicians/registration";
    const imageUrl = await uploadImage(technicianData.nicFrontImageUrl);
    const imageUrlBack = await uploadImage(technicianData.nicBackImageUrl);
    const imageUrlProfile = await uploadImage(technicianData.profileImageUrl);

    const mulipleWorkImages =
      technicianData.workExperience &&
      technicianData.workExperience.imagesOfWork.length > 0
        ? true
        : false;

    if (mulipleWorkImages) {
      const workImagesUrls = await uploadImageMuliple(
        technicianData.workExperience.imagesOfWork
      );
      if (workImagesUrls && workImagesUrls.ok) {
        dataBody = await workImagesUrls.json();

        var workUrls = dataBody.urls;
      }
    }

    if (imageUrl && imageUrl.errorCode) {
      return imageUrl;
    } else {
      const updatedConsumerDetails = mulipleWorkImages
        ? {
            ...technicianData,
            nicFrontImageUrl: imageUrl.url,
            nicBackImageUrl: imageUrlBack.url,
            profileImageUrl: imageUrlProfile.url,
            workExperience: {
              ...technicianData.workExperience,
              imagesOfWork: workUrls,
            },
          }
        : {
            ...technicianData,
            nicFrontImageUrl: imageUrl.url,
            nicBackImageUrl: imageUrlBack.url,
            profileImageUrl: imageUrlProfile.url,
          };

      const response = await fetchWithToken(end_url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedConsumerDetails),
      });
      if (response.status === 201) {
        return true;
      } else {
        const newTechnician = await response.json();
        return newTechnician;
      }
    }
  } catch (error) {
    throw new Error("Failed to create technician");
  }
};


interface ImageObject {
  id: string;
  url: string;
}

export const updateTechnician = async (
  technicianId: string,
  updatedData: any
) => {
  try {

    var dataBody = [];
    var imageUrlProfile = null;
    const end_url = `${BASE_URL}/technicians/${technicianId}`;

    if (updatedData?.profileImage) {
      imageUrlProfile = await uploadImage(updatedData?.profileImage);
    }

    
    if (updatedData?.workExperienceData?.imagesOfWork.length > 0) {
      const workImagesUrls = await uploadImageMuliple(
        [updatedData.workExperienceData.imagesOfWork]
      );
      if (workImagesUrls && workImagesUrls.ok) {
        dataBody = await workImagesUrls.json();

        var workUrls = dataBody.urls;
      }
    }

    const savedImagesOfWork: ImageObject[] = updatedData?.workExperienceData?.savedImagesOfWork || [];
    const savedImageUrls: string[] = savedImagesOfWork.map((image: ImageObject) => image.url);    

    const updatedTechnicianDataFormated: any = {
      ...(imageUrlProfile && { profileImageUrl: imageUrlProfile.url }),
      address: updatedData?.address,
      district: updatedData?.district,
      city: updatedData?.city,
      numberOfWorkers: parseInt(updatedData?.noOfWorkers),
      numberOfAverageWorkers: parseInt(updatedData?.avgWorkNo),
      
      workExperience: {
        subWorkingAreas: updatedData?.workExperienceData?.subWorkingAreas,
        mainWorkingArea: updatedData?.workExperienceData?.mainWorkingArea,
        tanentIds: updatedData?.workExperienceData?.tanentIds,
        jobTypeExperiences: updatedData?.workExperienceData?.jobTypeExperiences,
        imagesOfWork: [
          ...(savedImageUrls || []), 
          ...(workUrls || []) 
        ],
      },
    };

    const response = await fetchWithToken(end_url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedTechnicianDataFormated),
    });

    if (response.status === 201 || response.status === 200) {
      return true;
    } else {
      const updatedTechnician = await response.json();
      return updatedTechnician;
    }
  } catch (error) {
    throw new Error("Failed to update technician");
  }
};

export const promoteTechnicianLevel = async (
  technicianId: string,
  tanentId: string,
  technicianLevelId: string
) => {
  try {
    const end_url = `${BASE_URL}/technicians/${technicianId}/promote/tanent/${tanentId}/level/${technicianLevelId}`;
    const response = await fetchWithToken(end_url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 201 || response.status === 200) {
      return true;
    } else {
      const promotedTechnician = await response.json();
      return promotedTechnician;
    }
  } catch (error) {
    throw new Error("Failed to promote technician");
  }
};

export const fetchTechnicianTenentLevels = async (technicianId: string) => {
  try {
    const end_url = `${BASE_URL}/technicians/${technicianId}/tanent-level-data`;
    const response = await fetchWithToken(end_url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const technicianTenentLevels = await response.json();
    return technicianTenentLevels;
  } catch (error) {
    throw new Error("Failed to get tenents and levels of technician");
  }
};

export const fetchWorkRequestsByTechnicianID = async (technicianId: string) => {
  try {
    const end_url = `${BASE_URL}/work-requests/technician/${technicianId}?page=0&size=0`;
    const response = await fetchWithToken(end_url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const workRequests = await response.json();
    return workRequests;
  } catch (error) {
    throw new Error("Failed to get work requests for the technician");
  }
}

export const fetchTechnicianByJobType = async (jobID: string) => {
  try {
    const end_url = `${BASE_URL}/technicians/job-types/${jobID}?page=0&size=0`;
    const response = await fetchWithToken(end_url);
    if (!response.ok) {
      throw new Error("Failed to fetch technicians by job id");
    }
    const technician = await response.json();
    return technician.results;
  } catch (error) {
    throw new Error("Failed to fetch technicians by job id");
  }
};

export const downgradeTechnician = async (
  technicianID: string,
  updatedData: any
) => {
  try {
    const end_url = `${BASE_URL}/rate-downgrades/technicians/${technicianID}`;
    const response = await fetchWithToken(end_url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedData),
    });

    if (response.status === 201 || response.status === 200) {
      return true;
    } else {
      const updatedTechnician = await response.json();
      return updatedTechnician;
    }
    
  } catch (error) {
    throw new Error("Failed to downgrade technician");
  }
};


export const fetchTechnicianRatingsReviews = async (technicianId: string) => {
  try{
    const end_url = `${BASE_URL}/review-entries/technician/${technicianId}?page=0&size=0`;
    const response = await fetchWithToken(end_url);
    if(!response.ok){
      throw new Error("Failed to fetch ratings and reviews");
    }
    const ratingsReviews = await response.json();
    return ratingsReviews;

  }catch(error){
    throw new Error("Failed to fetch ratings and reviews");
  }
}

export const serachTechnicianAPI = async (serachBy: string, searchValue: string) => {
  try{
    const end_url = `${BASE_URL}/technicians/search?searchBy=${serachBy}&searchValue=${searchValue}`;
    const response = await fetchWithToken(end_url);
    if(!response.ok){
      throw new Error("Failed to fetch ratings and reviews");
    }
    const searchtechnicians = await response.json();
    return searchtechnicians;

  }catch(error){
    throw new Error("Failed to search technicians");
  }
}

export const filterTechnicianAPI = async (filterValues: Record<string, any>) => {
  try {
    // Construct the base URL
    let end_url = `${BASE_URL}/technicians/advanced-filtered?page=0&size=0`;

    // Iterate over the filter values and append non-empty ones to the URL
    Object.entries(filterValues).forEach(([key, value]) => {
      if (value) {
        end_url += `&${key}=${encodeURIComponent(value)}`;
      }
    });

    const response = await fetchWithToken(end_url);
    if (!response.ok) {
      throw new Error("Failed to fetch ratings and reviews");
    }
    const filteredTechnicians = await response.json();
    return filteredTechnicians;
  } catch (error) {
    throw new Error("Failed to filter technicians");
  }
};

