import React from 'react';
import { FaStar } from 'react-icons/fa';

interface RatingProps {
  rating: number;
  size?: number;
}

const Rating: React.FC<RatingProps> = ({ rating, size = 20 }) => {
  const starIcons = Array(5)
    .fill('')
    .map((_, index) => (
      <FaStar
        key={index}
        size={size}
        color={index < rating ? 'yellow' : 'gray'}
        style={{ marginRight: 4 }}
      />
    ));
  return <div>{starIcons}</div>;
};

export default Rating;
