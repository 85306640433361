import React from "react";
import TabView from "../../components/TabView";
import ConsumerGeneralinfor from "./ConsumerGeneralinfor";
import ConsumerWorkInfor from "./ConsumerWorkInfor";

const ConsumerInfor: React.FC<{ onClose: () => void; consumerData: any }> = ({ onClose, consumerData }) => {
    const tabs = [
        { eventKey: "gInfo", title: "General Information", component: <ConsumerGeneralinfor onClose={onClose} consumerData={consumerData} /> },
        { eventKey: "wInfo", title: "Work Information", component: <ConsumerWorkInfor consumerData={consumerData} /> }
    ];

    return (
        <div>
            <TabView tabs={tabs} />
        </div>
    );
};

export default ConsumerInfor;