import React from 'react';
import styles from "../styles/Button.module.css";
import Buttons from 'react-bootstrap/Button';

interface ButtonProps {
    children: string;
    onClick?: (event: any) => void;
    style?: any;
    bIcon?: string;
    disabledButton?: boolean;
    type?: string;
  }

const Button: React.FC<ButtonProps> = ({ onClick, children, style, bIcon, disabledButton, type}) => {
  return (
    <button onClick={onClick} className={styles.customButton}  style={style} disabled={disabledButton}>
      {children}
      {bIcon? <img src={bIcon} alt="Button Icon" className={styles.buttonIcon} /> : ""}
    </button>
  );
}

export default Button;