import React from "react";
import { Modal } from "react-bootstrap";
import Button from "../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

interface SuccessModalProps {
    show: boolean;
    success: boolean;
    handleClose: () => void;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ show, success, handleClose }) => {


    const handlebackLogin = () => {
        handleClose();
    }


    return (
        <>
            <Modal show={show} onHide={handleClose} centered>
                <div style={{alignContent:'center'}}>
                    <Modal.Body>
                        {success ? (
                            <FontAwesomeIcon icon={faCheckCircle} style={{ color: "green", fontSize: "2rem" }} />
                        ) : (
                            <FontAwesomeIcon icon={faTimesCircle} style={{ color: "red", fontSize: "2rem" }} />
                        )}
                        <h4>{success ? "successful!" : "Something went wrong"}</h4>

                        <div style={{ color: '#777777', fontSize: '2vh' }}>
                            Your account password has been
                            Successfully Updated.</div>


                        <div className="justify-content-center">
                            <Button
                                style={{
                                    width: "30vh",
                                    backgroundColor: "#FFC600",
                                    padding: "0.7rem",
                                }}
                                onClick={handlebackLogin}
                                children={"Login to My Account"}
                            />
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
        </>
    );
};

export default SuccessModal;
