import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import ExampleCarouselImage from "../assets/Groupuser.png";
import { BASE_URL_FOR_IMAGE } from "../constants/appConstants";

const ImageGalleryCarousel: React.FC<any> = ({ images, selectedImage }) => {
  const [viewImage, setViewImage] = useState<number>(0); // Initialize viewImage to 0

  const imageStyle = {
    width: "400px",
    height: "400px",
  };
  
  const selectedIndex = selectedImage ? images.findIndex((image:any, index:number) => index === selectedImage) : -1;
  
  return (
    <div>
      <Carousel>
        {selectedIndex !== -1 && (
          <Carousel.Item>
            <img className="d-block w-100" src={BASE_URL_FOR_IMAGE + images[selectedIndex].url} style={imageStyle} />
          </Carousel.Item>
        )}
        {images.map((image: any, index: number) => {
          if (selectedIndex !== -1 && index === selectedIndex) return null;
          return (
            <Carousel.Item key={index}>
              <img
                className="d-block w-100"
                src={BASE_URL_FOR_IMAGE + image.url}
                style={imageStyle}
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
};

export default ImageGalleryCarousel;
