import React, { useEffect, useState } from "react";
import editIcon from "../../assets/edit-icon.png";
import TableView from "../../components/TableView";
import CreateLevelForm from "./CreateLevelForm";
import EditLevelsForm from "./EditLevelsForm";
import { useDispatch, useSelector } from "react-redux";
import { getTechLevels } from "../../services/techLevelService";
import { JobTypeCreate } from "../../type";

const iconStyle: React.CSSProperties = {
  width: "2.5vh",
  height: "2.5vh",
  marginRight: "1.5vh",
};

interface ScLevelsProps {
}

const filters = [
  { label: "Level", key: "name" },
];

const ScLevels: React.FC<ScLevelsProps> = () => {
  const dispatch = useDispatch<any>();
  const [showCreateForm, setShowCreateForm] = useState<boolean>(false);
  const [showEditForm, setShowEditForm] = useState<boolean>(false);
  const [selectedLevelData, setSelectedLevelData] = useState<any>(null);

  useEffect(() => {
    reloadLevels();
  }, [dispatch]);
  
  const reloadLevels = () => {
    dispatch(getTechLevels());
  };

  const techLevelsBe = useSelector(
    (state: any) => state.techLevels.techLevels
  );
  
  const openCreatejobs = () => {
    setShowCreateForm(true);
  };
  const closeCreateJobForm = () => {
    setShowCreateForm(false);
    reloadLevels();
  };
  const handleSaveSuccess = () => {
    closeCreateJobForm();
  };
  
  const onClickView = () => {
    setShowEditForm(true);
  };

  const openEditJobForm = (jobTypeData: JobTypeCreate) => {
    setSelectedLevelData(jobTypeData);
    setShowEditForm(true);
  };

  const closeEditJobForm = () => {
    setSelectedLevelData(null);
    setShowEditForm(false);
    reloadLevels();
  };

  const iconStyle: React.CSSProperties = {
    width: "2.5vh",
    height: "2.5vh",
    marginLeft: "5vh",
  };

  return (
    <div style={{ margin: "0 2vh" }}>
      {showCreateForm ? (
        <CreateLevelForm onCancel={closeCreateJobForm} />
      ) : showEditForm ? (
        <EditLevelsForm levelData={selectedLevelData} onCancel={closeEditJobForm} />
      ) : (
        <>
          <TableView
            data={techLevelsBe}
            columns={["name", "rank", "description", "jobTypes"]}
            columnLabels={["Technician Level", "Level Rank", "Description", "Technician Job Type"]}
            buttonName={"Create Level"}
            buttonAction={openCreatejobs}
            viewAction={<img src={editIcon} style={iconStyle}/>}
            viewActionMethod={openEditJobForm}
            reloadData={reloadLevels}
            itemsPage={10}
            filters={filters}
          />
        </>
      )}
    </div>
  );
};

export default ScLevels;
