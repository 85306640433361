import React from "react";

interface ColorSelectionProps {
  colors: string[];
  //onChange: (quantity: number) => void;
}

const ColorSelection : React.FC<ColorSelectionProps> = ({ colors }) => {
  return (
    <div className="color-selection">
      {colors.map((color, index) => (
        <div
          key={index}
          className="color-box"
          style={{ backgroundColor: color }}
        />
      ))}
    </div>
  );
};

export default ColorSelection;
