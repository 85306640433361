
import { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Button from "../../components/Button";
import ProfilePhoto from "../../components/profilePhoto";
import formStyle from "../../styles/Consumer/consumerForm.module.css";
import { getCityData } from "../../services/cityService";
import { useDispatch, useSelector } from "react-redux";
import { updateConsumer } from "../../services/consumerService";
import Popup from "../../components/Popup";
import { useNavigate } from 'react-router-dom'

interface FormData {
  profileImage: {
    id: string;
    url: string;
  };
  title: string;
  firstName: string;
  lastName: string;
  address: string;
  district: string;
  city: string;
  email: string;
}

interface EditConsumerProps {
  consumer: any;
  setShowEditForm: React.Dispatch<React.SetStateAction<boolean>>;
  profileImg: string;
}


interface ProfilePhotoProps {
  setPhotoSelectedProp: (value: boolean) => void;
  defaultPhoto?: string;
}


const EditConsumer: React.FC<EditConsumerProps> = ({ consumer, setShowEditForm, profileImg }) => {

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [photoSelected, setPhotoSelected] = useState(false);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [showViewList, setshowViewList] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    // Initialize form fields with consumer data
    profileImage: {
      id: consumer.profileImage.id,
      url: consumer.profileImage.url
    },
    title: consumer.title,
    firstName: consumer.firstName,
    lastName: consumer.lastName,
    address: consumer.address,
    district: consumer.district,
    city: consumer.city,
    email: consumer.email,
  });


  const cityData = useSelector((state: any) => state.locationData.cityData);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [selectedPhotoUrl, setSelectedPhotoUrl] = useState<string | null>(null);
  const [errors, setErrors] = useState<Partial<FormData>>({});
  const [showSccessMsg, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const errorConsumer = useSelector((state: any) => state.consumers.error);
  const successConsumer = useSelector((state: any) => state.consumers.success);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    const newErrors = { ...errors };

    // Clear error for the edited field if it exists
    if (name in errors) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
    }

    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors(newErrors);
    setIsUpdated(true); // Set isUpdated to true when any field is updated
  };

  useEffect(() => {
    dispatch(getCityData());
  }, [dispatch]);

  //validation
  const validateForm = () => {

    const newErrors: Partial<FormData> = {};

    // Address
    if (!formData.address) {
      newErrors.address = "Please enter your address.";
    }


    // District
    if (!formData.district) {
      newErrors.district = "Please select your district.";
    }

    // City
    if (!formData.city) {
      newErrors.city = "Please select your city.";
    }


    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isValid = validateForm();
    var profilePicChanged = false;
    if (isValid) {
      const requestObj: any = {
        title: formData.title,
        address: formData.address,
        city: formData.city,
        district: formData.district,
      };


      if (photoSelected && selectedPhotoUrl) {
        // If a new photo is selected, use the selected photo URL
        requestObj.profileImageUrl = selectedPhotoUrl;
        profilePicChanged = true;
      } else {
        // If no new photo is selected, use the existing profile pic URL from consumer data
        requestObj.profileImageUrl = formData.profileImage.url;
      }

      // Call the updateConsumer service method to update consumer details
      await dispatch(updateConsumer(consumer.id, requestObj, profilePicChanged));

      setShowSuccessMessage(true);
      setShowErrorMessage(true);
      setShowPopup(true);
    } else {
      console.log("Form submission failed due to errors:", errors);
    }
  };


  useEffect(() => {
    // Update form fields with consumer data when component mounts
    setFormData({
      profileImage: {
        id: consumer.profileImage.id,
        url: consumer.profileImage.url,
      },
      title: consumer.title,
      firstName: consumer.firstName,
      lastName: consumer.lastName,
      address: consumer.address,
      district: consumer.district,
      city: consumer.city,
      email: consumer.email,
    });
  }, [consumer]);



  const handlePhotoSelection = (value: boolean) => {
    setPhotoSelected(value);
  };

  const setSelectedPhotoUrlProp = (url: string) => {
    setSelectedPhotoUrl(url);
    setIsUpdated(true); // Set isUpdated to true when a new photo is selected
  };


  const handleButtonClick = () => {
    navigate("/consumer");
  }

  return (

    <div>
      {showSccessMsg && successConsumer && (
        <Popup
          show={showPopup}
          onHide={() => {
            setShowPopup(false);
            setShowEditForm(false);
          }}
          actionButton={false}
          modaltitle={"Success!"}
          content={"Record Successfully Updated!"}
          item={'success'}

        />
      )}
      {errorConsumer && showErrorMessage && (
        <Popup
          show={showPopup}
          onHide={() => {
            setErrors({});
            setShowPopup(false)
          }}
          actionButton={false}
          modaltitle={"Error!"}
          content={errorConsumer}

        />
      )}

      <div className={formStyle.pageTitle}>Edit Consumer Details</div>
      <br />
      <Row>
        <Col>
          <div>
            <ProfilePhoto
              setPhotoSelectedProp={handlePhotoSelection}
              setSelectedPhotoUrlProp={setSelectedPhotoUrlProp}
              imgUrl={formData.profileImage.url}
            />
          </div>

        </Col>
        <Col className={formStyle.column}>
          <div>
            <Form
              className={formStyle.customFormWidth}>
              <Row>
                <Col>
                  <Form.Group controlId="formTitle">
                    <Form.Label>Title</Form.Label>
                    <Form.Select
                      className="form-select"
                      name="title"
                      value={formData.title}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Title</option>
                      {["Miss", "Mrs", "Ms", "Mr"].map((title) => (
                        <option key={title} value={title}>{title}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <br></br>

              <Row>
                <Col>
                  <Form.Group controlId="formfirstName">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      placeholder="First Name"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleInputChange}
                      disabled
                    >
                    </Form.Control>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group controlId="formLastName">
                    <Form.Label>&nbsp;</Form.Label>
                    <Form.Control placeholder="Last Name"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleInputChange}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <br></br>

              <Row className={formStyle.addresslabel}>
                <Col>
                  <Form.Group controlId="formAddress">
                    <Form.Label >Address</Form.Label>
                    <Form.Control placeholder="Enter Address"
                      name="address"
                      value={formData.address}
                      onChange={handleInputChange}
                    />
                    {errors.address && <span className={formStyle.inputerrorMessage}>{errors.address}</span>}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="formCity">
                    <Form.Select
                      className="form-select"
                      name="district"
                      value={formData.district}
                      onChange={handleInputChange}
                    >
                      <option value="">Select District</option>
                      {cityData.map((districtData: any) => (
                        <option
                          key={districtData.district.id}
                          value={districtData.district.name}
                        >
                          {districtData.district.name}
                        </option>
                      ))}
                    </Form.Select>
                    {errors.district && <span className={formStyle.inputerrorMessage}>{errors.district}</span>}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formDistrict">
                    <Form.Select
                      className="form-select"
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                    >
                      <option value="">Select City</option>
                      {formData.district &&
                        cityData
                          .find(
                            (districtData: any) =>
                              districtData.district.name === formData.district
                          )
                          ?.district.cities.map((city: any) => (
                            <option key={city.id} value={city.name}>
                              {city.name}
                            </option>
                          ))}
                    </Form.Select>
                    {errors.city && <span className={formStyle.inputerrorMessage}>{errors.city}</span>}
                  </Form.Group>
                </Col>
              </Row>
              <br></br>

              <Row>
                <Col>
                  <Form.Group controlId="formEmail">
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control
                      placeholder="Enter your email Address"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      disabled
                    />

                  </Form.Group>
                </Col>

                <br />
                <Col>
                  <div className={formStyle.buttonContainer}>
                    <Button
                      style={{
                        width: "25vh",
                        backgroundColor: "black",
                        marginTop: "4vh",
                        padding: "0.7rem",
                        marginRight: "2vh",
                      }}
                      onClick={handleButtonClick}
                      children={"Cancel"}
                    />

                    <Button
                      style={{
                        width: "25vh",
                        backgroundColor: "#FFC600",
                        marginTop: "4vh",
                        padding: "0.7rem",
                      }}

                      onClick={handleSubmit}
                      children={"Update"}
                      disabledButton={!isUpdated} // Disable the button if no field is updated
                    />
                  </div>
                </Col>

              </Row>
              <br></br>



            </Form>
          </div>
        </Col>
      </Row>

    </div>
  );
};

export default EditConsumer;