import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LevelCriteria } from "../type";

interface LevelCriteriaState {
  levelCriteria: LevelCriteria[];
  loading: boolean;
  error: string | null;
  success: boolean;
}

const initialState: LevelCriteriaState = {
  levelCriteria: [],
  loading: false,
  error: null,
  success: false,
};

const levelCriteriaSlice = createSlice({
  name: "levelCriteria",
  initialState,
  reducers: {
    setLevelCriteria(state, action: PayloadAction<LevelCriteria[]>) {
      state.levelCriteria = action.payload;
      state.loading = false;
      state.success = false;
      state.error = null;
    },
    setLevelCriteriaLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
      state.error = null;
      state.success = false;
    },
    setLevelCriteriaError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
    addLevelCriteriaState(state, action: PayloadAction<LevelCriteria>) {
      state.levelCriteria.push(action.payload);
      state.loading = false;
      state.error = null;
      state.success = true;
    },
    editLevelCriteriaState(state, action: PayloadAction<{ id: string; updatedLevelCriteria: LevelCriteria }>) {
      const { id, updatedLevelCriteria } = action.payload;
      const index = state.levelCriteria.findIndex(criteria => criteria.id === id);
      if (index !== -1) {
        state.levelCriteria[index] = updatedLevelCriteria;
        state.loading = false;
        state.error = null;
        state.success = true;
      }
    },
  },
});

export const {
  setLevelCriteria,
  setLevelCriteriaLoading,
  setLevelCriteriaError,
  addLevelCriteriaState,
  editLevelCriteriaState,
} = levelCriteriaSlice.actions;

export default levelCriteriaSlice.reducer;
