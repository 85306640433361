const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (error) {
    return null;
  }
};

const AuthVerify = () => {
  const token = localStorage.getItem("solidClubAccessToken") || "";
  
  if (token) {
    const decodedJwt = parseJwt(token);

    if (decodedJwt && decodedJwt.exp * 1000 < Date.now()) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export default AuthVerify;
