import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "../../components/Button";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../styles/CreateJobTypeForm.scss";
import { useDispatch, useSelector } from "react-redux";
import { setTechLevelsLoading } from "../../features/techLevelSlice";
import { addTechLevel } from "../../services/techLevelService";
import SpinnerLoading from "../../components/SpinnerLoading";
import Popup from "../../components/Popup";

interface Props {
  onCancel: () => void;
}

interface FormErrors {
  name: string;
  description: string;
  rank: string;
  tJobsType: string;
}

interface TechnicalJob {
  id: number;
  name: string;
}

const CreateLevelForm: React.FC<Props> = ({ onCancel }) => {
  const dispatch = useDispatch<any>();
  const [name, setTechnicalLevel] = useState("");
  const [rank, setrank] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [selectedJobType, setSelectedJobType] = useState<string[]>([]);
  const [description, setDescription] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [showSccessMsg, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [nameError, setTechnicalLevelError] = useState<string | null>(null);
  const [descriptionError, setDescriptionError] = useState<string | null>(null);
  const [tJobsTypeError, setTJobsTypeError] = useState<string | null>(null);
  const [rankError, setrankError] = useState<string | null>(null);
  const [showMinus, setShowMinus] = useState(true);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const errorlevel = useSelector((state: any) => state.techLevels.error);
  const successlevel = useSelector((state: any) => state.techLevels.success);
  const loading = useSelector((state: any) => state.techLevels.loading);
  const [errors, setErrors] = useState<FormErrors>({
    name: "",
    description: "",
    rank: "",
    tJobsType: "",
  });
  interface JobTypes {
    id: number;
    name: string;
  }

  // const jobTypesBe = useSelector((state: any) => state.jobTypes.jobTypes);
  const jobTypesBe = useSelector((state: any) =>
    state.jobTypes.jobTypes.filter((jobType: any) => jobType.status === 1)
  );

  const [numJobTypeDropdowns, setNumJobTypeDropdowns] = useState(1);

  useEffect(() => {
    const fieldsToValidate: Array<keyof FormErrors> = [
      "name",
      "description",
      "rank",
      "tJobsType",
    ];

    fieldsToValidate.forEach((field: keyof FormErrors) => {
      const value = eval(field); // Access value dynamically
      validateField(field, value);
    });
  }, [name, description, selectedJobType]);

  const addJobTypeDropdown = () => {
    setShowMinus(true);
    setNumJobTypeDropdowns(numJobTypeDropdowns + 1);
    setSelectedJobType((prevSelected) => [...prevSelected, ""]);
  };
  const deleteJobTypeDropdown = (indexToDelete: number) => {
    if (numJobTypeDropdowns !== 1) {
      setShowMinus(numJobTypeDropdowns > 1);
      setNumJobTypeDropdowns(numJobTypeDropdowns - 1);
      setSelectedJobType((prevSelected) => {
        const updatedSelected = [...prevSelected];
        updatedSelected.splice(indexToDelete, 1);
        return updatedSelected;
      });
    }
  };

  const clearInputs = () => {
    if (errorlevel || successlevel) {
      dispatch(setTechLevelsLoading(true));
      setTechnicalLevelError(null);
      setDescriptionError(null);
      setTJobsTypeError(null);
      setrankError(null);
    }
  };

  useEffect(() => {
    clearInputs();
  }, []);

  const changeMessages = () => {
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
    setTechnicalLevelError(null);
    setDescriptionError(null);
    setTJobsTypeError(null);
    setrankError(null);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const text = event.target.value;
    setDescription(text);
    const count = text.length;
    setWordCount(count);
    setDescriptionError(null);
    changeMessages();
    if (count > 300) {
      setDescriptionError("description not exceed 300 words.");
    }
  };

  const validateField = (fieldName: string, value: string) => {
    let errors: any = {};
    if (!name) {
      errors.name = "Level Name is required";
    }
    if (!description || descriptionError) {
      errors.description = "Description is required";
    }
    if (!rank) {
      errors.rank = "Level Rank is required";
    }

    if (selectedJobType.length === 0) {
      errors.productType = "Job type is required";
    }
    setIsFormValid(Object.keys(errors).length === 0);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!name) {
      setTechnicalLevelError("Level Name is required");
    }
    if (!description) {
      setDescriptionError("Description is required");
    }
    if (selectedJobType.length === 0) {
      setTJobsTypeError("Job type is required");
    }
    if (!rank) {
      setrankError("Level Rank is required");
    }

    if (isFormValid) {
      console.log("Form submitted with data:", {
        name,
        description,
        selectedJobType,
        rank,
      });
      const requestObj: any = {
        name: name,
        description: description,
        jobTypes: selectedJobType,
        rank: parseInt(rank),
      };

      dispatch(addTechLevel(requestObj));
      setShowSuccessMessage(true); // Show success message
      setShowErrorMessage(true);
      setShowPopup(true);

      // Reset all error states
      setTechnicalLevelError(null);
      setDescriptionError(null);
      setTJobsTypeError(null);
      setrankError(null);
    }
  };

  return (
    <>
      {showSccessMsg && successlevel && (
        <Popup
          show={showPopup}
          onHide={() => {
            setShowPopup(false);
            onCancel();
          }}
          actionButton={false}
          item={"success"}
          modaltitle={"Success!"}
          content={"Record Successfully Created!"}
        />
      )}
      {errorlevel && showErrorMessage && (
        <Popup
          show={showPopup}
          onHide={() => {
            setShowPopup(false);
            changeMessages();
          }}
          actionButton={false}
          modaltitle={"Error!"}
          content={errorlevel}
        />
      )}
      <Form onSubmit={handleSubmit}>
        <div className="create-job-type-title">Create Technician Level</div>
        <Form.Group controlId="name" className="mb-3 left-fields">
          <Form.Label className="left-type-label">Level Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter technical level"
            value={name}
            onChange={(e) => {
              setTechnicalLevel(e.target.value);
              setTechnicalLevelError(null);
              changeMessages();
            }}
          />
          {nameError && <p className="error-message">{nameError}</p>}
        </Form.Group>

        <Form.Group controlId="rank" className="mb-3 right-fields">
          <Form.Label className="right-type-label">Level Rank</Form.Label>
          <Form.Select
            as="select"
            value={rank}
            className="form-control"
            onChange={(e) => {
              setrank(e.target.value);
              setrankError(null);
              changeMessages();
            }}
          >
            <option value="">Select Level Rank</option>
            {[...Array(10).keys()].map((num) => (
              <option key={num + 1} value={num + 1}>
                {num + 1}
              </option>
            ))}
          </Form.Select>
          {rankError && <p className="error-message">{rankError}</p>}
        </Form.Group>

        <Form.Group controlId="description" className="mb-3 left-fields">
          <Form.Label className="left-type-label">Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            placeholder="Enter description"
            value={description}
            onChange={handleDescriptionChange}
          />
          <p>{wordCount}/300</p>
          {descriptionError && (
            <p className="error-message">{descriptionError}</p>
          )}
        </Form.Group>
        <Form.Group controlId="tJobsType" className="mb-3 right-fields">
          <Form.Label className="right-type-label">Job type</Form.Label>
          <div className="circular-icon" onClick={addJobTypeDropdown}>
            <FontAwesomeIcon
              icon={faPlus}
              style={{ fontSize: "1rem", color: "white", cursor: "pointer" }}
            />
          </div>
          {/* {showMinus ? (
            <div className="circular-icon" onClick={deleteJobTypeDropdown}>
              <FontAwesomeIcon
                icon={faMinus}
                style={{ fontSize: "1rem", color: "white", cursor: "pointer" }}
              />
            </div>
          ) : null} */}
          {[...Array(numJobTypeDropdowns)].map((_, index) => (
            <div key={index} className="dropdown-container">
              <div className="dropdown-wrapper">
                <Form.Select
                  as="select"
                  value={selectedJobType[index] || ""}
                  onChange={(e: any) => {
                    const productId = e.target.value;
                    const updatedSelectedProducts: any = [...selectedJobType];
                    const previouslySelectedProducts =
                      updatedSelectedProducts.slice(0, index);
                    if (previouslySelectedProducts.includes(productId)) {
                      return;
                    }

                    updatedSelectedProducts[index] = productId;
                    setSelectedJobType(updatedSelectedProducts);
                    setTJobsTypeError(null);
                    changeMessages();
                  }}
                >
                  <option value="">Select technician job type</option>
                  {jobTypesBe.map(
                    (jobTypes: any) =>
                      !selectedJobType
                        .slice(0, index)
                        .includes(jobTypes.id) && (
                        <option key={jobTypes.id} value={jobTypes.id}>
                          {jobTypes.type}
                        </option>
                      )
                  )}
                </Form.Select>
                {numJobTypeDropdowns > 1 && ( // Conditionally render minus circle icon
                  <div
                    className="circular-icon minus"
                    onClick={() => deleteJobTypeDropdown(index)}
                  >
                    <FontAwesomeIcon
                      icon={faMinus}
                      style={{
                        fontSize: "1rem",
                        color: "white",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                )}
              </div>
              {tJobsTypeError && (
                <p className="error-message">{tJobsTypeError}</p>
              )}
            </div>
          ))}
        </Form.Group>
        <div className="button-container">
          <Button
            style={{
              width: "25vh",
              backgroundColor: "black",
              marginTop: "4vh",
              padding: "0.7rem",
              marginRight: "2vh",
            }}
            onClick={onCancel}
            children={"Back"}
          />
          <Button
            style={{
              width: "25vh",
              backgroundColor: "#FFC600",
              marginTop: "4vh",
              padding: "0.7rem",
            }}
            type="submit"
            children={"Save"}
          />
        </div>
      </Form>
    </>
  );
};

export default CreateLevelForm;
