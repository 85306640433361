import React, { useEffect, useState } from "react";
import blueEye from "../../assets/blue-eye.png";
import TableView from "../../components/TableView";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductInquiries,
  getProductInquriesByID,
} from "../../services/productInquirieService";
import { getTechnicians } from "../../services/technicianService";
import { useNavigate } from "react-router-dom";

interface DataItem {
  id: number;
  technicalLevel: string;
  description: string;
  rank: string;
  tJobsType: any;
}

interface workInqury {
  id: string;
  status: string;
  consumer: any;
  workRequestId: string;
  createdDate: string;
  inquiryEntries: {
    id: string;
    product: {
      name: string;
      productCode: string;
      productType: {
        id: string;
        name: string;
      };
    };
    quantity: number;
  }[];
}

const iconStyle: React.CSSProperties = {
  width: "2.5vh",
  height: "2.5vh",
  marginRight: "1.5vh",
};

interface ProductInquryListProps {}

const ProductInquryList: React.FC<ProductInquryListProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const productInquiries = useSelector(
    (state: any) => state.productInquiries.inquiries || []
  );
  useEffect(() => {
    dispatch(getProductInquiries());
    reloadData();
  }, [dispatch]);

  const reloadData = () => {
    dispatch(getProductInquiries());
  };

  const openViewPage = (InquryData: any) => {
    // if (InquryData.inqID) {
    //   dispatch(getProductInquriesByID(InquryData.inqID));
    // }
    navigate(`/product-inquiry-single-view/:productInqId=${InquryData.inqID}`);
  };

  const iconStyle: React.CSSProperties = {
    width: "2.5vh",
    height: "2.5vh",
    marginLeft: "2.5vh",
  };

  const filters = [{ label: "Status", key: "status" }];
  const formatData =
    productInquiries && productInquiries.length > 0
      ? productInquiries.map((item: workInqury) => ({
          inqID: item.id,
          jobID: item.workRequestId,
          dateJ: item.createdDate,
          custName:
            item.consumer &&
            item.consumer.firstName + " " + item.consumer.lastName,
          contacts: item.consumer && item.consumer.contactNumberPrimary,
          productType:
            item.inquiryEntries &&
            item.inquiryEntries[0].product.productType.name,
          product: item.inquiryEntries && item.inquiryEntries[0].product.name,
          quantity: item.inquiryEntries && item.inquiryEntries[0].quantity,
          status: `${item.status.charAt(0).toUpperCase()}${item.status
            .slice(1)
            .toLowerCase()}`,
          inquries: item.inquiryEntries,
        }))
      : [];
  return (
    <div style={{ margin: "0 2vh" }}>
      <>
        <TableView
          data={formatData}
          columns={[
            // "inqID",
            // "jobID",
            "dateJ",
            "custName",
            "contacts",
            "status",
          ]}
          columnLabels={[
            // "Inquiry ID",
            // "Work Request ID",
            "Date",
            "Consumer name",
            "Contact",
            "Status",
          ]}
          viewAction={<img src={blueEye} style={iconStyle} />}
          viewActionMethod={openViewPage}
          filters={filters}
          itemsPage={25}
        />
      </>
    </div>
  );
};

export default ProductInquryList;
