import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "../../components/Button";
import "../../styles/EditJobTypeFrom.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { JobType } from "../../type";
import { extractIds } from "../../utils/utils";
import { editTechLevel } from "../../services/techLevelService";
import { setJobTypesError } from "../../features/JobTypeSlice";
import SpinnerLoading from "../../components/SpinnerLoading";
import Popup from "../../components/Popup";

interface LevelData {
  id: string;
  name: string;
  description: string;
  rank: number;
  jobTypes: JobType;
}

interface Props {
  levelData: LevelData; // Assume LevelData is the data structure for a level
  onCancel: () => void;
}

const EditLevelsForm: React.FC<Props> = ({ levelData, onCancel }) => {
  const dispatch = useDispatch<any>();
  const [name, setTechnicalLevel] = useState(levelData.name);
  const [description, setDescription] = useState(levelData.description);
  const [rank, setrank] = useState(levelData.rank.toString());
  const [submitJobTypes, setSubmitJobTypes] = useState<string[]>([]);
  const [showSccessMsg, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [nameError, setTechnicalLevelError] = useState<string | null>(null);
  const [selectedJobType, setSelectedJobType] = useState<any>(
    levelData.jobTypes
  );
  const [showMinus, setShowMinus] = useState(true);
  const [descriptionError, setDescriptionError] = useState<string | null>(null);
  const [rankError, setrankError] = useState<string | null>(null);
  const [tJobsTypeError, setTJobsTypeError] = useState<string | null>(null);
  const [isFormModified, setIsFormModified] = useState(false);
  const [numJobTypeDropdowns, setNumJobTypeDropdowns] = useState(1);
  const errorLevels = useSelector((state: any) => state.techLevels.error);
  const successLevels = useSelector((state: any) => state.techLevels.success);
  const loading = useSelector((state: any) => state.techLevels.loading);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  // const jobTypesBe = useSelector((state: any) => state.jobTypes.jobTypes);
  const jobTypesBe = useSelector((state: any) =>
    state.jobTypes.jobTypes.filter((jobType: any) => jobType.status === 1)
  );

  useEffect(() => {
    if (selectedJobType.length > 1) {
      selectedJobType.forEach(() => {
        setNumJobTypeDropdowns(selectedJobType.length);
      });
    }
    const idsArray = extractIds(selectedJobType);
    setSubmitJobTypes(idsArray);
  }, []);

  useEffect(() => {
    if (
      name !== levelData.name ||
      description !== levelData.description ||
      JSON.stringify(selectedJobType) !== JSON.stringify(levelData.jobTypes) ||
      rank !== levelData.rank.toString()
    ) {
      setIsFormModified(true);
    } else {
      setIsFormModified(false);
    }
  }, [name, description, rank, levelData, selectedJobType]);

  const changeMessages = () => {
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
    setTechnicalLevelError(null);
    setDescriptionError(null);
    setTJobsTypeError(null);
    setrankError(null);
  };

  const addJobTypeDropdown = () => {
    setNumJobTypeDropdowns(numJobTypeDropdowns + 1);
  };

  const deleteJobTypeDropdown = (indexToDelete: number) => {
    if (numJobTypeDropdowns !== 1) {
      setShowMinus(numJobTypeDropdowns > 1);
      setNumJobTypeDropdowns(numJobTypeDropdowns - 1);
      setIsFormModified(true);

      setSelectedJobType((prevSelected: any) =>
        prevSelected.filter((_: any, index: number) => index !== indexToDelete)
      );
      const updatedSubmitProducts = submitJobTypes.filter(
        (_, index) => index !== indexToDelete
      );
      setSubmitJobTypes(updatedSubmitProducts);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Perform validation
    let isValid = true;

    if (!name) {
      setTechnicalLevelError("Level Name is required");
      isValid = false;
    }

    if (!description) {
      setDescriptionError("Description is required");
      isValid = false;
    }

    if (!rank) {
      setrankError("Level rank is required");
      isValid = false;
    }

    if (selectedJobType.length === 0) {
      setTJobsTypeError("Job type is required");
      isValid = false;
    }

    if (isValid) {
      console.log("Form submitted with data:", {
        name,
        description,
        rank,
        selectedJobType,
        submitJobTypes,
      });

      const requestObj: any = {
        name: name,
        description: description,
        jobTypes: submitJobTypes,
        rank: parseInt(rank),
      };
      dispatch(editTechLevel(levelData.id, requestObj));
      setShowSuccessMessage(true);
      setShowErrorMessage(true);
      setShowPopup(true);
    }
  };

  if (loading) {
    return <SpinnerLoading />;
  }

  return (
    <>
      {showSccessMsg && successLevels && (
        <Popup
          show={showPopup}
          onHide={() => {
            setShowPopup(false);
            onCancel();
          }}
          actionButton={false}
          item={"success"}
          modaltitle={"Success!"}
          content={"Record Successfully Updated!"}
        />
      )}
      {errorLevels && showErrorMessage && (
        <Popup
          show={showPopup}
          onHide={() => {
            setShowPopup(false);
            changeMessages();
          }}
          actionButton={false}
          modaltitle={"Error!"}
          content={errorLevels}
        />
      )}
      <Form onSubmit={handleSubmit}>
        <div className="edit-job-type-title">Edit Technician Level</div>
        <Form.Group controlId="name" className="mb-3 left-fields">
          <Form.Label>Technical Level</Form.Label>
          <Form.Control
            type="text"
            value={name}
            onChange={(e) => {
              setTechnicalLevel(e.target.value);
              setTechnicalLevelError(null);
            }}
          />
          {nameError && <p className="error-message">{nameError}</p>}
        </Form.Group>
        <Form.Group controlId="rank" className="mb-3 right-fields">
          <Form.Label>Level Rank</Form.Label>
          <Form.Select
            as="select"
            value={rank}
            onChange={(e) => {
              setrank(e.target.value);
              setrankError(null);
            }}
          >
            {[...Array(10).keys()].map((num) => (
              <option key={num} value={num + 1}>
                {num + 1}
              </option>
            ))}
          </Form.Select>
          {rankError && <p className="error-message">{rankError}</p>}
        </Form.Group>
        <Form.Group controlId="description" className="mb-3 left-fields">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              setDescriptionError(null);
            }}
          />
          {descriptionError && (
            <p className="error-message">{descriptionError}</p>
          )}
        </Form.Group>
        <Form.Group controlId="tJobsType" className="mb-3 right-fields">
          <Form.Label className="right-type-label">Job type</Form.Label>
          <div className="circular-icon" onClick={addJobTypeDropdown}>
            <FontAwesomeIcon
              icon={faPlus}
              style={{ fontSize: "1rem", color: "white", cursor: "pointer" }}
            />
          </div>
          {[...Array(numJobTypeDropdowns)].map((_, index) => (
            <div key={index} className="dropdown-container">
              <div className="dropdown-wrapper">
                <Form.Select
                  as="select"
                  value={
                    selectedJobType[index] && selectedJobType[index].id
                      ? selectedJobType[index].id
                      : ""
                  }
                  onChange={(e: any) => {
                    const productId = e.target.value;
                    const updatedSubmitProducts = [...submitJobTypes];
                    if (!updatedSubmitProducts.includes(productId)) {
                      updatedSubmitProducts.push(productId);
                      setSubmitJobTypes(updatedSubmitProducts);
                    }
                    if (
                      selectedJobType[index] &&
                      selectedJobType[index].id &&
                      selectedJobType[index].id != productId
                    ) {
                      const newArray = updatedSubmitProducts.filter(
                        (item) => item !== selectedJobType[index].id
                      );
                      setSubmitJobTypes(newArray);
                    }

                    const updatedSelectedProducts = [...selectedJobType];
                    const product = jobTypesBe.find(
                      (product: any) => product.id === productId
                    );
                    if (product) {
                      updatedSelectedProducts[index] = product;
                      setSelectedJobType(updatedSelectedProducts);
                      setJobTypesError(null);
                    }
                  }}
                >
                  <option value="">
                    {selectedJobType[index] && selectedJobType[index].type
                      ? selectedJobType[index].type
                      : "Select technician job type"}
                  </option>
                  {jobTypesBe.map(
                    (jobTypes: any) =>
                      !selectedJobType.some(
                        (selectedProduct: any) =>
                          selectedProduct && selectedProduct.id === jobTypes.id
                      ) && (
                        <option key={jobTypes.id} value={jobTypes.id}>
                          {jobTypes.type}
                        </option>
                      )
                  )}
                </Form.Select>
                {numJobTypeDropdowns > 1 && ( // Conditionally render minus circle icon
                  <div
                    className="circular-icon minus"
                    onClick={() => deleteJobTypeDropdown(index)}
                  >
                    <FontAwesomeIcon
                      icon={faMinus}
                      style={{
                        fontSize: "1rem",
                        color: "white",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                )}
                {tJobsTypeError && (
                  <p className="error-message">{tJobsTypeError}</p>
                )}
              </div>
            </div>
          ))}
        </Form.Group>
        <div className="button-container">
          <Button
            style={{
              width: "25vh",
              backgroundColor: "black",
              marginRight: "2vh",
            }}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            style={{ width: "25vh", backgroundColor: "#FFC600" }}
            type="submit"
            disabledButton={!isFormModified}
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};

export default EditLevelsForm;
