import React, { useEffect, useState, ChangeEvent } from "react";
import Form from "react-bootstrap/Form";
import Button from "../../components/Button";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../styles/CreateJobTypeForm.scss";
import { getProductTypes } from "../../services/productService";
import { addJobType } from "../../services/jobTypeService";
import { useDispatch, useSelector } from "react-redux";
import { JobTypeCreate } from "../../type";
import { setJobTypesLoading } from "../../features/JobTypeSlice";
import SpinnerLoading from "../../components/SpinnerLoading";
import Popup from "../../components/Popup";

interface Props {
  onCancel: () => void;
}

interface FormErrors {
  jobType: string;
  description: string;
  productType: string;
  jobStatus: string;
}
interface Product {
  id: number;
  displayName: string;
}

const CreateJobTypeForm: React.FC<Props> = ({ onCancel }) => {
  const dispatch = useDispatch<any>();
  const [jobType, setJobType] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [description, setDescription] = useState("");
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [jobStatus, setJobStatus] = useState("1");
  const [wordCount, setWordCount] = useState(0);
  const [jobTypeError, setJobTypeError] = useState<string | null>(null);
  const [descriptionError, setDescriptionError] = useState<string | null>(null);
  const [jobStatusError, setJobStatusError] = useState<string | null>(null);
  const [productTypeError, setProductTypeError] = useState<string | null>(null);
  const [showMinus, setShowMinus] = useState(true);
  const [showSccessMsg, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errors, setErrors] = useState<FormErrors>({
    jobType: "",
    description: "",
    productType: "",
    jobStatus: "",
  });
  const productTypesBe = useSelector(
    (state: any) => state.productTypes.productTypes
  );
  const errorJobTypes = useSelector((state: any) => state.jobTypes.error);
  const successJobTypes = useSelector((state: any) => state.jobTypes.success);
  const [numProductTypeDropdowns, setNumProductTypeDropdowns] = useState(1);
  const loadingJobTypes = useSelector((state: any) => state.jobTypes.loading);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getProductTypes());
  }, [dispatch]);

  useEffect(() => {
    clearInputs();
  }, []);

  useEffect(() => {
    const fieldsToValidate: Array<keyof FormErrors> = [
      "jobType",
      "description",
      "productType",
      "jobStatus",
    ];

    fieldsToValidate.forEach((field: keyof FormErrors) => {
      const value = eval(field); // Access value dynamically
      validateField(field, value);
    });
  }, [jobType, description, jobStatus, selectedProducts]);

  const clearInputs = () => {
    if (errorJobTypes || successJobTypes) {
      dispatch(setJobTypesLoading(true));
      changeMessages();
    }
  };

  const addProductTypeDropdown = () => {
    setShowMinus(true);
    setNumProductTypeDropdowns(numProductTypeDropdowns + 1);
    setSelectedProducts((prevSelected) => [...prevSelected, ""]);
  };

  const deleteProductTypeDropdown = (indexToDelete: number) => {
    if (numProductTypeDropdowns !== 1) {
      setShowMinus(numProductTypeDropdowns > 1);
      setNumProductTypeDropdowns(numProductTypeDropdowns - 1);
      setSelectedProducts((prevSelected) => {
        const updatedSelected = [...prevSelected];
        updatedSelected.splice(indexToDelete, 1);
        return updatedSelected;
      });
    }
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const text = event.target.value;
    setDescription(text);
    const count = text.length;
    setWordCount(count);
    setDescriptionError(null);
    changeMessages();
    if (count > 300) {
      setDescriptionError("Description not exceed 300 words.");
    }
  };

  const validateField = (fieldName: string, value: string) => {
    let errors: any = {};
    if (!jobType) {
      errors.jobStatus = "Job type is required";
    }
    if (!description || descriptionError) {
      errors.description = "Description is required";
    }
    if (!jobStatus) {
      errors.jobStatus = "Job status is required";
    }
    if (selectedProducts.length === 0) {
      errors.productType = "Product type is required";
    }
    setIsFormValid(Object.keys(errors).length === 0);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!jobStatus) {
      setJobStatusError("Job status is required");
    }
    if (!jobType) {
      setJobTypeError("Job type is required");
    }
    if (selectedProducts.length === 0) {
      setProductTypeError("Product type is required");
    }
    if (!description) {
      setDescriptionError("Description is required");
    }
    if (isFormValid) {
      const nonEmptySubmitProducts = selectedProducts.filter(
        (item) => item !== ""
      );
      // Perform form submission
      console.log("Form submitted with data:", {
        jobType,
        description,
        jobStatus,
        nonEmptySubmitProducts,
      });
      const requestObj: JobTypeCreate = {
        type: jobType,
        description: description,
        productTypeIds: nonEmptySubmitProducts,
        status: parseInt(jobStatus),
      };
      dispatch(addJobType(requestObj));
      setShowSuccessMessage(true); // Show success message
      setShowErrorMessage(true);
      setShowPopup(true);
    }
  };

  const changeMessages = () => {
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
    setJobTypeError(null);
    setDescriptionError(null);
    setJobStatusError(null);
    setProductTypeError(null);
  };

  return (
    <>
      {showSccessMsg && successJobTypes && (
        <Popup
          show={showPopup}
          onHide={() => {
            setShowPopup(false);
            onCancel();
          }}
          actionButton={false}
          item={"success"}
          modaltitle={"Success!"}
          content={"Record Successfully Created!"}
        />
      )}
      {errorJobTypes && showErrorMessage && (
        <Popup
          show={showPopup}
          onHide={() => {
            setShowPopup(false);
            changeMessages();
          }}
          actionButton={false}
          modaltitle={"Error!"}
          content={errorJobTypes}
        />
      )}
      <Form onSubmit={handleSubmit}>
        <div className="create-job-type-title">Create Technician Job Type</div>
        <Form.Group controlId="jobType" className="mb-3 left-fields">
          <Form.Label className="left-type-label">Job Type</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter job type name"
            value={jobType}
            onChange={(e) => {
              setJobType(e.target.value);
              setJobTypeError(null);
              changeMessages();
            }}
          />
          {jobTypeError && <p className="error-message">{jobTypeError}</p>}
        </Form.Group>
        <Form.Group controlId="productType" className="mb-3 right-fields">
          <Form.Label className="right-type-label">Product Type </Form.Label>
          <div className="circular-icon" onClick={addProductTypeDropdown}>
            <FontAwesomeIcon
              icon={faPlus}
              style={{ fontSize: "1rem", color: "white", cursor: "pointer" }}
            />
          </div>
          {[...Array(numProductTypeDropdowns)].map((_, index) => (
            <div key={index} className="dropdown-container">
              <div className="dropdown-wrapper">
                <Form.Select
                  as="select"
                  value={selectedProducts[index] || ""}
                  onChange={(e: any) => {
                    const productId = e.target.value;
                    const updatedSelectedProducts: any = [...selectedProducts];
                    const previouslySelectedProducts =
                      updatedSelectedProducts.slice(0, index);
                    if (previouslySelectedProducts.includes(productId)) {
                      return;
                    }

                    updatedSelectedProducts[index] = productId;
                    setSelectedProducts(updatedSelectedProducts);
                    setProductTypeError(null);
                    changeMessages();
                  }}
                >
                  <option value="">Select product type</option>
                  {productTypesBe.map(
                    (product: any) =>
                      // Exclude already selected products from the options
                      !selectedProducts
                        .slice(0, index)
                        .includes(product.id) && (
                        <option key={product.id} value={product.id}>
                          {product.name}
                        </option>
                      )
                  )}
                </Form.Select>
                {numProductTypeDropdowns > 1 && ( // Conditionally render minus circle icon
                  <div
                    className="circular-icon minus"
                    onClick={() => deleteProductTypeDropdown(index)}
                  >
                    <FontAwesomeIcon
                      icon={faMinus}
                      style={{
                        fontSize: "1rem",
                        color: "white",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                )}
              </div>
              {productTypeError && (
                <p className="error-message">{productTypeError}</p>
              )}
            </div>
          ))}
        </Form.Group>

        <Form.Group controlId="description" className="mb-3 left-fields">
          <Form.Label className="left-type-label">Job Description</Form.Label>
          <Form.Control
            as="textarea" // Use textarea for multiline input
            rows={4}
            placeholder="Enter job description here"
            value={description}
            onChange={handleDescriptionChange}
          />
          <p>{wordCount}/300</p>

          {descriptionError && (
            <p className="error-message">{descriptionError}</p>
          )}
        </Form.Group>
        <div className="button-container">
          <Button
            style={{
              width: "25vh",
              backgroundColor: "black",
              marginTop: "4vh",
              padding: "0.7rem",
              marginRight: "2vh",
            }}
            onClick={onCancel}
            children={"Back"}
          />
          <Button
            style={{
              width: "25vh",
              backgroundColor: "#FFC600",
              marginTop: "4vh",
              padding: "0.7rem",
            }}
            type="submit"
            children={"Save"}
          />
        </div>
      </Form>
    </>
  );
};

export default CreateJobTypeForm;
