import React from 'react'
import QRCode from "react-qr-code"

interface QrProps {
    qr_string: string;
  }

export const QrCode: React.FC<QrProps> = ({qr_string}) => {

  return (
    <div>
        <QRCode value={qr_string} size={109}></QRCode>
    </div>
  )
}
