import React, { useState, useRef } from "react";
import "../../styles/Technician/Technician.IdQrInfo.page.scss";
import TechnicianID from "../../components/TechnicianID";
import GenericPdfDownloader from "../../components/GenericPdfDownloader";
import html2canvas from "html2canvas";
import { useSelector } from "react-redux";
import { BASE_URL_FOR_IMAGE } from "../../constants/appConstants";

const TechinicianIdQrInfo = () => {
  const pdfRef = useRef<HTMLDivElement>(null);

  const singleTechnicianData = useSelector(
    (state: any) => state.selectedTechnican.singleTechnician
  );

  const technician = {
    fullName: `${singleTechnicianData?.firstName} ${singleTechnicianData?.lastName}`,
    nic: singleTechnicianData?.nationalIdentityCardNo,
    mobile: singleTechnicianData?.contactNumberPrimary,
    address: singleTechnicianData?.address,
    profilePicture: `${singleTechnicianData && singleTechnicianData.profileImage ? BASE_URL_FOR_IMAGE + singleTechnicianData.profileImage.url : ""}`,
  };

  

  const dummy_technician = {
    fullName: "Your Name",
    nic: "XXXXXXXXXXXXX",
    mobile: "XXXXXX-XXXX-XXXX",
    address: "XXXXXXXXXXXX",
    profilePicture:
      "https://media.istockphoto.com/id/1461479420/vector/people-head-silhouette-profile-face-icon-vector-illustration.jpg?s=612x612&w=0&k=20&c=HSRE5mN4BBhSFvEH2B2Ex-8lBngNoXMOWeWtplpEr6c=",
  };


  const [id_presence, setId_presence] = useState(false);

  const handleGenerateId = () => {
    setId_presence(!id_presence);
  };


  const handleImageDownload = async () => {
    const element = document.getElementById('print');
  
    if (element) {
      const canvas = await html2canvas(element, {
        allowTaint: true,
        useCORS: true,
      });
      const data = canvas.toDataURL('image/jpg');
      const link = document.createElement('a');
  
      link.href = data;
      link.download = 'downloaded-image.jpg';
  
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Element with id 'print' not found.");
    }
  }

  return (
    <div className="techinician-id-qr-info">
      <div className="techinician-id-qr-info-top">
        <p className="technician-title">Create Technician ID</p>
        {/* Testing Purpose */}
        {/* <button onClick={handleGenerateId}>Change state for testing</button> */}
      </div>

      <div className="techinician-id-qr-info-bottem">
        {id_presence ? (
          <div className="techinician-id-presence">
            <p>Technician ID has been successfully Generated!</p>
            <button className="genarate-id-button" onClick={handleImageDownload}>
              Download ID
            </button>

            <div className="technician-id-display" id="print">
              <TechnicianID technician={technician} />
            </div>
          </div>
        ) : (
          <div className="techinician-id-not-presence">
            <p>
              Technician does not have ID. Please generate new Technician ID
              using the button below.
            </p>
            <button onClick={handleGenerateId} className="genarate-id-button">
              Genarate ID
            </button>
            <div className="technician-id-display" id="testId">
              <TechnicianID technician={dummy_technician} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TechinicianIdQrInfo;

