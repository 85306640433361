import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CartItem, Product } from "../type";

interface ItemsSlicetate {
  myItems: CartItem[];
  loading: boolean;
  error: string | null;
}

const initialState: ItemsSlicetate = {
  myItems: [],
  loading: false,
  error: null,
};

const myItemsSlice = createSlice({
  name: "myItems",
  initialState,
  reducers: {
    setMyItems(state, action: PayloadAction<CartItem[]>) {
      state.loading = false;
      state.error = null;
      state.myItems = action.payload;
    },
    setMyItemsLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
      state.error = null;
    },
    setMyItemsError(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.error = action.payload;
    },
    addToMyItems(state, action: PayloadAction<CartItem>) {
      if (!Array.isArray(state.myItems)) {
        state.myItems = [];
      }
      state.myItems.push(action.payload);
    },
    editMyItems(
      state,
      action: PayloadAction<{ index: number; updatedItem: CartItem }>
    ) {
      const { index, updatedItem } = action.payload;
      state.myItems[index] = updatedItem;
    },
    removeFromMyItems(state, action: PayloadAction<number>) {
      const indexToRemove = action.payload;
      state.myItems.splice(indexToRemove, 1);
    },
    clearMyItems(state) {
      state.myItems = [];
    },
  },
});

export const {
  setMyItems,
  setMyItemsLoading,
  setMyItemsError,
  addToMyItems,
  editMyItems,
  removeFromMyItems,
  clearMyItems,
} = myItemsSlice.actions;

export default myItemsSlice.reducer;
