import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JobType } from "../type";

interface JobTypeState {
  jobTypes: JobType[];
  loading: boolean;
  error: string | null;
  success: boolean;
}

const initialState: JobTypeState = {
  jobTypes: [],
  loading: false,
  error: null,
  success: false,
};

const jobTypeSlice = createSlice({
  name: "jobTypes",
  initialState,
  reducers: {
    setJobTypes(state, action: PayloadAction<JobType[]>) {
      state.loading = false;
      state.error = null;
      state.success= false;
      state.jobTypes = action.payload;
    },
    setJobTypesLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
      state.error = null;
      state.success= false;
    },
    setJobTypesError(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.success= false;
      state.error = action.payload;
    },
    addJobTypeState(state, action: PayloadAction<JobType>) {
      state.jobTypes.push(action.payload);
      state.loading = false;
      state.error = null;
      state.success= true;
    },
    editJobTypeState(state, action: PayloadAction<{ id: string; updatedJobType: JobType }>) {
      const { id, updatedJobType } = action.payload;
      const index = state.jobTypes.findIndex(jobType => jobType.id === id);
      if (index !== -1) {
        state.jobTypes[index] = updatedJobType;
        state.loading = false;
        state.error = null;
        state.success = true;
      }
    },
  },
});

export const {
  setJobTypes,
  setJobTypesLoading,
  setJobTypesError,
  addJobTypeState,
  editJobTypeState,
} = jobTypeSlice.actions;

export default jobTypeSlice.reducer;
