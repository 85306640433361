import { Dispatch } from "redux";
import {
    setConsumerReviewState,
    setConsumerReviewSuccess,
    setConsumerReviewFailure
} from "../features/consumerReviewSlice";
import { fetchConsumerReviewById } from "../api/consumerReviewAPI";
//get one consumer review by id
export const getConsumerReviewById = (consumerID: string) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(setConsumerReviewState());
            const review = await fetchConsumerReviewById(consumerID);
            console.log("reviewwww 1", review);
            dispatch(setConsumerReviewSuccess(review));
        } catch (error) {
            console.error("Error fetching review by ID", error);
            dispatch(
                setConsumerReviewFailure("Failed to fetch review by ID. Please try again!")
            );
        }
    };
};