import * as React from "react";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  Legend,
  Cell,
} from "recharts";

interface BarChartProps {
  dashboardBarChartData: any;
}

const BasicBars: React.FC<BarChartProps> = ({ dashboardBarChartData }) => {
  var data: any = [];

  if (dashboardBarChartData && dashboardBarChartData.length > 0) {
    data = dashboardBarChartData.map((item: any, index: any) => ({
      name: `${item.jobType
        .charAt(0)
        .toUpperCase()}${item.jobType.slice(1).toLowerCase()}` || "",
      value: parseFloat(item.workRequestCount) || 0,
      color: "#5055C9",
    }));
  }
  
  const COLORS = ["#5055C9", "#00C49F", "#FFBB28", "#FF8042", "#E45E56"];
  data.forEach((item: any, index: number) => {
    item.color = COLORS[index % COLORS.length];
  });
  
  return (
    <BarChart
      width={window.innerWidth > 1536 ? 650 : window.innerWidth < 1500 ? 400 : 500}
      height={window.innerWidth > 1536 ? 350 : 220}
      data={data}
      margin={{ top: 5, left: 5, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" tick={false}/>
      <YAxis dataKey="value" />
      <Tooltip />
      <Legend
        payload={data.map((entry: any, index: any) => ({
          value: `${entry.name
            .charAt(0)
            .toUpperCase()}${entry.name.slice(1).toLowerCase()}`,
          type: "square",
          color: entry.color,
        }))}
      />
      {/* <Legend /> */}
      <Bar dataKey="value" fill="#8884d8">
        {data.map((entry: any, index: any) => {
          return <Cell fill={entry.color} />;
        })}
      </Bar>
    </BarChart>
  );
};

export default BasicBars;
