import { Dispatch } from "redux";
import { fetchTenants } from "../api/tenantsAPI";
import {
  setTenants,
  setTenantsLoading,
  setTenantsError,
} from "../features/tenantsSlice";

export const getTenants = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setTenantsLoading(true));
      const tenants = await fetchTenants();
      dispatch(setTenantsLoading(false));
      dispatch(setTenants(tenants));
    } catch (error) {
      console.error("Error fetching job types:", error);
      dispatch(setTenantsError("Failed to fetch job types. Please try again!"));
    }
  };
};
