// get email address to send OTP
import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import mailIcon from "../../assets/mail.png";
import Button from "../../components/Button";
import pwdStyles from '../../styles/ForgotPassward.module.scss';
import OtpVerificationModal from "./OtpVerificationModal";
import { sendEmailForOTP } from '../../services/forgotPasswordService';
import { ForgotPasswordEmailVerify } from '../../type';


interface ForgotPasswordModalProps {
  show: boolean;
  handleClose: () => void;
}

const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({ show, handleClose }) => {

  const dispatch = useDispatch<any>();

  const [email, setEmail] = useState("");
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isEmailFilled, setIsEmailFilled] = useState(false);



  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setErrorMessage("");
    setIsEmailFilled(e.target.value.trim() !== "");
  };

  //email validation
  const validateEmail = (email: string) => {
    const isValid = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(email);
    return isValid;
  };


  const handleSendOTP = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isEmailFilled) {
      setErrorMessage("Please enter your email address.");
      return;
    }

    if (!validateEmail(email)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }
    // Create an object with the email property
    const emailData: ForgotPasswordEmailVerify = {

      email: email
    };
    dispatch(sendEmailForOTP(emailData));


    handleClose();
    // Set state to open OTP modal
    setOtpModalOpen(true);
  };


  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={pwdStyles.otpText}>
            Please enter your Email address to receive the OTP to Reset your password.
          </div>


          <Form>
            <Form.Group controlId="formPlaintextEmail">
              <Form.Label className={pwdStyles.inputLable}>
                Email Address
              </Form.Label>
              <div className={pwdStyles.inputWithIcon}>
                <Form.Control
                  type="email"
                  placeholder="example@gmail.com"
                  value={email}
                  onChange={handleEmailChange}
                />
                <img src={mailIcon} className={pwdStyles.inputIcon} />
              </div>
              {/* Display error message below the label */}
              {errorMessage && (
                <p style={{ color: "red", margin: "5px 0 0", fontSize: "0.9em" }}>{errorMessage}</p>
              )}
            </Form.Group>
          </Form>
          <div className="justify-content-center">
            <Button

              style={{
                width: "40vh",
                backgroundColor: "#FFC600",
                padding: "0.7rem",
              }}
              onClick={handleSendOTP}
              children={"Submit"}
            />
          </div>
        </Modal.Body>



      </Modal>
      {otpModalOpen && (
        <OtpVerificationModal
          show={true}
          email={email}
          handleClose={() => setOtpModalOpen(false)}

        />
      )}

    </>
  );
};

export default ForgotPasswordModal;
