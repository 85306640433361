import React from "react";
import Button from "../components/Button";
import Modal from "react-bootstrap/Modal";
import "../styles/Popup.scss";
import success_img from "../assets/success_msg.png";
import succee_img_rate from "../assets/success_rate.png";

interface PopupProps {
  show: boolean;
  onHide: () => void;
  modaltitle: string;
  content?: string | null;
  actionButton?: boolean;
  actionMethod?: () => void;
  imgIcon?: any;
  item?: string;
}

const Popup: React.FC<PopupProps> = (props) => {
  const modalHeight = window.innerWidth >= 1700 ? "50rem" : "40rem";
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="success-modal"
      style={{ height: modalHeight }}
    >
      <Modal.Header closeButton style={{border: "none", fontSize: "14px", display: "flex", justifyContent: "center"}}>
        {props.item === "normal" ? (
          <Modal.Title
          id="contained-modal-title-vcenter"
          className="modal-title"
        >
          {props.modaltitle}
        </Modal.Title>
        ) : props.item === "success" ? (
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="modal-title-success"
          >
            {props.modaltitle}
          </Modal.Title>
        ) : props.item === "rate" ? (
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="modal-title-rate"
          >
            {props.modaltitle}
          </Modal.Title>
        ) : (
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="modal-title-error"
          >
            {props.modaltitle}
          </Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body className="modal-body" style={{border: "none"}}>
        <p style={{fontWeight: "bold"}}>{props.content}</p>
        {props.imgIcon}
        {props.item === "normal" && (
          <img src={success_img} style={{width: "150px", marginTop: "10px"}}  /> 
        )}
        {props.item === "rate" && (
          <img src={succee_img_rate} style={{width: "150px", marginTop: "10px"}}  /> 
        )}
      </Modal.Body>
      <Modal.Footer style={{border: "none"}}>
        {props.actionButton ? (
          <Button onClick={props.actionMethod}>Activate</Button>
        ) : null}
        {props.item === "success" ? (
          <Button
            style={{
              backgroundColor: "green",
              color: "#FFFFFF",
              justifyContent: "flex-start",
              alignSelf: "flex-start",
              width: "100%",
            }}
            onClick={props.onHide}
          >
            Close
          </Button>
        ) : props.item === "normal" ||  props.item === "rate"? (
          <Button
            style={{
              backgroundColor: "black",
              color: "#FFFFFF",
              justifyContent: "flex-start",
              alignSelf: "flex-start",
              width: "100%",
            }}
            onClick={props.onHide}
          >
            Continue
          </Button>
        ) : (
          <Button
            style={{
              backgroundColor: "red",
              color: "#FFFFFF",
              justifyContent: "flex-start",
              alignSelf: "flex-start",
              width: "100%",
            }}
            onClick={props.onHide}
          >
            Close
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default Popup;
