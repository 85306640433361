import React from "react";

interface QuantityDropdownProps {
  quantity: number;
  onChange: (quantity: number) => void;
}
const QuantityDropdown: React.FC<QuantityDropdownProps> = ({
  quantity,
  onChange,
}) => {
  const handleIncrement = () => {
    onChange(quantity + 1);
  };

  const handleDecrement = () => {
    onChange(quantity - 1);
  };

  return (
    <div className="quantity-dropdown">
      <button style={{height: '1.5rem' , border: 'none'}} onClick={handleDecrement}>-</button>
      <span style={{marginLeft: '0.5rem', marginRight : '0.5rem'}}>{quantity}</span>
      <button style={{height: '1.5rem', border: 'none'}} onClick={handleIncrement}>+</button>
    </div>
  );
};

export default QuantityDropdown;
