import { Dispatch } from "redux";
import {
  setLevelCriteria,
  setLevelCriteriaError,
  setLevelCriteriaLoading,
  addLevelCriteriaState,
  editLevelCriteriaState,
} from "../features/levelCriteriaSlice";
import {
  fetchLevelCriteria,
  createLevelCriteria,
  updateLevelCriteria,
} from "../api/levelCriteriaAPI";
import { LevelCriteria, LevelCriteriaCreate } from "../type";

export const getLevelCriteria = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLevelCriteriaLoading(true));
      const levelCriteria = await fetchLevelCriteria();
      dispatch(setLevelCriteriaLoading(false));
      dispatch(setLevelCriteria(levelCriteria));
    } catch (error) {
      console.error("Error fetching level criteria:", error);
      dispatch(
        setLevelCriteriaError("Failed to fetch level criteria. Please try again!")
      );
    }
  };
};

export const addLevelCriteria = (levelCriteriaData: LevelCriteriaCreate) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLevelCriteriaLoading(true));
      const newLevelCriteria = await createLevelCriteria(levelCriteriaData);
      dispatch(setLevelCriteriaLoading(false)); // Reset loading state
      if (newLevelCriteria.errorCode) {
        dispatch(setLevelCriteriaError(newLevelCriteria.errorDescription));
      } else {
        dispatch(addLevelCriteriaState(newLevelCriteria));
      }
    } catch (error) {
      console.error("Error creating level criteria:", error);
      dispatch(
        setLevelCriteriaError("Failed to create level criteria. Please try again!")
      );
    }
  };
};

export const editLevelCriteria = (
  levelCriteriaId: string,
  updatedLevelCriteriaData: LevelCriteriaCreate
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setLevelCriteriaLoading(true));
      const updatedLevelCriteria = await updateLevelCriteria(
        levelCriteriaId,
        updatedLevelCriteriaData
      );
      dispatch(setLevelCriteriaLoading(false)); // Reset loading state
      if (updatedLevelCriteria.errorCode) {
        dispatch(setLevelCriteriaError(updatedLevelCriteria.errorDescription));
      } else {
        dispatch(editLevelCriteriaState({ id: levelCriteriaId, updatedLevelCriteria }));
      }
    } catch (error) {
      console.error("Error updating level criteria:", error);
      dispatch(
        setLevelCriteriaError("Failed to update level criteria. Please try again!")
      );
    }
  };
};
