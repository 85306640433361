import React, { useEffect, useState } from "react";
import { Row, Col, Form, InputGroup, FormControl } from "react-bootstrap";
import Button from "../../components/Button";
import CountryDropdown from "react-flags-select";
import ProfilePhoto from "../../components/profilePhoto";
import RegisteredConsumerListView from "./RegisteredConsumerListView";
import formStyle from "../../styles/Consumer/consumerForm.module.css";
import { useDispatch, useSelector } from "react-redux";
import { addConsumer } from "../../services/consumerService";
import { Consumer, ConsumerCreate } from "../../type";
import { setConsumersLoading } from "../../features/consumerSlice";
import { APP_SOURCE_NAME } from "../../constants/appConstants";
import { getCityData } from "../../services/cityService";
import SpinnerLoading from "../../components/SpinnerLoading";
import Popup from "../../components/Popup";
import { Navigate, useNavigate } from "react-router-dom";

interface FormData {
  title: string;
  firstName: string;
  lastName: string;
  address: string;
  district: string;
  city: string;
  nic: string;
  dateOfBirth: string;
  phoneNumber: string;
  email: string;
}

interface RegisterConsumerProps {
  closeRegistrationFrom: React.Dispatch<React.SetStateAction<boolean>>;
}

const RegisterConsumer: React.FC<RegisterConsumerProps> = ({ closeRegistrationFrom }) => {

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState("+94");
  const [photoSelected, setPhotoSelected] = useState(false);
  const [showViewList, setshowViewList] = useState(false);
  const [isCountryPickerVisible, setCountryPickerVisible] = useState(false);

  const loading = useSelector((state: any) => state.consumers.loading);
  const cityData = useSelector((state: any) => state.locationData.cityData);



  useEffect(() => {
    dispatch(getCityData());
  }, [dispatch]);

  const [formData, setFormData] = useState<FormData>({
    // Initialize form fields with default values
    title: "",
    firstName: "",
    lastName: "",
    address: "",
    district: "",
    city: "",
    nic: "",
    dateOfBirth: "",
    phoneNumber: "",
    email: "",
  });

  const [errors, setErrors] = useState<Partial<FormData>>({});
  const [selectedPhotoUrl, setSelectedPhotoUrl] = useState<any>(null);
  const [showSccessMsg, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  // Define a state to track whether a photo is selected
  const [photoError, setPhotoError] = useState<string | null>(null);

  const errorConsumer = useSelector((state: any) => state.consumers.error);
  const successConsumer = useSelector((state: any) => state.consumers.success);

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = event.target;
    const newErrors = { ...errors };

    // Clear error for the edited field if it exists
    if (name in errors) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
    }

    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors(newErrors);
  };

  const validateForm = () => {
    const newErrors: Partial<FormData> = {};

    // Title
    if (!formData.title) {
      newErrors.title = "Please select a title.";
    }

    // Full name
    if (!formData.firstName) {
      newErrors.firstName = "Please enter your first name.";
    }

    if (!formData.lastName) {
      newErrors.lastName = "Please enter your last name.";
    }

    // Address
    if (!formData.address) {
      newErrors.address = "Please enter your address.";
    }

    // District and city
    // District
    if (!formData.district) {
      newErrors.district = "Please select your district.";
    }

    // City
    if (!formData.city) {
      newErrors.city = "Please select your city.";
    }
    // NIC number validation
    if (!formData.nic) {
      newErrors.nic = "Please enter your NIC number.";
    } else if (
      !(/^\d{9}[Vv]$/.test(formData.nic) || /^\d{12}$/.test(formData.nic))
    ) {
      newErrors.nic = "Please enter a valid NIC number.";
    }

    // Date of birth validation
    if (!formData.dateOfBirth) {
      newErrors.dateOfBirth = "Please enter your date of birth.";
    } else if (!/^\d{4}-\d{2}-\d{2}$/.test(formData.dateOfBirth)) {
      newErrors.dateOfBirth =
        "Please enter a valid date of birth in the format YYYY-MM-DD.";
    }

    // Phone number
    //this validation should be change after ajust the selection flags error
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Please enter your phone numer.";
    }

    //email validation
    if (!formData.email) {
      newErrors.email = "Please enter your email address.";
    } else {
      // Use a comprehensive regular expression for email validation:
      const emailRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

      if (!emailRegex.test(formData.email)) {
        newErrors.email = "Please enter a valid email address";
      }
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent default form submission behavior

    const isValid = validateForm();
    if (isValid) {

      //check wether the profile photo has selected
      if (!photoSelected) {
        setPhotoError("Please select a profile photo.");
        return;
      }
      const requestObj: ConsumerCreate = {
        title: formData.title,
        firstName: formData.firstName,
        lastName: formData.lastName,
        address: formData.address,
        city: formData.city,
        district: formData.district,
        nationalIdentityCardNumber: formData.nic,
        dateOfBirth: formData.dateOfBirth,
        contactNumberPrimary: "+94" + formData.phoneNumber,
        profileImageUrl: selectedPhotoUrl,
        email: formData.email,
        registrationSource: APP_SOURCE_NAME,
      };

      dispatch(addConsumer(requestObj));
      setShowSuccessMessage(true);
      setShowErrorMessage(true);
      setShowPopup(true);

    } else {
      // Form has errors, display error messages
      console.log("Form submission failed due to errors:", errors);
    }
  };
  const clearInputs = () => {
    if (errorConsumer || successConsumer) {
      dispatch(setConsumersLoading(true));
    }
  };

  useEffect(() => {
    clearInputs();
  }, []);
  // UseEffect to retrieve form data from local storage when the component mounts
  useEffect(() => {
    const storedFormData = localStorage.getItem("formData");
    if (storedFormData) {
      setFormData(JSON.parse(storedFormData));
    }
  }, []);

  const toggleCountryPicker = () => {
    setCountryPickerVisible(!isCountryPickerVisible);
  };

  const handleCountryChange = (country: string) => {
    setSelectedCountry(country);
  };
  const handlePhotoSelection = (value: boolean) => {
    setPhotoSelected(value);
    // Clear the photo error when a photo is selected
    if (value) {
      setPhotoError(null);
    }
  };


  if (loading) {
    return <SpinnerLoading />;
  }

  return (
    <div>

      <>
        <div className={formStyle.pageTitle}>Register Consumer</div>
        <br />
        <Row>
          <Col>
            <div>
              <ProfilePhoto
                setPhotoSelectedProp={handlePhotoSelection}
                setSelectedPhotoUrlProp={setSelectedPhotoUrl}
              />
              {photoError && (
                <span className={formStyle.inputerrorMessage}>
                  {photoError}
                </span>
              )}     </div>
          </Col>
          <Col className={formStyle.column}>
            <div>
              <Form
                className={formStyle.customFormWidth}
                onSubmit={handleSubmit}
              >
                <Row>
                  <Col>
                    <Form.Group controlId="formTitle">
                      <Form.Label>Title</Form.Label>

                      <Form.Select
                        id="title"
                        name="title"
                        value={formData.title}
                        onChange={handleInputChange}
                      >
                        <option value="">Choose Title</option>
                        <option value="Miss">Miss</option>
                        <option value="Mrs">Mrs</option>
                        <option value="Ms">Ms</option>
                        <option value="Mr">Mr</option>
                      </Form.Select>
                      {errors.title && (
                        <span className={formStyle.inputerrorMessage}>
                          {errors.title}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <br></br>

                <Row>
                  <Col>
                    <Form.Group controlId="formfirstName">
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        placeholder="First Name"
                        name="firstName"
                        id="firstName"
                        value={formData.firstName}
                        onChange={handleInputChange}
                      ></Form.Control>
                      {errors.firstName && (
                        <span className={formStyle.inputerrorMessage}>
                          {errors.firstName}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formLastName">
                      <Form.Label>&nbsp;</Form.Label>
                      <Form.Control
                        placeholder="Last Name"
                        name="lastName"
                        id="lastName"
                        value={formData.lastName}
                        onChange={handleInputChange}
                      />
                      {errors.lastName && (
                        <span className={formStyle.inputerrorMessage}>
                          {errors.lastName}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <br></br>

                <Row className={formStyle.addresslabel}>
                  <Col>
                    <Form.Group controlId="formAddress">
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        placeholder="Enter Address"
                        id="address"
                        name="address"
                        value={formData.address}
                        onChange={handleInputChange}
                      />
                      {errors.address && (
                        <span className={formStyle.inputerrorMessage}>
                          {errors.address}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group controlId="formDistrict">
                      <Form.Label>District</Form.Label>
                      <Form.Select
                        id="district"
                        name="district"
                        value={formData.district}
                        onChange={handleInputChange}
                      >
                        <option value="">Select District</option>
                        {cityData.map((districtData: any) => (
                          <option
                            key={districtData.district.id}
                            value={districtData.district.name}
                          >
                            {districtData.district.name}
                          </option>
                        ))}
                      </Form.Select>
                      {errors.district && (
                        <span className={formStyle.inputerrorMessage}>{errors.district}</span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formCity">
                      <Form.Label>City</Form.Label>
                      <Form.Select
                        id="city"
                        name="city"
                        value={formData.city}
                        onChange={handleInputChange}
                      >
                        <option value="">Select City</option>
                        {formData.district &&
                          cityData
                            .find(
                              (districtData: any) =>
                                districtData.district.name === formData.district
                            )
                            ?.district.cities.map((city: any) => (
                              <option key={city.id} value={city.name}>
                                {city.name}
                              </option>
                            ))}
                      </Form.Select>
                      {errors.city && (
                        <span className={formStyle.inputerrorMessage}>{errors.city}</span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <br></br>

                <Row>
                  <Col>
                    <Form.Group controlId="formNIC">
                      <Form.Label>NIC Number</Form.Label>
                      <Form.Control
                        placeholder="Enter NIC Number"
                        name="nic"
                        id="nic"
                        value={formData.nic}
                        onChange={handleInputChange}
                      />
                      {errors.nic && (
                        <span className={formStyle.inputerrorMessage}>
                          {errors.nic}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <br></br>

                <Row>
                  <Col>
                    <Form.Group controlId="formDateOfBirth">
                      <Form.Label>Date of Birth</Form.Label>
                      <Form.Control
                        type="date"
                        id="dateOfBirth"
                        placeholder="Enter Date of Birth"
                        name="dateOfBirth"
                        value={formData.dateOfBirth}
                        onChange={handleInputChange}
                      />
                      {errors.dateOfBirth && (
                        <span className={formStyle.inputerrorMessage}>
                          {errors.dateOfBirth}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col></Col>
                </Row>

                <br></br>

                <Row>
                  <Col>
                    <Form.Group controlId="formPhoneNumber">
                      <Form.Label>Contact No</Form.Label>
                      <InputGroup>
                        <FormControl
                          placeholder="77 777 777"
                          id="phoneNumber"
                          type="tel"
                          name="phoneNumber"
                          value={formData.phoneNumber}
                          onChange={handleInputChange}
                          aria-label="Phone Number"
                        />
                      </InputGroup>
                      {errors.phoneNumber && (
                        <span className={formStyle.inputerrorMessage}>
                          {errors.phoneNumber}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <br></br>

                <Row>
                  <Col>
                    <Form.Group controlId="formEmail">
                      <Form.Label>E-mail</Form.Label>
                      <Form.Control
                        id="email"
                        placeholder="Enter your email Address"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                      {errors.email && (
                        <span className={formStyle.inputerrorMessage}>
                          {errors.email}
                        </span>
                      )}
                    </Form.Group>
                  </Col>

                  <br />
                  <Col>
                    <div className={formStyle.buttonContainer}>
                      {/* button component */}
                      <Button
                        style={{
                          width: "25vh",
                          backgroundColor: "black",
                          marginTop: "4vh",
                          padding: "0.7rem",
                          marginRight: "2vh",
                        }}
                        onClick={closeRegistrationFrom}
                        children={"Cancel"}
                      />

                      <Button
                        style={{
                          width: "25vh",
                          backgroundColor: "#FFC600",
                          marginTop: "4vh",
                          padding: "0.7rem",
                        }}
                        onClick={() => console.log("ok")}
                        children={"Save"}
                      />
                    </div>
                    {showSccessMsg && successConsumer && (
                      <Popup
                        show={showPopup}
                        onHide={() => {
                          setShowPopup(false);
                          closeRegistrationFrom(false);
                        }}
                        actionButton={false}
                        item={'success'}
                        modaltitle={"Success!"}
                        content={"Record Successfully Created!"}
                      />
                    )}
                    {errorConsumer && showErrorMessage && (
                      <Popup
                        show={showPopup}
                        onHide={() => {
                          setErrors({}); 
                          setShowPopup(false); 
                        }}
                        actionButton={false}
                        modaltitle={"Error!"}
                        content={errorConsumer}
                      />
                    )}
                  </Col>
                </Row>
                <br></br>
              </Form>
            </div>
          </Col>
        </Row>
      </>

    </div>
  );
};

export default RegisterConsumer;
