import { BASE_URL } from "../constants/appConstants";
import { fetchWithToken } from "../utils/utils";

export const fetchDashTechniciansCount = async () => {
    try {
        const end_url = BASE_URL + "/dashboard/technicians/count";
        const response = await fetchWithToken(end_url);
        if(!response.ok) {
            throw new Error("Failed to fetch dashboard technician count");
        }
        const techniciansCount = await response.json();
        return techniciansCount;
        
    } catch (error) {
        throw new Error("Failed to fetch technicians count");
    }
}

export const fetchDashConsumersCount = async () => {
    try {
        const end_url = BASE_URL + "/dashboard/consumers/count";
        const response = await fetchWithToken(end_url);
        if(!response.ok) {
            throw new Error("Failed to fetch dashboard consumers count");
        }
        const consumersCount = await response.json();
        return consumersCount;
        
    } catch (error) {
        throw new Error("Failed to fetch consumers count");
    }
}

export const fetchDashPieChartCount = async () => {
    try {
        const end_url = BASE_URL + "/dashboard/work-request/pie/percentages";
        const response = await fetchWithToken(end_url);
        if(!response.ok) {
            throw new Error("Failed to fetch dashboard pie chart count");
        }
        const pieChartCount = await response.json();
        return pieChartCount;
        
    } catch (error) {
        throw new Error("Failed to fetch pie chart count");
    }
}

export const fetchDashBarChartCount = async () => {
    try {
        const end_url = BASE_URL + "/dashboard/work-request/top/job-types";
        const response = await fetchWithToken(end_url);
        if(!response.ok) {
            throw new Error("Failed to fetch dashboard bar chart count");
        }
        const barChartCount = await response.json();
        return barChartCount;
        
    } catch (error) {
        throw new Error("Failed to fetch bar chart count");
    }
}