import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TechLevel } from "../type";

interface TechLevelState {
  techLevels: TechLevel[];
  loading: boolean;
  error: string | null;
  success: boolean;
}

const initialState: TechLevelState = {
  techLevels: [],
  loading: false,
  error: null,
  success: false,
};

const techLevelSlice = createSlice({
  name: "techLevels",
  initialState,
  reducers: {
    setTechLevels(state, action: PayloadAction<TechLevel[]>) {
      state.techLevels = action.payload;
      state.loading = false;
      state.success = false;
      state.error = null;
    },
    setTechLevelsLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
      state.error = null;
      state.success = false;
    },
    setTechLevelsError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
    addTechLevelsState(state, action: PayloadAction<TechLevel>) {
      state.techLevels.push(action.payload);
      state.loading = false;
      state.error = null;
      state.success = true;
    },
    editTechLevelsState(
      state,
      action: PayloadAction<{ id: string; updatedTechLevels: TechLevel }>
    ) {
      const { id, updatedTechLevels } = action.payload;
      const index = state.techLevels.findIndex(
        (techLevel) => techLevel.id === id
      );
      if (index !== -1) {
        state.techLevels[index] = updatedTechLevels;
        state.loading = false;
        state.error = null;
        state.success = true;
      }
    },
  },
});

export const {
  setTechLevels,
  setTechLevelsLoading,
  setTechLevelsError,
  addTechLevelsState,
  editTechLevelsState,
} = techLevelSlice.actions;

export default techLevelSlice.reducer;
