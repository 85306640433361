
import React, { useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import Rating from "../../components/RatingStars";
import workView from "../../styles/Consumer/Consumer.workView.module.scss";
import phone_icon from "../../assets/phone__icon(2).png";
import map_pin_icon from "../../assets/map-pin.png";
import breafcase from "../../assets/briefcase.png";
import clock from "../../assets/clock.png";
import dollar from "../../assets/dollar-sign.png";
import paperclip from "../../assets/paperclip.png";
import book_open from "../../assets/book-open.png";
import { BASE_URL_FOR_IMAGE } from "../../constants/appConstants";
import ImageGalleryCarousel from "../../components/ImageGalleryCarousel";
import WorkRequestStatus from "../../components/WorkRequestStatus";
import close_icon from "../../assets/close.png";

interface ConsumerData {
  id: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  contactNumberPrimary: string;
  email: string;
  profileImage: {
    id: string;
    url: string;
  };
  averageRating?: any;
}
interface ProductImage {
  id: string;
  url: string;
}

interface Product {
  id: string;
  name: string;
  notes: string;
  colorId: number;
  productCode: string;
  productType: string;
  unitPrice: number;
  productImages: ProductImage[];
}

interface ProductType {
  id: string;
  name: string;
  description: string;
  jobTypes: any[];
  products: Product[];
}
interface JobType {
  id: string;
  type: string;
  description: string;
  productTypes: ProductType[];
  status: number;
}
interface AssignedTechnician {
  id: string;
  firstName: string;
  lastName: string;
  address: string;
  district: string;
  city: string;
  profileImage: {
    id: string;
    url: string;
  };
}

interface WorkRequestData {
  id: string;
  startingDate: string;
  budjet: number;
  workDescription: string;
  address: string;
  district: string;
  city: string;
  workRequestImages: {
    [x: string]: any;
    id: string;
    url: string;
  };
  jobTypes: JobType[];
  status: string;
  longitude: number;
  latitude: number;
  consumer: ConsumerData;
  assignedTechnician: AssignedTechnician;
}

export interface SingleWorkRequest {
  id: string;
  startingDate: string;
  budget: number;
  workDescription: string;
  address: string;
  district: string;
  city: string;
  workRequestImages: { id: string; url: string; }[];
  jobTypes: JobType[];
  status: string;
  longitude: number;
  latitude: number;
  consumer: ConsumerData;
  assignedTechnician: AssignedTechnician;
  
}

const ConsumerWorkView: React.FC<{
  consumerData: ConsumerData;
  workRequestData: WorkRequestData | null;
  onClose: () => void;
}> = ({ consumerData, workRequestData , onClose}) => {

  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleOpenModal = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setShowModal(false);
  };

  return (
    <div>
       <Card style={{ width: "49.5rem", height: "38rem",border: "none" }}>
       <div className="close-icon-container" onClick={onClose}>
          <img src={close_icon} alt="Close Icon" className="close-icon" />
        </div>
        <Card.Title
          style={{ border: "none", fontWeight: "bold", marginLeft: "2rem" }}
        >
          {workRequestData &&
            workRequestData.jobTypes[0].type.charAt(0).toUpperCase() +
              workRequestData.jobTypes[0].type.slice(1)}
        </Card.Title>
          <Card.Body style={{ border: "none" }}>
            <div className={workView.details}>
              <div className={workView.profilelayer}>
                <div className={workView.profileinfo}>
                  <div className={workView.profileimg}>
                    <img
                      src={
                        consumerData.profileImage
                          ? BASE_URL_FOR_IMAGE + consumerData.profileImage.url
                          : "https://media.istockphoto.com/id/519977141/photo/daydreaming-about-her-future.jpg?s=2048x2048&w=is&k=20&c=iAGtxkCqomrBrW8fKsMJtZruQbcuX3oV6XZQdfkIGRo="
                      }
                      alt="Profile Picture"
                    />
                  </div>

                  <div className={workView.profiledetails}>
                    <div className={workView.profilename}>
                      <p>
                        {consumerData.firstName} {consumerData.lastName}
                      </p>
                    </div>
                    <div className={workView.profilerating}>
                      <div>
                        <Rating rating={consumerData.averageRating || 0} />
                        {consumerData.averageRating}
                      </div>
                      <div className={workView.profileratingvalue}></div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: 'row',
                    flex: "0.2",
                  }}
                >
                  <WorkRequestStatus status={workRequestData?.status} />
                </div>
              </div>
              <div className={workView.detaillayerone}>
                <div className={workView.contactnoinfo}>
                  <div className={workView.phonetitle}>Contact Number</div>
                  <div className={workView.phoneicon}>
                    <img src={phone_icon} alt="Phone Icon" />
                    {consumerData.contactNumberPrimary}
                  </div>
                </div>
                <div className={workView.addressinfo}>
                  <div className={workView.addresstitle}>Address</div>
                  <div className={workView.addressicon}>
                    <img src={map_pin_icon} alt="Address Icon" />
                    {consumerData.address}
                  </div>
                </div>
              </div>
              <div className={workView.detaillayertwo}>
                <div className={workView.locationinfo}>
                  <div className={workView.locationtitle}>Location</div>
                  <div className={workView.locationicon}>
                    <img src={map_pin_icon} alt="Location Icon" />
                    {workRequestData && (
                      <a
                        href={`https://www.google.com/maps?q=${workRequestData.latitude},${workRequestData.longitude}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        See Location on Google Maps
                      </a>
                    )}
                  </div>
                </div>
                <div className={workView.typeworkinfo}>
                  <div className={workView.typeworktitle}>Type of the work</div>
                  <div className={workView.typeworkicon}>
                    <img src={breafcase} alt="Type of Work Icon" />
                    {workRequestData?.jobTypes[0]?.type}

                  </div>
                </div>
              </div>
              <div className={workView.detaillayerthree}>
                <div className={workView.startdateinfo}>
                  <div className={workView.startdatetitle}>
                    Expected starting date
                  </div>
                  <div className={workView.startdateicon}>
                    <img src={clock} alt="Starting Date Icon" />
                    {workRequestData?.startingDate}
                  </div>
                </div>
                <div className={workView.budgetinfo}>
                  <div className={workView.budgettitle}>Expected budget</div>
                  <div className={workView.budgeticon}>
                    {/* <img src={dollar} alt="Budget Icon" /> */}
                    RS: {workRequestData?.budjet}
                  </div>
                </div>
              </div>
              <div className={workView.detaillayerfour}>
                <div className={workView.noteinfo} style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '22rem' }}>
                  <div className={workView.notetitle}>Notes for technician</div>
                  <div className={workView.noteicon} >
                    <img src={book_open} alt="Note Icon" />
                    {workRequestData?.workDescription}
                  </div>
                </div>
                <div className={workView.attachmentinfo}>
                  <div className={workView.attachmenttitle}>Attachments</div>
                  <div className={workView.attachmenticon}>
                    <img src={paperclip} alt="Attachment Icon" />

                    {workRequestData?.workRequestImages &&
                      workRequestData.workRequestImages.map((attachment: any, index: any) => (
                        <img
                          src={`${BASE_URL_FOR_IMAGE}${attachment.url}`}
                          key={index}
                          alt={`Attachment ${index}`}
                          style={{ width: '2rem', height: '2rem', cursor: 'pointer' }}
                          onClick={() => handleOpenModal(index)}
                        />
                      ))}
                  </div>
                </div>
              </div>
              {/* <div className={workView.detaillayerbuttons}>
                <div className={workView.holdbtn}>
                  <button>Hold</button>
                </div>
                <div className={workView.completedbtn}>
                  <button>Mark as Completed</button>
                </div>
              </div> */}
            </div>
          </Card.Body>
        </Card>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <h4>Attachments</h4>
        </Modal.Header>
        <Modal.Body>
          <ImageGalleryCarousel images={workRequestData?.workRequestImages || []} selectedImage={selectedImage} />
        </Modal.Body>
      </Modal>

    </div>
  );
};

export default ConsumerWorkView;