import { BASE_URL } from '../constants/appConstants';
import { fetchWithToken, uploadImage, uploadImageMuliple } from '../utils/utils';

export const fetchWorkRequests = async () => {
  try {
    const end_url = BASE_URL + "/work-requests?page=0&size=0";
    const response = await fetchWithToken(end_url);
    if (!response.ok) {
      throw new Error('Failed to fetch work requests');
    }
    const workRequests = await response.json();
    return workRequests.results;
  } catch (error) {
    throw new Error('Failed to fetch work requests');
  }
};

export const createWorkRequestApi = async (workRequestData: any) => {
  try {

    var workUrls;
    const multipleWorkImages = workRequestData.workRequestImageUrls && workRequestData.workRequestImageUrls.length > 0;

    if (multipleWorkImages) {
      const workImagesUrls = await uploadImageMuliple(workRequestData.workRequestImageUrls);
      if (workImagesUrls && workImagesUrls.ok) {
        const dataBody = await workImagesUrls.json();
        workUrls = dataBody.urls;

      }
    }

    const updatedWorkRequestData = {
      ...workRequestData,
      workRequestImageUrls: workUrls,
    };

    const end_url = BASE_URL + "/work-requests";
    const response = await fetchWithToken(end_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedWorkRequestData),
    });

    const newWorkRequest = await response.json();
    return newWorkRequest;
  } catch (error) {
    console.error('Error creating work request:', error);
    throw new Error('Failed to create work request');
  }
};

//   try {
//     const end_url = `${BASE_URL}/job-types/${jobTypeId}`;
//     const response = await fetchWithToken(end_url, {
//       method: 'PATCH',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(updatedData),
//     });

//     if (!response.ok) {
//       throw new Error('Failed to update job type');
//     }

//     const updatedJobType = await response.json();
//     return updatedJobType;
//   } catch (error) {
//     throw new Error('Failed to update job type');
//   }
// };

export const assignTechnicianToWorkRequest = async (
  workRequesID: string, tecnicianID: string
) => {
  try {
    const end_url = `${BASE_URL}/work-requests/assign/${workRequesID}/technician/${tecnicianID}/`;
    const response = await fetchWithToken(end_url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      }
    });
    if (response.status === 201 || response.status === 200) {
      return true;
    } else {
      const assignRequest = await response.json();
      return assignRequest;
    }

  } catch (error) {
    throw new Error("Failed to assign technicans");
  }
}

export const unAssignTechnicianToWorkRequest = async (
  workRequesID: string
) => {
  try {
    const end_url = `${BASE_URL}/work-requests/un-assign/${workRequesID}`;
    const response = await fetchWithToken(end_url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      }
    });
    if (response.status === 201 || response.status === 200) {
      return true;
    } else {
      const assignRequest = await response.json();
      return assignRequest;
    }

  } catch (error) {
    throw new Error("Failed to unassign technicans");
  }
}


export const deleteWorkRequestAPI = async (workRequestId: string, consumerId: string) => {
  try {
    const endUrl = `${BASE_URL}/work-requests/${workRequestId}/consumer/${consumerId}/`;
    const response = await fetchWithToken(endUrl, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return true;
    } else {
      const deleteRequest = await response.json();
      return deleteRequest;
    }
  } catch (error) {
    throw new Error("Failed to delete work request");
  }
};


//to get specific work request by id
export const fetchWorkRequestById = async (workRequestId: string) => {
  try {
    const endUrl = `${BASE_URL}/work-requests/${workRequestId}`;
    const response = await fetchWithToken(endUrl);

    if (!response.ok) {
      throw new Error('Failed to fetch work request');
    }

    const workRequest = await response.json();
    return workRequest;
  } catch (error) {
    throw new Error('Failed to fetch work request');
  }
}

export const updateWorkRequestAPI = async (workRequestId: string, updatedWorkRequestData: any) => {
  try {
    // Check if there are multiple workRequestImageUrls
    // const multipleWorkImages = updatedWorkRequestData.newImages && updatedWorkRequestData.newImages.length > 0;
    const multipleWorkImages = updatedWorkRequestData.newImages && updatedWorkRequestData.newImages.length > 0 && updatedWorkRequestData.newImages[0].length > 0;

  //   const multipleWorkImages =
  // updatedWorkRequestData.newImages &&
  // updatedWorkRequestData.newImages.length > 0 &&
  // updatedWorkRequestData.newImages[0] instanceof File;

    let workUrls = [];

    if (multipleWorkImages) {
      // Upload multiple images
      const workImagesUrls = await uploadImageMuliple(updatedWorkRequestData.newImages);
      
      if (workImagesUrls && workImagesUrls.ok) {
        const dataBody = await workImagesUrls.json();
        workUrls = dataBody.urls;
      }
    }

    let allImages = [...updatedWorkRequestData.workRequestImageUrls, ...workUrls]
    
    // Update workRequestData with new image URLs
    const updatedDataWithImages = {
      startingDate:updatedWorkRequestData.startingDate,
      budjet:updatedWorkRequestData.budjet,
      workRequestImageUrls: allImages,
    };

    // Make the PATCH request to update the work request
    const endUrl = `${BASE_URL}/work-requests/${workRequestId}`;
    const response = await fetchWithToken(endUrl, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedDataWithImages),
    });

    if (response.status === 200) {
      const updatedWorkRequest = await response.json();
      return updatedWorkRequest;
    } else {
      // Handle error response
      const errorData = await response.json();
      console.error("Error updating work request:", errorData);
      throw new Error("Failed to update work request");
    }
  } catch (error) {
    console.error("Error updating work request:", error);
    throw new Error("Failed to update work request");
  }
};

export const updateWorkRequestStatusAPI = async (
  workRequesID: string, tecnicianID: string, updatedWorkRequestData:any
) => {
  try {
    const end_url = `${BASE_URL}/work-requests/${workRequesID}/technician/${tecnicianID}/status`;
    const response = await fetchWithToken(end_url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedWorkRequestData),
    });
    const updatedWorkRequest = await response.json();
    return updatedWorkRequest;

  } catch (error) {
    throw new Error("Failed to update workrequest status");
  }
}

export const updateWorkRequestRatingForConsumerAPI = async (
  workRequesID: string, consumerID: string, ratingData:any
) => {
  try {
    const end_url = `${BASE_URL}/review-entries/work-requests/${workRequesID}/consumers/${consumerID}`;
    const response = await fetchWithToken(end_url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(ratingData),
    });
    const updatedWorkRequest = await response.json();
    return updatedWorkRequest;

  } catch (error) {
    throw new Error("Failed to update workrequest rating for consumer");
  }
}

export const fetchWorkRequestHistory = async (workRequestId: string) => {
  try {
    const endUrl = `${BASE_URL}/work-requests/${workRequestId}/history?page=0&size=0`;
    const response = await fetchWithToken(endUrl);

    if (!response.ok) {
      throw new Error('Failed to fetch work request history');
    }

    const workRequestHistory = await response.json();
    return workRequestHistory;
  } catch (error) {
    throw new Error('Failed to fetch work request history');
  }
}