import React, { useRef, useState, ChangeEvent } from "react";
import profilePhoto from "../assets/profilePhoto.png";
import { BASE_URL_FOR_IMAGE } from "../constants/appConstants";

interface ProfilePhotoProps {
    setPhotoSelectedProp: (value: boolean) => void;
    setSelectedPhotoUrlProp: (url: any) => void;
    imgUrl?: string;
}

const MAX_WIDTH = 800;
const MAX_HEIGHT = 800;
const MIN_WIDTH = 100;
const MIN_HEIGHT = 100;

const ProfilePhoto = ({ setPhotoSelectedProp, setSelectedPhotoUrlProp, imgUrl }: ProfilePhotoProps) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [photoSelected, setPhotoSelected] = useState(false);
    const [selectedPhotoUrl, setSelectedPhotoUrl] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleImageClick = () => {
        fileInputRef.current?.click();
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            const img = new Image();
            img.onload = () => {
                const { naturalWidth, naturalHeight } = img;
                if (naturalWidth > MAX_WIDTH || naturalHeight > MAX_HEIGHT) {
                    setErrorMessage(`Please ensure that your profile image does not exceed the maximum dimensions of ${MAX_WIDTH} pixels by ${MAX_HEIGHT} pixels.`);
                    setSelectedPhotoUrl(null);
                } else if (naturalHeight < MIN_HEIGHT || naturalWidth < MIN_WIDTH) {
                    setErrorMessage(`Please ensure that your profile image meets the minimum dimensions of ${MIN_WIDTH} pixels by ${MIN_HEIGHT} pixels.`);
                    setSelectedPhotoUrl(null);
                } else {
                    setPhotoSelected(true);
                    setSelectedPhotoUrl(imageUrl);
                    setPhotoSelectedProp(true);
                    setSelectedPhotoUrlProp(file);
                    setErrorMessage(null);
                }
            };
            img.src = imageUrl;
        }
    };

    return (
        <div style={{ paddingLeft: '1vh' }}>
            <p>Profile Photo</p>
            <img
                src={selectedPhotoUrl ? selectedPhotoUrl : (imgUrl ? BASE_URL_FOR_IMAGE + imgUrl : profilePhoto)}
                alt="profilePhoto"
                onClick={handleImageClick}
                style={{ cursor: 'pointer', width: '10rem', height: '12rem' }}
            />
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
        </div>
    );
};

export default ProfilePhoto;
