import React, { useState } from "react";
import { AuthStackScreenProps } from "../type";
import logo from "../assets/applogo.png";
import bIcon from "../assets/365icon.png";
import eyeOff from "../assets/eye-off.png";
import eyeOn from "../assets/eye.png";
import mailIcon from "../assets/mail.png";
import styles from "../styles/Login.module.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "../components/Button";
import { validateEmail } from "../utils/utils";
import { LoginUser } from "../services/authService";
import { Credentials } from "../type";
import { useDispatch, useSelector } from "react-redux";
import SpinnerLoading from "../components/SpinnerLoading";
import Popup from "../components/Popup";
import ForgotPasswordModal from "./ForgotPassword/ForgotPasswordModal";

const LoginScreen: React.FC<
  AuthStackScreenProps<"Login"> & { setLoggedIn: (status: boolean) => void }
> = ({ setLoggedIn }) => {
  const dispatch = useDispatch<any>();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(true);
  const loading = useSelector((state: any) => state.auth.loading);
  const errorAuth = useSelector((state: any) => state.auth.error);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordPaste = (e: any) => {
    e.preventDefault(); // Prevent pasting into the password field
  };

  const handlePasswordChange = (event: any) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setIsPasswordValid(true);
  };

  const handleLogin = () => {
    if (!email || !validateEmail(email)) {
      setIsEmailValid(false);
      return;
    } else if (!password) {
      setIsPasswordValid(false);
      return;
    } else {
      const credentials: Credentials = {
        username: email,
        password: password,
      };
      dispatch(LoginUser(credentials, setLoggedIn));
      setShowErrorMessage(true);
      setShowPopup(true);
    }
  };

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
    setIsEmailValid(true);
  };

  if (loading) {
    return <SpinnerLoading />;
  }
  return (
    <>
      {errorAuth && showErrorMessage && (
        <Popup
          show={showPopup}
          onHide={() => setShowPopup(false)}
          actionButton={false}
          modaltitle={"Error!"}
          content={errorAuth}
        />
      )}
      <ForgotPasswordModal
        show={showForgotPasswordModal}
        handleClose={() => setShowForgotPasswordModal(false)}
      />
      <div className={styles.appContainer}>
        <div className={styles.backgroundSection}>
          <div className={styles.logoTile}>
            <img src={logo} alt="Logo" className={styles.logo} />
          </div>
        </div>
        <div className={styles.loginSection}>
          <div className={styles.loginContainer}>
            <div className={styles.appTitle}>Log In</div>
            <div className={styles.appDescription}>
              Enter your Office 365 login details to log into the Solid Club
              system.
            </div>
            <Form className={styles.form}>
              <Form.Group as={Row} controlId="formPlaintextEmail">
                <Form.Label column className={styles.inputLable}>
                  Email Address
                </Form.Label>
                <Col sm="12">
                  <div className={styles.inputWithIcon}>
                    <Form.Control
                      type="email"
                      placeholder="example@gmail.com"
                      className={styles.inputField}
                      onChange={handleEmailChange}
                    />
                    <img src={mailIcon} className={styles.inputIcon} />
                  </div>
                </Col>
                {!isEmailValid && (
                  <div className={styles.errorMessage}>
                    Please enter a valid email.
                  </div>
                )}
              </Form.Group>
              <Form.Group as={Row} controlId="formPlaintextPassword">
                <Form.Label column sm="2" className={styles.inputLable}>
                  Password
                </Form.Label>
                <Col sm="12">
                  <div className={styles.inputWithIcon}>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="***************"
                      className={styles.inputField}
                      onChange={handlePasswordChange}
                      onPaste={handlePasswordPaste}
                    />
                    {showPassword ? (
                      <img
                        src={eyeOn}
                        className={styles.inputIcon}
                        onClick={togglePasswordVisibility}
                      />
                    ) : (
                      <img
                        src={eyeOff}
                        className={styles.inputIcon}
                        onClick={togglePasswordVisibility}
                      />
                    )}
                  </div>
                  {!isPasswordValid && (
                    <div className={styles.errorMessage}>
                      Please enter the password.
                    </div>
                  )}
                </Col>
                <div className={styles.forgotPassword}>
                  <a
                    href="#"
                    className={styles.forgotPasswordLink}
                    onClick={() => setShowForgotPasswordModal(true)}
                  >
                    Forgot Password?
                  </a>
                </div>
              </Form.Group>
            </Form>
            <Button
              onClick={handleLogin}
              children={"LOGIN"}
            />
            <div className={styles.separatorLine}></div>
            <div className={styles.orText}>OR</div>
            <Button
              style={{ backgroundColor: "#000000", color: "#FFFFFF" }}
              onClick={() => setLoggedIn(true)}
              children={"LOGIN WITH O365"}
              bIcon={bIcon}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default LoginScreen;
