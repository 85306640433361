import { Dispatch } from "redux";
import { JobType, JobTypeCreate } from "../type";
import {
  setJobTypes,
  setJobTypesError,
  setJobTypesLoading,
  addJobTypeState,
  editJobTypeState,
} from "../features/JobTypeSlice";
import { fetchJobTypes, createJobType, updateJobTypes } from "../api/jobTypeAPI";

export const getJobTypes = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setJobTypesLoading(true));
      const jobTypes = await fetchJobTypes();
      dispatch(setJobTypesLoading(false));
      dispatch(setJobTypes(jobTypes));
    } catch (error) {
      console.error("Error fetching job types:", error);
      dispatch(
        setJobTypesError("Failed to fetch job types. Please try again!")
      );
    }
  };
};

export const addJobType = (jobTypeData: JobTypeCreate) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setJobTypesLoading(true));
      const newJobType = await createJobType(jobTypeData);
      dispatch(setJobTypesLoading(false)); // Reset loading state
      if (newJobType.errorCode) {
        dispatch(setJobTypesError(newJobType.errorDescription));
      } else {
        dispatch(addJobTypeState(newJobType));
      }
    } catch (error) {
      console.error("Error creating job type:", error);
      dispatch(
        setJobTypesError("Failed to create job type. Please try again!")
      );
    }
  };
};

export const editJobType = (jobTypeId: string, updatedJobTypeData: JobType) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setJobTypesLoading(true));
      const updatedJobType = await updateJobTypes(jobTypeId, updatedJobTypeData);
      dispatch(setJobTypesLoading(false));
      if (updatedJobType.errorCode) {
        dispatch(
          setJobTypesError(updatedJobType.errorDescription)
        );
      } else {
        dispatch(
          editJobTypeState({ id: jobTypeId, updatedJobType })
        );  
      }
    } catch (error) {
      console.error('Error updating job type:', error);
      dispatch(
        setJobTypesError('Failed to update job type. Please try again!')
      );
    }
  };
};
