import { BASE_URL } from '../constants/appConstants';
import { fetchWithToken } from '../utils/utils';

export const fetchProductInquiries = async () => {
    try {
      const response = await fetchWithToken(`${BASE_URL}/product-inquiries?page=0&size=0`);
      if (!response.ok) {
        throw new Error('Failed to fetch product inquiries');
      }
      const inquiries = await response.json();
      return inquiries.results;
    } catch (error) {
      throw new Error('Failed to fetch product inquiries');
    }
  }; 
  
  export const createProductInquiry = async (inquiryData: any) => {
    try {
      const response = await fetchWithToken(`${BASE_URL}/product-inquiries`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(inquiryData),
      });
      const newInquiry = await response.json();
      return newInquiry;
    } catch (error) {
      throw new Error('Failed to create product inquiry');
    }
  };
  
  export const fetchProductInquiryByID = async (productInqID: string) => {
    try {
      const response = await fetchWithToken(`${BASE_URL}/product-inquiries/${productInqID}`);
      if (!response.ok) {
        throw new Error('Failed to fetch product inquiries');
      }
      const inquiries = await response.json();
      return inquiries;
    } catch (error) {
      throw new Error('Failed to fetch product inquiries');
    }
  };