import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductType } from "../type";

interface ProductState {
  productTypes: ProductType[];
  loading: boolean;
  error: string | null;
}

const initialState: ProductState = {
  productTypes: [],
  loading: false,
  error: null,
};

const productTypeSlice = createSlice({
  name: "productTypes",
  initialState,
  reducers: {
    setProductTypes(state, action: PayloadAction<ProductType[]>) {
      state.loading = false;
      state.error = null;
      state.productTypes = action.payload;
    },
    setProductTypesLoading(state, action: PayloadAction<boolean>) {
      state.loading = true;
      state.error = null;
    },
    setProductTypesError(state, action: PayloadAction<string | null>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setProductTypes,
  setProductTypesLoading,
  setProductTypesError,
} = productTypeSlice.actions;

export default productTypeSlice.reducer;
