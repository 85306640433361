import React, { useState } from "react";
import SidebarMenu from "react-bootstrap-sidebar-menu";
import "../styles/Styles.scss";
import logo from "../assets/appInLogo.png";
import { LogoutUser } from "../services/authService";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

interface SidebarProps {
  activeButton: string;
  // setActiveButton: (button: string) => void;
}

const Sidebar: React.FC<
  SidebarProps & {
    activeButton: string;
    setActiveButton: (button: string) => void;
  }
> = ({ activeButton, setActiveButton }) => {
  const theme = "dark";
  const dispatch = useDispatch<any>();
  const navigate = useNavigate(); 
  const handleItemClick = (itemName: string) => {
    if (itemName === "logout") {
      dispatch(LogoutUser());
      return;
    }
    setActiveButton(itemName);
  };

  const handleNavigation = (eventKey: string) => {
    setActiveButton(eventKey);
    if (eventKey === "menu_dashboard") {
      navigate("/dashboard");
    } else if (eventKey === "menu_technician") {
      navigate("/technician");
    } else if (eventKey === "menu_consumer") {
      navigate("/consumer");
    } else if (eventKey === "sum_menu_workrequest") {
      navigate("/work-request");
    } else if (eventKey === "menu_application_setups") {
      navigate("/appication-setups");
    }else if (eventKey === "sum_menu_product_inq_list") {
      navigate("/product-inquiry-list");
    }
  };

  return (
    <SidebarMenu variant={theme} bg={theme} expand="sm">
      <SidebarMenu.Collapse>
        <SidebarMenu.Header>
          <SidebarMenu.Brand>
            <img src={logo} alt="Logo" className="logo" />
          </SidebarMenu.Brand>
        </SidebarMenu.Header>
        <SidebarMenu.Body>
          <SidebarMenu.Nav>
            <SidebarMenu.Nav.Link
              eventKey="menu_dashboard"
              onClick={() => handleNavigation("menu_dashboard")}
            >
              <SidebarMenu.Nav.Icon></SidebarMenu.Nav.Icon>
              <SidebarMenu.Nav.Title>Dashboard</SidebarMenu.Nav.Title>
            </SidebarMenu.Nav.Link>
            <SidebarMenu.Nav.Link
              eventKey="menu_application_setups"
              onClick={() => handleNavigation("menu_application_setups")}
            >
              <SidebarMenu.Nav.Icon></SidebarMenu.Nav.Icon>
              <SidebarMenu.Nav.Title>Application Setups</SidebarMenu.Nav.Title>
            </SidebarMenu.Nav.Link>
            {/* <SidebarMenu.Nav.Link eventKey="menu_tenants" onClick={() => handleNavigation("menu_tenants")}>
              <SidebarMenu.Nav.Icon></SidebarMenu.Nav.Icon>
              <SidebarMenu.Nav.Title>Tenants</SidebarMenu.Nav.Title>
            </SidebarMenu.Nav.Link> */}
            <SidebarMenu.Nav.Link
              eventKey="menu_technician"
              onClick={() => handleNavigation("menu_technician")}
            >
              <SidebarMenu.Nav.Icon></SidebarMenu.Nav.Icon>
              <SidebarMenu.Nav.Title>Technician</SidebarMenu.Nav.Title>
            </SidebarMenu.Nav.Link>
            <SidebarMenu.Nav.Link
              eventKey="menu_consumer"
              onClick={() => handleNavigation("menu_consumer")}
            >
              <SidebarMenu.Nav.Icon></SidebarMenu.Nav.Icon>
              <SidebarMenu.Nav.Title>Consumer</SidebarMenu.Nav.Title>
            </SidebarMenu.Nav.Link>
            <SidebarMenu.Sub eventKey={0}>
              <SidebarMenu.Sub.Toggle>
                <SidebarMenu.Nav.Icon />
                <SidebarMenu.Nav.Title>Work Request</SidebarMenu.Nav.Title>
              </SidebarMenu.Sub.Toggle>
              <SidebarMenu.Sub.Collapse>
                <SidebarMenu.Nav>
                  <SidebarMenu.Nav.Link
                    eventKey="sum_menu_workrequest"
                    onClick={() => handleNavigation("sum_menu_workrequest")}
                  >
                    <SidebarMenu.Nav.Icon></SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>
                      Work Request List
                    </SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link>
                  <SidebarMenu.Nav.Link
                    eventKey="sum_menu_product_inq_list"
                    onClick={() => handleNavigation("sum_menu_product_inq_list")}
                  >
                    <SidebarMenu.Nav.Icon></SidebarMenu.Nav.Icon>
                    <SidebarMenu.Nav.Title>
                      Product Inquiry List
                    </SidebarMenu.Nav.Title>
                  </SidebarMenu.Nav.Link >
                </SidebarMenu.Nav>
              </SidebarMenu.Sub.Collapse>
            </SidebarMenu.Sub>
            <SidebarMenu.Nav.Link eventKey="logout" onClick={() => handleItemClick("logout")}>
              <SidebarMenu.Nav.Icon></SidebarMenu.Nav.Icon>
              <SidebarMenu.Nav.Title>Log Out</SidebarMenu.Nav.Title>
            </SidebarMenu.Nav.Link>
          </SidebarMenu.Nav>
        </SidebarMenu.Body>
        {/* <div className="bottom-items">
          <button
            className={`bottom-item ${
              activeButton === "account" ? "active" : ""
            }`}
            onClick={() => handleItemClick("account")}
          >
            Account
          </button>
          <button
             className={`bottom-item ${
              activeButton === "settings" ? "active" : ""
            }`}
            onClick={() => handleItemClick("settings")}
          >
            Settings
          </button>
          <button
             className={`bottom-item ${
              activeButton === "help" ? "active" : ""
            }`}
            onClick={() => handleItemClick("help")}
          >
            Help & Support
          </button>
          <button
            className={`bottom-item ${
              activeButton === "logout" ? "active" : ""
            }`}
            onClick={() => handleItemClick("logout")}
          >
            Log Out
          </button>
        </div> */}
      </SidebarMenu.Collapse>
    </SidebarMenu>
  );
};

export default Sidebar;
