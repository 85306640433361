import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SingleWorkRequest } from "../type";

interface SingleWorkRequestState {
  singleWorkRequest: SingleWorkRequest | null;
  loading: boolean;
  error: string | null;
  workHistroy?: any;
}

const initialState: SingleWorkRequestState = {
  singleWorkRequest: null,
  loading: false,
  error: null,
  workHistroy: null,
};

const singleWorkRequestSlice = createSlice({
  name: "singleworkRequest",
  initialState,
  reducers: {
    setSingleWorkRequestState(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
      state.error = null;
    },
    setSingleWorkRequestSuccess(
      state,
      action: PayloadAction<SingleWorkRequest>
    ) {
      state.loading = false;
      state.error = null;
      state.singleWorkRequest = action.payload;
    },
    setSingleWorkRequestHistory(
      state,
      action: PayloadAction<SingleWorkRequest>
    ) {
      return {
        ...state,
        loading: false,
        error: null,
        workHistroy: action.payload,
      };
    },
    setSingleWorkRequestFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setSingleWorkRequestState,
  setSingleWorkRequestSuccess,
  setSingleWorkRequestFailure,
  setSingleWorkRequestHistory,
} = singleWorkRequestSlice.actions;

export default singleWorkRequestSlice.reducer;
