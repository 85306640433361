import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import Button from "../../components/Button";
import pwdStyles from '../../styles/ForgotPassward.module.scss';
import eyeOn from "../../assets/eye.png";
import eyeOff from "../../assets/eye-off.png";
import SuccessModal from "./SuccessModal";
import { useDispatch, useSelector } from 'react-redux';
import { restPasswordByEmailService } from '../../services/forgotPasswordService';
import { ResetPassword } from '../../type';
import Popup from "../../components/Popup";

interface ResetPasswordModalProps {
    show: boolean;
    handleClose: () => void;
    otp: string;
    email: string;
}

const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({ show, handleClose, otp, email }) => {

    const dispatch = useDispatch<any>();

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [isPasswordResetSuccess, setPasswordResetSuccess] = useState(false);
    const errorForgetPwd = useSelector((state: any) => state.forgotPassword.error);
    const successForgetPwd = useSelector((state: any) => state.forgotPassword.success);
    const [newPasswordError, setNewPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [showSccessMsg, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const handleResetPassword = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setNewPasswordError("");
        setConfirmPasswordError("");

        // Check if newPassword is empty
        if (!newPassword.trim()) {
            setNewPasswordError("Please enter new password.");
            return;
        }

        // Check if confirmPassword is empty
        if (!confirmPassword.trim()) {
            setConfirmPasswordError("Please confirm password.");
            return;
        }

        // Check if password is at least 8 characters long
        if (newPassword.length < 8) {
            setNewPasswordError("Password must be at least 8 characters long.");
            return;
        } else {
            setNewPasswordError(""); // Clear newPassword error
        }

        // Check if password contains at least one uppercase letter
        if (!/[A-Z]/.test(newPassword)) {
            setNewPasswordError("Password must contain at least one uppercase letter.");
            return;
        } else {
            setNewPasswordError(""); // Clear newPassword error
        }

        // Check if password contains at least one special character
        if (!/[@#$%^&+=]/.test(newPassword)) {
            setNewPasswordError("Password must contain at least one special character.");
            return;
        } else {
            setNewPasswordError(""); // Clear newPassword error
        }

        // Check if password contains at least one number
        if (!/\d/.test(newPassword)) {
            setNewPasswordError("Password must contain at least one number.");
            return;
        } else {
            setNewPasswordError(""); // Clear newPassword error
        }

        // Check if password contains no spaces
        if (/\s/.test(newPassword)) {
            setNewPasswordError("Password must not contain spaces.");
            return;
        } else {
            setNewPasswordError(""); // Clear newPassword error
        }

        // Check if confirmPassword is empty
        if (!confirmPassword.trim()) {
            setConfirmPasswordError("Please confirm password.");
            return;
        }

        if (newPassword === confirmPassword) {
            const resetDetails: ResetPassword = {
                email: email,
                otp: otp,
                newPassword: newPassword
            };
            dispatch(restPasswordByEmailService(resetDetails));
            setShowPopup(true);
            setShowSuccessMessage(true); // Show success message
            setShowErrorMessage(true);
        } else {
            setPasswordsMatch(false);
            setNewPasswordError("")
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setConfirmPassword(value);
        setPasswordsMatch(newPassword === value);
    };

    return (
        <>
            {showSccessMsg && successForgetPwd && (
                <Popup
                    show={showPopup}
                    onHide={() => {
                        setShowPopup(false);
                        setShowSuccessModal(false); // Clear success popup state
                        setNewPassword("");
                        setConfirmPassword("");
                    }}
                    actionButton={false}
                    item={"success"}
                    modaltitle={"Success!"}
                    content={"Your account password has been successfully updated!"}
                />
            )}
            {errorForgetPwd && showErrorMessage && (
                <Popup
                    show={showPopup}
                    onHide={() => {
                        setShowPopup(false)
                        setShowErrorMessage(false);
                    }}
                    actionButton={false}
                    modaltitle={"Error!"}
                    content={errorForgetPwd}
                />
            )}
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Reset Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={pwdStyles.otpText}>
                        Create a new password to reset your older password
                    </div>
                    <Form>
                        <Form.Group controlId="formNewPassword">
                            <Form.Label className={pwdStyles.inputLable}>Create New Password</Form.Label>
                            <div className={pwdStyles.inputWithIcon}>
                                <Form.Control
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Enter new password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                                <img
                                    src={showPassword ? eyeOn : eyeOff}
                                    className={pwdStyles.inputIcon}
                                    onClick={togglePasswordVisibility}
                                />
                            </div>
                            {newPasswordError && (
                                <Form.Text className="text-danger">
                                    {newPasswordError}
                                </Form.Text>
                            )}
                        </Form.Group>
                        <Form.Group controlId="formConfirmPassword">
                            <Form.Label className={pwdStyles.inputLable}>Confirm Password</Form.Label>
                            <div className={pwdStyles.inputWithIcon}>
                                <Form.Control
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Confirm password"
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                />
                                <img
                                    src={showPassword ? eyeOn : eyeOff}
                                    className={pwdStyles.inputIcon}
                                    onClick={togglePasswordVisibility}
                                />
                            </div>
                            {!passwordsMatch && (
                                <Form.Text className="text-danger">
                                    Passwords do not match
                                </Form.Text>
                            )}
                            {newPasswordError && (
                                <Form.Text className="text-danger">
                                    {newPasswordError}
                                </Form.Text>
                            )}
                        </Form.Group>
                    </Form>


                    <div className={pwdStyles.passwordInstructions}>
                        <div>Password must:</div>
                        <div className={pwdStyles.passwordInstructionslist}>
                            <ul>
                                <li>08 characters long</li>
                                <li>01 uppercase letter</li>
                                <li>01 special character</li>
                                <li>01 number</li>
                                <li>No spaces</li>
                            </ul>
                        </div>
                    </div>



                    <div className="justify-content-center">
                        <Button
                            style={{
                                width: "30vh",
                                backgroundColor: "#FFC600",
                                padding: "0.7rem",
                            }}
                            onClick={handleResetPassword}
                            children={"Reset Password"}
                        />

                    </div>
                </Modal.Body>


            </Modal>
            <SuccessModal show={showSuccessModal} success={isPasswordResetSuccess} handleClose={() => setShowSuccessModal(false)} />
        </>
    );
};

export default ResetPasswordModal;
