import React, { useEffect, useState } from "react";
import { Col, Dropdown, Modal, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import styles from "../../styles/WorkRequest.module.css";
import Button from "../../components/Button";
import upload from "../../assets/upload.png";
import close from "../../assets/close.png";
import { useDispatch, useSelector } from "react-redux";
import { getJobTypes } from "../../services/jobTypeService";
import { addWorkRequest } from "../../services/workRequestService";
import { JobType, WorkRequestCreate } from "../../type";
import {
  setWorkRequestsLoading,
  setWorkRequestsError,
} from "../../features/workRequestSlice";
// import { GoogleMap, useLoadScript, Marker, Library } from '@react-google-maps/api';
import {
  GoogleMap,
  useLoadScript,
  Marker,
  Libraries,
} from "@react-google-maps/api";
import { GOOGLE_MAP_API_KEY } from "../../constants/appConstants";
import { searchConsumersByNic } from "../../services/consumerService";
import {
  clearFormData,
  updateFormData,
} from "../../features/workRequestCreateSlice";
import { getCityData } from "../../services/cityService";
import { unSetSelectedProduts } from "../../features/productInquiriesSlice";
import SpinnerLoading from "../../components/SpinnerLoading";
import Popup from "../../components/Popup";
import pdfFile from "../../assets/pdf-file.png";
import xlsFile from "../../assets/xls-file.png";

const libraries: Libraries = ["places"];
const mapContainerStyle = {
  width: "100vw",
  height: "100vh",
};
const center = {
  lat: 7.2905715,
  lng: 80.6337262,
};

interface Props {
  onCancel: () => void;
  openProductList: (items: any) => void;
}

interface State {
  showMapModal: boolean;
  formData: {
    workImages: any;
    nic: string;
    date: string;
    budget: number;
    workDescription: string;
    address: string;
    district: string;
    city: string;
    jobType: string[];
    selectedJobTypes: any;
    latitude: number;
    longitude: number;
    workRequestImageUrls: string[];
    selectedImages: string[];
  };
  errors: {
    nic: string;
    date: string;
    budget: string;
    workDescription: string;
    address: string;
    district: string;
    city: string;
    jobType: string;
  };
  isCreateWorkRequestClicked: boolean;
}

const MAX_CHARACTER_COUNT = 300;

const WorkRequestComponent: React.FC<Props> = ({
  onCancel,
  openProductList,
}) => {
  const dispatch = useDispatch<any>();
  const [showMap, setShowMap] = useState(false);
  const [selectedJobTypes, setSelectedJobTypes] = useState<JobType[]>([]);
  const [showPopup, setShowPopup] = useState<boolean>(true);
  const [productButtonEnable, setProductButtonEnable] =
    useState<boolean>(false);
  const [showSccessMsg, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [jobTypes, setJobTypes] = useState<JobType[]>([]);

  const jobTypesBe = useSelector((state: any) => state.jobTypes.jobTypes);
  const errorWorkRequest = useSelector(
    (state: any) => state.workRequests.error
  );
  const successWorkRequest = useSelector(
    (state: any) => state.workRequests.success
  );
  const loading = useSelector((state: any) => state.workRequests.loading);
  const selectedProductsFroInqury = useSelector(
    (state: any) => state.productInquiries.selectedProduts
  );
  const formData = useSelector(
    (state: any) => state.workRequestCreate.formData
  );
  const [selectedImages, setSelectedImages] = useState<any[]>([]);
  // const [workRequestImages, setWorkRequestImages] = useState<string[]>([]);
  const [imageUrls, setImageUrls] = useState<any>([]);
  const [selectedImageCount, setSelectedImageCount] = useState(
    formData.workImages.length
  );

  const initialState = {
    showMapModal: false,
    formData: formData || {
      nic: "",
      date: "",
      budget: 0,
      workDescription: "",
      address: "",
      district: "",
      city: "",
      jobType: [],
      selectedJobTypes: [],
      latitude: center.lat,
      longitude: center.lng,
      workRequestImageUrls: [],
      selectedImages: [],
      workImages: [],
    },
    errors: {
      nic: "",
      date: "",
      budget: "",
      workDescription: "",
      address: "",
      district: "",
      city: "",
      jobType: "",
    },
    isCreateWorkRequestClicked: false,
  };
  const [state, setState] = useState<State>(initialState);
  const [files, setFiles] = useState<File[]>([]);
  const [workImages, setWorkImages] = useState<any>([]);
  const cityData = useSelector((state: any) => state.locationData.cityData);
  
  var displayImage: any = "";
  const [characterCount, setCharacterCount] = useState(formData.description);

  if (!Array.isArray(state.formData.workRequestImageUrls)) {
    displayImage = state.formData.workRequestImageUrls;
  }
  useEffect(() => {
    dispatch(getCityData());
  }, [dispatch]);

  useEffect(() => {
    setJobTypes(jobTypesBe);
  }, [dispatch]);

  useEffect(() => {
    const newCharacterCount = state.formData.workDescription.length;
    setCharacterCount(newCharacterCount);
  }, [state.formData.workDescription]);

  const resetState = () => {
    setState(initialState);
  };

  const clearInputs = () => {
    if (errorWorkRequest || successWorkRequest) {
      dispatch(setWorkRequestsLoading(true));
    }

    if (successWorkRequest) {
      resetState();
    }
  };

  useEffect(() => {
    clearInputs();
    dispatch(clearFormData(true));
  }, []);

  useEffect(() => {
    const newFileList = createFileList(files);
    setWorkImages(newFileList);
  }, [files]);

  useEffect(() => {
    if (state.formData.selectedJobTypes.length > 0) {
      setProductButtonEnable(true);
    } else {
      setProductButtonEnable(false);
    }
  }, [selectedJobTypes]);

  const handleJobTypeSelect = (selectedItem: any) => {
    const selected = jobTypes.find((type) => type.id === selectedItem.id);
    if (
      selected &&
      !selectedJobTypes.some((existingType) => existingType.id === selected.id)
    ) {
      // setSelectedJobTypes((prevSelected) => [...prevSelected, selected]);
      setSelectedJobTypes([selected]);
      // Update formData with the selected job type id
      setState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          jobType: [selected.id],
          selectedJobTypes: [selected],
        },
      }));
    }
  };

  const handleRemoveJobType = (removedType: string) => {
    var newData = [...state.formData.selectedJobTypes]; // Create a copy of the selectedJobTypes array
    if (newData.length > 0) {
      newData.pop(); // Remove the last element from the copied array
    } else if (newData.length === 1) {
      newData = []; // Clear the array if it has only one element
    }

    setSelectedJobTypes((prevSelected) =>
      prevSelected.filter((type) => type.type !== removedType)
    );

    // Update the state with the new array
    setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        selectedJobTypes: newData,
      },
    }));
    // Update the state of editWorkRequestData here if needed
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;

    if (name === "workDescription") {
      const newCharacterCount = value.length;
      setCharacterCount(newCharacterCount);
    }

    setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [name]: e.target.type === "number" ? parseFloat(value) : value,
      },
      errors: { ...prevState.errors, [name]: "" },
    }));
  };

  const createFileList = (files: any) => {
    const fileList = new DataTransfer();
    files.forEach((file: any) => fileList.items.add(file));
    return fileList.files;
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files) {
      const filesWithBlob: File[] = [];
      for (const file of files) {
        filesWithBlob.push(new File([file], file.name, { type: file.type }));
      }
      setFiles(filesWithBlob);
      const newFileList = createFileList(filesWithBlob);

      const updatedImages = [...state.formData.workImages, ...newFileList];
      setWorkImages(updatedImages);

      // Calculate the total count of selected images
      const totalSelectedImageCount = updatedImages.length;

      setSelectedImageCount(totalSelectedImageCount);

      const newImageUrls = updatedImages.map((file: File) =>
        URL.createObjectURL(file)
      );

      setState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          workRequestImageUrls: newImageUrls,
          workImages: updatedImages,
        },
      }));
    }
  };

  const getFileType = (file: any) => {
    const extension = file.name.split(".").pop();
    if (["pdf", "xls", "jpg", "jpeg", "png"].includes(extension)) {
      return extension;
    }
    return null;
  };

  const validateForm = async (forCreateWorkRequest: boolean) => {
    const newErrors: { [key: string]: string } = {};
    let nicSearchResult: string | null = null;
    // NIC validation logic
    if (!state.formData.nic) {
      newErrors.nic = "Please enter Consumer NIC number.";
    } else {
      try {
        const result = await dispatch(searchConsumersByNic(state.formData.nic));
        if (result && result.length === 0) {
          newErrors.nic = "No consumer registered with the given NIC";
        } else {
          nicSearchResult = result[0].id;
          console.error("Consumer registered with the given NIC", result[0].id);
        }
      } catch (error) {
        // Handle errors
        console.error("Error fetching data:", error);
      }
    }

    // Starting Date validation logic
    if (!state.formData.date) {
      newErrors.date = "Please select a starting date.";
    }

    if (!state.formData.selectedImages) {
      newErrors.date = "Please upload work image.";
    }

    // Budget validation logic
    if (!state.formData.budget) {
      newErrors.budget = "Please enter the budget.";
    }

    // Work Description validation logic
    if (!state.formData.workDescription) {
      newErrors.workDescription = "Please enter the work description.";
    }

    // Address validation logic
    if (!state.formData.address) {
      newErrors.address = "Please enter the address.";
    }

    // District validation logic
    if (!state.formData.district) {
      newErrors.district = "Please select a district.";
    }

    // City validation logic
    if (!state.formData.city) {
      newErrors.city = "Please select a city.";
    }

    // JobType validation logic
    if (state.formData.selectedJobTypes.length === 0) {
      newErrors.jobType = "Please select at least one Job Type.";
    }

    setState(
      (prevState) =>
        ({
          ...prevState,
          errors: newErrors,
          isCreateWorkRequestClicked: forCreateWorkRequest,
        } as State)
    );
    const isValid = Object.keys(newErrors).length === 0;

    return { isValid, nicSearchResult };
  };

  const handleRemoveImage = (indexToRemove: number) => {
    // Convert workImages to an array
    const workImagesArray = Array.from(state.formData.workImages);

    // Filter out the image at the specified index
    const updatedImages = workImagesArray.filter(
      (_, index) => index !== indexToRemove
    );
    const updatedImageUrls = state.formData.workRequestImageUrls.filter(
      (_, index) => index !== indexToRemove
    );

    setSelectedImageCount(updatedImageUrls.length);

    // Update the state with the filtered images
    setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        workImages: updatedImages,
        workRequestImageUrls: updatedImageUrls,
      },
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent default form submission behavior

    const jobTypeIds: string[] = state.formData.selectedJobTypes.map(
      (jobType: any) => jobType.id
    );

    const { isValid, nicSearchResult } = await validateForm(true);
    const isCharacterCountValid =
      state.formData.workDescription.length <= MAX_CHARACTER_COUNT;

    if (isValid && isCharacterCountValid) {
      const requestObj: WorkRequestCreate = {
        startingDate: state.formData.date,
        budjet: state.formData.budget,
        workDescription: state.formData.workDescription,
        address: state.formData.address,
        district: state.formData.district,
        city: state.formData.city,
        jobTypesIds: jobTypeIds,
        // workRequestImageUrls: state.formData.workRequestImageUrls,
        workRequestImageUrls: [state.formData.workImages],
        longitude: state.formData.longitude,
        latitude: state.formData.latitude,
        consumerId: nicSearchResult,
      };

      // Check if the number of selected images exceeds 10
      if (selectedImageCount > 10) {
        setState((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            selectedImages: "You can only select up to 10 images.",
          },
        }));
        return; // Stop form submission
      }
      if (selectedImageCount <= 10) {
        dispatch(addWorkRequest(requestObj, selectedProductsFroInqury));
        setShowSuccessMessage(true); // Show success message
        setShowErrorMessage(true);
        setShowPopup(true);
        setTimeout(() => {
          if (successWorkRequest) {
            dispatch(clearFormData(true));
          }
        }, 5000);
      }
    } else {
      console.log("Form contains errors. Please fix them.");
    }
  };

  const handleOpenGoogleMaps = () => {
    // event.preventDefault();
    setShowMap(true);
  };

  const handleApiLoaded = (map: any, maps: any) => {
    // use map and maps objects
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries,
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  const handleMapClick = (event: google.maps.MouseEvent) => {
    const clickedLat = event.latLng?.lat() as number;
    const clickedLng = event.latLng?.lng() as number;

    // Update the state with the clicked location
    setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        latitude: clickedLat,
        longitude: clickedLng,
      },
    }));
  };

  if (loading) {
    return <SpinnerLoading />;
  }

  return (
    <div className={styles.appContainer}>
      {showSccessMsg && successWorkRequest && (
        <Popup
          show={showPopup}
          onHide={() => {
            setShowPopup(false);
            onCancel();
          }}
          actionButton={false}
          item={"success"}
          modaltitle={"Success!"}
          content={"Record Successfully Created!"}
        />
      )}
      {errorWorkRequest && showErrorMessage && (
        <Popup
          show={showPopup}
          onHide={() => setShowPopup(false)}
          actionButton={false}
          modaltitle={"Error!"}
          content={errorWorkRequest}
        />
      )}
      <div className={styles.appTitle}>Create Work Request</div>
      <Modal
        show={showMap}
        onHide={() => setShowMap(false)}
        style={{ width: "80%", maxWidth: "1200px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Google Map</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ width: "100%", height: "400px" }}>
            {isLoaded && (
              <GoogleMap
                mapContainerStyle={{ width: "100%", height: "100%" }}
                zoom={10}
                center={center}
                onClick={(event) => handleMapClick(event)}
              >
                {/* <Marker position={center} /> */}
                <Marker
                  position={{
                    lat: state.formData.latitude,
                    lng: state.formData.longitude,
                  }}
                />
              </GoogleMap>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <div className={styles.mainSection}>
        <Form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formDiv}>
            <div className={styles.left}>
              <Form.Group
                as={Col}
                className={styles.inputDiv}
                controlId="formPlaintextNIC"
              >
                <Form.Label column className={styles.inputLable}>
                  Consumer NIC
                </Form.Label>
                <Col sm="12">
                  <div className={styles.inputField}>
                    <Form.Control
                      type="text"
                      name="nic"
                      value={state.formData.nic}
                      onChange={handleChange}
                      placeholder="Enter Consumer NIC number"
                      className={styles.inputField}
                    />
                    {state.errors.nic && (
                      <div className={styles.errorText}>{state.errors.nic}</div>
                    )}
                  </div>
                </Col>
              </Form.Group>

              <Form.Group as={Col} controlId="formPlaintextDate">
                <Form.Label column className={styles.inputLable} >
                  Starting Date
                </Form.Label>
                <Col sm="12">
                  <div className={styles.inputField}>
                    <Form.Control
                      type="date"
                      name="date"
                      onChange={handleChange}
                      value={state.formData.date}
                      className={styles.dateInput}
                    />
                    {state.errors.date && (
                      <div className={styles.errorText}>
                        {state.errors.date}
                      </div>
                    )}
                  </div>
                </Col>
              </Form.Group>

              <Form.Group as={Col} controlId="formPlaintextBudget">
                <Form.Label column className={styles.inputLable}>
                  Budget
                </Form.Label>
                <Col sm="12">
                  <div className={styles.inputField}>
                    <Form.Control
                      type="number"
                      name="budget"
                      value={state.formData.budget}
                      onChange={handleChange}
                      placeholder="500,000.00"
                      className={styles.inputField}
                    />
                    {state.errors.budget && (
                      <div className={styles.errorText}>
                        {state.errors.budget}
                      </div>
                    )}
                  </div>
                </Col>
              </Form.Group>

              <Form.Group
                as={Col}
                className={styles.inputDiv}
                controlId="formPlaintextDescription"
              >
                <Form.Label column className={styles.inputLable}>
                  Work Description ({characterCount}/{MAX_CHARACTER_COUNT})
                </Form.Label>
                <Col sm="12">
                  <div className={styles.inputField}>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="workDescription"
                      value={state.formData.workDescription}
                      onChange={handleChange}
                      placeholder="Enter Work Description"
                      className={styles.inputField}
                      // maxLength={MAX_CHARACTER_COUNT}
                    />
                    {characterCount > MAX_CHARACTER_COUNT && (
                      <div className={styles.errorText}>
                        Work description exceeds the maximum character limit.
                      </div>
                    )}
                  </div>
                </Col>
              </Form.Group>
            </div>

            <div className={styles.right}>
              <Form.Group as={Col} controlId="formPlaintextAddress">
                <Form.Label column className={styles.inputLable}>
                  Address
                </Form.Label>
                <Col sm="12">
                  <div className={styles.inputField}>
                    <Form.Control
                      type="text"
                      name="address"
                      value={state.formData.address}
                      onChange={handleChange}
                      placeholder="Enter Address"
                      className={styles.inputField}
                    />
                    {state.errors.address && (
                      <div className={styles.errorText}>
                        {state.errors.address}
                      </div>
                    )}
                  </div>
                </Col>

                <div className={styles.addressLine}>
                  <Col sm="6">
                    <div className={styles.inputField}>
                      <Form.Select
                        aria-label="District"
                        name="district"
                        onChange={handleChange}
                        value={state.formData.district}
                        className={styles.inputField}
                      >
                        <option value="">District</option>
                        {cityData.map((districtData: any) => (
                          <option
                            key={districtData.district.id}
                            value={districtData.district.name}
                          >
                            {districtData.district.name}
                          </option>
                        ))}
                      </Form.Select>
                      {state.errors.district && (
                        <div className={styles.errorText}>
                          {state.errors.district}
                        </div>
                      )}
                    </div>
                  </Col>

                  <Col sm="6">
                    <div className={styles.inputField}>
                      <Form.Select
                        aria-label="City"
                        name="city"
                        onChange={handleChange}
                        value={state.formData.city}
                        className={styles.inputField}
                      >
                        <option value="">City</option>
                        {state.formData.district &&
                          cityData
                            .find(
                              (districtData: any) =>
                                districtData.district.name ===
                                state.formData.district
                            )
                            ?.district.cities.map((city: any) => (
                              <option key={city.id} value={city.name}>
                                {city.name}
                              </option>
                            ))}
                      </Form.Select>
                      {state.errors.city && (
                        <div className={styles.errorText}>
                          {state.errors.city}
                        </div>
                      )}
                    </div>
                  </Col>
                </div>
              </Form.Group>

              <Form.Group as={Col} controlId="formPlaintextFile">
                <Form.Label column className={styles.inputLable}>
                  Attachments
                </Form.Label>
                <Col sm="12">
                  <div className={styles.fileInputContainer}>
                    <label
                      htmlFor="fileInput"
                      className={styles.customFileLabel}
                    >
                      <span className={styles.uploadIcon}>
                        <img src={upload} className={styles.inputIcon} />
                      </span>{" "}
                      Upload Attachments
                    </label>
                    <input
                      id="fileInput"
                      type="file"
                      name="selectedImages"
                      value={state.formData.selectedImages}
                      className={styles.fileInput}
                      onChange={handleFileInputChange}
                      multiple
                    />
                    {/* <p className={styles.fileTypes}>Jpeg</p> */}
                  </div>
                  <div className={styles.previewContainer}>
                    {state.formData.workImages &&
                      state.formData.workImages.map((file: any, index: any) => {
                        const fileType = getFileType(file);
                        return (
                          <div key={index} className={styles.imagePreview}>
                            {fileType === "pdf" && (
                              <img
                                src={pdfFile}
                                alt={`PDF Preview ${index}`}
                                style={{
                                  marginRight: "10px",
                                  width: "2rem",
                                  height: "2rem",
                                }}
                              />
                            )}
                            {fileType === "xls" && (
                              <img
                                src={xlsFile}
                                alt={`XLS Preview ${index}`}
                                style={{
                                  marginRight: "10px",
                                  width: "2rem",
                                  height: "2rem",
                                }}
                              />
                            )}
                            {(fileType === "jpg" ||
                              fileType === "jpeg" ||
                              fileType === "png") && (
                              <img
                                src={URL.createObjectURL(file)}
                                alt={`Image Preview ${index}`}
                                style={{
                                  marginRight: "10px",
                                  width: "2rem",
                                  height: "2rem",
                                }}
                              />
                            )}
                            <span
                              className={styles.removeImageIcon}
                              onClick={() => handleRemoveImage(index)}
                            >
                              &#x2715;
                            </span>
                          </div>
                        );
                      })}
                  </div>

                  {selectedImageCount > 10 && (
                    <div className={styles.errorText}>
                      You can only upload maximum 10 images.
                    </div>
                  )}
                </Col>
              </Form.Group>

              <Col sm="12">
                <Button
                  style={{
                    backgroundColor: "black",
                    color: "#ffffff",
                    width: "100%",
                    marginTop: "20px",
                    padding: "10px",
                    marginBottom: "10px",
                  }}
                  onClick={(e: any) => {
                    e.preventDefault(); // Prevent form submission
                    handleOpenGoogleMaps();
                  }}
                  children={"Pin My Site Location"}
                  bIcon=""
                />
              </Col>

              <Form.Group as={Col} controlId="formPlaintextFile">
                <Form.Label column className={styles.inputLable}>
                  Job Types
                </Form.Label>
                <Col sm="12">
                  <div className={styles.jobTypesContainer}>
                    {state.formData.selectedJobTypes &&
                      state.formData.selectedJobTypes.map((type: any) => (
                        <span key={type.id} className={styles.selectedJobType}>
                          {type.type}

                          <img
                            src={close}
                            style={{ height: "15px", width: "15px" }}
                            alt="Close"
                            onClick={() => handleRemoveJobType(type.type)}
                          />
                        </span>
                      ))}
                  </div>

                  {state.errors.jobType && (
                    <div className={styles.errorText}>
                      {state.errors.jobType}
                    </div>
                  )}

                  <Dropdown>
                    <Dropdown.Toggle
                      className={styles.dropdown}
                      id="dropdown-basic"
                      style={{ backgroundColor: "black", color: "#ffffff" }}
                    >
                      Add Job Type
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {jobTypesBe
                        .filter(
                          (type: any) =>
                            type.status === 1 &&
                            !state.formData.selectedJobTypes.some(
                              (selectedType: any) => selectedType.id === type.id
                            )
                        )
                        .map((type: any) => (
                          <Dropdown.Item
                            key={type.id}
                            onClick={() => handleJobTypeSelect(type)}
                          >
                            {type.type}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col sm="12">
                  <Button
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      width: "10vw",
                      padding: "5px",
                      marginTop: "5px",
                      border: "1px solid black",
                    }}
                    onClick={() => {
                      openProductList(state.formData.selectedJobTypes);
                      dispatch(updateFormData(state.formData));
                    }}
                    children={"View Products"}
                    disabledButton={!productButtonEnable}
                    bIcon=""
                  />
                </Col>
              </Form.Group>

              <div className={styles.buttons}>
                <Col sm="6">
                  <Button
                    style={{
                      backgroundColor: "black",
                      color: "#ffffff",
                      width: "95%",
                      marginTop: "10px",
                      padding: "10px",
                    }}
                    onClick={() => {
                      dispatch(clearFormData(true));
                      dispatch(unSetSelectedProduts(true));
                      onCancel();
                    }}
                    children={"Cancel"}
                    bIcon=""
                  />
                </Col>
                <Col sm="6">
                  <Button
                    style={{
                      backgroundColor: "#FFC600",
                      color: "#ffffff",
                      width: "95%",
                      marginTop: "10px",
                      padding: "10px",
                    }}
                    onClick={() => validateForm(false)}
                    children={"Create Work Request"}
                    type="submit"
                    bIcon=""
                  />
                </Col>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default WorkRequestComponent;
