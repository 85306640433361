import { Dispatch } from "redux";
import {
  fetchDashBarChartCount,
  fetchDashConsumersCount,
  fetchDashPieChartCount,
  fetchDashTechniciansCount,
} from "../api/dashboardAPI";
import {
  setDashConsumersLoading,
  setDashConsumersError,
  setDashConsumersState,
  setDashTechniciansLoading,
  setDashTechniciansState,
  setDashTechniciansError,
  setDashPieChartCountLoading,
  setDashPieChartCountError,
  setDashPieChartCountState,
  setDashBarChartCountLoading,
  setDashBarChartCountError,
  setDashBarChartCountState,
} from "../features/dashboardSlice";

export const getDashTechConsumersCounts = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setDashConsumersLoading(true));
      dispatch(setDashTechniciansLoading(true));

      const dashConsumersCount = await fetchDashConsumersCount();
      const dashTechniciansCount = await fetchDashTechniciansCount();
      
      dispatch(setDashConsumersState(dashConsumersCount));
      dispatch(setDashTechniciansState(dashTechniciansCount));

      dispatch(setDashConsumersLoading(false));
      dispatch(setDashTechniciansLoading(false));
    } catch (error) {
      console.error("Error fetching dash tech consumers:", error);
      dispatch(
        setDashConsumersError(
          "Failed to fetch dash consumers. Please try again!"
        )
      );
      dispatch(
        setDashTechniciansError(
          "Failed to fetch dash technicians. Please try again!"
        )
      );
    }
  };
};

export const getDashPieChartCounts = () => {
  return async (dispatch: Dispatch) => {
    try {
      
      dispatch(setDashPieChartCountLoading(true));
      const pieChartCounts = await fetchDashPieChartCount();
      dispatch(setDashPieChartCountState(pieChartCounts));
      dispatch(setDashPieChartCountLoading(false));

    } catch (error) {
      console.error("Failed to fetch pie chart count.:", error);
      dispatch(
        setDashPieChartCountError(
          "Failed to fetch pie chart count. Please try again!"
        )
      );
    }
  }
}

export const getDashJobTypesBarChartData = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setDashBarChartCountLoading(true));
      const barChartCounts = await fetchDashBarChartCount(); 
      dispatch(setDashBarChartCountState(barChartCounts));
      dispatch(setDashBarChartCountLoading(false));
    }catch (error) {
      console.error("Failed to fetch Bar chart count.:", error);
      dispatch(
        setDashBarChartCountError(
          "Failed to fetch Bar chart count. Please try again!"
        )
      );
    }
  }
}
