import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "./Button";
import SearchIcon from "../assets/search.png";
import ResetIcon from "../assets/reset.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import chevronLeft from "../assets/chevron-left.png";
import chevronRight from "../assets/chevron-right.png";

interface Props {
  data: any;
  buttonName?: string;
  buttonAction?: () => void;
  viewAction?: any;
  viewActionMethod?: (item: any) => void;
  hideFilter?: boolean;
  columns: (keyof any)[];
  columnLabels: string[];
  filters?: { label: string; key: string }[];
  statusAction?: any;
  statusActionMethod?: (item: any) => void;
  hideSerach?: boolean;
  editAction?: any;
  editActionMethod?: (item: any) => void;
  reloadData?: () => void;
  itemsPage?: number;
}

const searchInputStyle: React.CSSProperties = {
  // paddingRight: "0.5rem",
  paddingTop: "0.4rem",
  paddingBottom: "0.4rem",
  borderRadius: "5px",
  border: "1px solid #ccc",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  marginTop: "4vh",
  // backgroundImage: `url(${SearchIcon})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "24vh 50%",
  paddingLeft: "2vh",
};

const TableView: React.FC<Props> = ({
  data,
  buttonName,
  buttonAction,
  viewAction,
  viewActionMethod,
  hideFilter,
  columns,
  columnLabels,
  filters = [],
  statusAction,
  statusActionMethod,
  hideSerach,
  editAction,
  editActionMethod,
  reloadData,
  itemsPage,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage] = useState<number>(itemsPage || 10);
  const [selectedFilters, setSelectedFilters] = useState<{
    [key: number]: any;
  }>({});
  const [appliedFilters, setAppliedFilters] = useState<{
    [key: string]: string;
  }>({});

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset current page to 1 when the search term changes
  };

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = data && data.slice(indexOfFirstItem, indexOfLastItem);
  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setSelectedFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setCurrentPage(1);
  };

  // const filteredData =
  //   searchTerm || Object.keys(selectedFilters).length > 0
  //     ? currentItems.filter((item: any) => filterItem(item))
  //     : currentItems;

  const currentItems =
    searchTerm || Object.keys(selectedFilters).length > 0
      ? data &&
        data
          .filter((item: any) => filterItem(item))
          .slice(indexOfFirstItem, indexOfLastItem)
      : data && data.slice(indexOfFirstItem, indexOfLastItem);

  const showCount = (currentItems && currentItems.length) || 0;
  const filteredItems =
    searchTerm || Object.keys(selectedFilters).length > 0 ? currentItems : data;

  const totalCount = (filteredItems && filteredItems.length) || 0;

  function filterItem(item: any): boolean {
    const searchTermLower = searchTerm.toLowerCase();
    if (searchTermLower) {
      const isMatchingSearchTerm = (columns as string[]).some(
        (columnName: string) => {
          const columnValue = item[columnName];
          return (
            typeof columnValue === "string" &&
            columnValue.toLowerCase().includes(searchTermLower)
          );
        }
      );
      return isMatchingSearchTerm;
    } else if (
      "status" in selectedFilters &&
      selectedFilters["status"] !== "" &&
      filters.length === 1
    ) {
      const filterValue =
        selectedFilters["status"] === 1
          ? "Active"
          : selectedFilters["status"] === 0
          ? "Inactive"
          : selectedFilters["status"];
      return item["status"] == filterValue;
    } else if (filters.length > 0) {
      let isMatchingFilters;
      if (Object.keys(selectedFilters).length === 1) {
        // If only one filter is applied, use `some()` to check if at least one filter is met
        isMatchingFilters = filters.some((filter: any) => {
          const selectedValue = selectedFilters[filter.key];
          return selectedValue === "" || item[filter.key] === selectedValue;
        });
      } else {
        // If multiple filters are applied, use `every()` to check if all filters are met
        isMatchingFilters = filters.every((filter: any) => {
          const selectedValue = selectedFilters[filter.key];
          return selectedValue === "" || item[filter.key] === selectedValue;
        });
      }

      return isMatchingFilters;
    } else {
      return true;
    }
  }

  const handleFilterReset = () => {
    setSearchTerm("");
    setSelectedFilters({});
    setAppliedFilters({}); // Reset applied filters
    if (reloadData) {
      reloadData();
    }
  };

  return (
    <div style={{ margin: "0 2vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        {buttonName ? (
          <Button
            style={{
              width: "25vh",
              backgroundColor: "black",
              marginTop: "4vh",
              padding: "0.5rem",
            }}
            onClick={buttonAction}
            children={buttonName}
          />
        ) : null}

        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          {filters &&
            filters.map((filter: any, index: number) => {
              // Get unique values for the filter key

              if (data && data.length > 0) {
                const uniqueValues = [
                  ...new Set(data.map((item: any) => item[filter.key])),
                ];

                uniqueValues.sort((a: any, b: any) => {
                  // Check if both a and b are strings before using localeCompare
                  if (typeof a === "string" && typeof b === "string") {
                    return a.localeCompare(b);
                  }
                  // If a or b is not a string, fallback to default comparison
                  return a - b;
                });
                return (
                  <select
                    key={index}
                    style={{
                      padding: "0.4rem",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                      marginRight: "3vh",
                      marginTop: "4vh",
                      width: "150px",
                    }}
                    name={filter.key}
                    value={selectedFilters[filter.key] || ""}
                    onChange={handleFilterChange}
                  >
                    <option value="">{filter.label}</option>
                    {uniqueValues.map((value: any, index) => (
                      <option key={index} value={value}>
                        {value === 1
                          ? "Active"
                          : value === 0
                          ? "Inactive"
                          : value}
                      </option>
                    ))}
                  </select>
                );
              }
            })}

          {!hideFilter && (
            <div style={{ marginTop: "4vh", marginRight: "3vh" }}>
              <FontAwesomeIcon
                icon={faSyncAlt}
                onClick={handleFilterReset}
                style={{
                  backgroundColor: "#777777",
                  color: "white",
                  borderRadius: "5px",
                  padding: "5px",
                  width: "2.3vh",
                  height: "2.5vh",
                  border: "2px solid #777777",
                  marginLeft: "10px",
                }}
              />
            </div>
          )}
          {!hideSerach && (
            <input
              type="text"
              placeholder="Search"
              style={searchInputStyle}
              value={searchTerm}
              onChange={handleSearchChange}
            />
          )}
        </div>
      </div>
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            {columnLabels.map((label, index) => (
              <th key={index}>{label}</th>
            ))}
            {viewAction ? <th>Actions</th> : <></>}
          </tr>
        </thead>
        <tbody>
          {currentItems &&
            currentItems.length > 0 &&
            currentItems.map((item: any) => (
              <tr key={item.id}>
                {columns.map((columnName, index) => {
                  let cellValue = item[columnName];
                  let cellColor = "black";

                  // Check the column name and cell value to determine the color
                  if (columnName === "status") {
                    if (cellValue === "In-Review") {
                      cellColor = "orange";
                    } else if (cellValue === "Active" || cellValue === 1) {
                      cellColor = "green";
                    } else if (cellValue === "Inactive" || cellValue === 0) {
                      cellColor = "red";
                    }

                    if (cellValue === 1) {
                      cellValue = "Active";
                    } else if (cellValue === 0) {
                      cellValue = "Inactive";
                    }
                  }

                  return (
                    <td key={index} style={{ color: cellColor }}>
                      {Array.isArray(cellValue)
                        ? cellValue.map(
                            (
                              product: { name?: string; type?: string },
                              index: number
                            ) => {
                              // Check if 'name' or 'type' exists and display accordingly
                              const value = product.name
                                ? product.name
                                : product.type
                                ? product.type
                                : "";
                              return (
                                value +
                                (index !== cellValue.length - 1 ? ", " : "")
                              );
                            }
                          )
                        : typeof cellValue === "object" // Check if cellValue is an object
                        ? Object.entries(cellValue).map(
                            ([key, value]: [string, any], index: number) => {
                              if (key === "name") {
                                return <div key={index}>{value}</div>;
                              }
                              return null;
                            }
                          )
                        : cellValue}
                    </td>
                  );
                })}
                {viewAction ? (
                  <td>
                    <button
                      style={{
                        border: "none",
                        background: "transparent",
                        width: "3vh",
                      }}
                      onClick={() => viewActionMethod && viewActionMethod(item)}
                    >
                      {viewAction}
                    </button>
                    {editAction ? (
                      <button
                        style={{
                          border: "none",
                          width: "3vh",
                          background: "transparent",
                          cursor: "pointer",
                          marginLeft: "2vh",
                        }}
                        onClick={() =>
                          editActionMethod && editActionMethod(item)
                        }
                      >
                        {editAction}
                      </button>
                    ) : (
                      <></>
                    )}
                    {statusAction ? (
                      <button
                        style={{
                          border: "none",
                          width: "3vh",
                          background: "transparent",
                          cursor: "pointer",
                          marginLeft: "2vh",
                        }}
                        onClick={() =>
                          statusActionMethod && statusActionMethod(item)
                        }
                      >
                        {statusAction}
                      </button>
                    ) : (
                      <></>
                    )}
                  </td>
                ) : (
                  <></>
                )}
              </tr>
            ))}
        </tbody>
      </Table>
      <div
        style={{
          marginLeft: "20px",
          display: "flex",
          alignItems: "center",
          gap: "2",
        }}
      >
        <p style={{ marginRight: "5px" }}>
          {currentItems && currentItems.length > 0
            ? `Show ${showCount} out of ${totalCount} results`
            : "No results found"}
        </p>

        <p>
          <img
            src={chevronLeft}
            style={{ marginRight: "5px", height: "20px", width: "20px" }}
          />
        </p>
        <p>
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={totalCount}
            currentPage={currentPage}
            paginate={paginate}
          />
        </p>

        <p>
          <img
            src={chevronRight}
            style={{ height: "10px", width: "10px", marginLeft: "-10px" }}
          />
        </p>
      </div>
    </div>
  );
};
interface PaginationProps {
  itemsPerPage: number;
  totalItems: number;
  currentPage: number;
  paginate: (pageNumber: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  itemsPerPage,
  totalItems,
  currentPage,
  paginate,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav style={{ marginTop: "1rem" }}>
      <ul
        className="pagination"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "1rem",
        }}
      >
        {pageNumbers.map((number) => (
          <li
            key={number}
            className="page-item"
            style={{ marginRight: "10px" }}
          >
            <a
              onClick={() => paginate(number)}
              href="#"
              className={`page-link ${
                number === currentPage ? "current-page" : ""
              }`}
              style={{
                color: "black",
                boxShadow:
                  number === currentPage ? "0px 0px 5px #888888" : "none",
              }}
            >
              {number}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default TableView;
