
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Consumer, profileImageUrl } from "../type";
// Define the shape of the state for consumers slice
interface ConsumerState {
    consumers: Consumer[];
    loading: boolean;
    error: string | null;
    success: boolean;
    selectedConsumer: Consumer | null;
    searchConsumer: Consumer | null;
    count?: number,
}
// Define the initial state for the consumers slice
const initialState: ConsumerState = {
    consumers: [],
    loading: false,
    error: null,
    success: false,
    selectedConsumer: null,
    searchConsumer: null,
    count: 0,
};
// Create the consumers slice
const consumerSlice = createSlice({
    name: 'consumers',           // Name of the slice
    initialState,
    reducers: {
        // Reducer to set loading to true and clear any existing error message
        setConsumers(state, action: PayloadAction<Consumer[]>) {
            state.loading = false;
            state.error = null;
            state.success = false;
            state.consumers = action.payload;
        },
        setConusmersCount(state, action: any) {
            return {
              ...state,
              count : action.payload,
            };
          },
        setConsumersFilters(state, action: PayloadAction<Consumer | null>) {
            state.searchConsumer = action.payload;
        },
        setConsumersLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
            state.error = null;
            state.success = false;
        },
        setConsumersError(state, action: PayloadAction<string | null>) {
            state.loading = false;
            state.success = false;
            state.error = action.payload;
        },
        addConsumersState(state, action: PayloadAction<Consumer>) {
            // state.consumers.push(action.payload);
            state.loading = false;
            state.error = null;
            state.success = true;
        },
        addprofileImageUrl(state, action: PayloadAction<{ consumerId: string, profileImageUrl: profileImageUrl }>) {
            // Assuming your API returns the updated consumer object with the new profile image
            const { consumerId, profileImageUrl } = action.payload;
            const consumerIndex = state.consumers.findIndex(consumer => consumer.id === consumerId);
            if (consumerIndex !== -1) {
                state.consumers[consumerIndex].profileImageUrl = profileImageUrl;
            }
        },
        setSelectedConsumer(state, action: PayloadAction<Consumer | null>) {
            state.selectedConsumer = action.payload; // Update selectedConsumer in the state
        },

        editConsumerState(state, action: PayloadAction<{ consumerId: string; updatedConsumer: Consumer }>) {
            const { consumerId, updatedConsumer } = action.payload;
            const index = state.consumers.findIndex(consumers => consumers.id === consumerId);
            if (index !== -1) {
                state.consumers[index] = updatedConsumer;
                state.loading = false;
                state.error = null;
                state.success = true;
            }
        },
    },
});
export const {
    setConsumers,
    setConusmersCount,
    setConsumersFilters,
    setConsumersLoading,
    setConsumersError,
    addConsumersState,
    addprofileImageUrl,
    setSelectedConsumer,
    editConsumerState
} = consumerSlice.actions;
export default consumerSlice.reducer;
