import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import "../../styles/Technician/Technician.WorkInfo.page.css";
import TechnicianWorkView from "./TechnicianWorkView";
import { Modal } from "react-bootstrap";
import arrowIcon from "../../assets/down_arrow.png";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL, BASE_URL_FOR_IMAGE } from "../../constants/appConstants";
import { getWorkRequests } from "../../services/workRequestService";
import WorkRequestStatus from "../../components/WorkRequestStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

interface WorkRequest {
  id: string;
  startingDate: string;
  budjet: number;
  workDescription: string;
  address: string;
  district: string;
  city: string;
  jobTypes: [
    {
      id: string;
      type: string;
      description: string;
      status: number;
    }
  ];
  status: string;
  longitude: string;
  latitude: string;
  consumer: {
    id: string;
    firstName: string;
    lastName: string;
    contactNumberPrimary: string;
    profileImage: {
      url: string;
    };
  };
  assignedTechnician: {
    id: string;
    firstName: string;
    lastName: string;
    address: string;
    city: string;
    contactNumberPrimary: string;
    profileImage: {
      url: string;
    };
  };
}

const TechnicianWorkInfo = () => {
  const technicianWorkRequests = useSelector(
    (state: any) => state.selectedTechnican.workRequests
  );
  const workRequests = technicianWorkRequests?.results;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage] = useState<number>(10);
  const [filter, setFilter] = useState("all");
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    workRequests && workRequests.length > 0
      ? workRequests.slice(indexOfFirstItem, indexOfLastItem)
      : [];

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const [showModal, setShowModal] = useState(false);
  const [selectedWorkRequest, setSelectedWorkRequest] = useState<any>();

  const handleClose = () => setShowModal(false);
  const handleShow = (workRequest: any) => {
    setSelectedWorkRequest(workRequest);
    setShowModal(true);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value.toLowerCase();
    setFilter(selectedValue);
    setCurrentPage(1); // Reset pagination when filter changes
  };

  const filteredItems = currentItems.filter((workRequest: any) => {
    if (filter === "all") return true;
    return workRequest.status.toLowerCase() === filter;
  });

  return (
    <div className="work-info-tab">
      <h4>Current Work</h4>
      <div className="filter-option">
        <select value={filter} onChange={handleFilterChange}>
          <option value="all">All</option>
          <option value="in-review">New</option>
          <option value="assigned">Assigned</option>
          <option value="in-progress">In Progress</option>
          <option value="on-hold">On Hold</option>
          <option value="completed">Completed</option>
          <option value="accepted">Accepted</option>
          <option value="rejected">Rejected</option>
        </select>
        <img src={arrowIcon} alt="Arrow Icon" className="arrow-icon" />
      </div>
      {filteredItems.length > 0 &&
        filteredItems.map((workRequest: WorkRequest) => (
          <div className="work-card">
            <Card key={workRequest.id}>
              <Card.Body>
                <div
                  className="work-info"
                  style={{ height: "4rem", alignItems: "baseline" }}
                >
                  <div className="profile" style={{ flex: 2 }}>
                    <div className="profile-img">
                      <img
                        src={
                          BASE_URL_FOR_IMAGE +
                          workRequest.consumer?.profileImage?.url
                        }
                        alt="profile"
                      />
                    </div>
                    <div className="work-title">
                      <div style={{ color: "black", fontWeight: "bold" }}>
                        {workRequest.jobTypes[0].type}
                      </div>
                      <div>
                        {workRequest.consumer?.firstName}{" "}
                        {workRequest.consumer?.lastName}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flex: "1",
                    }}
                  >
                    <WorkRequestStatus status={workRequest.status} />
                  </div>
                  <div
                    className="work-budget"
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    RS {workRequest.budjet}
                  </div>
                  <div
                    className="work-date"
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FontAwesomeIcon icon={faClock} className="clock-icon" />
                    {workRequest.startingDate}
                  </div>
                  <div
                    className="work-location"
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      className="location-icon"
                    />{" "}
                    {workRequest.district}
                  </div>
                  <div
                    className="work-view"
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      className="view-button"
                      onClick={() => handleShow(workRequest)}
                    >
                      View
                    </button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        ))}

      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={workRequests && workRequests.length}
        paginate={paginate}
      />

      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        style={{ border: "none" }}
      >
        {/* <Modal.Header closeButton style={{ border: "none" }}>
        </Modal.Header> */}
        <TechnicianWorkView
          workRequest={selectedWorkRequest}
          onClose={handleClose}
        />
      </Modal>
    </div>
  );
};

interface PaginationProps {
  itemsPerPage: number;
  totalItems: number;
  paginate: (pageNumber: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  itemsPerPage,
  totalItems,
  paginate,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav style={{ marginTop: "1rem" }}>
      <ul
        className="pagination"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        {pageNumbers.map((number) => (
          <li key={number} className="page-item">
            <a
              onClick={() => paginate(number)}
              href="#"
              className="page-link"
              // style={{ color: "black" }}
            >
              {number}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default TechnicianWorkInfo;
