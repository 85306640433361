import { BASE_URL } from '../constants/appConstants';


export const authSignIn = async (signinData: any) => {
  try {
    const end_url = BASE_URL + "/auth/sign-in";
    const response = await fetch(end_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(signinData),
    });
    const appUser = await response.json();
    return appUser;
  } catch (error) {
    throw new Error('Failed to log in');
  }
};
