import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CityData, DistrictData } from "../type";
// Define the shape of the state for city and district data slice
interface LocationDataState {
    cityData: CityData[];
    districtData: DistrictData[];
    loading: boolean;
    error: string | null;
}
// Define the initial state for the city and district data slice
const initialState: LocationDataState = {
    cityData: [],
    districtData: [],
    loading: false,
    error: null,
};
// Create the city and district data slice
const locationDataSlice = createSlice({
    name: 'locationData',           
    initialState,
    reducers: {
        // Reducer to set city data
        setCityData(state, action: PayloadAction<CityData[]>) {
            state.loading = false;
            state.error = null;
            state.cityData = action.payload;
        },
        // Reducer to set district data
        setDistrictData(state, action: PayloadAction<DistrictData[]>) {
            state.loading = false;
            state.error = null;
            state.districtData = action.payload;
        },
        // Reducer to set loading status for city and district data
        setLocationDataLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
            state.error = null;
        },
        // Reducer to set error message for city and district data
        setLocationDataError(state, action: PayloadAction<string | null>) {
            state.loading = false;
            state.error = action.payload;
        },
    },
});
export const {
    setCityData,
    setDistrictData,
    setLocationDataLoading,
    setLocationDataError,
} = locationDataSlice.actions;
export default locationDataSlice.reducer;