import React, { useEffect, useState } from "react";
import styles from "../../styles/WorkRequestList.module.scss";
import { Container, Form } from "react-bootstrap";
import Button from "../../components/Button";
import SingleWorkRequestComponent from "./SingleWorkRequestComponent";
import chevronLeft from "../../assets/chevron-left.png";
import chevronRight from "../../assets/chevron-right.png";
import { workRequestType, workRequestCreateType } from "../../type";
import { useDispatch } from "react-redux";

interface Technician {
  id: number;
  name: string;
  nic: string;
  education: string;
  address: string;
  imageUrl: string;
  workingArea: string;
  primary: string;
  mobile: string;
  jobType: string;
  rating: number;
}

interface Props {
  buttonAction: () => void;
  data: workRequestType[]; // Change the type here
  viewActionMethod?: (item: any) => void;
  viewAssignTechnician?: (item: any) => void;
  reloadData: () => void;
}

const WorkRequestList: React.FC<Props> = ({
  buttonAction,
  data,
  viewActionMethod,
  viewAssignTechnician,
  reloadData,
}) => {
  const dispatch = useDispatch<any>();
  const [showCreateForm, setShowCreateForm] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage] = useState<number>(25);
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems =
  //   data && data.length > 0 && data.slice(indexOfFirstItem, indexOfLastItem);
  useEffect(() => {
    reloadData();
  }, [dispatch]);

  const filteredItems = selectedStatus
    ? data.filter((item) =>
        selectedStatus === "assign"
          ? item.assignedTechnician
          : !item.assignedTechnician
      )
    : data;

  const [searchTerm, setSearchTerm] = useState<string>("");

  const filteredItemsByStatus = selectedStatus
    ? data.filter((item) =>
        selectedStatus === "assign"
          ? item.assignedTechnician
          : !item.assignedTechnician
      )
    : data;

  const filteredItemsBySearchTerm = searchTerm
    ? data.filter(
        (item) =>
          item.consumer &&
          (item.consumer.firstName.toLowerCase().includes(searchTerm) ||
            item.consumer.lastName.toLowerCase().includes(searchTerm) ||
            item.consumer.contactNumberPrimary.includes(searchTerm))
      )
    : data;

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    setCurrentPage(1);
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedStatus(e.target.value);
    setCurrentPage(1);
  };

  const searchInputStyle: React.CSSProperties = {
    // paddingRight: "0.5rem",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    borderRadius: "5px",
    border: "1px solid #ccc",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginTop: "4vh",
    // backgroundImage: `url(${SearchIcon})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "24vh 50%",
    paddingLeft: "2vh",
    marginRight: "4vh",
  };
  // const currentItems = filteredItems && filteredItems.length && filteredItems.slice(indexOfFirstItem, indexOfLastItem);
  const currentItems = filteredItemsByStatus
    .filter((item) => filteredItemsBySearchTerm.includes(item))
    .slice(indexOfFirstItem, indexOfLastItem);
  const showCount = (currentItems && currentItems.length) || 0;
  const totalCount = (filteredItems && filteredItems.length) || 0;

  return (
    <div className={styles.appContainer}>
      <div className={styles.topSection}>
        <div className={styles.topRight}>
        <Button
            style={{
              backgroundColor: "black",
              color: "#ffffff",
              width: "200px",
              marginLeft: "28px",
              // marginRight: "120px",
              paddingTop: "1vh",
              paddingBottom: "1vh",
              fontSize: "16px",
            }}
            onClick={buttonAction}
            children={"Create Work Request"}
            bIcon=""
          />
        </div>
        <div className={styles.topLeft}>
          <Form.Select
            aria-label="WorkRequests"
            className={styles.inputField}
            value={selectedStatus}
            onChange={handleStatusChange}
            // style={{ width: "240px", marginTop: "10px", marginRight: "35px" }}
            style={{
              padding: "0.5rem",
              borderRadius: "5px",
              border: "1px solid #ccc",
              marginRight: "4vh",
              marginTop: "4vh",
            }}
          >
            <option value="">Status</option>
            <option value="unassign">New Work Requests</option>
            <option value="assign">Assigned Work Requests</option>
          </Form.Select>
          <Form.Control
            type="text"
            placeholder="Search"
            className={styles.inputField}
            // style={{ width: "10rem", marginTop: "10px"}}
            style={searchInputStyle}
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        
      </div>
      <SingleWorkRequestComponent
            data={currentItems}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            viewActionMethod={viewActionMethod}
            viewAssignTechnician={viewAssignTechnician}
            reloadData={reloadData}
          ></SingleWorkRequestComponent>
      <div className={styles.paginationSection}>
        <p style={{ marginRight: "5px" }}>
          {"Show " + showCount + " out of " + data.length + " results"}
        </p>

        <p>
          <img
            src={chevronLeft}
            style={{ marginRight: "5px", height: "20px", width: "20px" }}
          />
        </p>
        <p>
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={totalCount}
            currentPage={currentPage}
            paginate={paginate}
          />
        </p>

        <p>
          <img
            src={chevronRight}
            style={{
              marginLeft: "-10px", // Adjust marginLeft for the right chevron
              height: "10px",
              width: "10px",
            }}
          />
        </p>
      </div>
    </div>
  );
};

interface PaginationProps {
  itemsPerPage: number;
  totalItems: number;
  currentPage: number;
  paginate: (pageNumber: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  itemsPerPage,
  totalItems,
  currentPage,
  paginate,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav style={{ marginTop: "1rem" }}>
      <ul
        className="pagination"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "1rem",
        }}
      >
        {pageNumbers.map((number) => (
          <li
            key={number}
            className="page-item"
            style={{ marginRight: "10px" }}
          >
            <a
              onClick={() => paginate(number)}
              href="#"
              className={`page-link ${
                number === currentPage ? "current-page" : ""
              }`}
              style={{
                color: "black",
                boxShadow:
                  number === currentPage ? "0px 0px 5px #888888" : "none",
              }}
            >
              {number}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default WorkRequestList;
