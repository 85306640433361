import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import "../styles/CardCustom.scss";
import Button from "./Button";
import QuantityDropdown from "./QuantityDropdown";
import ColorSelection from "./ColorSelection";
import CustomCard from "./SingleCard";
import p1 from "../assets/p1.png";

import { useDispatch, useSelector } from "react-redux";
import {
  addToMyItems,
  editMyItems,
  setMyItems,
} from "../features/myItemsSlice";
import { CartItem, cardItem } from "../type";

export const CustomNextArrow = (props: any) => {
  const { onClick } = props;
  return (
    <div className="custom-next-arrow" onClick={onClick}>
      <FontAwesomeIcon icon={faAngleRight} />
    </div>
  );
};

export const CustomPrevArrow = (props: any) => {
  const { onClick } = props;
  return (
    <div className="custom-prev-arrow" onClick={onClick}>
      <FontAwesomeIcon icon={faAngleLeft} />
    </div>
  );
};

const settings = {
  dots: false,
  infinite: false,
  arrows: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: <CustomNextArrow />,
  prevArrow: <CustomPrevArrow />,
};

interface CardCustomProps {
  data: any;
  onCancel?: () => void;
  setSingleView: (status: boolean) => void;
}

const CardCustom: React.FC<CardCustomProps> = ({
  data,
  onCancel,
  setSingleView,
}) => {
  const dispatch = useDispatch<any>();
  const myItemsArray = useSelector((state: any) => state.myItems.myItems);
  const [selectedCard, setSelectedCard] = useState<cardItem | null>(null);
  const [showSingleCardView, setShowSingleCardView] = useState<boolean>(false);
  const [editMyListItem, setEditMyListItem] = useState<boolean>(false);
  const [inquryButtonEnable, setInquryButtonEnable] = useState<boolean>(false);
  const [quentity, setQuentity] = useState<number>(1);
  const buttonColour = inquryButtonEnable ? "#FFC600" : "#777777";

  const onCancelSingleView = () => {
    setShowSingleCardView(false);
    setSingleView(false);
  };
  const handleCardClick = (item: cardItem) => {
    setSelectedCard(item);
    setShowSingleCardView(true);
    setSingleView(true);
  };

  const handleQuantityChange = (newQuantity: any) => {
    if (newQuantity >= 1) {
      setQuentity(newQuantity);
    }
  };

  const addToCart = () => {
    if (selectedCard) {
      const newItem: CartItem = {
        ...selectedCard,
        quantity: quentity,
      };
      dispatch(addToMyItems(newItem));
      setShowSingleCardView(false);
      setSingleView(false);
    }
  };

  const colors = ["red", "blue", "green", "yellow"];
  return (
    <Container>
      {showSingleCardView && selectedCard ? (
        <div>
          <div className="single-view">
            <img
              src={selectedCard.parameterView ? selectedCard.parameterView : p1}
              className="product-image"
              alt="Card"
            />
            <div className="wording-box">
              <p className="wording-name">{selectedCard.name}</p>
              <p className="wording-desc">{selectedCard.description}</p>
              <div>
                <p className="wording-lines">Unit Price</p>
                <p className="wording-qty">Rs : 450</p>
              </div>
              <p className="wording-lines">Choose Colors</p>
              <ColorSelection colors={colors} />
              <div className="qty-box">
                <p className="wording-qty">Qty</p>
                <QuantityDropdown
                  quantity={quentity}
                  onChange={handleQuantityChange}
                />
              </div>
            </div>
          </div>
          <div className="button-container">
            <Button
              style={{
                width: "25vh",
                backgroundColor: "black",
                marginRight: "3.5vh",
                marginTop: "0.5rem",
              }}
              onClick={onCancelSingleView}
            >
              Cancel
            </Button>
            <Button
              style={{
                width: "25vh",
                marginRight: "3.5vh",
                marginTop: "0.5rem",
              }}
              onClick={() => {
                addToCart();
              }}
            >
              {editMyListItem ? "Edit" : "Add to Inquiry"}
            </Button>
          </div>
        </div>
      ) : (
        <>
            {data && data.length > 0 ? (
              <>
                {data.map((productType: any, index: any) => (
                  <Row key={index}>
                    <div className="product-type">{productType.title}</div>
                    <Slider {...settings}>
                      {productType.items &&
                        productType.items.map((item: any, itemIndex: any) => (
                          <Col key={itemIndex}>
                            <div className="card-container">
                              <CustomCard
                                parameterView={item.parameterView}
                                description={item.name}
                                unitPrice={
                                  item.unitPrice ? item.unitPrice : false
                                }
                                onClick={() => handleCardClick(item)}
                                cardHeight={"45vh"}
                              />
                            </div>
                          </Col>
                        ))}
                    </Slider>
                  </Row>
                ))}
              </>
            ) : (
              <>
                <Row key={0}>
                  <div className="product-type">{data.title}</div>
                  <Slider {...settings}>
                    {data.items &&
                      data.items.map((item: any, itemIndex: any) => (
                        <Col key={itemIndex}>
                          <div className="card-container">
                            <CustomCard
                              parameterView={item.parameterView}
                              description={item.name}
                              unitPrice={
                                item.unitPrice ? item.unitPrice : false
                              }
                              onClick={() => handleCardClick(item)}
                              cardHeight={"45vh"}
                            />
                          </div>
                        </Col>
                      ))}
                  </Slider>
                </Row>
              </>
            )}
        </>
      )}
    </Container>
  );
};

export default CardCustom;
