import { Dispatch } from "redux";
import { TechnicianType, TechnicianCreateType } from "../type";
import {
  setTechnicians,
  setTechniciansError,
  setTechniciansLoading,
  addTechnicianState,
  editTechnicianState,
  setTechniciansForJobs,
  setTechnicianRatingsReviewsLoading,
  setTechnicianRatingsReviewsError,
  setTechnicianRatingsReviewsState,
  setTechniciansCount,
} from "../features/technicianSlice";
import {
  fetchTechnicians,
  createTechnician,
  updateTechnician,
  fetchTechnicianByID,
  fetchTechnicianByJobType,
  promoteTechnicianLevel,
  fetchTechnicianTenentLevels,
  fetchWorkRequestsByTechnicianID,
  downgradeTechnician,
  fetchTechnicianRatingsReviews,
  serachTechnicianAPI,
  filterTechnicianAPI,
} from "../api/technicianAPI";
import { convertToImageFile } from "../utils/utils";
import {
  promoteTechnicianState,
  setPromoteTchniciansLoading,
  setPromoteTechniciansError,
  setSelectedTechniciansError,
  setSelectedTechniciansLoading,
  setSingleTechnician,
  setSingleTechnicianWorkRequestsLoading,
  setSingleTechniciansWorkRequestsError,
  setSingleTechniciansWorkRequestsState,
  setTechnicianTenentLevelError,
  setTechnicianTenentLevelState,
  setTechnicianTenetLevelLoading,
} from "../features/selectedTechnicanSlice";

export const getTechnicians = (page: number, items: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setTechniciansLoading(true));
      const technicians = await fetchTechnicians(page, items);
      dispatch(setTechniciansLoading(false));
      if (technicians.errorCode) {
        dispatch(setTechniciansError(technicians.errorDescription));
      } else {
        dispatch(setTechnicians(technicians.results));
        dispatch(setTechniciansCount(technicians.count));
      }
    } catch (error) {
      console.error("Error fetching technicians:", error);
      dispatch(
        setTechniciansError("Failed to fetch technicians. Please try again!")
      );
    }
  };
};

export const getTechniciansByID = (technicianId: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setSelectedTechniciansLoading(true));
      const technicianData = await fetchTechnicianByID(technicianId);
      dispatch(setSelectedTechniciansLoading(false));
      console.log("technicianData",technicianData)
      if (technicianData.errorCode) {
        dispatch(setSelectedTechniciansError(technicianData.errorDescription));
      } else {
        dispatch(setSingleTechnician(technicianData));
      }
      
    } catch (error) {
      console.error("Error fetching technicians:", error);
      dispatch(
        setSelectedTechniciansError("Failed to fetch technicians. Please try again!")
      );
    }
  };
};

export const getTechniciansByJobID = (jobID: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const technicianData = await fetchTechnicianByJobType(jobID);
      dispatch(setTechniciansForJobs(technicianData));
    } catch (error) {
      console.error("Error fetching technicians:", error);
      dispatch(
        setTechniciansError("Failed to fetch technicians. Please try again!")
      );
    }
  };
};

export const getWorkRequestsByTechnicianID = (technicianId: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setSingleTechnicianWorkRequestsLoading(true));
      const workRequestsForTechnician = await fetchWorkRequestsByTechnicianID(
        technicianId
      );
      dispatch(setSingleTechnicianWorkRequestsLoading(false));

      if (workRequestsForTechnician.errorCode) {
        dispatch(
          setSingleTechniciansWorkRequestsError(
            workRequestsForTechnician.errorDescription
          )
        );
      } else {
        dispatch(
          setSingleTechniciansWorkRequestsState(workRequestsForTechnician)
        );
      }
    } catch (error) {
      console.error("Error fetching technician's work requests:", error);
      dispatch(
        setSingleTechniciansWorkRequestsError(
          "Failed to fetch technician's work requests. Please try again!"
        )
      );
    }
  };
};

export const addTechnician = (technicianData: TechnicianCreateType) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setTechniciansLoading(true));
      const nicFrontImageUrl = technicianData.nicFrontImageUrl;
      const nicBackImageUrl = technicianData.nicBackImageUrl;
      const profileImageUrl = technicianData.profileImageUrl;

      const registrationData = {
        ...technicianData,
        nicFrontImageUrl: nicFrontImageUrl,
        nicBackImageUrl: nicBackImageUrl,
        profileImageUrl: profileImageUrl,
      };
      const newTechnician = await createTechnician(registrationData);

      dispatch(setTechniciansLoading(false));
      if (newTechnician.errorCode) {
        dispatch(setTechniciansError(newTechnician.errorDescription));
      } else {
        dispatch(addTechnicianState(newTechnician));
      }
    } catch (error) {
      console.error("Error creating technician:", error);
      dispatch(
        setTechniciansError("Failed to create technician. Please try again!")
      );
    }
  };
};

export const editTechnician = (
  technicianId: string,
  updatedTechnicianData: TechnicianType
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setTechniciansLoading(true));

      const updatedTechnician = await updateTechnician(
        technicianId,
        updatedTechnicianData
      );
      dispatch(setTechniciansLoading(false));

      if (updatedTechnician.errorCode) {
        dispatch(setTechniciansError(updatedTechnician.errorDescription));
      } else {
        dispatch(editTechnicianState({ id: technicianId, updatedTechnician }));
      }
    } catch (error) {
      console.error("Error updating technician:", error);
      dispatch(
        setTechniciansError("Failed to update technician. Please try again!")
      );
    }
  };
};

export const promoteTechnician = (promoteData: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setPromoteTchniciansLoading(true));
      const promotedTechnician = await promoteTechnicianLevel(
        promoteData.technicianId,
        promoteData.tanentId,
        promoteData.technicianLevelId
      );
      dispatch(setPromoteTchniciansLoading(false));
      if (promotedTechnician.errorCode) {
        dispatch(
          setPromoteTechniciansError(promotedTechnician.errorDescription)
        );
      } else {
        dispatch(promoteTechnicianState(true));
      }
    } catch (error) {
      console.error("Error promoting technician:", error);
      dispatch(
        setPromoteTechniciansError("Failed to promote technician. Please try again!")
      );
    }
  };
};

export const technicianTenentLevels = (technicianId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setTechnicianTenetLevelLoading(true));
      const tenentLevels = await fetchTechnicianTenentLevels(technicianId);
      dispatch(setTechnicianTenetLevelLoading(false));

      if (tenentLevels.errorCode) {
        dispatch(setTechnicianTenentLevelError(tenentLevels.errorDescription));
      } else {
        dispatch(setTechnicianTenentLevelState(tenentLevels.results));
      }
    } catch (error) {
      console.error("Error Fetching Technician Tenent Levels:", error);
      dispatch(
        setTechnicianTenentLevelError(
          "Failed to fetch technician tenent levels. Please try again!"
        )
      );
    }
  };
};

export const downgradeTechnicianService = (downgradeData: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setPromoteTchniciansLoading(true));
      const downgradeTechnicianData = await downgradeTechnician(
        downgradeData.technicianId,
        downgradeData.downgradeTechnician
      );
      dispatch(setPromoteTchniciansLoading(false));
      if (downgradeTechnicianData.errorCode) {
        dispatch(
          setPromoteTechniciansError(downgradeTechnicianData.errorDescription)
        );
      } else {
        dispatch(promoteTechnicianState(true));
      }
    } catch (error) {
      console.error("Error downgrade technician:", error);
      dispatch(
        setTechniciansError("Failed to downgrade technician. Please try again!")
      );
    }
  };
};

export const getTechniciansRatingsReviews = (technicianId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setTechnicianRatingsReviewsLoading(true));
      const ratingsReviews = await fetchTechnicianRatingsReviews(technicianId);
      dispatch(setTechnicianRatingsReviewsLoading(false));
      if (ratingsReviews.errorCode) {
        dispatch(
          setTechnicianRatingsReviewsError(ratingsReviews.errorDescription)
        );
      } else {
        dispatch(setTechnicianRatingsReviewsState(ratingsReviews.results));
      }
    } catch (error) {
      console.error("Error fetching technician's work requests:", error);
      dispatch(
        setSingleTechniciansWorkRequestsError(
          "Failed to fetch technician's work requests. Please try again!"
        )
      );
    }
  };
};

export const serachTechnicianByValues = (
  serachBy: string,
  searchValue: string
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setTechniciansLoading(true));
      const searchTechnicians = await serachTechnicianAPI(
        serachBy,
        searchValue
      );
      dispatch(setTechniciansLoading(false));
      if (searchTechnicians.errorCode) {
        dispatch(
          setTechnicianRatingsReviewsError(searchTechnicians.errorDescription)
        );
      } else {
        dispatch(setTechnicians(searchTechnicians.results));
        dispatch(setTechniciansCount(searchTechnicians.count));
      }
    } catch (error) {
      console.error("Error serach technician error:", error);
      dispatch(
        setTechniciansError("Failed to search technicians. Please try again!")
      );
    }
  };
};

export const filterTechnicians = (filterValues: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setTechniciansLoading(true));
      const { mainWorkingArea, tanentId, leveledUp, registrationSource } =
        filterValues;

      // Filter out empty values
      const filteredValues: any = {};
      if (mainWorkingArea) filteredValues.area = mainWorkingArea;
      if (tanentId) filteredValues.tanentId = tanentId;
      if (leveledUp && leveledUp === "true") {
        filteredValues.leveledUp = true;
      } else if (leveledUp && leveledUp === "false") {
        filteredValues.leveledUp = "false";
      }
      if (registrationSource) filteredValues.source = registrationSource;

      const searchTechnicians = await filterTechnicianAPI(filteredValues);
      dispatch(setTechniciansLoading(false));
      if (searchTechnicians.errorCode) {
        dispatch(
          setTechnicianRatingsReviewsError(searchTechnicians.errorDescription)
        );
      } else {
        dispatch(setTechnicians(searchTechnicians.results));
        dispatch(setTechniciansCount(searchTechnicians.count));
      }
    } catch (error) {
      console.error("Error searching for technicians:", error);
      dispatch(
        setTechniciansError("Failed to search for technicians. Please try again!")
      );
    }
  };
};

