import { Card } from "react-bootstrap";
import ratingStyles from "../../styles/Consumer/Consumer.rating.module.scss";
import Rating from "../../components/RatingStars";
import star_purple from "../../assets/star_purple.png";
import { FaStar } from "react-icons/fa";
import { BASE_URL_FOR_IMAGE } from "../../constants/appConstants";

interface ReviewResult {
  id: string;
  rating: number;
  comment: string;
  ratedBy: {
    id: string;
    profileImage: {
      url: string;
    };
    userAccount: {
      firstName: string;
      lastName: string;
    };
  };
  createdDate: string;
}

interface ConsumerReviewData {
  consumerData: any;
  count: number;
  review: any;
}

const TechnicianFeedback: React.FC<ConsumerReviewData> = ({
  consumerData,
  review,
}) => {
  const result = review?.results;

  return (
    <div className={ratingStyles.feedbackCard}>
      <Card>
        <Card.Body>
          <div>
            <div>
              <h6 className={ratingStyles.feedbacktext}>Technician Feedback</h6>
            </div>

            <div className={ratingStyles.rating}>
              <div className={ratingStyles.bottemratingstars}>
                <Rating rating={consumerData.averageRating} />
                <p>{consumerData.averageRating}</p>
              </div>
              <div className={ratingStyles.ratingnum}></div>
              <div className={ratingStyles.ratingnum}>
                ({result?.length} reviews)
              </div>
            </div>
            <hr />

            <div className={ratingStyles.reviewsbottem}>
              <div className={ratingStyles.reviewsContainer}>
                {result?.length > 0 &&
                  result.map((reviewItem: any) => (
                    <div key={reviewItem.id} className={ratingStyles.review}>
                      <div
                        className={ratingStyles.ratingstarimg}
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        <img src={star_purple} alt="four star" />
                        <span
                          style={{
                            position: "absolute",
                            top: "40%",
                            left: "55%",
                            transform: "translate(-50%, -50%)",
                            color: "black",
                            fontSize: "10px",
                          }}
                        >
                          {consumerData.averageRating &&
                          consumerData.averageRating !== "NaN"
                            ? consumerData.averageRating
                            : ""}
                        </span>
                      </div>

                      <div className={ratingStyles.reviewdetails}>
                        <div>{reviewItem.comment}</div>
                        <div
                          style={{
                            flex: 1,
                            display: "flex",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <div className={ratingStyles.reviewerimage}>
                            <img
                              src={
                                reviewItem.ratedBy.profileImage
                                  ? BASE_URL_FOR_IMAGE +
                                    reviewItem.ratedBy.profileImage.url
                                  : "https://media.istockphoto.com/id/519977141/photo/daydreaming-about-her-future.jpg?s=2048x2048&w=is&k=20&c=iAGtxkCqomrBrW8fKsMJtZruQbcuX3oV6XZQdfkIGRo="
                              }
                              alt="reviewer image"
                            />
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div
                              style={{ fontSize: "13px", fontWeight: "bold" }}
                            >
                              {reviewItem.ratedBy.userAccount.firstName}{" "}
                              {reviewItem.ratedBy.userAccount.lastName}
                            </div>

                            <div
                              className={ratingStyles.datetext}
                              style={{ fontSize: "11px" }}
                            >
                              {reviewItem.createdDate}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};
export default TechnicianFeedback;
