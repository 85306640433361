import { Dispatch } from 'redux';
import {
    setEmailVeifycationLoading,
    setEmailVeifycationError,
    addEmailVeifycationState,
    setResetPasswordLoading,
    setResetPasswordError,
    editResetPasswordState
} from '../features/ForgotPasswordSlice';
import { emailVerify, resetPassword } from '../api/forgotPasswordAPI';
import { ForgotPasswordEmailVerify, ResetPassword } from '../type';

// to get otp 
export const sendEmailForOTP = (email: ForgotPasswordEmailVerify) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(setEmailVeifycationLoading(true));
            const response = await emailVerify(email);
            dispatch(setEmailVeifycationLoading(false));
            if (response.errorCode) {
                dispatch(setEmailVeifycationError(response.errorDescription));
            } else {
                dispatch(addEmailVeifycationState(response));
            }
        } catch (error: any) {
            console.error("Error sending email:", error);
            dispatch(
                setEmailVeifycationError("Failed to send email. Please try again! " + (error as Error).message)
            );
        }


    };
};


//rest the password
export const restPasswordByEmailService = (resetDetails: ResetPassword) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(setResetPasswordLoading(true)); // Dispatch action to set loading state
            const responseReset = await resetPassword(resetDetails);// Call API method to reset password
            dispatch(setResetPasswordLoading(false));
            if (responseReset.errorCode) {
                dispatch(setResetPasswordError(responseReset.errorDescription)); // Dispatch action if there's an error
            } else {
                dispatch(editResetPasswordState(true));
            }
        } catch (error: any) {
            console.error("Error resetting password:", error);
            dispatch(setResetPasswordError("Failed to reset password. Please try again! " + (error as Error).message));
        }
    };
};
