import { Card } from "react-bootstrap";
import "../styles/CardCustom.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";

interface SingleCardProps {
  parameterView?: string;
  description?: string;
  onClick?: () => void;
  quantity?: number;
  unitPrice?: string;
  deleteIcon?: boolean;
  cardHeight?: any;
  deleteAction?: () => void;
  editIcon?: boolean;
  editAction?: () => void;
}

const SingleCard: React.FC<SingleCardProps> = ({
  parameterView,
  description,
  onClick,
  quantity,
  unitPrice,
  deleteIcon,
  cardHeight,
  deleteAction,
  editIcon,
  editAction,
}) => {
  
  return (
    <Card
      style={{ margin: "0.5rem 0.8rem 0.5rem 0", height: cardHeight }}
      onClick={onClick}
    >
      <div style={{display: 'flex', justifyContent: 'flex-start', flexDirection: "row"}}>
        {deleteIcon ? (
          <FontAwesomeIcon
          icon={faTrash}
          className="delete-icon"
          onClick={deleteAction}
        />
        ) : null}
        {editIcon ? (
          <FontAwesomeIcon
          icon={faEdit}
          className="edit-icon"
          onClick={editAction}
        />
        ) : null}
      </div>

      <Card.Img style={{ height: "30vh" , cursor: "pointer"}} variant="top" src={parameterView} />
      <Card.Body>
        <Card.Text>{description}</Card.Text>
        {quantity ? (
          <Card.Text className="product-label">Quantity: {quantity}</Card.Text>
        ) : null}
        {unitPrice ? (
          <Card.Text className="product-label">Rs: {unitPrice}</Card.Text>
        ) : null}
      </Card.Body>
    </Card>
  );
};
export default SingleCard;
