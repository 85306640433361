import { Dispatch } from "redux";
import { useSelector } from "react-redux";
import { WorkRequest, WorkRequestCreate } from "../type";
import {
  setWorkRequests,
  setWorkRequestsError,
  setWorkRequestsLoading,
  addWorkRequestState,
  assignTechnicianState,
  unAssignTechnicianState,
  deleteWorkRequestState,
  updateWorkRequestState,
  setWorkRequestsAssignLoading,
  statusUpdated,
} from "../features/workRequestSlice";
import {
  fetchWorkRequests,
  createWorkRequestApi,
  assignTechnicianToWorkRequest,
  unAssignTechnicianToWorkRequest,
  deleteWorkRequestAPI,
  fetchWorkRequestById,
  updateWorkRequestAPI,
  updateWorkRequestStatusAPI,
  updateWorkRequestRatingForConsumerAPI,
  fetchWorkRequestHistory,
} from "../api/workRequestAPI";
import { convertToImageFile } from "../utils/utils";
import { createProductInquiry } from "../api/productInquiriesAPI";
import {
  setProductInquiriesError,
  setSendInqury,
  unSetSelectedProduts,
} from "../features/productInquiriesSlice";
import {
  setSingleWorkRequestFailure,
  setSingleWorkRequestHistory,
  setSingleWorkRequestState,
  setSingleWorkRequestSuccess,
} from "../features/singleWorkRequestSlice";
import {
  editConsumerState,
  setConsumersError,
  setConsumersLoading,
} from "../features/consumerSlice";

export const getWorkRequests = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setWorkRequestsLoading(true));
      const workRequests = await fetchWorkRequests();
      dispatch(setWorkRequestsLoading(false));
      dispatch(setWorkRequests(workRequests));
    } catch (error) {
      console.error("Error fetching work requests", error);
      dispatch(
        setWorkRequestsError("Failed to fetch workRequests. Please try again!")
      );
    }
  };
};

export const addWorkRequest = (
  workRequestData: WorkRequestCreate,
  selectedProductsForInquiry: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setWorkRequestsLoading(true));
      const newWorkRequest = await createWorkRequestApi(workRequestData);
      dispatch(setWorkRequestsLoading(false));
      if (newWorkRequest.errorCode) {
        dispatch(setWorkRequestsError(newWorkRequest.errorDescription));
      } else {
        dispatch(addWorkRequestState(newWorkRequest));
        if (
          selectedProductsForInquiry &&
          selectedProductsForInquiry.length > 0
        ) {
          const inquryData = {
            workRequestId: newWorkRequest.id,
            inquiryEntries: selectedProductsForInquiry,
          };
          const newProductInqury = await createProductInquiry(inquryData);
          if (newWorkRequest.errorCode) {
            console.log("send inqury failed");
          } else {
            console.log("send inqury success");
          }
          dispatch(unSetSelectedProduts(true));
        }
      }
    } catch (error) {
      console.error("Error creating work request:", error);
      dispatch(
        setWorkRequestsError("Failed to create work request. Please try again!")
      );
    }
  };
};

export const createProductInquiryForWorkRequest =  (
  workRequestId: string,
  selectedProducts: any,
  isChecked: boolean,
) => {
  return async (dispatch: Dispatch) => {
    try {
      const inquryData = {
        workRequestId: workRequestId,
        inquiryEntries: selectedProducts,
        referWorkRequestAssignedTechnician: isChecked,
      };
      const newProductInquiry = await createProductInquiry(inquryData);
      dispatch(unSetSelectedProduts(true));
      if (newProductInquiry.errorCode) {
        console.log("Send inquiry failed error",newProductInquiry.errorDescription);
        dispatch(setProductInquiriesError(newProductInquiry.errorDescription));
        console.log("Send inquiry failed",inquryData);
      } else {
        dispatch(setSendInqury(true));
        console.log("Send inquiry success", inquryData);
      }
      
    } catch (error) {
      console.error("Error creating product inquiry:", error);
    }
  };
};

export const assignTechnician = (
  technicianID: string,
  workRequestID: string
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setWorkRequestsAssignLoading(true));
      const updatedWorkRequest = await assignTechnicianToWorkRequest(
        workRequestID,
        technicianID
      );
      dispatch(setWorkRequestsAssignLoading(false));
      if (updatedWorkRequest.errorCode) {
        dispatch(setWorkRequestsError(updatedWorkRequest.errorDescription));
      } else {
        dispatch(
          assignTechnicianState({ id: workRequestID, updatedWorkRequest })
        );
      }
    } catch (error) {
      console.error("Error assign technician:", error);
      dispatch(
        setWorkRequestsError(
          "Failed to assign technician to work request. Please try again!"
        )
      );
    }
  };
};

export const unAssignTechnician = (workRequestID: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setWorkRequestsAssignLoading(true));
      const updatedWorkRequest = await unAssignTechnicianToWorkRequest(
        workRequestID
      );
      dispatch(setWorkRequestsAssignLoading(false));
      if (updatedWorkRequest.errorCode) {
        dispatch(setWorkRequestsError(updatedWorkRequest.errorDescription));
      } else {
        dispatch(
          unAssignTechnicianState({ id: workRequestID, updatedWorkRequest })
        );
      }
    } catch (error) {
      console.error("Error Unassign technician:", error);
      dispatch(
        setWorkRequestsError(
          "Failed to unassign technician from work request. Please try again!"
        )
      );
    }
  };
};

export const deleteWorkRequest = (
  workRequestId: string,
  consumerId: string
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setWorkRequestsLoading(true));
      const deletedWorkRequest = await deleteWorkRequestAPI(
        workRequestId,
        consumerId
      );
      dispatch(setWorkRequestsLoading(false));

      if (deletedWorkRequest.errorCode) {
        dispatch(setWorkRequestsError(deletedWorkRequest.errorDescription));
      } else {
        dispatch(deleteWorkRequestState(workRequestId));
      }
    } catch (error) {
      console.error("Error deleting work request:", error);
      dispatch(
        setWorkRequestsError("Failed to delete work request. Please try again!")
      );
    }
  };
};

//get one work request by id
export const getWorkRequestById = (workRequestId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setSingleWorkRequestState(true)); // Set loading state for single work request
      const workRequest = await fetchWorkRequestById(workRequestId);
      dispatch(setSingleWorkRequestState(false));
      dispatch(setSingleWorkRequestSuccess(workRequest)); // Dispatch action with fetched work request
    } catch (error) {
      console.error("Error fetching work request by ID", error);
      dispatch(
        setSingleWorkRequestFailure(
          "Failed to fetch work request by ID. Please try again!"
        ) // Set error state
      );
    }
  };
};
export const updateWorkRequest = (
  workRequestId: string,
  updatedWorkRequestData: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setWorkRequestsLoading(true));

      const updatedWorkRequest = await updateWorkRequestAPI(
        workRequestId,
        updatedWorkRequestData
      );

      dispatch(setWorkRequestsLoading(false));

      if (updatedWorkRequest.errorCode) {
        dispatch(setWorkRequestsError(updatedWorkRequest.errorDescription));
      } else {
        dispatch(
          updateWorkRequestState({
            id: workRequestId,
            updateWorkRequest: updatedWorkRequest,
          })
        );
      }
    } catch (error) {
      console.error("Error updating work request:", error);
      dispatch(
        setWorkRequestsError("Failed to update work request. Please try again!")
      );
    }
  };
};

export const updateWorkRequestStatusService = (
  technicianID: string,
  workRequestId: string,
  workRequestData: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setWorkRequestsLoading(true));
      const updatedWorkRequest = await updateWorkRequestStatusAPI(
        workRequestId,
        technicianID,
        workRequestData
      );

      dispatch(setWorkRequestsLoading(false));
      if (updatedWorkRequest.errorCode) {
        dispatch(setWorkRequestsError(updatedWorkRequest.errorDescription));
      } else {
        dispatch(statusUpdated());
      }
    } catch (error) {
      console.error("Error update workrequest status by technician:", error);
      dispatch(
        setWorkRequestsError(
          "Failed to update workrequest status. Please try again!"
        )
      );
    }
  };
};

export const updateWorkRequestRatingForConsumerService = (
  consumerId: string,
  workRequestId: string,
  ratingData: any
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setConsumersLoading(true));
      const editedConsumer = await updateWorkRequestRatingForConsumerAPI(
        workRequestId,
        consumerId,
        ratingData
      );
      dispatch(setConsumersLoading(false));
      if (editedConsumer.errorCode) {
        dispatch(setConsumersError(editedConsumer.errorDescription));
      } else {
        dispatch(
          editConsumerState({
            consumerId: consumerId,
            updatedConsumer: editedConsumer,
          })
        );
      }
    } catch (error) {
      console.error("Error consumer rating by technician:", error);
      dispatch(
        setConsumersError("Failed to consumer rating. Please try again!")
      );
    }
  };
};

export const getWorkRequestHistoryById = (workRequestId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(setSingleWorkRequestState(true));
      const workRequest = await fetchWorkRequestHistory(workRequestId);
      dispatch(setSingleWorkRequestState(false));
      if (workRequest.errorCode) {
        dispatch(setSingleWorkRequestFailure(workRequest.errorDescription));
      } else {
        dispatch(setSingleWorkRequestHistory(workRequest.results));
      }
    } catch (error) {
      console.error("Error fetching work request history by ID", error);
      dispatch(
        setSingleWorkRequestFailure(
          "Failed to fetch work request history by ID. Please try again!"
        )
      );
    }
  };
};
