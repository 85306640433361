
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ForgotPasswordEmailVerify, ResetPassword } from "../type";

interface ForgotPasswordState {
    forgotPassword: ForgotPasswordEmailVerify[];
    loading: boolean;
    error: string | null;
    success: boolean;
    resetPassword: ResetPassword[];

}

const initialState: ForgotPasswordState = {
    forgotPassword: [],
    loading: false,
    error: null,
    success: false,
    resetPassword: []

};

const ForgotPasswordSlice = createSlice({
    name: 'forgotPassword',
    initialState,
    reducers: {

        //email verification
        setEmailVeifycation(state, action: PayloadAction<ForgotPasswordEmailVerify[]>) {
            state.loading = false;
            state.error = null;
            state.success = false;
            state.forgotPassword = action.payload;
        },

        setEmailVeifycationLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
            state.error = null;
            state.success = false;
        },
        setEmailVeifycationError(state, action: PayloadAction<string | null>) {
            state.loading = false;
            state.success = false;
            state.error = action.payload;
        },
        addEmailVeifycationState(state, action: PayloadAction<ForgotPasswordEmailVerify>) {
            state.forgotPassword.push(action.payload);
            state.loading = false;
            state.error = null;
            state.success = true;
        },

        //rest password
        setResetPassword(state, action: PayloadAction<ResetPassword[]>) {
            state.loading = false;
            state.error = null;
            state.success = false;
            state.resetPassword = action.payload;
        },

        setResetPasswordLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
            state.error = null;
            state.success = false;
        },
        setResetPasswordError(state, action: PayloadAction<string | null>) {
            state.loading = false;
            state.success = false;
            state.error = action.payload;
        },
        editResetPasswordState(state, action: PayloadAction<boolean>) {
            state.loading = false;
            state.error = null;
            state.success = true;
        },
    },
});
export const {
    setEmailVeifycation,
    setEmailVeifycationLoading,
    setEmailVeifycationError,
    addEmailVeifycationState,
    setResetPassword,
    setResetPasswordLoading,
    setResetPasswordError,
    editResetPasswordState
} = ForgotPasswordSlice.actions;
export default ForgotPasswordSlice.reducer;
